<form [formGroup]="form" style="max-width: 100%!important;" autocomplete="false" (ngSubmit)="onSave()">
    <div class="grid fields-group">
        <div class="col-12">
            <div class="grid p-fluid">
                <div class="field col-12 md:col-12">
                    <app-stt-input inputId="name" [control]="form.controls['name']"
                                   icon="pi pi-users" type="text"
                                   label="Denumire"></app-stt-input>
                </div>
            </div>

        </div>
    </div>
    <p-progressBar mode="indeterminate" class="addEditProgressBar" *ngIf="loading"></p-progressBar>

    <div class="actions-area">
        <div class="actions-left">
            <button pButton pRipple type="button" label="Anulare" class="p-button-raised p-button-danger"
                    icon="pi pi-ban" [disabled]="loading" (click)="onCancel()">
            </button>
        </div>

        <div class="actions-right">
            <button pButton pRipple type="submit" label="Salveaza" class="p-button-raised p-button-success"
                    icon="pi pi-save" [disabled]="loading">
            </button>
        </div>
    </div>
</form>


