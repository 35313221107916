import { BaseModel } from './base.model';
import { OrderCustomerTransportProductsModel } from './order-customer-transport-products.model';

export class OrderCustomerTransportModel extends BaseModel{
    id: number;
    uid: string;
    fnc_id: number;
    products: Array<OrderCustomerTransportProductsModel> = [];

}
