import { Component, OnInit } from '@angular/core';
import { SttTableComponent } from '../../../../shared/components/stt-table/stt-table.component';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Router } from '@angular/router';
import { ReceptionNoteModel } from '../../../../shared/entities/reception-note.model';
import { ConfirmationService, MessageService, PrimeNGConfig } from 'primeng/api';
import { ValidationMessageService } from '../../../../shared/services/base/validation-message-handle';
import { ReceptionNoteService } from '../../../../shared/services/app/reception-note.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-reception-note-action-table',
  templateUrl: './reception-note-action-table.component.html',
  styleUrls: ['./reception-note-action-table.component.scss']
})
export class ReceptionNoteActionTableComponent implements OnInit {

    public data: ReceptionNoteModel;
    public table: SttTableComponent;
    public ref: DynamicDialogRef;
    public loading = true;

    constructor(
        public dialogService: DialogService,
        public router: Router,
        public confirmationService: ConfirmationService,
        public primengConfig: PrimeNGConfig,
        public itemService: ReceptionNoteService,
        public messageService: MessageService,
        private validationMessageService: ValidationMessageService,
    ) {

    }

    ngOnInit(): void {
    }

    setParams(entity: ReceptionNoteModel): void {
        this.data = entity;
    }

    setTableReference(table: SttTableComponent): void
    {
        this.table = table;
    }

    showDialog(){
        this.router.navigate(
            ['stock-management/reception-note/add-edit'],
            { queryParams: { uid: this.data.uid } }
        );
    }

    confirm() {
        this.confirmationService.confirm({
            header: 'Confirmare stergere Nota de receptie',
            message: 'Sunteti sigur ca doriti sa stergeti aceasta Nota de receptie?',
            key: 'deleteReceptionNote',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Da',
            rejectLabel: 'Nu',
            acceptButtonStyleClass: 'p-button-md p-button-success',
            rejectButtonStyleClass: 'p-button-md p-button-danger',
            accept: () => {
                this.deleteItem(this.data.uid);
            },
            reject: () => {
                this.confirmationService.close();
                this.table.refresh();
            }
        });
    }

    deleteItem(uid: string){
        this.itemService.delete(uid)
            .pipe(finalize(() => {this.loading = false;this.table.refresh();}))
            .subscribe((response: any) => {
                this.messageService.add({
                    severity: 'success',
                    summary: 'Nota de receptie a fost stearsa cu success!'
                });
            });
    }

}
