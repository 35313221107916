import { Component, OnInit } from '@angular/core';
import { TransportUnitTModel } from '../../../../../shared/entities/transport-unit.model';
import { AbstractControl, UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { HttpApiService } from '../../../../../shared/services/base/http-api.service';
import { ValidationMessageService } from '../../../../../shared/services/base/validation-message-handle';
import { MessageService } from 'primeng/api';
import { finalize } from 'rxjs/operators';
import { TransportUnitService } from '../../../../../shared/services/app/transport-unit.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'app-transport-unit-add-edit',
    templateUrl: './transport-unit-add-edit.component.html',
    styleUrls: ['./transport-unit-add-edit.component.scss']
})
export class TransportUnitAddEditComponent implements OnInit {

    public uid: string = null;
    public companyId: number = null;
    public transportUnitModel: TransportUnitTModel = new TransportUnitTModel();
    public loading = false;
    public form: UntypedFormGroup;
    public dropDownWithTrashed = false;
    public readonly = false;

    constructor(
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private formBuilder: UntypedFormBuilder,
        private httpApiService: HttpApiService,
        private validationMessageService: ValidationMessageService,
        private transportUnitService: TransportUnitService,
        private messageService: MessageService
    ) {
    }


    ngOnInit(): void {

        this.uid = (this.config.data) ? this.config.data.uid : null;
        this.companyId = (this.config.data) ? this.config.data.companyId : null;

        this.createForm();
        if (this.uid) {
            this.getItem();
            this.form.controls.transportTrailRegistrationNumber.patchValue({
                disabled: !this.transportUnitModel.hasTrail
            });
        }
        return;
    }

    createForm(): void {
        const companyId = (this.companyId) ? this.companyId : this.transportUnitModel.transport_company_id;

        this.form = this.formBuilder.group({
            uid: [this.transportUnitModel.uid],
            hasTrail: [this.transportUnitModel.hasTrail, Validators.required],
            transport_company_id: [{ value: companyId, disabled: true }, Validators.required],
            transport_unit_type_id: [this.transportUnitModel.transport_unit_type_id, Validators.required],
            transport_unit_driver_id: [this.transportUnitModel.transport_unit_driver_id, Validators.required],
            transport_unit_brand_id: [this.transportUnitModel.transport_unit_brand_id],
            transportRegistrationNumber: [this.transportUnitModel.transportRegistrationNumber,
                [Validators.required, Validators.maxLength(20)]],
            transportTrailRegistrationNumber: [this.transportUnitModel.transportTrailRegistrationNumber,
                [Validators.required,Validators.maxLength(20)]],
        });
    }

    disableField(): void {
        if(this.form.value.hasTrail === false || this.form.value.hasTrail === 0){
            this.form.patchValue({
                transportTrailRegistrationNumber: null
            });
            this.form.get('transportTrailRegistrationNumber').clearValidators();
            this.form.get('transportTrailRegistrationNumber').updateValueAndValidity();
            this.readonly = true;
        }
        else{
            this.form.get('transportTrailRegistrationNumber').setValidators(Validators.required);
            this.readonly = false;
        }
    }

    getItem(): void {
        this.loading = true;
        this.dropDownWithTrashed = true;
        this.transportUnitService.get(this.uid)
            .pipe(finalize(() => {this.loading = false; this.dropDownWithTrashed = false;}))
            .subscribe((response: any) => {
                this.transportUnitModel.map(response.payload);
                this.form.patchValue(this.transportUnitModel);
            });
    }

    onCancel(): void {
        this.ref.close(null);
    }

    checkIfFormValid(): boolean {
        if (this.form.invalid) {
            this.messageService.add({
                severity: 'error',
                summary: 'Formularul nu este valid!',
                detail: 'Vericficati toate campurile si incercati din nou.'
            });
            this.form.markAllAsTouched();
            return false;
        }
        return true;
    }

    onSave(): void {
        if (!this.checkIfFormValid()) {
            return;
        }
        this.loading = true;
        const data = this.form.getRawValue();

        this.transportUnitService.set(data)
            .pipe(finalize(() => this.loading = false))
            .subscribe((response: any) => {
                this.transportUnitModel.map(response.payload);
                this.form.patchValue(this.transportUnitModel);
                this.messageService.add({
                    severity: 'success',
                    summary: 'Unitatea de transport a fost salvat cu success!'
                });
                this.ref.close(this.transportUnitModel);
            }, err => {
                if (err instanceof HttpErrorResponse) {
                    this.validationMessageService.serverSideValidation(err, this.form, '', true);
                }
            });
    }

}
