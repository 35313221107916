
<div class="flex-box" style="  display:flex;justify-content:space-between;">
    <p style="font-size: 20px">Gestiune / Stoc / Materii prime</p>
</div>
<p-divider></p-divider>
<div class="flex-box" style="display: flex; justify-content: space-between">
    <app-stock>

    </app-stock>
</div>
<p-divider></p-divider>

<div class="grid">
    <div class="col-12">
        <div class="card">
            <app-stt-table #table
                           [requiresFNC]="true"
                           [endpoint]="tableEndpoint"
                           [tableStyle]="tableStyle"
                           [query]="tableQuery">
            </app-stt-table>
        </div>
    </div>
</div>

