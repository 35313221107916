<div class="actions-area">
    <button (click)="addFormItem()" class="p-button-raised p-button-success text-right" icon="pi pi-plus"
            label="Adauga adresa" pButton
            pRipple type="button"></button>
</div>
<div [formGroup]="formGroup" class="grid fields-group">
    <ng-container formArrayName="contacts">
        <ng-container *ngFor="let contactForm of contactsFormArray.controls; let i = index">
            <div class="col-12 according-wrapper">
                <p-accordion>
                    <p-accordionTab [class.isDeleted]="contactForm.controls.needDelete.value"
                                    [formGroup]="contactForm"
                                    [header]="'Contact: ' + (contactForm.controls.firstName.value) ? contactForm.controls.firstName.value : ' - '"
                                    [selected]="!contactForm.controls.uid.value">
                        <div *ngIf="!contactForm.controls.needDelete.value" class="col-12">

                            <div class="grid p-fluid">
                                <div class="field col-12 md:col-6">
                                    <app-stt-input inputId="lastName" [control]="contactForm.controls['lastName']"
                                                   icon="pi pi-user"
                                                   label="Nume"></app-stt-input>
                                </div>
                                <div class="field col-12 md:col-6">
                                    <app-stt-input inputId="firstName" [control]="contactForm.controls['firstName']"
                                                   icon="pi pi-user"
                                                   label="Prenume"></app-stt-input>
                                </div>
                            </div>

                            <div class="grid p-fluid">
                                <div class="field col-12 md:col-6">
                                    <app-stt-input inputId="email" [control]="contactForm.controls['email']"
                                                   icon="pi pi-envelope"  type="email"
                                                   label="Email"></app-stt-input>
                                </div>
                                <div class="field col-12 md:col-6">
                                    <app-stt-input inputId="phone" [control]="contactForm.controls['phone']"
                                                   icon="pi pi-phone"
                                                   label="Telefon"></app-stt-input>
                                </div>
                            </div>

                            <div class="grid p-fluid">
                                <div class="field col-12 md:col-6">
                                    <app-stt-input inputId="position" [control]="contactForm.controls['position']"
                                                   icon="pi pi-info"
                                                   label="Functie in companie"></app-stt-input>
                                </div>
                                <div class="field col-12 md:col-6">
                                    <app-stt-dropdown endPoint="users" label="Utilizator asociat"
                                                      placeholder="Nu ati selectat utilizatorul!"
                                                       icon="pi pi-user"
                                                      [formControlParent]="contactForm.controls['user_id']"
                                                      [fields]="{ text: 'firstName', value: 'id' }">
                                    </app-stt-dropdown>
                                </div>
                            </div>

                            <div class="actions-area">
                                <div class="actions-left">
                                    <button (click)="removeFormItem(i)" class="p-button-raised p-button-danger"
                                            icon="pi pi-trash" label="Sterge contact"
                                            pButton pRipple
                                            type="button">
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="contactForm.controls.needDelete.value" class="col-12">
                            <p>Contactul va fi sters dupa apasarea butonului "Salveaza"</p>
                        </div>
                    </p-accordionTab>
                </p-accordion>
            </div>
        </ng-container>
    </ng-container>
</div>

<h3 *ngIf="contactsFormArray.length === 0" class="text-center"> Nu sunt adrese in sistem pentru acest client </h3>
