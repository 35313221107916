<a routerLink="/orders/customer/list" style="color: #808080;font-size: 18px">
    <i class="fas fa-arrow-left"></i>
    <span>Inapoi la lista de comenzi</span>
</a>
<!--<!<a routerLink="/orders/customer/list" style="color: #808080;font-size: 18px"> this is interesting worth a talk!!!!!!!!!!!!!-->
<p-divider></p-divider>
<div class="flex-box" style="  display:flex;justify-content:space-between;">
    <p style="font-size: 20px">Comenzi  /  Client  /  Vizualizare Transport</p>
</div>
<p-divider></p-divider>

<div class="grid">
    <div class="col-12">
        <div class="card">
            <app-stt-table #table
                           [requiresFNC]="true"
                           [endpoint]="tableEndpoint"
                           [actionTemplate]="tableActionComponent"
                           [query]="tableQuery">
            </app-stt-table>
        </div>
    </div>
</div>
