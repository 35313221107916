import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    Inject,
    Injector,
    Input,
    OnInit,
    ViewChild, ViewContainerRef
} from '@angular/core';

@Component({
  selector: 'app-stt-table-raw-expand',
  templateUrl: './stt-table-raw-expand.component.html',
  styleUrls: ['./stt-table-raw-expand.component.scss']
})
export class SttTableRawExpandComponent implements OnInit, AfterViewInit {

    @Input() template: any;
    @Input() data: any;
    @Input() table: any;

    @ViewChild('containerSlot', {static: false, read: ViewContainerRef }) containerSlot: ViewContainerRef;

    constructor(
      @Inject(ChangeDetectorRef) private cdr: ChangeDetectorRef,
      private componentResolver: ComponentFactoryResolver,
      private injector: Injector,
    ) { }


    ngOnInit(): void {}

    ngAfterViewInit(): void {
        setTimeout(() => {
            const factory = this.componentResolver.resolveComponentFactory(this.template);
            const componentRef: any = this.containerSlot.createComponent(factory);
            componentRef.instance.setParams(this.data, this.table);
        }, 50);
    }

}
