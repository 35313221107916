import { Component, OnInit, ViewChild } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SttTableComponent } from '../../../../shared/components/stt-table/stt-table.component';
import { SttTableService } from '../../../../shared/components/stt-table/stt-table-service';
import {
    TransportCompanyTypesActionTableComponent
} from './transport-company-types-action-table/transport-company-types-action-table.component';
import {
    TransportCompanyTypesAddEditComponent
} from './transport-company-types-add-edit/transport-company-types-add-edit.component';
import { TransportCompanyTypesModel } from 'src/app/shared/entities/transport-company-type.model';

@Component({
    selector: 'app-transport-company-types',
    templateUrl: './transport-company-types.component.html',
    styleUrls: ['./transport-company-types.component.scss']
})
export class TransportCompanyTypesComponent implements OnInit {

    @ViewChild('table', { static: false }) table: SttTableComponent;

    public tableEndpoint = 'transport-company-types';
    public tableActionComponent = TransportCompanyTypesActionTableComponent;
    public tableQuery: any;
    public ref: DynamicDialogRef;

    constructor(public dialogService: DialogService) {
        this.tableQuery = new SttTableService();
    }

    ngOnInit(): void {

    }

    addNewEntity() {
        this.ref = this.dialogService.open(TransportCompanyTypesAddEditComponent, {
            header: 'Adaugare Tip companie transport',
            width: '30%',
            contentStyle: { 'max-height': '70vh' },
            baseZIndex: 10000,
            closeOnEscape: false,
            data: null,
        });

        this.ref.onClose.subscribe((transport_company_type: TransportCompanyTypesModel) => {
            if (transport_company_type) {
                this.table.refresh();
            }
        });
    }
}
