import { BaseNomenclatorModel } from './base.model';
import { DeliveryNoteLotModel } from './delivery-note-lot.model';
import { Validators } from "@angular/forms";

export class DeliveryNoteModel extends BaseNomenclatorModel {
    uid: string;
    customer_id: number;
    code: string;
    customer_address_id: number;
    documentNo: string;
    description: string;
    product_category_id: number;
    product_id: number;
    deliveryDate: Date;
    transport_company_id: number;
    transport_unit_type_id: number;
    transport_unit_driver_id: number;
    customer_order_item_id: number;
    units: number;
    gross_quantity: number;
    tar_quantity: number;
    fnc_id: number;
    items: Array<DeliveryNoteLotModel>;
}
