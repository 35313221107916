import {BaseModel} from './base.model';
import {UserModel} from '../../auth/models/user.model';
import {CityModel} from './city.model';

export class CountyModel extends BaseModel {

    id: number;
    name: string;
    code: string;
    cities: Array<CityModel>;
}
