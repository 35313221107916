import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpApiService} from '../base/http-api.service';
import {ApiResponseModel} from '../../entities/api-response.model';
import {TransportUnitTModel} from '../../entities/transport-unit.model';

@Injectable({
    providedIn: 'root'
})
export class TransportUnitService {

    constructor(
        public httpApiService: HttpApiService,
    ) {

    }

    public get(uid): Observable<ApiResponseModel> {
        return this.httpApiService.httpGet('transport-units/view', {uid});
    }

    public set(item: TransportUnitTModel): Observable<ApiResponseModel> {
        return this.httpApiService.httpPost('transport-units/store', item);
    }

    public delete(uid): Observable<ApiResponseModel> {
        return this.httpApiService.httpDelete('transport-units/destroy', { uid });
    }
}
