<form [formGroup]="form" style="max-width: 100%!important;" autocomplete="false" (ngSubmit)="onSave()">
    <div class="grid fields-group">
        <div class="col-12">

            <div class="grid p-fluid">
                <div class="field col-12 md:col-6">
                    <app-stt-dropdown endPoint="transport-companies/dropdown" label="Companie"
                                      icon="pi pi-building"
                                      [formControlParent]="form.controls['transport_company_id']"></app-stt-dropdown>
                </div>
                <div class="field col-12 md:col-6">
                    <app-stt-dropdown endPoint="transport-unit-drivers/dropdown" label="Sofer"
                                      placeholder="Nu ati selectat un sofer!"
                                      icon="pi pi-user"
                                      [fields]=" { text: 'firstName', value: 'id'}"
                                      [formControlParent]="form.controls['transport_unit_driver_id']"
                                      [dropDownWithTrashed]="dropDownWithTrashed"></app-stt-dropdown>
                </div>
            </div>

            <div class="grid p-fluid">
                <div class="field col-12 md:col-6">
                    <app-stt-dropdown endPoint="transport-unit-types/dropdown" label="Tip unitate"
                                       icon="pi pi-th-large"
                                      [formControlParent]="form.controls['transport_unit_type_id']"
                                      placeholder="Nu ati selectat tipul unitatii!"
                                      [dropDownWithTrashed]="dropDownWithTrashed"></app-stt-dropdown>
                </div>
                <div class="field col-12 md:col-6">
                    <app-stt-dropdown endPoint="transport-unit-brands" label="Brand vehicul"
                                      icon="pi pi-car"
                                      [formControlParent]="form.controls['transport_unit_brand_id']"
                                      placeholder="Nu ati selectat brand-ul vehiculului!"
                                      [dropDownWithTrashed]="dropDownWithTrashed"></app-stt-dropdown>
                </div>
            </div>

            <div class="grid p-fluid">
                <div class="field col-12 md:col-12">
                    <p-toggleButton formControlName="hasTrail"
                                    onLabel="Cu remorca" offLabel="Fara remorca"
                                    onIcon="pi pi-check" offIcon="pi pi-times"
                                    (click)="disableField()"
                                    [ngModel]="form.get('hasTrail').value">
                    </p-toggleButton>
                    <app-stt-validator-message [control]="form.controls['hasTrail']"></app-stt-validator-message>
                </div>
            </div>

            <div class="grid p-fluid">
                <div class="field col-12 md:col-6">
                    <app-stt-input inputId="transportRegistrationNumber"
                                   [control]="form.controls['transportRegistrationNumber']"
                                   icon="pi pi-car" type="text"
                                   label="Nr. Inregistrare"></app-stt-input>
                </div>
                <div class="field col-12 md:col-6">
                    <app-stt-input inputId="transportTrailRegistrationNumber"
                                   [control]="form.controls['transportTrailRegistrationNumber']"
                                   icon="pi pi-car" type="text" [readonly]="readonly"
                                   label="Nr. Inregistrare remorca"></app-stt-input>
                </div>
            </div>

        </div>
    </div>
    <p-progressBar mode="indeterminate" class="addEditProgressBar" *ngIf="loading"></p-progressBar>

    <div class="actions-area">
        <div class="actions-left">
            <button pButton pRipple type="button" label="Anulare" class="p-button-raised p-button-danger"
                    icon="pi pi-ban" [disabled]="loading" (click)="onCancel()">
            </button>
        </div>

        <div class="actions-right">
            <button pButton pRipple type="submit" label="Salveaza" class="p-button-raised p-button-success"
                    icon="pi pi-save" [disabled]="loading">
            </button>
        </div>
    </div>
</form>
