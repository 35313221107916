import { BaseNomenclatorModel } from './base.model';
import {CountyModel} from './county.model';
import {CityModel} from './city.model';
import {TransportCompanyTypesModel} from './transport-company-type.model';

export class TransportCompanyModel extends BaseNomenclatorModel {
    id: number;
    name: string;
    uid: string;
    code: string;
    uniqueCode: string;
    registrationNumber: string;
    phone: string;
    address: string;
    email: string;
    county: CountyModel = new CountyModel();
    city: CityModel = new CityModel();
    city_id: number;
    county_id: number;
    type: TransportCompanyTypesModel;
    type_id: number;
}
