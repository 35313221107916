<script src="../../../../app.component.ts"></script><a routerLink="/stock-management/reception-note/list"
                                                       style="color: #808080;font-size: 18px">
    <i class="fas fa-arrow-left"></i>
    <span>Inapoi la notele de receptie</span>
</a>
<br>
<br>
<form [formGroup]="form" autocomplete="false" (ngSubmit)="onSave()">
    <p-card>
        <div class="grid fields-group" style="padding: 0 1em">
            <div class="col-12">
                <div class="grid p-fluid">
                    <div class="field col-12 md:col-3">
                        <app-stt-dropdown endPoint="suppliers/dropdown"
                                          [formControlParent]="form.controls['supplier_id']"
                                          icon="pi pi-user"
                                          [readonly]="(itemUID && orderUID) || readOnly"
                                          placeholder="Nu ati selectat un furnizor!"
                                          label="Furnizor"
                                          [dropDownWithTrashed]="dropDownWithTrashed"></app-stt-dropdown>
                    </div>
                    <div class="field col-12 md:col-3">
                        <app-stt-dropdown
                            endPoint="suppliers/getAddresses" [readonly]="readOnly"
                            [fields]="{ text: 'address', value: 'id'}" icon="pi pi-home"
                            [formControlParent]="form.controls['supplier_address_id']"
                            formControlNameDependency="supplier_id"
                            placeholder="Nu ati selectat o adresa!"
                            label="Adresa de colectare">
                        </app-stt-dropdown>
                    </div>
                    <div class="field col-12 md:col-3">
                        <app-stt-dropdown endPoint="fncs/dropdown" [formControlParent]="form.controls['fnc_id']" label="Fnc"
                                          [readonly]="(itemUID && orderUID) || readOnly" icon="pi pi-cog"
                                          placeholder="Nu ati selectat un fnc!"></app-stt-dropdown>
                    </div>
                    <div class="field col-12 md:col-3">
                        <app-stt-input inputId="code" [control]="form.controls['code']"
                                       icon="pi pi-key" [readonly]="readOnly"
                                       label="Cod SAP"></app-stt-input>
                    </div>
                </div>
                <div class="grid p-fluid">
                    <div class="field col-12 md:col-4">
                        <app-stt-input inputId="documentNo" [control]="form.controls['documentNo']"
                                       icon="pi pi-paperclip"
                                       [readonly]="readOnly"
                                       label="Numar Document"></app-stt-input>
                        <br>
                        <app-stt-calendar label="Data" icon="pi pi-calendar" [minimumDate]="minimumDate" [showCalendar]="showCalendar"
                                          [control]="form.controls['receptionDate']"></app-stt-calendar>
                    </div>
                    <div class="field col-12 md:col-8" style="border-left: 1px solid lightgray">
                        <app-stt-textarea inputId="description" [control]="form.controls['description']"
                                          icon="pi pi-pencil" [readOnly]="readOnly"
                                          label="Descriere"></app-stt-textarea>
                    </div>
                </div>
                <br>
                <br>
                <div class="grid p-fluid">
                    <div class="col-12 md:col-3">
                        <app-stt-input inputId="grossQuantity" [control]="form.controls['gross_quantity']"
                                       icon="pi pi-list" type="number" [readonly]="readOnly"
                                       label="Cantitate Bruta"></app-stt-input>
                        <br>
                        <br>
                        <app-stt-input inputId="transportWeight" [control]="form.controls['tar_quantity']"
                                       icon="pi pi-list" type="number" [readonly]="readOnly"
                                       label="Cantitate Tara"></app-stt-input>
                        <br>
                        <br>
                        <app-stt-input inputId="netQuantity" [control]="form.controls['net_quantity']"
                                       icon="pi pi-list" type="number" [readonly]="true"
                                       label="Cantitate Neta"></app-stt-input>
                    </div>
                    <div class="col-12 md:col-7" style="border-left: 1px solid lightgray">
<!--                        -->
<!--                        <div class="grid p-fluid">-->
<!--                            <div class="col-6">-->
<!--                                <app-stt-dropdown endPoint="transport-companies" (changeEvent)="onCompanyChange($event)"-->
<!--                                                  placeholder="Nu ati selectat o companie!"-->
<!--                                                  [formControlParent]="form.controls['transport_company_id']"-->
<!--                                                  label="Companie Transport"-->
<!--                                                  icon="pi pi-car"></app-stt-dropdown>-->
<!--                            </div>-->
<!--                            <div class="col-6">-->
<!--                                <app-stt-dropdown endPoint="transport-unit-types/dropdown"-->
<!--                                                  [formControlParent]="form.controls['transport_unit_type_id']"-->
<!--                                                  label="Tip transport" icon="pi pi-th-large" [readonly]="readOnly"-->
<!--                                                  placeholder="Nu ati selectat un tip de transport"-->
<!--                                                  [dropDownWithTrashed]="dropDownWithTrashed"></app-stt-dropdown>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <br>-->
<!--                        <br>-->
<!--                        -->
                        <p-button [label]="selectedUnit ? selectedUnitDisplayName : 'Selectati unitatea de transport'" icon="pi pi-search" (click)="op.toggle($event)"></p-button>

                        <p-overlayPanel #op [showCloseIcon]="true" [style]="{width: '750px'}">
                            <app-stt-table #table
                                           [endpoint]="tableEndpoint" [enableSelection]="true" (onRowSelectEvent)="rowSelected($event)"
                                           [query]="tableQuery">
                            </app-stt-table>
                        </p-overlayPanel>

                        <br>
                        <p style="color: red">Daca masina de transport nu este cunscuta in nomenclatoare se pot trece detaliile transportului in sectiunea de detalii</p>
                        <br>
                        <app-stt-textarea inputId="description" [control]="form.controls['transport_details']"
                                          icon="pi pi-pencil" [readOnly]="readOnly"
                                          label="Detalii transport"></app-stt-textarea>
                    </div>
                </div>
                <br>
                <div class="grid fields-group" style="border-top: 1px solid lightgray; padding-top: 2em">
                    <div class="col-12 grid" style="flex-wrap: nowrap; gap: 2rem;">

                        <div class="field col-5">
                            <app-stt-dropdown endPoint="raw-materials/dropdown"
                                              placeholder="Nu ati selectat materia prima!"
                                              [readonly]="(itemUID && orderUID) || readOnly"
                                              [formControlParent]="form.controls['raw_material_id']"
                                              label="Materia prima"
                                              [dropDownWithTrashed]="dropDownWithTrashed">
                            </app-stt-dropdown>
                        </div>
                        <div class="field col-2">
                            <app-stt-input inputId="lot" [control]="form.controls['lot']"
                                           icon="pi pi-list" type="string" [readonly]="readOnly"
                                           label="Lot"></app-stt-input>
                        </div>
                        <div class="field col-4">
                            <app-stt-dropdown endPoint="storage-spaces/dropdown"
                                              style="width: 100%"
                                              [formControlParent]="form.controls['storage_space_id']"
                                              formControlNameDependency="fnc_id"
                                              label="Spatiu de stocare"
                                              placeholder="Nu ati selectat stocarea!" [readonly]="readOnly"
                                              [dropDownWithTrashed]="dropDownWithTrashed"></app-stt-dropdown>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </p-card>

    <p-progressBar mode="indeterminate" class="addEditProgressBar" *ngIf="loading"></p-progressBar>

    <div class="actions-area">
        <div class="actions-left">
            <button pButton pRipple type="button" label="Anulare" class="p-button-raised p-button-danger"
                    icon="pi pi-ban" [disabled]="loading"
                    routerLink="/stock-management/reception-note/list"></button>
        </div>

        <div class="actions-right">
            <button *ngIf="!status || status === enumStatus.Draft" pButton pRipple type="submit" label="Salveaza" class="p-button-raised p-button-success"
                    icon="pi pi-save" [disabled]="loading">
            </button>
            <p-button *ngIf="status === enumStatus.Draft" [label]="'Validare'" icon="pi pi-check" iconPos="right" (click)="validateForm()" [ngStyle]="{'margin-left' : '2px'}"></p-button>
        </div>
    </div>
    <div *ngIf="form.controls['documentNo'].errors">
        {{ form.controls['documentNo'].errors.maxLength}}
    </div>
</form>
