import { BaseModel } from './base.model';
import { CountyModel } from './county.model';
import { CityModel } from './city.model';
import { CustomerContactModel } from './customer-contact.model';
import { CustomerAddressModel } from './customer-address.model';

export class CustomerModel extends BaseModel {
    id: number;
    name: string;
    uid: string;
    code: string;
    uniqueCode: string;
    registrationNumber: string;
    phone: string;
    address: string;
    email: string;
    county: CountyModel = new CountyModel();
    city: CityModel = new CityModel();
    contacts: Array<CustomerContactModel>;
    addresses: Array<CustomerAddressModel>;
}
