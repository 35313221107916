import { Component, OnInit, ViewChild } from '@angular/core';
import { SttTableService } from '../../../../shared/components/stt-table/stt-table-service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { OrderCustomerService } from '../../../../shared/services/app/order-customer.service';
import {
    OrdersCustomerTransportMapProgressBarComponent
} from './orders-customer-transport-map-progress-bar/orders-customer-transport-map-progress-bar.component';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SttTableComponent } from '../../../../shared/components/stt-table/stt-table.component';

@Component({
  selector: 'app-orders-customers-transport-map',
  templateUrl: './orders-customers-transport-map.component.html',
  styleUrls: ['./orders-customers-transport-map.component.scss']
})
export class OrdersCustomersTransportMapComponent implements OnInit {

    @ViewChild('table', { static: false }) table: SttTableComponent;

    public tableEndpoint = 'customer-order-transports/map';
    public tableQuery: SttTableService;
    public ref: DynamicDialogRef;
    public tableIsReadyToLoad = false;
    public expandComponent = OrdersCustomerTransportMapProgressBarComponent;
    public form: UntypedFormGroup;

    constructor(public dialogService: DialogService,
                public orderCustomerService: OrderCustomerService,
                private formBuilder: UntypedFormBuilder,) {
    }

    ngOnInit(): void {
        this.tableQuery = new SttTableService()
            .addInclude('customerOrder')
            .addInclude('customer')
            .addInclude('product');
            // .addInclude('customerOrderCustomer')
            // .addInclude('customerAddress');
            // .addInclude('product');

        this.createForm();
    }

    createForm(): void {
        const startDate = new Date();
        const endDate = new Date();
        if(startDate.getDay() === 1){
            startDate.setDate( startDate.getDate() + 1);
        }
        startDate.setDate(startDate.getDate() + (1 + 7 - startDate.getDay()) % 7);
        endDate.setMonth(startDate.getMonth());
        endDate.setFullYear(startDate.getFullYear());
        endDate.setDate(startDate.getDate() + 6);

        // Set time to 0
        startDate.setHours(0,0,0);
        endDate.setHours(0,0,0);


        this.form = this.formBuilder.group({
            startDate: [startDate],
            endDate: [endDate]
        });
    }

    setDateFilter(){
        this.table.refresh();
    }


}
