import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Form, UntypedFormControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { MessageService } from 'primeng/api';

@Injectable({
    providedIn: 'root'
})

export class ValidationMessageService {

    constructor(private messageService: MessageService) {
    }

    public serverSideValidation(err: HttpErrorResponse, form: UntypedFormGroup, prefix = '', showOtherErrors: boolean = true): void {

        if (err.status === 422) {

            const errors: Array<any> = err.error.payload.errors;

            Object.keys(errors).forEach(propertyName => {

                const prop = propertyName.replace(prefix, '');

                const formControl = form.get(prop);

                if (formControl) {
                    // activate the error message

                    let message = '';

                    if (Symbol.iterator in Object(errors[propertyName])) {
                        errors[propertyName].forEach((value, index) => {
                            message += value.replace(prefix, '') + '\r\n';
                        });
                    } else {
                        message = errors[propertyName].replace(prefix, '') + '\r\n';
                    }


                    formControl.setErrors({
                        serverError: message,
                    });


                } else {
                    if (showOtherErrors === true) {

                        let message = '';

                        // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
                        errors[propertyName].forEach((value, index) => {
                            message += value;
                        });

                        this.messageService.add({
                            severity: 'error',
                            summary: 'Eroare la validarea datelor',
                            detail: message
                        });
                    }
                }

            });
        }
    }

    getFormControlValidationErrors(formControl: UntypedFormControl): void {
        const controlErrors: ValidationErrors = formControl.errors;
        if (controlErrors != null) {
            Object.keys(controlErrors).forEach(keyError => {
                // console.log('keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
            });
        }
    }

    getFormValidationErrors(form: UntypedFormGroup): void {
        Object.keys(form.controls).forEach(key => {

            const controlErrors: ValidationErrors = form.get(key).errors;
            if (controlErrors != null) {
                Object.keys(controlErrors).forEach(keyError => {
                    // console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
                });
            }
        });
    }
}

