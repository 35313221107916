<a
    routerLink="/stock-management/consumption-ticket/list"
    style="color: #808080;font-size: 18px">
    <i class="fas fa-arrow-left"></i>
    <span>Inapoi la bonurile de consum productie</span>
</a>

<br>
<br>
<form [formGroup]="form" autocomplete="off" (ngSubmit)="onSave()">
    <p-card>
        <div class="grid fields-group" style="padding: 0 1em">
            <div class="col-12">
                <div class="grid p-fluid">
                    <div class="field col-7">
                        <app-stt-dropdown endPoint="fncs/dropdown" [formControlParent]="form.controls['fnc_id']" label="Fnc"
                                          icon="pi pi-cog" [readonly]="readOnly"
                                          placeholder="Nu ati selectat un FNC!"></app-stt-dropdown>
                    </div>
                    <div class="field col-5">
                        <app-stt-input inputId="code" [control]="form.controls['code']"
                                       icon="pi pi-key" [readonly]="readOnly"
                                       label="Cod SAP"></app-stt-input>
                    </div>
                </div>
                <p-divider></p-divider>
                <br>
                <div class="grid p-fluid">
                    <div class="field col-12 md:col-4">
                        <app-stt-input inputId="documentNo" [control]="form.controls['documentNo']"
                                       icon="pi pi-paperclip" type="text" [readonly]="readOnly"
                                       label="Numar Document"></app-stt-input>
                        <br>
                        <app-stt-calendar label="Data" icon="pi pi-calendar" [minimumDate]="minimumDate" [showCalendar]="showCalendar"
                                          [control]="form.controls['documentDate']"></app-stt-calendar>
                        <br>
                    </div>
                    <div class="field col-12 md:col-8" style="border-left: 1px solid lightgray">
                        <app-stt-textarea inputId="description" [control]="form.controls['description']"
                                          icon="pi pi-pencil" [readOnly]="readOnly"
                                          label="Descriere"></app-stt-textarea>
                    </div>
                </div>
                <p-divider></p-divider>
                <div class="grid fields-group">
                    <div class="col-12 grid" style="flex-wrap: nowrap; gap: 2rem;">
                        <div class="container col-9" formArrayName="items">
                            <ng-container
                                *ngFor="let recipeItemForm of formConsumptionTicketItems().controls; let index = index">
                                <ng-container [formGroup]="recipeItemForm">
                                    <div class="grid p-fluid" *ngIf="!recipeItemForm.controls.needDelete.value">
                                        <div class="field col-5 md:col-4 xl:col-5">
                                            <app-stt-dropdown endPoint="products/dropdown"
                                                              icon="pi pi-check-square"
                                                              [formControlParent]="recipeItemForm.controls['product_id']"
                                                              placeholder="Nu ati selectat produs!"
                                                              [fields]="{ text: 'name' , value: 'id' }"
                                                              [dropDownWithTrashed]="dropDownWithTrashed"
                                                              [readonly]="readOnly"
                                                              label="Produs">
                                            </app-stt-dropdown>
                                        </div>
                                        <div class="field col-3 md:col-2 xl:col-3">
                                            <app-stt-input inputId="lot" [control]="recipeItemForm.controls['lot']"
                                                           icon="pi pi-box" type="string" [readonly]="readOnly"
                                                           label="Lot"></app-stt-input>
                                        </div>
                                        <div class="field col-3 md:col-2 xl:col-3">
                                            <app-stt-input inputId="units" [control]="recipeItemForm.controls['units']"
                                                           icon="pi pi-box" type="number" [readonly]="readOnly"
                                                           label="Unitati"></app-stt-input>
                                        </div>
                                        <div class="field col-1 md:col-1 xl:col-1">
                                            <button (click)="deleteFormItem(index)"
                                                    class="p-button-raised p-button-danger text-right" [disabled]="readOnly"
                                                    icon="pi pi-minus" pButton pRipple type="button"></button>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>
                        <div class="col-3 grid align-items-end" style="padding: 14px">
                            <button (click)="addFormElement()"
                                    class="p-button-raised p-button-success text-right"
                                    icon="pi pi-plus"
                                    [style]="getStyleButton()"
                                    label="Adauga" pButton [disabled]="readOnly"
                                    pRipple type="button"></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </p-card>

    <p-progressBar mode="indeterminate" class="addEditProgressBar" *ngIf="loading"></p-progressBar>

    <div class="actions-area">
        <div class="actions-left">
            <button pButton pRipple type="button" label="Anulare" class="p-button-raised p-button-danger"
                    icon="pi pi-ban" [disabled]="loading"
                    routerLink="/stock-management/consumption-ticket/list"></button>
        </div>

        <div class="actions-right">
            <button *ngIf="!status || status === enumStatus.Draft" pButton pRipple type="submit" label="Salveaza" class="p-button-raised p-button-success"
                    icon="pi pi-save" [disabled]="loading">
            </button>
            <p-button *ngIf="status === enumStatus.Draft" [label]="'Validare'" icon="pi pi-check" iconPos="right" (click)="validateForm()" [ngStyle]="{'margin-left' : '2px'}"></p-button>
        </div>
    </div>
</form>

