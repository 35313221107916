<!--IMPORT -->
<div *ngIf="view === false">
    <p style="color: red"><i class="pi pi-exclamation-triangle"></i> Pentru ajustarea stocului importati dozarea live!</p>
    <div class="grid p-fluid">
        <div class="field col-12 md:col-6">
        <span class="p-float-label p-input-icon-right">
            <input type="file" id="importedfile" pInputText (change)="onImportFile($event)" >
            <i class="pi pi-upload"></i>
        </span>
        </div>
    </div>
    <table id="TheTable" class="ExcelTable2007" style="width: 100%;border-collapse: collapse;">
        <!--    <thead #header>-->
        <!--    <tr>-->
        <!--        <th *ngFor="let headValue of headData">-->
        <!--            {{headValue}}-->
        <!--        </th>-->
        <!--    </tr>-->
        <!--    </thead>-->
        <tbody #container *ngIf="liveDosageData">
        <tr *ngFor="let row of liveDosageData; let rowIndex = index">
            <td *ngFor="let val of row; let i =index" [style]="getStyle(rowIndex, i)">
                {{row[i] || ' '}}
            </td>
        </tr>
        </tbody>
    </table>
    <p *ngIf="liveDosageData.length === 0" style="padding-left: 5px">Niciun fisier importat!</p>
    <div class="actions-area">
        <div class="actions-left">
            <button pButton pRipple type="button" label="Anulare" class="p-button-raised p-button-danger"
                    icon="pi pi-ban" (click)="onCancel()">
            </button>
        </div>

        <div class="actions-right">
            <button pButton pRipple type="submit" label="Salveaza" class="p-button-raised p-button-success"
                    icon="pi pi-save" [disabled]="loading" (click)="onSave()">
            </button>
        </div>
    </div>
</div>

<!-- VIEW -->
<div *ngIf="view === true">
    <table id="Table" class="ExcelTable" style="width: 100%;border-collapse: collapse;">
        <tbody #container *ngIf="liveDosageData">
        <tr *ngFor="let row of liveDosageData; let rowIndex = index">
            <td *ngFor="let val of row; let i =index" [style]="getStyle(rowIndex, i)">
                {{row[i] || ' '}}
            </td>
        </tr>
        </tbody>
    </table>
    <p *ngIf="liveDosageData.length === 0" style="padding-left: 5px">Niciun fisier importat!</p>
    <p-progressBar mode="indeterminate" class="addEditProgressBar" *ngIf="loading"></p-progressBar>
    <div class="actions-area">
        <div class="actions-left">
            <button pButton pRipple type="button" label="Iesire" class="p-button-raised p-button-danger"
                    icon="pi pi-ban" (click)="onCancel()">
            </button>
        </div>
    </div>
</div>

