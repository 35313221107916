import { Component, OnInit } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Router } from '@angular/router';
import { SttTableService } from '../../../../shared/components/stt-table/stt-table-service';

@Component({
  selector: 'app-stock-products',
  templateUrl: './stock-products.component.html',
  styleUrls: ['./stock-products.component.scss']
})
export class StockProductsComponent implements OnInit {
    public tableEndpoint = 'stock/products';
    public tableQuery: any;
    public ref: DynamicDialogRef;
    public tableStyle  = 'p-datatable-striped p-datatable';

    constructor(public dialogService: DialogService,
                public router: Router) {
        this.tableQuery = new SttTableService()
                    .addInclude('product');
    }

    ngOnInit(): void {

    }
}
