import { Component, OnInit } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SttTableService } from '../../../shared/components/stt-table/stt-table-service';
import { LossTicketActionTableComponent } from './loss-ticket-action-table/loss-ticket-action-table.component';

@Component({
  selector: 'app-loss-ticket',
  templateUrl: './loss-ticket.component.html',
  styleUrls: ['./loss-ticket.component.scss']
})
export class LossTicketComponent implements OnInit {
    public tableEndpoint = 'loss-tickets';
    public tableActionComponent = LossTicketActionTableComponent;
    public tableQuery: any;
    public ref: DynamicDialogRef;

    constructor(public dialogService: DialogService) {
        this.tableQuery = new SttTableService()
            .addInclude('fnc');
    }

    ngOnInit(): void {

    }
}
