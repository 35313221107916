import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { ApiResponseModel } from '../../entities/api-response.model';
import { HttpApiService } from '../../services/base/http-api.service';
import { AbstractControl, FormControl } from '@angular/forms';
import { Directive, ElementRef } from '@angular/core';

@Component({
    selector: 'app-stt-dropdown',
    templateUrl: './stt-dropdown.component.html',
    styleUrls: ['./stt-dropdown.component.scss']
})
export class SttDropdownComponent implements OnInit {

    @Input() placeholder: string;
    @Input() iconPosition = 'left';
    @Input() icon: string;
    @Input() readonly = false;
    @Input() formControlParent: AbstractControl;
    @Input() formControlNameDependency?: string;
    @Input() label = '';
    @Input() endPoint;
    @Input() fields = { text: 'name', value: 'id' };
    // New added
    @Input() returnProductId = false;
    @Input() indexOfProductId: number;
    @Input() dropDownWithTrashed = false;
    @Input() appendTo: string;

    @Output() changeEvent = new EventEmitter<any>();
    @Output() loadedDataEvent = new EventEmitter<any>();
    // New added
    @Output() setProductIdEvent = new EventEmitter<any>();

    public loading = false;
    public items: Array<any> = [];
    public formControl: FormControl;

    constructor(private httpApiService: HttpApiService) {
    }

    hasRequired(abstractControl: AbstractControl): boolean {
        if (abstractControl.validator) {
            const validator = abstractControl.validator({} as AbstractControl);
            if (validator && validator.required) {
                return true;
            }
        }
        return false;
    }

    ngOnInit(): void {
        this.formControl = this.formControlParent as FormControl;

        const filter = {};

        this.formControlParent.valueChanges.subscribe(value => {
            if (this.returnProductId === true) {
                this.setProductIdEvent.emit({ id: value, index: this.indexOfProductId });
            }

            // If exist in list, not make request to API
            const exist = this.items.find((obj) => obj[this.fields.value] === value);

            // this.setImplicitValue(value);
            if (!exist) {
                this.setImplicitValue(value);
            }
        });

        if (this.formControlNameDependency) {
            this.formControlParent.parent.get(this.formControlNameDependency).valueChanges.subscribe(value => {
                this.getData(filter);
            });


            // TODO make some logic here
            // IF dependency change value

        }

        filter["dropDown"] = this.dropDownWithTrashed;

        if (this.formControlParent.value) {
            this.setImplicitValue(this.formControlParent.value);
        } else {
            this.getData(filter);
        }


    }


    setImplicitValue(fieldValue): void {
        const filter = { complexFilters: { id: { matchMode: 'equals', value: fieldValue } } };
        this.getData(filter);
    }

    getData(filter?: any) {
        this.items = [];
        this.loading = true;
        this.httpApiService.httpPost(this.endPoint, this.addDependencyFilter(filter))
            .pipe(finalize(() => this.loading = false))
            .subscribe((response: ApiResponseModel) => {
                this.items = response.payload.data;
                this.loadedDataEvent.emit(this.items);
            });
    }

    onChange($event: any): void {
        this.changeEvent.emit($event);
    }

    onSearch($event: { term: string; items: any[] }) {
        let filter = {};
        const filterField = this.fields.text;
        if ($event.term.length > 2) {
            filter = { complexFilters: { [filterField]: { matchMode: 'contains', value: $event.term } } };
            this.getData(filter);
        }
    }

    addDependencyFilter(filter?: any): any {

        if (this.formControlNameDependency?.length) {

            const parentField = this.formControlNameDependency;
            const parentValue = this.formControlParent.parent.get(this.formControlNameDependency).value;

            if (filter.complexFilters) {
                filter.complexFilters[parentField] = { matchMode: 'equals', value: parentValue };
            } else {
                filter = { complexFilters: { [parentField]: { matchMode: 'equals', value: parentValue } } , dropDown: true};
            }
        }
        filter["dropDown"] = this.dropDownWithTrashed;

        return filter;
    }
}

