import { BaseModel } from './base.model';
import { CustomerOrderTransportMapItems } from './customar-order-transport-map-items.model';

export class CustomerOrderTransportMap extends BaseModel{
    id: number;
    uid: string;
    fnc_id: number;
    customer_order_id: number;
    product_id: number;
    deliveryDate: Date;
    items: Array<CustomerOrderTransportMapItems> = [];

}
