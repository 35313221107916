import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { HttpApiService } from '../../../../shared/services/base/http-api.service';
import { ValidationMessageService } from '../../../../shared/services/base/validation-message-handle';
import { MessageService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { TicketItemModel } from '../../../../shared/entities/ticket-item.model';
import { LossTicketModel } from '../../../../shared/entities/loss-ticket.model';
import { LossTicketService } from '../../../../shared/services/app/loss-ticket.service';

enum Status {
    Draft = 0,
    Validated = 1,
}

@Component({
    selector: 'app-loss-ticket-add-edit',
    templateUrl: './loss-ticket-add-edit.component.html',
    styleUrls: ['./loss-ticket-add-edit.component.scss']
})
export class LossTicketAddEditComponent implements OnInit {

    public uid: string = null;
    public itemModel: LossTicketModel = new LossTicketModel();
    public loading = false;
    public form: UntypedFormGroup;
    public minimumDate: Date = new Date();
    public fnc_id: number;
    public dropDownWithTrashed = false;
    public waitForFinalize = false;
    public status: number;
    public readOnly = false;
    public showCalendar = true;
    public enumStatus = Status;

    constructor(
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private formBuilder: UntypedFormBuilder,
        private httpApiService: HttpApiService,
        private validationMessageService: ValidationMessageService,
        private itemService: LossTicketService,
        private messageService: MessageService,
        private route: ActivatedRoute,
        public router: Router
    ) {
        this.uid = this.route.snapshot.queryParams.uid;
    }

    ngOnInit(): void {

        const localStorageData = JSON.parse(localStorage.getItem('user'));
        this.fnc_id = localStorageData.fnc_id;

        this.createForm();

        if (this.uid) {
            this.getItem();
        }


        return;
    }

    getStyleButton(){
        const itemsLength = this.formLossTicketItems().value.length;
        let jsonStyle;
        if(this.uid){
            if(!this.waitForFinalize){
                return ;
            }
        }
        if((this.formLossTicketItems().at(itemsLength -1).get('raw_material_id').invalid && this.formLossTicketItems().at(itemsLength -1).get('raw_material_id').touched)
            || (this.formLossTicketItems().at(itemsLength -1).get('units').invalid && this.formLossTicketItems().at(itemsLength -1).get('units').touched)){
            jsonStyle = {'margin-bottom' : '44px'};
        }
        else{
            jsonStyle = {'margin-bottom' : '24px'};
        }
        return jsonStyle;
    }


    createForm(): void {
        this.form = this.formBuilder.group({
            uid: [this.itemModel.uid],
            fnc_id: [this.fnc_id, Validators.required],
            code: [this.itemModel.code, [Validators.required, Validators.maxLength(100)]],
            documentDate: [this.itemModel.documentDate, Validators.required],
            documentNo: [this.itemModel.documentNo, [Validators.required, Validators.minLength(3), Validators.maxLength(255)]],
            description: [this.itemModel.description, [Validators.maxLength(2000)]],
            items: this.formBuilder.array([]),
        });

        if (!this.uid) {
            // Add empty line
            this.addFormElement();
        }
    }

    formLossTicketItems(): UntypedFormArray {
        return this.form.get('items') as UntypedFormArray;
    }


    deleteFormItem(index: number): void {
        const formItem = this.formLossTicketItems().at(index);
        formItem.patchValue({
            needDelete: true,
        });
        this.clearValidatorsOnItem(index);
    }

    clearValidatorsOnItem(index: number): void{
        this.formLossTicketItems().at(index).get('raw_material_id').clearValidators();
        this.formLossTicketItems().at(index).get('units').clearValidators();
        this.formLossTicketItems().at(index).get('raw_material_id').updateValueAndValidity();
        this.formLossTicketItems().at(index).get('units').updateValueAndValidity();
    }


    addFormElement(item?: any) {
        const lossTicketItemForm = this.formBuilder.group({
            uid: [(item) ? item.uid : null],
            raw_material_id: [(item) ? item.raw_material_id : null, Validators.required],
            units: [(item) ? item.units : null, Validators.required],
            needDelete: [false,Validators.required]
        });
        this.formLossTicketItems().push(lossTicketItemForm);
    }

    getItem(): void {
        this.loading = true;
        this.dropDownWithTrashed = true;
        this.itemService.get(this.uid)
            .pipe(finalize(() => {this.loading = false; this.waitForFinalize = true;}))
            .subscribe((response: any) => {
                this.itemModel.map(response.payload);
                this.form.patchValue(this.itemModel);
                const dateParse = new Date(response.payload.documentDate);
                this.form.patchValue({
                    documentDate: dateParse,
                });
                this.itemModel.items.forEach(item => {
                    this.addFormElement(item);
                });
                this.status = response.payload.status;
                if(this.status > Status.Draft){
                    this.readOnly = true;
                    this.showCalendar = false;
                }
            });
    }

    checkIfFormValid(): boolean {
        if (this.form.invalid) {
            this.messageService.add({
                severity: 'error',
                summary: 'Formularul nu este valid!',
                detail: 'Vericficati toate campurile si incercati din nou.'
            });
            this.form.markAllAsTouched();
            return false;
        }
        return true;
    }


    onSave(): void {
        if (!this.checkIfFormValid()) {
            return;
        }
        this.loading = true;
        const data = this.form.getRawValue();
        this.itemService.set(data)
            .pipe(finalize(() => this.loading = false))
            .subscribe((response: any) => {
                this.itemModel.map(response.payload);
                this.form.patchValue(this.itemModel);
                this.messageService.add({
                    severity: 'success',
                    summary: 'Bonul de Pierdere a fost salvata cu success!'
                });
                this.router.navigate(['stock-management/loss-ticket/list'], {});
            }, err => {
                if (err instanceof HttpErrorResponse) {
                    this.validationMessageService.serverSideValidation(err, this.form, '', true);
                }
            });
    }

    validateForm(){
        this.status = this.enumStatus.Validated;
        const summary = 'Bonul de pierdere a fost validat cu success!!';
        this.changeStatus(summary);
    }

    changeStatus(summary: string){
        this.itemService.statusChange(this.status, this.uid)
            .pipe(finalize(() => this.loading = false))
            .subscribe((response: any) => {
                this.itemModel.map(response.payload);
                this.form.patchValue(this.itemModel);
                this.messageService.add({
                    severity: 'success',
                    summary
                });
                this.router.navigate(['stock-management/loss-ticket/list'], {});
            }, err => {
                if (err instanceof HttpErrorResponse) {
                    this.validationMessageService.serverSideValidation(err, this.form, '', true);
                }
            });
    }
}
