import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';

@Component({
    selector: 'app-stock-management',
    templateUrl: './stock-management.component.html',
    styleUrls: ['./stock-management.component.scss']
})
export class StockManagementComponent implements OnInit {
    public items: MenuItem[];

    constructor() {
    }

    ngOnInit(): void {
        this.items = [
            {
                label: 'Materii Prime',
                icon: 'fas fa-industry',
                routerLink: ['/stock-management/inventory/raw-materials']
            },
            { label: 'Produse', icon: 'fas fa-users', routerLink: ['/stock-management/inventory/products'] },
        ];
    }

}
