<!--
<form [formGroup]="form" autocomplete="false">
    <p-fieldset legend="Detalii Comanda" [transitionOptions]="'100ms'">
        <div class="grid fields-group">
            <div class="col-12" >
                <div class="grid p-fluid">
                    <div class="field col-12 md:col-4">-->
                        <!--<app-stt-dropdown endPoint="customers" [formControlParent]="form.controls['customer_id']" label="Client"></app-stt-dropdown>-->
<!--                       <p-dropdown [options]="customerList" (onChange)="onChange($event)" placeholder="Client" optionLabel="name" filter="true" filterBy="name"></p-dropdown>
                    </div>
                    <div class="field col-12 md:col-4">-->
                        <!--<app-stt-dropdown endPoint="customers" [fields]="fieldAddress"  [formControlParent]="form.controls['customer_address_id']" label="Client"></app-stt-dropdown>-->
<!--                        <p-dropdown lazy="true" [options]="selectedCustomer?.value.addresses" (onChange)="onChangeAddress($event)" placeholder="Adresa de livrare" optionLabel="address" filter="true" filterBy="address"></p-dropdown>
                    </div>
                    <div class="field col-12 md:col-4">
                        <app-stt-dropdown endPoint="product-categories" [formControlParent]="form.controls['product_category_id']" label="Categorie Produs"></app-stt-dropdown>
                    </div>
                </div>

                <div class="container" style="width: 1400px!important;">
                        <div formArrayName="weekOrder">
                        <p-table [value]="formWeek().controls" [scrollable]="true" scrollDirection="both" styleClass="p-datatable-gridlines">
                            <ng-template pTemplate="header" style="background-color: white">
                                <tr style="background: white">
                                    <th class="frozen-header" [frozen]="true" pFrozenColumn style="background: white">Data Livrare</th>
                                    <th class="frozen-header" [frozen]="true" pFrozenColumn style="background: white" >Interval Orar (minim 4 ore)</th>
                                    <th *ngFor="let prod of formProducts(0).controls;let i=index" class="unfrozen-header" style="background: white" pResizableColumn>Produs {{i+1}}</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
                                <tr style="overflow: visible;" [formGroupName]="rowIndex">
                                    <ng-container [formGroup]="rowData">
                                        <td class="first-col" pFrozenColumn alignFrozen="left">
                                            <div *ngIf="rowIndex == 0">
                                                <div class="col-12" style="width: 300px"></div>
                                            </div>
                                            <div *ngIf="rowIndex > 0">
                                                <div class="grid p-fluid justify-content-start" style="margin-top: 10px;">
                                                    <div class="col-1" style="width:5px;">
                                                        <label>{{rowData.value.day}}</label>
                                                    </div>
                                                    <div class="col-6" style="width: 300px">-->
                                                            <!--<p-calendar (onSelect)="onSelectCalendar($event,rowIndex)" [minDate]="minimumDate" [disabledDays]="rowData.value.currentAvailableDay" appendTo="body" showIcon="true"></p-calendar>-->
                                                            <!--formControlName="ceva"-->
<!--                                                          <p-calendar formControlName="deliveryDate" [minDate]="minimumDate" [disabledDays]="rowData.value.currentAvailableDay" appendTo="body" showIcon="true"></p-calendar>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="second-col" pFrozenColumn alignFrozen="left">
                                            <div *ngIf="rowIndex == 0">
                                                <div class="grid p-fluid justify-content-start" style="margin-top: 10px;">
                                                    <div class="col-6" style="width: 150px">De la</div>
                                                    <div class="col-6" style="width: 150px">Pana la</div>
                                                </div>
                                            </div>
                                            <div *ngIf="rowIndex > 0">
                                                <div class="grid p-fluid justify-content-start" style="margin-top: 10px;">
                                                    <div class="col-6" style="width: 150px">-->
                                                        <!--<input type="time" id="quantity" formControlName="quantity" style="border-width: 1px">-->
                                                        <!--<p-calendar (onSelect)="onSelectTimeStart($event,rowIndex)"  timeOnly="true" hourFormat="24" appendTo="body" ></p-calendar>-->
<!--                                                        <p-calendar formControlName="deliveryHourStart"  timeOnly="true" hourFormat="24" appendTo="body" ></p-calendar>
                                                    </div>
                                                    <div class="col-6" style="width: 150px">-->
                                                        <!--<input type="time" id="quantity" formControlName="quantity" style="border-width: 1px">-->
                                                        <!--<p-calendar  (onSelect)="onSelectTimeEnd($event,rowIndex)" timeOnly="true" hourFormat="24" appendTo="body"></p-calendar>-->
<!--                                                      <p-calendar  formControlName="deliveryHourEnd" timeOnly="true" hourFormat="24" appendTo="body"></p-calendar>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td *ngFor="let prod of formProducts(rowIndex).controls;let i=index"  class="products-cols">
                                            <div *ngIf="rowIndex == 0">
                                                <div class="grid p-fluid justify-content-start" style="margin-top: 10px;">
                                                    <div class="col-12" style="width: 320px">
                                                        <ng-container [formGroup]="prod">
                                                            <app-stt-dropdown endPoint="products" [returnProductId]="returnProductId" (onSetProductIdEvent)="SetProductId($event)" [indexOfProductId]="i" [formControlParent]="prod.controls['product_id']" label="Produs"></app-stt-dropdown>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="rowIndex > 0">
                                                <div class="grid p-fluid justify-content-start" style="margin-top: 10px;">
                                                    <div class="col-4" >
                                                        <ng-container [formGroup]="prod">
                                                            <div class="p-float-label p-input-icon-right">
                                                                <input type="number"  formControlName="quantity" pInputText>
                                                                <label>Cantitate</label>
                                                                <i class="pi pi-location"></i>
                                                            </div>
                                                        </ng-container>
                                                    </div>
                                                    <div class="col-8" >
                                                        <app-stt-dropdown endPoint="product-packing-types" [formControlParent]="prod.controls['product_packing_type_id']" label="Ambalaj Produs"></app-stt-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </ng-container>
                                </tr>
                            </ng-template>
                        </p-table>
                        </div>
                </div>
                <br>
                <p-button label="Adauga produs" icon="pi pi-plus" iconPos="right" (click)="addProduct()"></p-button>

            </div>
        </div>
    </p-fieldset>

    <p-progressBar mode="indeterminate" class="addEditProgressBar" *ngIf="loading"></p-progressBar>

    <div class="actions-area">
        <div class="actions-left">
            <button pButton pRipple type="button" label="Anulare" class="p-button-raised p-button-danger" icon="pi pi-ban" [disabled]="loading" (click)="onCancel()">
            </button>
        </div>

        <div class="actions-right">
            <button pButton pRipple type="button" label="Salveaza" class="p-button-raised p-button-success" icon="pi pi-save" [disabled]="loading"  (click)="onSave()">
            </button>
        </div>
    </div>
</form>
-->
<p>dashboard add-edit works!</p>
