import { Component, OnInit, ViewChild } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SttTableComponent } from '../../../shared/components/stt-table/stt-table.component';
import { SttTableService } from '../../../shared/components/stt-table/stt-table-service';
import { RecipeActionTableComponent } from './recipe-action-table/recipe-action-table.component';
import { RecipeAddEditComponent } from './recipe-add-edit/recipe-add-edit.component';
import { RecipeModel } from 'src/app/shared/entities/recipe.model';
import { RecipeService } from 'src/app/shared/services/app/recipe.service';


@Component({
    selector: 'app-recipes',
    templateUrl: './recipes.component.html',
    styleUrls: ['./recipes.component.scss']
})
export class RecipesComponent implements OnInit {

    @ViewChild('table', { static: false }) table: SttTableComponent;

    public tableEndpoint = 'recipes';
    public tableActionComponent = RecipeActionTableComponent;
    public tableQuery: any;
    public ref: DynamicDialogRef;

    constructor(public dialogService: DialogService) {
        this.tableQuery = new SttTableService();
    }

    ngOnInit(): void {

    }

    addNewEntity() {
        this.ref = this.dialogService.open(RecipeAddEditComponent, {
            header: 'Adaugare reteta',
            width: '70%',
            contentStyle: { 'max-height': '70vh' },
            baseZIndex: 10000,
            closeOnEscape: false,
            data: null,
        });

        this.ref.onClose.subscribe((item: RecipeModel) => {
            if (item) {
                this.table.refresh();
            }
        });
    }
}
