import { Component, OnInit } from '@angular/core';
import { OrderRawMaterialModel } from '../../../../shared/entities/order-raw-material.model';
import { SttTableComponent } from '../../../../shared/components/stt-table/stt-table.component';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Router } from '@angular/router';
import {
    OrdersRawMaterialsReceptionNoteComponent
} from '../orders-raw-materials-reception-note/orders-raw-materials-reception-note.component';
import { ConfirmationService, MessageService, PrimeNGConfig } from 'primeng/api';
import { ValidationMessageService } from '../../../../shared/services/base/validation-message-handle';
import { OrderRawMaterialService } from '../../../../shared/services/app/order-raw-material.service';
import { finalize } from 'rxjs/operators';

enum Status {
    Draft = 0,
    Validated = 1,
    Finished = 2,
}

@Component({
    selector: 'app-order-raw-materials-action-table',
    templateUrl: './order-raw-materials-action-table.component.html',
    styleUrls: ['./order-raw-materials-action-table.component.scss']
})
export class OrderRawMaterialsActionTableComponent implements OnInit {

    public data: OrderRawMaterialModel;
    public table: SttTableComponent;
    public ref: DynamicDialogRef;
    public loading = true;
    public enumStatus = Status;

    constructor(
        public dialogService: DialogService,
        public router: Router,
        public confirmationService: ConfirmationService,
        public primengConfig: PrimeNGConfig,
        public itemService: OrderRawMaterialService,
        public messageService: MessageService,
        private validationMessageService: ValidationMessageService,
    ) {

    }

    ngOnInit(): void {
    }

    setParams(entity: OrderRawMaterialModel): void {
        this.data = entity;
    }

    setTableReference(table: SttTableComponent): void {
        this.table = table;
    }

    showAddEdit() {
        this.router.navigate(
            ['orders/raw-materials/add-edit'],
            { queryParams: { uid: this.data.uid } }
        );
    }

    showReceptionNote(): void {
        this.ref = this.dialogService.open(OrdersRawMaterialsReceptionNoteComponent, {
            header: 'Generare nota de receptie',
            width: '70%',

            contentStyle: { 'max-height': '70vh' },
            baseZIndex: 10000,
            closeOnEscape: false,
            data: this.data,
        });

        this.ref.onClose.subscribe((model: OrderRawMaterialModel) => {
            if (model) {
                this.table.refresh();
            }
        });
    }

    confirm() {
        this.confirmationService.confirm({
            header: 'Confirmare stergere Comanda materie prima',
            message: 'Sunteti sigur ca doriti sa stergeti aceasta comanda de materie prima?',
            key: 'deleteOrderRawMaterial',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Da',
            rejectLabel: 'Nu',
            acceptButtonStyleClass: 'p-button-md p-button-success',
            rejectButtonStyleClass: 'p-button-md p-button-danger',
            accept: () => {
                this.deleteItem(this.data.uid);
            },
            reject: () => {
                this.confirmationService.close();
                this.table.refresh();
            }
        });
    }

    deleteItem(uid: string){
        this.itemService.delete(uid)
            .pipe(finalize(() => {this.loading = false;this.table.refresh();}))
            .subscribe((response: any) => {
                this.messageService.add({
                    severity: 'success',
                    summary: 'Comanda de materie prima a fost stearsa cu success!'
                });
            });
    }

}
