import { Component, OnInit, ViewChild } from '@angular/core';
import { SttTableComponent } from '../../../../../shared/components/stt-table/stt-table.component';
import { SttTableService } from '../../../../../shared/components/stt-table/stt-table-service';
import {
    OrdersCustomerActionTableComponent
} from '../../orders-customer-action-table/orders-customer-action-table.component';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { OrderCustomerService } from '../../../../../shared/services/app/order-customer.service';
import { OrderCustomerTransportService } from '../../../../../shared/services/app/order-customer-transport.service';
import { MessageService } from 'primeng/api';
import { ValidationMessageService } from '../../../../../shared/services/base/validation-message-handle';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { CustomerOrderTransportMap } from '../../../../../shared/entities/customar-order-transport-map.model';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-orders-customer-transport-map-progress-bar',
  templateUrl: './orders-customer-transport-map-progress-bar.component.html',
  styleUrls: ['./orders-customer-transport-map-progress-bar.component.scss']
})
export class OrdersCustomerTransportMapProgressBarComponent implements OnInit {
    @ViewChild('table', { static: false }) table: SttTableComponent;

    public tableEndpoint = 'customer-orders-transport/map';
    public tableActionComponent = OrdersCustomerActionTableComponent;
    public tableQuery: SttTableService;
    public ref: DynamicDialogRef;
    public orderCustomerItemModel: any;
    public tableIsReadyToLoad = false;

    public loading = false;
    public canSave = true;
    public uid: string;
    public id: string;
    public form: UntypedFormGroup;
    public itemModel: CustomerOrderTransportMap = new CustomerOrderTransportMap();
    public quantityTransportMapped = 0;
    public progressValue: number;

    constructor(public dialogService: DialogService,
                public orderCustomerService: OrderCustomerService,
                private formBuilder: UntypedFormBuilder,
                private orderCustomerTransportService: OrderCustomerTransportService,
                private messageService: MessageService,
                private validationMessageService: ValidationMessageService,
                private datePipe: DatePipe,
                private router: Router,
    ) {

        this.tableQuery = new SttTableService().addInclude('customer-order');
    }

    ngOnInit(): void {

        this.id = this.orderCustomerItemModel.customer_order_id;

        this.createForm();

        this.getItem();
    }

    formTransport(): UntypedFormArray {
        return this.form.get('items') as UntypedFormArray;
    }

    createForm(): void {
        const dateObject = new Date(this.orderCustomerItemModel.deliveryDate);
        this.form = this.formBuilder.group({
            customer_order_id: [this.orderCustomerItemModel.customer_order_id, Validators.required],
            product_id: [this.orderCustomerItemModel.product_id, Validators.required],
            deliveryDate: [dateObject, Validators.required],
            quantity: [this.orderCustomerItemModel.quantity],
            fnc_id: [null, Validators.required],
            items: this.formBuilder.array([]),
        });

        this.formTransport().push(this.newItem());
    }

    newItem(): UntypedFormGroup {
        return this.formBuilder.group({
            uid: [null],
            transport_company_id: [null],
            transport_unit_type_id: [null],
            transport_unit_driver_id: [null],
            transport_quantity: [null, Validators.required],
        });
    }

    getItem(): void {
        this.loading = true;
        this.orderCustomerService.getById(this.id)
            .pipe(finalize(() => {this.loading = false;}))
            .subscribe((response: any) => {
                this.form.patchValue({
                    fnc_id: response.payload.fnc_id,
                });
                let index = 0;
                if(response.payload.customerOrderTransportItems.length > 0){
                    response.payload.customerOrderTransportItems.forEach(element => {
                        const dateObject = new Date(element.delivery_date);
                        if(element.product_id === this.form.value.product_id && element.delivery_date === this.orderCustomerItemModel.deliveryDate){
                            this.formTransport().at(index).patchValue({
                                uid: element.uid,
                                transport_company_id: element.transport_company_id,
                                transport_unit_type_id: element.transport_unit_type_id,
                                transport_unit_driver_id: element.transport_unit_driver_id,
                                transport_quantity: element.transport_quantity,
                            });
                            this.quantityTransportMapped = this.quantityTransportMapped + element.transport_quantity;
                            if(this.quantityTransportMapped < this.form.value.quantity){
                                this.formTransport().push(this.newItem());
                                index++;
                            }
                        }
                    });
                }
                this.calculateQuantityTransport();
            });
    }

    setParams(data: any): void {
        this.orderCustomerItemModel = data;
        this.tableQuery.complexFilters = { ['uid']: { matchMode: 'equals', value: this.orderCustomerItemModel.uid } };
        this.tableIsReadyToLoad = true;
    }

    checkAddTransport(index: number){

        if(this.quantityTransportMapped < this.form.value.quantity){
            this.quantityTransportMapped = this.quantityTransportMapped + Number(this.formTransport().at(index).value.transport_quantity);
            if(this.quantityTransportMapped < this.form.value.quantity){
                this.formTransport().push(this.newItem());
            }
        }
        this.calculateQuantityTransport();
    }

    calculateQuantityTransport(): void {
        const value = (this.quantityTransportMapped * 100)
            / this.form.value.quantity;
        const factor = 10 ** 2;
        this.progressValue = Math.round(value * factor) / factor;
    }

    onSave(){
        this.loading = true;
        const data = this.form.getRawValue();
        this.orderCustomerTransportService.setMap(data)
            .pipe(finalize(() => {
                this.loading = false;
                this.canSave = true;
            }))
            .subscribe((response: any) => {
                this.itemModel.map(response.payload);
                this.form.patchValue(this.itemModel);
                this.messageService.add({
                    severity: 'success',
                    summary: 'Transport alocat cu success!'
                });
                window.location.reload();
                // this.router.navigate(['orders/customer/list'], {});
            }, err => {
                if (err instanceof HttpErrorResponse) {
                    this.validationMessageService.serverSideValidation(err, this.form, '', true);
                }
            });
    }
}
