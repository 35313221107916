import { Component, OnInit } from '@angular/core';
import { SttTableComponent } from '../../../../shared/components/stt-table/stt-table.component';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ProductModel } from '../../../../shared/entities/product.model';
import { ProductsAddEditComponent } from '../products-add-edit/products-add-edit.component';
import { ConfirmationService, MessageService, PrimeNGConfig } from 'primeng/api';
import { ValidationMessageService } from '../../../../shared/services/base/validation-message-handle';
import { ProductService } from '../../../../shared/services/app/product.service';
import { finalize } from "rxjs/operators";

@Component({
    selector: 'app-products-action-table',
    templateUrl: './products-action-table.component.html',
    styleUrls: ['./products-action-table.component.scss']
})
export class ProductsActionTableComponent implements OnInit {

    public data: ProductModel;
    public table: SttTableComponent;
    public ref: DynamicDialogRef;
    public loading = true;

    constructor(public dialogService: DialogService,
                public confirmationService: ConfirmationService,
                public primengConfig: PrimeNGConfig,
                public itemService: ProductService,
                public messageService: MessageService,
                private validationMessageService: ValidationMessageService) {
    }

    ngOnInit(): void {
    }

    setParams(entity: ProductModel): void {
        this.data = entity;
    }

    setTableReference(table: SttTableComponent): void {
        this.table = table;
    }


    showDialog() {
        this.ref = this.dialogService.open(ProductsAddEditComponent, {
            header: 'Modificare Produs',
            width: '70%',
            contentStyle: { 'max-height': '70vh' },
            baseZIndex: 10000,
            closeOnEscape: false,
            data: this.data,
        });

        this.ref.onClose.subscribe((product: ProductModel) => {
            if (product) {
                this.table.refresh();
            }
        });
    }

    confirm() {
        this.confirmationService.confirm({
            header: 'Confirmare stergere Produs',
            message: 'Sunteti sigur ca doriti sa stergeti acest Produs?',
            key: 'deleteProduct',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Da',
            rejectLabel: 'Nu',
            acceptButtonStyleClass: 'p-button-md p-button-success',
            rejectButtonStyleClass: 'p-button-md p-button-danger',
            accept: () => {
                this.deleteItem(this.data.uid);
            },
            reject: () => {
                this.confirmationService.close();
                this.table.refresh();
            }
        });
    }

    deleteItem(uid: string){
        this.itemService.delete(uid)
            .pipe(finalize(() => {this.loading = false;this.table.refresh();}))
            .subscribe((response: any) => {
                this.messageService.add({
                    severity: 'success',
                    summary: 'Produsul a fost sters cu success!'
                });
            });
    }

}
