import { Injectable } from '@angular/core';
import { HttpApiService } from '../base/http-api.service';
import { Observable } from 'rxjs';
import { ApiResponseModel } from '../../entities/api-response.model';

@Injectable({
  providedIn: 'root'
})
export class OrderCustomerTransportService {

    constructor(
        public httpApiService: HttpApiService,
    ) {

    }

    public get(uid): Observable<ApiResponseModel>
    {
        return this.httpApiService.httpGet('customer-order-transports/view', {uid});
    }

    public set(item: any): Observable<ApiResponseModel>
    {
        return this.httpApiService.httpPost('customer-order-transports/store', item);
    }

    public setMap(item: any): Observable<ApiResponseModel>
    {
        return this.httpApiService.httpPost('customer-order-transports/store-map', item);
    }

}
