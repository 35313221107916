import { Component, Input, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { UserModel } from '../../../../auth/models/user.model';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { HttpApiService } from '../../../../shared/services/base/http-api.service';
import { ValidationMessageService } from '../../../../shared/services/base/validation-message-handle';
import { finalize } from 'rxjs/operators';
import { UserService } from '../../../../auth/user.service';
import { MessageService } from 'primeng/api';
import { HttpErrorResponse } from '@angular/common/http';
import { CustomerService } from '../../../../shared/services/app/customer.service';

@Component({
    selector: 'app-users-add-edit',
    templateUrl: './users-add-edit.component.html',
    styleUrls: ['./users-add-edit.component.scss']
})

export class UsersAddEditComponent implements OnInit {

    public uid: string = null;
    public userModel: UserModel = new UserModel();
    public loading = false;
    public form: UntypedFormGroup;

    constructor(
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private formBuilder: UntypedFormBuilder,
        private httpApiService: HttpApiService,
        private validationMessageService: ValidationMessageService,
        private userService: UserService,
        private customerService: CustomerService,
        private messageService: MessageService
    ) {
    }

    ngOnInit(): void {
        this.uid = (this.config.data) ? this.config.data.uid : null;

        this.createForm();

        if (this.uid) {
            this.getItem();
        }
    }

    onCloseDialog(user: UserModel) {
        this.ref.close(user);
    }


    createForm() {
        this.form = this.formBuilder.group({
            uid: [this.userModel.uid],
            code: [this.userModel.code, [Validators.required, Validators.maxLength(100)]],
            firstName: [this.userModel.firstName, [Validators.required, Validators.maxLength(200)]],
            lastName: [this.userModel.lastName, [Validators.required, Validators.maxLength(200)]],
            email: [{
                value: this.userModel.email,
                disabled: (this.uid)
            }, [Validators.required, Validators.email, Validators.maxLength(255)]],
            role_id: [null, Validators.required],
            fnc_id: [null],
            customer_id: [null],
            password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(255)]],
            password_confirmation: [''],
            isActive: [true],
        });

    }

    getItem(): void {
        this.loading = true;
        this.userService.getUserProfile(this.uid)
            .pipe(finalize(() => this.loading = false))
            .subscribe((response: any) => {
                this.userModel.map(response.payload);
                this.form.patchValue(this.userModel);
            });
    }


    onCancel(): void {
        this.ref.close(null);
    }

    checkIfFormValid(): boolean {
        if (this.form.invalid) {
            this.messageService.add({
                severity: 'error',
                summary: 'Formularul nu este valid!',
                detail: 'Vericficati toate campurile si incercati din nou.'
            });
            this.form.markAllAsTouched();
            return false;
        }
        return true;
    }

    onSave(): void {
        if (!this.checkIfFormValid()) {
            return;
        }
        this.loading = true;
        const data = this.form.getRawValue();
        this.userService.create(data)
            .pipe(finalize(() => this.loading = false))
            .subscribe((response: any) => {
                this.userModel.map(response.payload);
                this.form.patchValue(this.userModel);
                this.messageService.add({
                    severity: 'success',
                    summary: 'Utilizatorul a fost salvat cu success!'
                });
                this.ref.close(this.userModel);
            }, err => {
                if (err instanceof HttpErrorResponse) {
                    this.validationMessageService.serverSideValidation(err, this.form, '', true);
                }
            });
    }

}
