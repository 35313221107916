import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../../app.component';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html',
    styleUrls: ['./app.menu.component.scss'],
})
export class AppMenuComponent implements OnInit {
    model: any[];
    fullModel: any[];
    public roleAs: string;

    constructor(public app: AppComponent) {
    }

    checkForUserRole(): void {
        this.model = [];
        let modelIndex = 0;
        // const user: any = JSON.parse(localStorage.getItem('user'));

        // if (user.isAdmin === 1 || user.isAdmin === '1') {
        //     return;
        // }
        // this.model = this.fullModel;
        const userRole = this.getRole();
        this.fullModel.forEach(category => {
            if(category.roles.indexOf(userRole) !== -1){
                this.model.push({label: category.label, icon: category.icon, routerLink: category.routerLink, roles: category.roles, items:[]});
                let modelItemIndex = 0;
                category.items.forEach(item => {
                    if(item.roles.indexOf(userRole) !== -1){
                        if(this.checkIfItemsExist(item)){
                            this.model[modelIndex].items.push({label: item.label, icon: item.icon, routerLink: item.routerLink, roles: item.roles, items:[]});
                            item.items.forEach(subItem => {
                                if(subItem.roles.indexOf(userRole) !== -1){
                                    this.model[modelIndex].items[modelItemIndex].items.push(subItem);
                                }
                            });
                        }
                        else{
                            this.model[modelIndex].items.push(item);
                        }
                        modelItemIndex++;
                    }
                });
                modelIndex++;
            }
        });
    }

    checkIfItemsExist(element: any){
        return !!element.items;
    }

    getRole() {
        const role = JSON.parse(localStorage.getItem('user'));
        this.roleAs = role.roles[0].name;
        return this.roleAs;
    }

    ngOnInit() {
        this.fullModel = [
            {
                label: 'Administrare',
                icon: 'fas fa-gauge fa-fw',
                routerLink: ['/nomenclatures/'],
                roles: ['Administrator', 'Logistica', 'Laborant'],
                items: [
                    {
                        label: 'FNC',
                        icon: 'fas fa-industry fa-fw',
                        routerLink: ['/nomenclatures/fnc'],
                        roles: ['Administrator'],
                    },
                    {
                        label: 'Utilizatori',
                        icon: 'fas fa-users fa-fw',
                        routerLink: ['/nomenclatures/users'],
                        roles: ['Administrator'],
                    },
                    {
                        label: 'Clienti',
                        icon: 'fas fa-users-rectangle fa-fw',
                        routerLink: ['/nomenclatures/customers'],
                        roles: ['Administrator'],
                    },
                    {
                        label: 'Furnizori',
                        icon: 'fas fa-person-chalkboard fa-fw',
                        routerLink: ['/nomenclatures/suppliers'],
                        roles: ['Administrator'],
                    },
                    {
                        label: 'Stocare',
                        icon: 'fas fa-box fa-fw',
                        roles: ['Administrator'],
                        items: [
                            {
                                label: 'Tipuri de stocare',
                                icon: 'fas fa-boxes-stacked fa-fw',
                                routerLink: ['/nomenclatures/storage-types'],
                                roles: ['Administrator'],
                            },
                            {
                                label: 'Spatii de stocare',
                                icon: 'fas fa-warehouse fa-fw',
                                routerLink: ['/nomenclatures/storage-spaces'],
                                roles: ['Administrator'],
                            },
                        ]
                    },
                    {
                        label: 'Produse',
                        icon: 'fas fa-box-open fa-fw',
                        roles: ['Administrator', 'Laborant'],
                        items: [
                            {
                                label: 'Produse',
                                icon: 'fas fa-box-open fa-fw',
                                routerLink: ['/nomenclatures/products'],
                                roles: ['Administrator'],
                            },
                            {
                                label: 'Produse Client',
                                icon: 'fas fa-users fa-fw',
                                routerLink: ['/nomenclatures/customer-products'],
                                roles: ['Administrator'],
                            },
                            {
                                label: 'Specii',
                                icon: 'fas fa-pen-ruler fa-fw',
                                routerLink: ['/nomenclatures/product-categories'],
                                roles: ['Administrator'],
                            },
                            {
                                label: 'Retete',
                                icon: 'fas fa-rectangle-list fa-fw',
                                routerLink: ['/nomenclatures/recipes'],
                                roles: ['Administrator', 'Laborant'],
                            },
                            {
                                label: 'Tip ambalaj',
                                icon: 'fas fa-cubes fa-fw',
                                routerLink: ['/nomenclatures/product-packing-types'],
                                roles: ['Administrator'],
                            },
                            {
                                label: 'Unitati de masura',
                                icon: 'fas fa-ruler fa-fw',
                                routerLink: ['/nomenclatures/units-measure'],
                                roles: ['Administrator'],
                            },
                        ]
                    },
                    {
                        label: 'Materii Prime',
                        icon: 'fas fa-dolly fa-fw',
                        roles: ['Administrator'],
                        items: [
                            {
                                label: 'Materii prime',
                                icon: 'fas fa-dolly fa-fw',
                                routerLink: ['/nomenclatures/row-materials'],
                                roles: ['Administrator'],
                            },
                            {
                                label: 'Tipuri Materie prima',
                                icon: 'fas fa-table-cells-large fa-fw',
                                routerLink: ['/nomenclatures/row-material-types'],
                                roles: ['Administrator'],
                            },
                            {
                                label: 'Grup de Materie prima',
                                icon: 'fas fa-object-group fa-fw',
                                routerLink: ['/nomenclatures/raw-material-groups'],
                                roles: ['Administrator'],
                            },
                        ]
                    },
                    {
                        label: 'Laborator',
                        icon: 'fas fa-vial-virus fa-fw',
                        roles: ['Administrator'],
                        items: [
                            {
                                label: 'Enzime',
                                icon: 'fas fa-virus fa-fw',
                                routerLink: ['/nomenclatures/enzymes'],
                                roles: ['Administrator'],
                            },
                            {
                                label: 'Coccidiostatice',
                                icon: 'fas fa-bacteria fa-fw',
                                routerLink: ['/nomenclatures/coccibiostatics'],
                                roles: ['Administrator'],
                            },
                        ]
                    },
                    {
                        label: 'Transport',
                        icon: 'fas fa-truck fa-fw',
                        roles: ['Administrator', 'Logistica'],
                        items: [
                            {
                                label: 'Companii',
                                icon: 'fas fa-building-user fa-fw',
                                routerLink: ['/nomenclatures/transport-company'],
                                roles: ['Administrator', 'Logistica'],
                            },
                            {
                                label: 'Tipuri companii',
                                icon: 'fas fa-building-flag fa-fw',
                                routerLink: ['/nomenclatures/transport-company-types'],
                                roles: ['Administrator', 'Logistica'],
                            },
                            {
                                label: 'Soferi',
                                icon: 'fas fa-id-card fa-fw',
                                routerLink: ['/nomenclatures/transport-unit-drivers'],
                                roles: ['Administrator', 'Logistica'],
                            },
                            {
                                label: 'Tipuri de transport',
                                icon: 'fas fa-cart-flatbed fa-fw',
                                routerLink: ['/nomenclatures/transport-unit-types'],
                                roles: ['Administrator', 'Logistica'],
                            },
                        ]
                    },
                ],
            },

            {
                label: 'Achizitii',
                icon: 'fas fa-cart-shopping fa-fw',
                routerLink: ['/sales/'],
                roles: ['Administrator', 'Achizitii'],
                items: [
                    {
                        label: 'MP/PF',
                        icon: 'fas fa-cart-flatbed fa-fw',
                        routerLink: ['/stock-management/raw-material-vs-product'],
                        roles: ['Administrator', 'Achizitii'],
                    }
                ],
            },

            {
                label: 'Laborator',
                icon: 'fas fa-flask fa-fw',
                routerLink: ['/laboratory/'],
                roles: ['Administrator', 'Laborant'],
                items: [
                    {
                        label: 'Raport Calitate',
                        icon: 'fas fa-file-prescription fa-fw',
                        routerLink: ['/laboratory/quality-report'],
                        roles: ['Administrator', 'Laborant'],
                    },
                ],
            },

            {
                label: 'Gestiune',
                icon: 'fas fa-check-to-slot fa-fw',
                routerLink: ['/stock-management'],
                roles: ['Administrator', 'Gestionar', 'Laborant', 'Achizitii', 'Productie'],
                items: [
                    {
                        label: 'NIR',
                        icon: 'fas fa-note-sticky fa-fw',
                        routerLink: ['/stock-management/reception-note'],
                        roles: ['Administrator', 'Gestionar'],
                    },
                    {
                        label: 'Aviz',
                        icon: 'fas fa-note-sticky fa-fw',
                        routerLink: ['/stock-management/delivery-note'],
                        roles: ['Administrator', 'Gestionar'],
                    },
                    {
                        label: 'Raport de productie',
                        icon: 'fas fa-note-sticky fa-fw',
                        routerLink: ['/stock-management/production-note'],
                        roles: ['Administrator', 'Gestionar', 'Laborant', 'Productie'],
                    },
                    // {
                    //     label: 'Bon consum productie',
                    //     icon: 'fas fa-circle-check fa-fw',
                    //     routerLink: ['/stock-management/consumption-ticket'],
                    //     roles: ['Administrator', 'Gestionar'],
                    // },
                    {
                        label: 'Bon consum',
                        icon: 'fas fa-circle-exclamation fa-fw',
                        routerLink: ['/stock-management/loss-ticket'],
                        roles: ['Administrator', 'Gestionar'],
                    },
                    {
                        label: 'Inventar',
                        icon: 'fas fa-cart-flatbed fa-fw',
                        routerLink: ['/stock-management/inventory'],
                        roles: ['Administrator', 'Gestionar'],
                    },
                    {
                        label: 'Stoc',
                        icon: 'fas fa-cart-flatbed fa-fw',
                        routerLink: ['/stock-management/stock'],
                        roles: ['Administrator', 'Gestionar', 'Achizitii'],
                    },
                ],
            },

            // {
            //     label: 'Productie',
            //     icon: 'fas fa-helmet-safety fa-fw',
            //     routerLink: ['/production'],
            //     items: [
            //         {
            //             label: 'Test',
            //             icon: 'pi pi-fw pi-id-card',
            //             routerLink: ['/production/list'],
            //         },
            //     ],
            // },
            //
            // {
            //     label: 'Rapoarte',
            //     icon: 'fas fa-chart-pie fa-fw',
            //     routerLink: ['/reports/'],
            //     items: [
            //         {
            //             label: 'Test',
            //             icon: 'pi pi-fw pi-id-card',
            //             routerLink: ['/reports/list'],
            //         },
            //     ],
            // },

            {
                label: 'Comenzi',
                icon: 'fas fa-truck fa-fw',
                routerLink: ['/orders/'],
                roles: ['Administrator', 'Gestionar', 'Logistica', 'Achizitii'],
                items: [
                    { label: 'Client', icon: 'fas fa-users fa-fw', routerLink: ['/orders/customer/list'], roles: ['Administrator', 'Gestionar', 'Logistica']},
                    { label: 'Materie prima', icon: 'fas fa-ruler fa-fw', routerLink: ['/orders/raw-materials/list'], roles: ['Administrator', 'Gestionar', 'Logistica', 'Achizitii']}
                ]
            },
        ];
        this.checkForUserRole();
    }

}
