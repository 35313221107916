import { Component, OnInit } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SttTableService } from '../../../shared/components/stt-table/stt-table-service';
import { ConsumptionTicketActionTableComponent } from './consumption-ticket-action-table/consumption-ticket-action-table.component';

@Component({
  selector: 'app-consumption-ticket',
  templateUrl: './consumption-ticket.component.html',
  styleUrls: ['./consumption-ticket.component.scss']
})
export class ConsumptionTicketComponent implements OnInit {
    public tableEndpoint = 'consumption-tickets';
    public tableActionComponent = ConsumptionTicketActionTableComponent;
    public tableQuery: any;
    public ref: DynamicDialogRef;

    constructor(public dialogService: DialogService) {
        this.tableQuery = new SttTableService()
            .addInclude('fnc');
    }

    ngOnInit(): void {

    }
}
