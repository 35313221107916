import {BaseNomenclatorModel} from './base.model';
import {WeekOrderModel} from './week-order.model';

export class OrderCustomerModel extends BaseNomenclatorModel {
    uid: string;
    code: string;
    customer_id: number;
    customer_address_id: number;
    product_category_id: number;
    fnc_id: number;
    weekOrder: Array<WeekOrderModel>;
}
