import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-orders-customers-transport-view-add-edit',
  templateUrl: './orders-customers-transport-view-add-edit.component.html',
  styleUrls: ['./orders-customers-transport-view-add-edit.component.scss']
})
export class OrdersCustomersTransportViewAddEditComponent implements OnInit {
    public order_uid: string = null;
    public transport_uid: string = null;
    public form: UntypedFormGroup;
    public dropDownWithTrashed = false;


  constructor(
      private route: ActivatedRoute,
      public router: Router,
      public config: DynamicDialogConfig,
      private formBuilder: UntypedFormBuilder,
      public ref: DynamicDialogRef,
  ) {
      // this.uid = this.route.snapshot.queryParams.uid;
  }

  ngOnInit(): void {
      this.order_uid = (this.config.data) ? this.config.data.customerOrder.uid : null;
      this.transport_uid = (this.config.data) ? this.config.data.uid : null;
      if (this.order_uid){
          this.createForm();
      }
  }

    createForm(): void {
        const dateParse = new Date(this.config.data.delivery_date);
        this.form = this.formBuilder.group({
            order_uid: [this.config.data.customerOrder.uid],
            code: [null],
            customer_id: [this.config.data.customerOrder.customer_id, Validators.required],
            customer_address_id: [this.config.data.customerOrder.customer_address_id, Validators.required],
            product_category_id: [this.config.data.customerOrder.product_category_id, Validators.required],

            transport_uid: [this.config.data.uid],
            deliveryDate: [dateParse, Validators.required],
            product_id: [this.config.data.product_id, Validators.required],
            transport_company_id: [this.config.data.transport_company_id, Validators.required],
            transport_unit_driver_id: [this.config.data.transport_unit_driver_id, Validators.required],
            transport_quantity: [this.config.data.transport_quantity, Validators.required],
            transport_unit_type_id: [this.config.data.transport_unit_type_id, Validators.required],

            fnc_id: [this.config.data.fnc_id, Validators.required]
        });
    }

    onGenerate() {
        this.router.navigate(
            ['stock-management/delivery-note/add-edit'],
            { queryParams: { uid: this.order_uid , generated: true, transport_uid: this.transport_uid} }
        );
        this.ref.close();
    }

    onCancel(): void {
        this.ref.close(null);
    }
}
