import { Injectable } from '@angular/core';
import { HttpApiService } from '../base/http-api.service';
import { Observable } from 'rxjs';
import { ApiResponseModel } from '../../entities/api-response.model';
import { LossTicketModel } from '../../entities/loss-ticket.model';


@Injectable({
  providedIn: 'root'
})
export class LossTicketService {

    constructor(
        public httpApiService: HttpApiService,
    ) {

    }

    public get(uid): Observable<ApiResponseModel>
    {
        return this.httpApiService.httpGet('loss-tickets/view', {uid});
    }

    public set(item: LossTicketModel): Observable<ApiResponseModel>
    {
        return this.httpApiService.httpPost('loss-tickets/store', item);
    }

    public delete(uid): Observable<ApiResponseModel> {
        return this.httpApiService.httpDelete('loss-tickets/destroy', { uid });
    }

    public statusChange(status: number, uid): Observable<ApiResponseModel> {
        return this.httpApiService.httpPost('loss-tickets/change-status', {status, uid});
    }

}
