import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'app-stt-input',
    templateUrl: './stt-input.component.html',
    styleUrls: ['./stt-input.component.scss']
})
export class SttInputComponent implements OnInit {
    // Only use PrimeIcons, FontAwesome breaks
    @Input() icon: string;
    @Input() label = '';
    // Default type is text, you can specify other types -> 'number'
    @Input() type = 'text';
    @Input() inputId: string;
    @Input() control: AbstractControl;
    @Input() readonly = false;
    @Input() iconPosition = 'left';
    @Input() form: UntypedFormGroup;
    @Input() index: number;

    convertedControl: UntypedFormControl;
    validatorType = 'string';

    constructor() {
    }

    hasRequired(abstractControl: AbstractControl): boolean {
        if (abstractControl.validator) {
            const validator = abstractControl.validator({} as AbstractControl);
            if (validator && validator.required) {
                return true;
            }
        }
        return false;
    }

    // formRawMaterialOrderItems(): FormArray {
    //     return this.form.get('items') as FormArray;
    // }

    ngOnInit(): void {
        this.convertedControl = this.control as UntypedFormControl;
        if (this.type === 'number') {
            this.validatorType = 'number';
        }

        // this.control.valueChanges.subscribe(
        //     item => {
        //         if (!item){
        //             this.formRawMaterialOrderItems().at(this.index).get('raw_material_id').clearValidators();
        //             this.formRawMaterialOrderItems().at(this.index).get('raw_material_id').updateValueAndValidity();
        //         }
        //         else {
        //             // item.get('raw_material_id').setValidators([Validators.required]);
        //             // item.get('units').setValidators([Validators.required]);
        //             this.formRawMaterialOrderItems().at(this.index).get('raw_material_id').setValidators([Validators.required]);
        //             this.formRawMaterialOrderItems().at(this.index).get('raw_material_id').updateValueAndValidity();
        //         }
        //     });
    }

}
