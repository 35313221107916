import { Component, OnInit } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SttTableService } from '../../../shared/components/stt-table/stt-table-service';
import { ReceptionNoteActionTableComponent } from './reception-note-action-table/reception-note-action-table.component';

@Component({
  selector: 'app-reception-note',
  templateUrl: './reception-note.component.html',
  styleUrls: ['./reception-note.component.scss']
})
export class ReceptionNoteComponent implements OnInit {
    public tableEndpoint = 'reception-notes';
    public tableActionComponent = ReceptionNoteActionTableComponent;
    public tableQuery: any;
    public ref: DynamicDialogRef;

    constructor(public dialogService: DialogService) {
        this.tableQuery = new SttTableService()
            .addInclude('fnc')
            .addInclude('supplier')
            .addInclude('rawMaterial');
    }

    ngOnInit(): void {

    }
}

