import { Component, OnInit, ViewChild } from '@angular/core';
import { SttTableService } from '../../../shared/components/stt-table/stt-table-service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SttTableComponent } from '../../../shared/components/stt-table/stt-table.component';
import { ProductCategoryModel } from '../../../shared/entities/product-category.model';
import { ProductCategoriesService } from '../../../shared/services/app/product-categories.service';
import {
    ProductCategoriesActionTableComponent
} from './product-categories-action-table/product-categories-action-table.component';
import { ProductCategoriesAddEditComponent } from './product-categories-add-edit/product-categories-add-edit.component';

@Component({
    selector: 'app-product-categories',
    templateUrl: './product-categories.component.html',
    styleUrls: ['./product-categories.component.scss']
})
export class ProductCategoriesComponent implements OnInit {
    @ViewChild('table', { static: false }) table: SttTableComponent;

    public tableEndpoint = 'product-categories';
    public tableActionComponent = ProductCategoriesActionTableComponent;
    public tableQuery: any;
    public ref: DynamicDialogRef;

    constructor(private productCategoriesService: ProductCategoriesService, public dialogService: DialogService) {
        this.tableQuery = new SttTableService();
    }

    ngOnInit(): void {

    }

    addNewEntity() {
        this.ref = this.dialogService.open(ProductCategoriesAddEditComponent, {
            header: 'Adaugare Specie',
            width: '30%',
            contentStyle: { 'max-height': '70vh' },
            baseZIndex: 10000,
            closeOnEscape: false,
            data: null,
        });

        this.ref.onClose.subscribe((item: ProductCategoryModel) => {
            if (item) {
                this.table.refresh();
            }
        });
    }
}
