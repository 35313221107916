import { BaseNomenclatorModel } from './base.model';
import { RawMaterialTypeFncRelationModel } from './raw-material-type-fnc-relation.model';
import { UnitMeasureModel } from './unit-measure.model';

export class RawMaterialModel extends BaseNomenclatorModel {
    name: string;
    code: string;
    //raw_material_type_id : number;
    raw_material_group_id: number;
    unit_measure_id: number;
    unitMeasure: UnitMeasureModel;
    fncTypes: Array<RawMaterialTypeFncRelationModel>;
}
