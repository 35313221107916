import { BaseModel } from './base.model';
import { TicketItemModel } from './ticket-item.model';

export class InventoryModel extends BaseModel {
    uid: string;
    fnc_id: number;
    code: string;
    documentDate: Date;
    documentNo: string;
    description: string;
    items: Array<TicketItemModel> = [];
}
