import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';

@Component({
    selector: 'app-stt-calendar',
    templateUrl: './stt-calendar.component.html',
    styleUrls: ['./stt-calendar.component.scss']
})
export class SttCalendarComponent implements OnInit {
    @Input() control: AbstractControl;
    @Input() icon: string;
    @Input() inputId: string;
    @Input() label = 'Data';
    @Input() iconPosition = 'left';
    @Input() disabledDays: Array<number>;
    @Input() minimumDate: Date;
    @Input() showCalendar = true;

    convertedControl: UntypedFormControl;
    @Output() changeEvent = new EventEmitter<any>();

    constructor() {
    }

    hasRequired(abstractControl: AbstractControl): boolean {
        if (abstractControl.validator) {
            const validator = abstractControl.validator({} as AbstractControl);
            if (validator && validator.required) {
                return true;
            }
        }
        return false;
    }

    onChange($event: any): void {
        this.changeEvent.emit($event);
    }

    onChangeClear(){
        this.changeEvent.emit(null);
    }

    onTodayClick(){
        // Used in Customer Order
        if(this.disabledDays.length > 0){
            this.changeEvent.emit(null);
        }
    }


    ngOnInit(): void {
        this.convertedControl = this.control as UntypedFormControl;
    }

}
