import { Component, OnInit } from '@angular/core';
import {UserModel} from '../../../../auth/models/user.model';
import {SttTableComponent} from '../../../../shared/components/stt-table/stt-table.component';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {UsersAddEditComponent} from '../users-add-edit/users-add-edit.component';
import { ConfirmationService, MessageService, PrimeNGConfig } from 'primeng/api';
import { ValidationMessageService } from '../../../../shared/services/base/validation-message-handle';
import { UserService } from '../../../../auth/user.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-users-actions-template',
  templateUrl: './users-actions-template.component.html',
  styleUrls: ['./users-actions-template.component.scss']
})
export class UsersActionsTemplateComponent implements OnInit {

  public data: UserModel;
  public table: SttTableComponent;
  public ref: DynamicDialogRef;
  public loading = true;

  constructor(
      public dialogService: DialogService,
      public confirmationService: ConfirmationService,
      public primengConfig: PrimeNGConfig,
      public itemService: UserService,
      public messageService: MessageService,
      private validationMessageService: ValidationMessageService,
  ) {

  }

  ngOnInit(): void {
  }

  setParams(data: UserModel): void {
      this.data = data;
  }

  setTableReference(table: SttTableComponent): void
  {
      this.table = table;
  }


  showDialog() {
      this.ref = this.dialogService.open(UsersAddEditComponent, {
          header: 'Modificare utilizator',
          width: '70%',
          contentStyle: {'max-height': '70vh'},
          baseZIndex: 10000,
          closeOnEscape: false,
          data: this.data,
      });

      this.ref.onClose.subscribe((user: UserModel) => {
          if (user) {
              this.table.refresh();
          }
      });
  }

    confirm() {
        this.confirmationService.confirm({
            header: 'Confirmare stergere FNC',
            message: 'Sunteti sigur ca doriti sa stergeti acest Utilizator?',
            key: 'deleteUser',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Da',
            rejectLabel: 'Nu',
            acceptButtonStyleClass: 'p-button-md p-button-success',
            rejectButtonStyleClass: 'p-button-md p-button-danger',
            accept: () => {
                this.deleteItem(this.data.uid);
            },
            reject: () => {
                this.confirmationService.close();
                this.table.refresh();
            }
        });
    }

    deleteItem(uid: string){
        this.itemService.delete(uid)
            .pipe(finalize(() => {this.loading = false;this.table.refresh();}))
            .subscribe((response: any) => {
                this.messageService.add({
                    severity: 'success',
                    summary: 'Utilizatorul a fost sters cu success!'
                });
            });
    }

}
