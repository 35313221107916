import { Injectable } from '@angular/core';
import { HttpApiService } from '../base/http-api.service';
import { Observable } from 'rxjs';
import { ApiResponseModel } from '../../entities/api-response.model';
import { CustomerProductModel } from '../../entities/customer-product.model';


@Injectable({
  providedIn: 'root'
})
export class CustomerProductsService {

    constructor(
        public httpApiService: HttpApiService,
    ) {

    }

    public get(uid): Observable<ApiResponseModel> {
        return this.httpApiService.httpGet('customer-products/view', {uid});
    }

    public set(customerProduct: CustomerProductModel): Observable<ApiResponseModel> {
        return this.httpApiService.httpPost('customer-products/store', customerProduct);
    }

    public delete(uid): Observable<ApiResponseModel> {
        return this.httpApiService.httpDelete('customer-products/destroy', { uid });
    }

}
