import { BaseNomenclatorModel } from './base.model';
import { RecipeItemModel } from './recipe-item.model';
import { RecipeNutrientsModel } from './recipe-nutrients.model';

export class RecipeModel extends BaseNomenclatorModel {
    name: string;
    code: string;
    //importedFile : string;
    items: Array<RecipeItemModel>;
    nutrients: Array<RecipeNutrientsModel>;
}
