import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { HttpApiService } from '../../../../../shared/services/base/http-api.service';
import { ValidationMessageService } from '../../../../../shared/services/base/validation-message-handle';
import { MessageService } from 'primeng/api';
import { finalize } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { TransportUnitTypeModel } from 'src/app/shared/entities/transport-unit-type.model';
import { TransportUnitTypeService } from 'src/app/shared/services/app/transport-unit-type.service';


@Component({
    selector: 'app-transport-unit-type-add-edit',
    templateUrl: './transport-unit-type-add-edit.component.html',
    styleUrls: ['./transport-unit-type-add-edit.component.scss']
})
export class TransportUnitTypeAddEditComponent implements OnInit {

    public uid: string = null;
    public itemModel: TransportUnitTypeModel = new TransportUnitTypeModel();
    public loading = false;
    public form: UntypedFormGroup;

    constructor(
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private formBuilder: UntypedFormBuilder,
        private httpApiService: HttpApiService,
        private validationMessageService: ValidationMessageService,
        private itemService: TransportUnitTypeService,
        private messageService: MessageService
    ) {
    }

    ngOnInit(): void {
        this.uid = (this.config.data) ? this.config.data.uid : null;

        this.createForm();

        if (this.uid) {
            this.getItem();
        }

        return;
    }

    onCloseDialog(item: TransportUnitTypeModel) {
        this.ref.close(item);
    }


    createForm(): void {
        this.form = this.formBuilder.group({
            uid: [this.itemModel.uid],
            name: [this.itemModel.name, [Validators.required, Validators.maxLength(100)]],
        });
    }

    getItem(): void {
        this.loading = true;
        this.itemService.get(this.uid)
            .pipe(finalize(() => this.loading = false))
            .subscribe((response: any) => {
                this.itemModel.map(response.payload);
                this.form.patchValue(this.itemModel);
            });
    }


    onCancel(): void {
        this.ref.close(null);
    }

    checkIfFormValid(): boolean {
        if (this.form.invalid) {
            this.messageService.add({
                severity: 'error',
                summary: 'Formularul nu este valid!',
                detail: 'Vericficati toate campurile si incercati din nou.'
            });
            this.form.markAllAsTouched();
            return false;
        }
        return true;
    }

    onSave(): void {
        if (!this.checkIfFormValid()) {
            return;
        }
        this.loading = true;
        const data = this.form.getRawValue();
        this.itemService.set(data)
            .pipe(finalize(() => this.loading = false))
            .subscribe((response: any) => {
                this.itemModel.map(response.payload);
                this.form.patchValue(this.itemModel);
                this.messageService.add({
                    severity: 'success',
                    summary: 'Tipul de unitate transport a fost salvat cu success!'
                });
                this.ref.close(this.itemModel);
            }, err => {
                if (err instanceof HttpErrorResponse) {
                    this.validationMessageService.serverSideValidation(err, this.form, '', true);
                }
            });
    }

}
