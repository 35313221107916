import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { HttpApiService } from '../../../../shared/services/base/http-api.service';
import { ValidationMessageService } from '../../../../shared/services/base/validation-message-handle';
import { MessageService } from 'primeng/api';
import { finalize } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { RawMaterialModel } from 'src/app/shared/entities/raw-material.model';
import { RawMaterialService } from 'src/app/shared/services/app/raw-material.service';
import { FncService } from 'src/app/shared/services/app/fnc.service';
import { FncModel } from 'src/app/shared/entities/fnc.model';
import { ApiResponseModel } from 'src/app/shared/entities/api-response.model';
import { RawMaterialTypeFncRelationModel } from 'src/app/shared/entities/raw-material-type-fnc-relation.model';


@Component({
    selector: 'app-raw-materials-add-edit',
    templateUrl: './raw-materials-add-edit.component.html',
    styleUrls: ['./raw-materials-add-edit.component.scss']
})

export class RawMaterialsAddEditComponent implements OnInit {
    public uid: string = null;
    public rawMaterialModel: RawMaterialModel = new RawMaterialModel();
    public rawMaterialTypeFncRelationModel: RawMaterialTypeFncRelationModel = new RawMaterialTypeFncRelationModel();
    public loading = false;
    public form: UntypedFormGroup;
    public fncsList: Array<FncModel> = [];
    public dropDownWithTrashed = false;

    constructor(
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private formBuilder: UntypedFormBuilder,
        private httpApiService: HttpApiService,
        private validationMessageService: ValidationMessageService,
        private rawMaterialService: RawMaterialService,
        private messageService: MessageService,
        private fncService: FncService,
    ) {
    }


    get formFncTypes() {
        return this.form.controls.fncTypes as UntypedFormArray;
    }

    ngOnInit(): void {
        this.uid = (this.config.data) ? this.config.data.uid : null;

        this.createForm();
        this.getFncList();
        return;
    }

    createForm(): void {
        this.form = this.formBuilder.group({
            uid: [this.rawMaterialModel.uid],
            name: [this.rawMaterialModel.name, [Validators.required, Validators.maxLength(200)]],
            code: [this.rawMaterialModel.code, [Validators.required, Validators.maxLength(100)]],
            unit_measure_id: [this.rawMaterialModel.unit_measure_id, Validators.required],
            raw_material_group_id : [this.rawMaterialModel.raw_material_group_id, Validators.required],
            fncTypes: this.formBuilder.array([]),
        });
    }

    getFncList() {
        this.fncService.getList()
            .pipe(finalize(() => this.loading = false))
            .subscribe((response: ApiResponseModel) => {
                this.fncsList = response.payload.data;
                this.fncsList.forEach((fnc: FncModel) => {
                    const fncTypeForm = this.formBuilder.group({
                        name: [fnc.name],
                        fnc_id: [fnc.id],
                        raw_material_type_id: [null, Validators.required],
                    });
                    this.formFncTypes.push(fncTypeForm);
                });

                if (this.uid) {
                    this.getItem();
                }
            });
    }


    getItem(): void {
        this.loading = true;
        this.dropDownWithTrashed = true;
        this.rawMaterialService.get(this.uid)
            .pipe(finalize(() => {this.loading = false; this.dropDownWithTrashed = false;}))
            .subscribe((response: ApiResponseModel) => {
                this.rawMaterialModel.map(response.payload);
                this.form.patchValue({
                    uid: this.rawMaterialModel.uid,
                    // Temporary resolve
                    name: response.payload.rawMaterialName,
                    code: this.rawMaterialModel.code,
                    unit_measure_id: this.rawMaterialModel.unit_measure_id,
                    raw_material_group_id: this.rawMaterialModel.raw_material_group_id
                });

                this.rawMaterialModel.fncTypes.forEach((fncType: RawMaterialTypeFncRelationModel) => {
                    const fncFormIndex = this.formFncTypes.controls.findIndex(item => (item.value.fnc_id === fncType.fnc_id));
                    if (fncFormIndex >= 0) {
                        const formFnc = this.formFncTypes.at(fncFormIndex);
                        formFnc.patchValue({
                            raw_material_type_id: fncType.raw_material_type_id,
                        });
                    }
                });
            });
    }

    onCloseDialog(rawMaterial: RawMaterialModel) {
        this.ref.close(rawMaterial);
    }

    onCancel(): void {
        this.ref.close(null);
    }

    checkIfFormValid(): boolean {
        if (this.form.invalid) {
            this.messageService.add({
                severity: 'error',
                summary: 'Formularul nu este valid!',
                detail: 'Vericficati toate campurile si incercati din nou.'
            });
            this.form.markAllAsTouched();
            return false;
        }
        return true;
    }
    onSave(): void {
        if (!this.checkIfFormValid()) {
            return;
        }
        this.loading = true;
        const data = this.form.getRawValue();
        this.rawMaterialService.set(data)
            .pipe(finalize(() => this.loading = false))
            .subscribe((response: any) => {
                this.rawMaterialModel.map(response.payload);
                this.form.patchValue(this.rawMaterialModel);
                this.messageService.add({
                    severity: 'success',
                    summary: 'Materia prima fost salvat cu success!'
                });
                this.ref.close(this.rawMaterialModel);
            }, err => {
                if (err instanceof HttpErrorResponse) {

                    this.validationMessageService.serverSideValidation(err, this.form, '', true);
                }
            });
    }

}
