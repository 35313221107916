import { Component, OnInit, ViewChild } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SttTableComponent } from '../../../../shared/components/stt-table/stt-table.component';
import { SttTableService } from '../../../../shared/components/stt-table/stt-table-service';
import { StorageSpaceService } from '../../../../shared/services/app/storage-space.service';
import { StorageSpaceModel } from '../../../../shared/entities/storage-space.model';
import { StorageSpaceTableActionComponent } from './storage-space-table-action/storage-space-table-action.component';
import { StorageSpaceAddEditComponent } from './storage-space-add-edit/storage-space-add-edit.component';

@Component({
    selector: 'app-storage-spaces',
    templateUrl: './storage-spaces.component.html',
    styleUrls: ['./storage-spaces.component.scss']
})
export class StorageSpacesComponent implements OnInit {

    @ViewChild('table', { static: false }) table: SttTableComponent;


    public tableEndpoint = 'storage-spaces';
    public tableActionComponent = StorageSpaceTableActionComponent;
    public tableQuery: any;
    public ref: DynamicDialogRef;

    constructor(private storageSpaceService: StorageSpaceService, public dialogService: DialogService) {
        this.tableQuery = new SttTableService().addInclude('fnc').addInclude('storageType');
    }

    ngOnInit(): void {
    }

    addNewEntity() {
        this.ref = this.dialogService.open(StorageSpaceAddEditComponent, {
            header: 'Adaugare Spatiu de Stocare',
            width: '70%',
            contentStyle: { 'max-height': '70vh' },
            baseZIndex: 10000,
            closeOnEscape: false,
            data: null,
        });

        this.ref.onClose.subscribe((storage: StorageSpaceModel) => {
            if (storage) {
                this.table.refresh();
            }
        });
    }
}
