import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductionComponent } from './production.component';
import { RouterModule, Routes } from '@angular/router';
import {AppMainComponent} from '../../app.main.component';
import {AuthGuardService} from '../../guards/auth-guard.service';

const routes: Routes = [
    {path: 'production', redirectTo: 'production/list', pathMatch: 'full'},
    {
        path: 'production', component: AppMainComponent,
        children: [
            {path: 'list', component: ProductionComponent, canActivate: [AuthGuardService]},
        ]
    },
];

@NgModule({
  declarations: [
    ProductionComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'}),
  ]
})
export class ProductionModule { }
