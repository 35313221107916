import {BaseModel} from './base.model';
import {UserModel} from '../../auth/models/user.model';
import {CountyModel} from './county.model';

export class CityModel extends BaseModel {

    id: number;
    name: string;
    county: CountyModel;
}
