import { Injectable } from '@angular/core';
import { HttpApiService } from '../base/http-api.service';
import { Observable } from 'rxjs';
import { ApiResponseModel } from '../../entities/api-response.model';
import { ConsumptionTicketModel } from '../../entities/consumption-ticket.model';

@Injectable({
  providedIn: 'root'
})
export class ConsumptionTicketService {

    constructor(
        public httpApiService: HttpApiService,
    ) {

    }

    public get(uid): Observable<ApiResponseModel>
    {
        return this.httpApiService.httpGet('consumption-tickets/view', {uid});
    }

    public set(item: ConsumptionTicketModel): Observable<ApiResponseModel>
    {
        return this.httpApiService.httpPost('consumption-tickets/store', item);
    }

    public delete(uid): Observable<ApiResponseModel> {
        return this.httpApiService.httpDelete('consumption-tickets/destroy', { uid });
    }

    public statusChange(status: number, uid): Observable<ApiResponseModel> {
        return this.httpApiService.httpGet('consumption-tickets/change-status', {status, uid});
    }
}
