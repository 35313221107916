import {BaseModel, BaseNomenclatorModel} from './base.model';
import {CountyModel} from './county.model';
import {CityModel} from './city.model';

export class FncModel extends BaseNomenclatorModel{

    id: number;
    name: string;
    uid: string;
    code: string;
    address: string;
    email: string;
    sarja: number;
    hasPlc = true;
    ipPlc: string;
    county: CountyModel = new CountyModel();
    city: CityModel = new CityModel();
}
