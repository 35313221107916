import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { HttpApiService } from '../../../../shared/services/base/http-api.service';
import { ValidationMessageService } from '../../../../shared/services/base/validation-message-handle';
import { MessageService } from 'primeng/api';
import { CustomerProductModel } from '../../../../shared/entities/customer-product.model';
import { CustomerProductsService } from '../../../../shared/services/app/customer-products.service';

@Component({
  selector: 'app-customer-products-add-edit',
  templateUrl: './customer-products-add-edit.component.html',
  styleUrls: ['./customer-products-add-edit.component.scss']
})

export class CustomerProductsAddEditComponent implements OnInit {
    public form: UntypedFormGroup;
    public loading = false;
    public customerProductsModel: CustomerProductModel = new CustomerProductModel();
    public uid: string = null;
    public dropDownWithTrashed = false;

  constructor(
      public ref: DynamicDialogRef,
      public config: DynamicDialogConfig,
      private formBuilder: UntypedFormBuilder,
      private httpApiService: HttpApiService,
      private validationMessageService: ValidationMessageService,
      private itemService: CustomerProductsService,
      private messageService: MessageService
      ) { }

  ngOnInit(): void {
      this.uid = (this.config.data) ? this.config.data.uid : null;

      this.createForm();

      if (this.uid) {
          this.getItem();
      }

      return;
  }

    onCancel(): void {
        this.ref.close(null);
    }

    getItem(): void {
        this.loading = true;
        this.dropDownWithTrashed = true;
        this.itemService.get(this.uid)
            .pipe(finalize(() => {this.loading = false; this.dropDownWithTrashed = false;}))
            .subscribe((response: any) => {
                this.customerProductsModel.map(response.payload);
                this.form.patchValue(this.customerProductsModel);
            });
    }

    createForm(): void {
        this.form = this.formBuilder.group({
            uid: [this.customerProductsModel.uid],
            name: [this.customerProductsModel.name, [Validators.required, Validators.maxLength(200)]],
            code: [this.customerProductsModel.name, [Validators.required, Validators.maxLength(200)]],
            product_category_id: [this.customerProductsModel.product_category_id, Validators.required],
            product_packing_type_id: [this.customerProductsModel.product_packing_type_id, Validators.required],
            unit_measure_id: [this.customerProductsModel.unit_measure_id, Validators.required],
        });
    }

    onSave(): void {
        if (this.form.invalid) {
            this.form.markAllAsTouched();
            return;
        }
        this.loading = true;
        const data = this.form.getRawValue();
        this.itemService.set(data)
            .pipe(finalize(() => this.loading = false))
            .subscribe((response: any) => {
                this.customerProductsModel.map(response.payload);
                this.form.patchValue(this.customerProductsModel);
                this.messageService.add({
                    severity: 'success',
                    summary: 'Produsul a fost salvat cu success!'
                });
                this.ref.close(this.customerProductsModel);
            }, err => {
                if (err instanceof HttpErrorResponse) {

                    this.validationMessageService.serverSideValidation(err, this.form, '', true);
                }
            });
    }

}
