<div class="grid">

    <div class="col-12">
        <div class="card" *ngIf="tableIsReadyToLoad">
            <!--<h2>Unitati de transport -  {{transportCompanyModel.name}}</h2>-->
            <app-stt-table #table
                           [endpoint]="tableEndpoint"
                           [actionTemplate]="tableActionComponent"
                           [query]="tableQuery"
                           (emitExpandReference)="emitExpandReference($event)">
            </app-stt-table>
        </div>

        <div class="card" *ngIf="!tableIsReadyToLoad">
            <p-progressBar mode="indeterminate" class="addEditProgressBar"></p-progressBar>
        </div>
    </div>
</div>

<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" appendTo="body" closeOnEscape="true" dismissableMask="true" key="deleteUnits" ></p-confirmDialog>
