import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpApiService} from '../base/http-api.service';
import {ApiResponseModel} from '../../entities/api-response.model';
import {TransportCompanyModel} from '../../entities/transport-company.model';

@Injectable({
    providedIn: 'root'
})
export class TransportCompanyService {

    constructor(
        public httpApiService: HttpApiService,
    ) {

    }

    public get(uid: string): Observable<ApiResponseModel> {
        return this.httpApiService.httpGet('transport-companies/view', {uid});
    }

    public set(transportCompany: TransportCompanyModel): Observable<ApiResponseModel> {
        return this.httpApiService.httpPost('transport-companies/store', transportCompany);
    }

    public getTransportUnit(uid: string): Observable<ApiResponseModel> {
        return this.httpApiService.httpGet('transport-companies/view', {uid});
    }

    public delete(uid): Observable<ApiResponseModel> {
        return this.httpApiService.httpDelete('transport-companies/destroy', { uid });
    }

}
