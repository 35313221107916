import { Component, OnInit, ViewChild } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SttTableComponent } from '../../../../shared/components/stt-table/stt-table.component';
import { SttTableService } from '../../../../shared/components/stt-table/stt-table-service';
import { TransportUnitAddEditComponent } from './transport-unit-add-edit/transport-unit-add-edit.component';
import { TransportUnitActionTableComponent } from './transport-unit-action-table/transport-unit-action-table.component';
import { TransportUnitTModel } from '../../../../shared/entities/transport-unit.model';
import { TransportCompanyModel } from '../../../../shared/entities/transport-company.model';

@Component({
    selector: 'app-transport-units',
    templateUrl: './transport-units.component.html',
    styleUrls: ['./transport-units.component.scss']
})
export class TransportUnitsComponent implements OnInit {
    @ViewChild('table', { static: false }) table: SttTableComponent;

    public tableEndpoint = 'transport-units';
    public tableActionComponent = TransportUnitActionTableComponent;
    public tableQuery: SttTableService;
    public ref: DynamicDialogRef;
    public transportCompanyModel: TransportCompanyModel;
    public tableIsReadyToLoad = false;

    constructor(public dialogService: DialogService) {
        this.tableQuery = new SttTableService()
            .addInclude('transportCompany')
            .addInclude('transportUnitDriver')
            .addInclude('transportUnitType')
            .addInclude('transportUnitBrand');
    }

    ngOnInit(): void {

    }

    setParams(data: any): void {
        this.transportCompanyModel = new TransportCompanyModel().map(data);
        this.tableQuery.complexFilters = {
            ['transport_company_id']: {
                matchMode: 'equals',
                value: this.transportCompanyModel.id
            }
        };
        this.tableIsReadyToLoad = true;
    }

    addNewEntity() {
        this.ref = this.dialogService.open(TransportUnitAddEditComponent, {
            header: 'Adaugare Materie prima',
            width: '70%',
            contentStyle: { 'max-height': '70vh' },
            baseZIndex: 10000,
            closeOnEscape: false,
            data: null,
        });

        this.ref.onClose.subscribe((item: TransportUnitTModel) => {
            if (item) {
                this.table.refresh();
            }
        });
    }

    emitExpandReference($event: any) {
        // console.log($event);
    }
}
