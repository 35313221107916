import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpApiService } from '../base/http-api.service';
import { ApiResponseModel } from '../../entities/api-response.model';
import { TransportUnitTypeModel } from '../../entities/transport-unit-type.model';

@Injectable({
    providedIn: 'root'
})
export class TransportUnitTypeService {

    constructor(
        public httpApiService: HttpApiService,
    ) {

    }

    public get(uid): Observable<ApiResponseModel> {
        return this.httpApiService.httpGet('transport-unit-types/view', { uid });
    }

    public set(item: TransportUnitTypeModel): Observable<ApiResponseModel> {
        return this.httpApiService.httpPost('transport-unit-types/store', item);
    }

    public delete(uid): Observable<ApiResponseModel> {
        return this.httpApiService.httpDelete('transport-unit-types/destroy', { uid });
    }
}
