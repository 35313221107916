import { Component, OnInit, ViewChild } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SttTableComponent } from 'src/app/shared/components/stt-table/stt-table.component';
import { ProductModel } from 'src/app/shared/entities/product.model';
import { SttTableService } from '../../../shared/components/stt-table/stt-table-service';
import { ProductsActionTableComponent } from './products-action-table/products-action-table.component';
import { ProductService } from '../../../shared/services/app/product.service';
import { ProductsAddEditComponent } from './products-add-edit/products-add-edit.component';

@Component({
    selector: 'app-products',
    templateUrl: './products.component.html',
    styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {
    @ViewChild('table', { static: false }) table: SttTableComponent;

    public tableEndpoint = 'products';
    public tableActionComponent = ProductsActionTableComponent;
    public tableQuery: any;
    public ref: DynamicDialogRef;

    constructor(public dialogService: DialogService) {
        this.tableQuery = new SttTableService()
            .addInclude('recipe')
            .addInclude('fnc')
            .addInclude('category')
            .addInclude('productPackingType')
            .addInclude('unitMeasure');
    }

    ngOnInit(): void {

    }

    addNewEntity() {
        this.ref = this.dialogService.open(ProductsAddEditComponent, {
            header: 'Adaugare produs',
            width: '70%',
            contentStyle: { 'max-height': '70vh' },
            baseZIndex: 10000,
            closeOnEscape: false,
            data: null,
        });

        this.ref.onClose.subscribe((product: ProductModel) => {
            if (product) {
                this.table.refresh();
            }
        });
    }

}
