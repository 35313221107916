import { BaseNomenclatorModel } from './base.model';
import { RecipeItemModel } from './recipe-item.model';

export class OrderRawMaterialModel extends BaseNomenclatorModel{
    uid: string;
    supplier_id: number;
    fnc_id: number;
    code: string;
    orderDate: Date;
    description: string;
    status: number;
    items: Array<RecipeItemModel> = [];
}
