import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpApiService} from '../base/http-api.service';
import {ApiResponseModel} from '../../entities/api-response.model';
import {FncModel} from '../../entities/fnc.model';
import { TableService } from 'primeng/table';

@Injectable({
    providedIn: 'root'
})

export class FncService {

    constructor(
        public httpApiService: HttpApiService,
    ) {

    }

    public getList(params?: TableService): Observable<ApiResponseModel>
    {
        return this.httpApiService.httpGet('fncs', params);
    }

    public get(uid): Observable<ApiResponseModel>
    {
        return this.httpApiService.httpGet('fncs/view', {uid});
    }

    public getById(id): Observable<ApiResponseModel>
    {
        return this.httpApiService.httpGet('fncs/view-by-id', {id});
    }

    public set(fnc: FncModel): Observable<ApiResponseModel>
    {
        return this.httpApiService.httpPost('fncs/store', fnc);
    }

    public delete(uid): Observable<ApiResponseModel> {
        return this.httpApiService.httpDelete('fncs/destroy', { uid });
    }

}


