<a
    routerLink="/orders/raw-materials/list"
    style="color: #808080;font-size: 18px">
    <i class="fas fa-arrow-left"></i>
    <span>Inapoi la lista de comenzi</span>
</a>

<br>
<br>
<form [formGroup]="form" autocomplete="off">
    <p-card>
        <div class="grid fields-group" style="padding: 0 1em">
            <div class="col-12">
                <div class="grid p-fluid">
                    <div class="field col-12 md:col-4">
                        <app-stt-dropdown endPoint="suppliers/dropdown"
                                          placeholder="Nu ati selectat un furnizor!"
                                          icon="pi pi-user"
                                          [formControlParent]="form.controls['supplier_id']"
                                          label="Furnizor"
                                          [dropDownWithTrashed]="dropDownWithTrashed"
                                            [readonly]="readOnly"></app-stt-dropdown>
                    </div>
                    <div class="field col-12 md:col-4">
                        <app-stt-dropdown placeholder="Nu ati selectat un FNC!" endPoint="fncs/dropdown"
                                          icon="pi pi-cog"
                                          [formControlParent]="form.controls['fnc_id']" label="Fnc"
                                          [readonly]="readOnly"
                                          [dropDownWithTrashed]="dropDownWithTrashed"></app-stt-dropdown>
                    </div>
                    <div class="field col-12 md:col-4">
                        <app-stt-input inputId="code" [control]="form.controls['code']"
                                       icon="pi pi-key"
                                       [readonly]="readOnly"
                                       label="Cod SAP"></app-stt-input>
                    </div>
                </div>
                <div class="grid p-fluid">
                    <div class="field col-12 md:col-5">
                        <app-stt-calendar label="Data" icon="pi pi-calendar" [minimumDate]="minimumDate"
                                          [control]="form.controls['orderDate']"
                                            [showCalendar]="showCalendar"></app-stt-calendar>
                    </div>
                    <div class="field col-12 md:col-7" style="border-left: 1px solid lightgray">
                        <app-stt-textarea inputId="description" [control]="form.controls['description']"
                                          icon="pi pi-pencil" [readOnly]="readOnly"
                                          label="Descriere"></app-stt-textarea>
                    </div>
                </div>
                <p-divider></p-divider>
                <div class="grid fields-group">
                    <div class="col-12 grid" style="flex-wrap: nowrap; gap: 2rem;">
                        <div class="container col-9" formArrayName="items">
                            <ng-container
                                *ngFor="let recipeItemForm of formRawMaterialOrderItems().controls; let index = index">
                                <ng-container [formGroup]="recipeItemForm">
                                    <div class="grid p-fluid" *ngIf="!recipeItemForm.controls.needDelete.value">
                                        <div class="field col-7 md:col-6 xl:col-7">
                                            <app-stt-dropdown endPoint="raw-materials/dropdown"
                                                              icon="pi pi-check-square"
                                                              placeholder="Nu ati selectat o materie prima!"
                                                              [formControlParent]="recipeItemForm.controls['raw_material_id']"
                                                              [dropDownWithTrashed]="dropDownWithTrashed"
                                                              [readonly]="readOnly"
                                                              label="Materia prima">
                                            </app-stt-dropdown>
                                        </div>
                                        <div class="field col-4 md:col-5 xl:col-4">
                                            <app-stt-input inputId="units" [control]="recipeItemForm.controls['units']"
                                                           icon="pi pi-box" type="number"
                                                           label="Unitati"
                                                           [form]="form"
                                                           [readonly]="readOnly"
                                                           [index]="index"
                                            ></app-stt-input>
                                        </div>
                                        <div class="field col-1 md:col-1 xl:col-1">
                                            <button (click)="deleteFormItem(index)"
                                                    class="p-button-raised p-button-danger text-right"
                                                    [disabled]="readOnly"
                                                    icon="pi pi-minus" pButton pRipple type="button"></button>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>
                        <div class="col-3 grid align-items-end" style="padding: 14px">
                            <button (click)="addFormElement()"
                                    class="p-button-raised p-button-success text-right"
                                    icon="pi pi-plus"
                                    [style]="getStyleButton()"
                                    label="Adauga" pButton [disabled]="readOnly"
                                    pRipple type="button"></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </p-card>
    <p-progressBar mode="indeterminate" class="addEditProgressBar" *ngIf="loading"></p-progressBar>

    <div class="actions-area">
        <div class="actions-left">
            <!--<button pButton pRipple type="button" label="Anulare" class="p-button-raised p-button-danger" icon="pi pi-ban" [disabled]="loading" (click)="onCancel()">
            </button>-->
            <button pButton pRipple type="button" label="Anulare" class="p-button-raised p-button-danger"
                    icon="pi pi-ban" [disabled]="loading" routerLink="/orders/raw-materials/list"></button>
        </div>

        <div class="actions-right">
            <button *ngIf="!status || status === enumStatus.Draft" pButton pRipple type="button" label="Salveaza" class="p-button-raised p-button-success"
                    icon="pi pi-save" [disabled]="loading" (click)="onSave()">
            </button>
            <p-button *ngIf="status === enumStatus.Draft" [label]="'Validare'" icon="pi pi-check" iconPos="right" (click)="validateForm()" [ngStyle]="{'margin-left' : '2px'}"></p-button>
            <p-button *ngIf="status === enumStatus.Validated" [label]="'Finalizare'" icon="pi pi-check" iconPos="right" (click)="finishForm()" [ngStyle]="{'margin-left' : '2px'}"></p-button>
        </div>
    </div>
</form>

