import { Component, OnInit, ViewChild } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SttTableComponent } from '../../../shared/components/stt-table/stt-table.component';
import { SttTableService } from '../../../shared/components/stt-table/stt-table-service';
import { RawMaterialsActionTableComponent } from './raw-materials-action-table/raw-materials-action-table.component';
import { RawMaterialsAddEditComponent } from './raw-materials-add-edit/raw-materials-add-edit.component';
import { RawMaterialModel } from 'src/app/shared/entities/raw-material.model';
import { RawMaterialService } from 'src/app/shared/services/app/raw-material.service';

@Component({
    selector: 'app-raw-materials',
    templateUrl: './raw-materials.component.html',
    styleUrls: ['./raw-materials.component.scss']
})
export class RawMaterialsComponent implements OnInit {

    @ViewChild('table', { static: false }) table: SttTableComponent;

    public tableEndpoint = 'raw-materials';
    public tableActionComponent = RawMaterialsActionTableComponent;
    public tableQuery: any;
    public ref: DynamicDialogRef;

    constructor(public dialogService: DialogService) {
        this.tableQuery = new SttTableService().addInclude('unitMeasure');
    }

    ngOnInit(): void {

    }

    addNewEntity() {
        this.ref = this.dialogService.open(RawMaterialsAddEditComponent, {
            header: 'Adaugare Materie prima',
            width: '70%',
            contentStyle: { 'max-height': '70vh' },
            baseZIndex: 10000,
            closeOnEscape: false,
            data: null,
        });

        this.ref.onClose.subscribe((rawMaterial: RawMaterialModel) => {
            if (rawMaterial) {
                this.table.refresh();
            }
        });
    }
}
