import { BaseNomenclatorModel } from './base.model';
import { LossTicketItemModel } from './loss-ticket-item.model';

export class LossTicketModel extends BaseNomenclatorModel {
    uid: string;
    fnc_id: number;
    code: string;
    documentDate: Date;
    documentNo: string;
    description: string;
    items: Array<LossTicketItemModel> = [];
}
