import { Component, OnInit } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { MessageService } from 'primeng/api';
import { UntypedFormBuilder, FormGroup } from '@angular/forms';
import { OrderCustomerService } from '../../../../shared/services/app/order-customer.service';
import { OrderCustomerTransportService } from '../../../../shared/services/app/order-customer-transport.service';
import { ValidationMessageService } from '../../../../shared/services/base/validation-message-handle';
import { finalize } from 'rxjs/operators';
import {
    OrdersCustomerActionTableComponent
} from '../orders-customer-action-table/orders-customer-action-table.component';
import { SttTableService } from '../../../../shared/components/stt-table/stt-table-service';
import {
    OrdersCustomersTransportViewActionTableComponent
} from './orders-customers-transport-view-action-table/orders-customers-transport-view-action-table.component';
import {
    OrderCustomersTransportProgressbarComponent
} from '../orders-customers-transport/order-customers-transport-progressbar/order-customers-transport-progressbar.component';

@Component({
    selector: 'app-orders-customers-transport-view',
    templateUrl: './orders-customers-transport-view.component.html',
    styleUrls: ['./orders-customers-transport-view.component.scss']
})
export class OrdersCustomersTransportViewComponent implements OnInit {


    public tableEndpoint = 'customer-order-transports';
    public tableActionComponent = OrdersCustomersTransportViewActionTableComponent;
    public tableQuery: SttTableService;
    public ref: DynamicDialogRef;
    public orderCustomerModel: any;
    public tableIsReadyToLoad = false;
    public expandComponent = OrderCustomersTransportProgressbarComponent;

    constructor(public dialogService: DialogService,
                public orderCustomerService: OrderCustomerService,
                private formBuilder: UntypedFormBuilder,
                private orderCustomerTransportService: OrderCustomerTransportService,
                private messageService: MessageService,
                private validationMessageService: ValidationMessageService) {
    }

    ngOnInit(): void {
        this.tableQuery = new SttTableService()
            .addInclude('customerOrder')
            .addInclude('transportCompany')
            .addInclude('transportUnitDriver')
            .addInclude('transportUnitType')
            .addInclude('product')
            .addInclude('fnc');
    }
}
