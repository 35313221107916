import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { HttpApiService } from '../../../shared/services/base/http-api.service';
import { ValidationMessageService } from '../../../shared/services/base/validation-message-handle';
import { MessageService } from 'primeng/api';
import { finalize } from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {FncService} from '../../../shared/services/app/fnc.service';
import { OrderCustomerModel } from '../../../shared/entities/order-customer.model';
import { OrderCustomerService } from '../../../shared/services/app/order-customer.service';
import { ApiResponseModel } from '../../../shared/entities/api-response.model';
import {FncModel} from '../../../shared/entities/fnc.model';
import { CustomerService } from '../../../shared/services/app/customer.service';
import { CustomerModel } from '../../../shared/entities/customer.model';


@Component({
  selector: 'app-dashboard-add-edit',
  templateUrl: './dashboard-add-edit.component.html',
  styleUrls: ['./dashboard-add-edit.component.scss']
})
export class DashboardAddEditComponent implements OnInit {

    public uid: string = null;
    public itemModel: OrderCustomerModel = new OrderCustomerModel();
    public loading = false;
    public form: UntypedFormGroup;
    // New added
    public minimumDate = new Date();
    products: any[];
    public customerList: Array<CustomerModel>;
    public selectedCustomer: any;
    public selectedAddress: any;
    public returnProductId = true;



    constructor(
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private formBuilder: UntypedFormBuilder,
        private httpApiService: HttpApiService,
        private validationMessageService: ValidationMessageService,
        private fncService: OrderCustomerService,
        private messageService: MessageService,
        // New Added
        private customerService: CustomerService
    ) { }


    ngOnInit(): void
    {   this.customerList = [];

        this.uid = (this.config.data) ? this.config.data.uid : null;

        this.createForm();

        if (this.uid){
            this.getItem();
        }

        this.getCustomerList();

        return;
    }

    createForm(): void
    {
        this.form = this.formBuilder.group({
            uid: [this.itemModel.uid],
            customer_id: [this.itemModel.customer_id, Validators.required],
            customer_address_id: [this.itemModel.customer_address_id, Validators.required],
            product_category_id: [this.itemModel.product_category_id, Validators.required],
            weekOrder: this.formBuilder.array([])
        });

        this.formWeek().push(this.newWeekDay(null, null));
        this.formWeek().push(this.newWeekDay('L', [0, 2, 3, 4, 5, 6]));
        this.formWeek().push(this.newWeekDay('M', [0, 1, 3, 4, 5, 6]));
        this.formWeek().push(this.newWeekDay('M', [0, 1, 2, 4, 5, 6]));
        this.formWeek().push(this.newWeekDay('J', [0, 1, 2, 3, 5, 6]));
        this.formWeek().push(this.newWeekDay('V', [0, 1, 2, 3, 4, 6]));
        this.formWeek().push(this.newWeekDay('S', [0, 1, 2, 3, 4, 5]));
        this.formWeek().push(this.newWeekDay('D', [1, 2, 3, 4, 5, 6]));

        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < this.formWeek().length; i++){
            this.formProducts(i).push(this.newProduct());
        }
    }


    formWeek(): UntypedFormArray{
        return this.form.get('weekOrder') as UntypedFormArray;
    }

    formProducts(index: number): UntypedFormArray{
        return this.formWeek().at(index).get('products') as UntypedFormArray;
    }

    newWeekDay(day?, currentAvailableDay?): UntypedFormGroup{
        const newForm = this.formBuilder.group({
            day: [day, Validators.required],
            currentAvailableDay: [currentAvailableDay, Validators.required],
            deliveryDate: [null, Validators.required],
            deliveryHourStart: [null, Validators.required],
            deliveryHourEnd: [null, Validators.required],
            products: this.formBuilder.array([]),
        });
        return newForm;
    }

    newProduct(): UntypedFormGroup{
        return this.formBuilder.group({
            product_id: [null],
            quantity: [null],
            product_packing_type_id: [null],
        });
    }


    addProduct(){
        for (let i = 0; i < this.formWeek().length; i++){
            this.formProducts(i).push(this.newProduct());
        }
    }


    SetProductId(event): void{
        for (let i = 1; i < this.formWeek().length; i++){
            const formItem = this.formProducts(i);
            formItem.at(event.index).patchValue({
                product_id: event.id
            });
        }
    }


    onSelectCalendar(event , index): void{
        const date = new Date(event).getDate();
        const month = new Date(event).getMonth();
        const year = new Date(event).getFullYear();
        const formOrder = this.formWeek().at(index);
        formOrder.patchValue({
            deliveryDate: event
        });
    }
    onSelectTimeStart(event , index): void{
        let time;
        const hour = new Date(event).getHours();
        const minutes = new Date(event).getMinutes();
        if (minutes < 10){
            time = `${hour}:0${minutes}`;
        }
        else{
            time = `${hour}:${minutes}`;
        }
        const formOrder = this.formWeek().at(index);
        formOrder.patchValue({
            deliveryHourStart: time
        });
    }

    onSelectTimeEnd(event , index): void{
        let time;
        const hour = new Date(event).getHours();
        const minutes = new Date(event).getMinutes();
        if (minutes < 10){
            time = `${hour}:0${minutes}`;
        }
        else{
            time = `${hour}:${minutes}`;
        }
        const formOrder = this.formWeek().at(index);
        formOrder.patchValue({
            deliveryHourEnd: time
        });
    }

    onChange(ev){
        this.selectedCustomer = ev;
        // this.form.value.customer_id = ev.value.customer_id;
        this.form.patchValue({
            customer_id: this.selectedCustomer.value.id,
        });
    }

    onChangeAddress(ev){
        this.selectedAddress = ev;
        this.form.patchValue({
            customer_address_id: this.selectedAddress.value.id,
        });
    }

    getCustomerList() {
        this.customerService.getList()
            .pipe(finalize(() => this.loading = false))
            .subscribe((response: ApiResponseModel) => {
                response.payload.data.forEach((customer: CustomerModel) => {
                    // console.log('Aici', customer);
                    this.customerService.get(customer.uid)
                        .pipe(finalize(() => this.loading = false))
                        .subscribe((addressesResponse: any) => {
                            this.customerList.push(addressesResponse.payload);
                        });
                });
            });
    }





    onCloseDialog(item: OrderCustomerModel)
    {
        this.ref.close(item);
    }

    getItem(): void
    {
        this.loading = true;
        this.fncService.get(this.uid)
            .pipe(finalize(() => this.loading = false))
            .subscribe((response: any) => {
                this.itemModel.map(response.payload);
                this.form.patchValue(this.itemModel);
            });
    }


    onCancel(): void {
        this.ref.close(null);
    }

    onSave(): void {/*
        this.loading = true;
        const data = this.form.getRawValue();
        this.fncService.set(data)
            .pipe(finalize(() => this.loading = false))
            .subscribe((response: any) => {
                this.itemModel.map(response.payload);
                this.form.patchValue(this.itemModel);
                this.messageService.add({
                    severity: 'success',
                    summary: 'Comanda a fost plasata cu success!'
                });
                this.ref.close(this.itemModel);
            }, err => {
                if (err instanceof HttpErrorResponse) {
                    this.validationMessageService.serverSideValidation(err, this.form, '' , true);
                }
            });*/
    }

}
