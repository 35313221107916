import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class MenuService {

    menuSource$: Observable<string>;
    resetSource$: Observable<any>;

    private menuSource = new Subject<string>();
    private resetSource = new Subject();


    constructor() {
        this.menuSource$ = this.menuSource.asObservable();
        this.resetSource$ = this.menuSource.asObservable();
    }

    onMenuStateChange(key: string) {
        this.menuSource.next(key);
    }

    reset() {
        this.resetSource = new Subject<void>();
    }
}
