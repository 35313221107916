import { Component, OnInit, ViewChild } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SttTableComponent } from '../../../shared/components/stt-table/stt-table.component';
import { UserModel } from '../../../auth/models/user.model';
import { CustomersAddEditComponent } from './customers-add-edit/customers-add-edit.component';
import { CustomerService } from '../../../shared/services/app/customer.service';
import { CustomersActionsTableComponent } from './customers-actions-table/customers-actions-table.component';
import { SttTableService } from '../../../shared/components/stt-table/stt-table-service';

@Component({
    selector: 'app-customers',
    templateUrl: './customers.component.html',
    styleUrls: ['./customers.component.scss']
})
export class CustomersComponent implements OnInit {
    @ViewChild('table', { static: false }) table: SttTableComponent;

    public tableEndpoint = 'customers';
    public tableActionComponent = CustomersActionsTableComponent;
    public tableQuery: any;
    public ref: DynamicDialogRef;

    constructor(public dialogService: DialogService) {
        this.tableQuery = new SttTableService()
            .addInclude('county')
            .addInclude('city');
    }

    ngOnInit(): void {
    }

    addNewEntity() {
        this.ref = this.dialogService.open(CustomersAddEditComponent, {
            header: 'Adaugare Client',
            width: '70%',
            contentStyle: { 'max-height': '70vh' },
            baseZIndex: 10000,
            closeOnEscape: false,
            data: null,
        });

        this.ref.onClose.subscribe((user: UserModel) => {
            if (user) {
                this.table.refresh();
            }
        });
    }
}
