<div class="layout-wrapper" [ngClass]="{'p-input-filled': app.inputStyle === 'filled', 'p-ripple-disabled': !app.ripple,
        'layout-static': isStatic(),
        'layout-overlay': isOverlay(),
        'layout-overlay-active': overlayMenuActive && isOverlay(),
        'layout-horizontal': isHorizontal(),
        'layout-static-active': !staticMenuDesktopInactive && isStatic(),
        'layout-mobile-active': staticMenuMobileActive,
        'layout-rtl': app.isRTL,
        'layout-rightpanel-active': rightPanelActive}" [class]="'layout-menu-'+ app.menuTheme+ ' layout-topbar-'+ app.topbarTheme">

    <app-topbar></app-topbar>

    <app-rightpanel></app-rightpanel>

    <div class="menu-wrapper" (click)="onMenuClick($event)">
        <div class="layout-menu-container">
            <h2>Vitall</h2>
            <app-menu></app-menu>
        </div>
    </div>

    <div class="layout-main">
        <app-breadcrumb></app-breadcrumb>

        <div class="layout-content">
            <router-outlet></router-outlet>
        </div>

        <app-footer></app-footer>
    </div>


    <div *ngIf="staticMenuMobileActive" class="layout-mask modal-in"></div>

</div>

