import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { HttpApiService } from '../../../../shared/services/base/http-api.service';
import { ValidationMessageService } from '../../../../shared/services/base/validation-message-handle';
import { MessageService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { QualityReportModel } from '../../../../shared/entities/quality-report.model';
import { finalize } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { RecipeService } from '../../../../shared/services/app/recipe.service';
import { EnzymesService } from '../../../../shared/services/app/enzymes.service';
import { EnzymesModel } from '../../../../shared/entities/enzimes.model';
import { CoccidiostatsModel } from '../../../../shared/entities/coccidiostats.model';
import { CoccidiostatsService } from '../../../../shared/services/app/coccidiostats.service';
import { QualityReportService } from '../../../../shared/services/app/quality-report.service';

@Component({
  selector: 'app-quality-report-add-edit',
  templateUrl: './quality-report-add-edit.component.html',
  styleUrls: ['./quality-report-add-edit.component.scss']
})
export class QualityReportAddEditComponent implements OnInit {

    public form: UntypedFormGroup;
    public itemModel: QualityReportModel = new QualityReportModel();
    public uid: string = null;
    public loading = false;
    public dropDownWithTrashed = false;
    public minimumDate: Date = new Date();

  constructor(
      public ref: DynamicDialogRef,
      public config: DynamicDialogConfig,
      private formBuilder: UntypedFormBuilder,
      private httpApiService: HttpApiService,
      private validationMessageService: ValidationMessageService,
      private itemService: QualityReportService,
      private recipeService: RecipeService,
      private enzymeService: EnzymesService,
      public coccidiostatService: CoccidiostatsService,
      private messageService: MessageService,
      private route: ActivatedRoute,
      private router: Router,
      private datePipe: DatePipe
  ) {
      this.uid = this.route.snapshot.queryParams.uid;
  }

  ngOnInit(): void {

      this.createForm();

      if(this.uid){
          this.getItem();
      }
  }


    createForm(): void {
        this.form = this.formBuilder.group({
            uid: [this.itemModel.uid],
            product_name: [this.itemModel.product_name, Validators.required],
            product_id: [this.itemModel.product_id, Validators.required],
            lot: [this.itemModel.lot, Validators.required],
            production_date: [this.itemModel.production_date, Validators.required],
            validity: [this.itemModel.validity, Validators.required],
            usage_instructions: [this.itemModel.usage_instructions, Validators.maxLength(255)],
            recipe_id: [this.itemModel.recipe_id, Validators.required],
            genetically_modified_soy: [this.itemModel.genetically_modified_soy, Validators.required],
            observations: [null, Validators.required],
            enzyme_items: this.formBuilder.array([]),
            coccidiostats_items: this.formBuilder.array([]),
            recipe_raw_materials: this.formBuilder.array([]),
            recipe_nutrients: this.formBuilder.array([]),
        });

        if (!this.uid) {
            this.getEnzymes();
            this.getCoccidiostats();
        }
    }

    formEnzymeItems(): UntypedFormArray {
        return this.form.get('enzyme_items') as UntypedFormArray;
    }

    formCoccidiostatItems(): UntypedFormArray {
        return this.form.get('coccidiostats_items') as UntypedFormArray;
    }

    formRecipeRawMaterials(): UntypedFormArray {
        return this.form.get('recipe_raw_materials') as UntypedFormArray;
    }

    formRecipeNutrients(): UntypedFormArray {
        return this.form.get('recipe_nutrients') as UntypedFormArray;
    }

    addEnzymeItem(item?: EnzymesModel) {
        const enzymeItem = this.formBuilder.group({
            enzime_id: [(item) ? item.id : null, Validators.required],
            checked: [false]
        });
        this.formEnzymeItems().push(enzymeItem);
    }

    addCoccidiostatItem(item?: CoccidiostatsModel) {
        const coccidiostatsItem = this.formBuilder.group({
            coccibiostatic_id: [(item) ? item.id : null, Validators.required],
            checked: [false]
        });
        this.formCoccidiostatItems().push(coccidiostatsItem);
    }

    addRecipeRawMaterial(item?: any): void {
        const rawMaterial = this.formBuilder.group({
            raw_material_id: [(item) ? item.raw_material_id : null, Validators.required],
            units: [(item) ? item.units : null, Validators.required],
        });
        this.formRecipeRawMaterials().push(rawMaterial);
    }

    addRecipeNutrients(item?: any): void {
        const nutrient = this.formBuilder.group({
            uid: [(item) ? item.uid : null],
            name: [(item) ? item.name : null],
            units: [(item) ? item.units : null],
            unit_measure: [(item) ? item.unit_measure : null],
            additives: [(item) ? item.additives : false],
        });
        this.formRecipeNutrients().push(nutrient);
    }

    onProductChange(event){
        this.loading = true;
        if (event){
            this.recipeService.getRecipesByProductId(event.id)
                .pipe(finalize(() => this.loading = false))
                .subscribe((response: any) => {
                    response.payload.items.forEach((element, index) => {
                        this.addRecipeRawMaterial(element);
                    });
                    response.payload.nutrients.forEach((element, index) => {
                        this.addRecipeNutrients(element);
                    });
                    this.form.patchValue({
                        recipe_id: response.payload.id
                    });
                });
        }
        else{
            this.loading = false;
            this.formRecipeRawMaterials().clear();
            this.formRecipeNutrients().clear();
        }
    }


    getEnzymes(){
      this.enzymeService.getList()
          .pipe(finalize(() => this.loading = false))
          .subscribe((response: any) => {
              response.payload.forEach(element =>{
                  this.addEnzymeItem(element);
              });
          });
    }

    getCoccidiostats(){
        this.coccidiostatService.getList()
            .pipe(finalize(() => this.loading = false))
            .subscribe((response: any) => {
                response.payload.forEach(element =>{
                    this.addCoccidiostatItem(element);
                });
            });
    }


    getItem(): void {
        this.loading = true;
        this.dropDownWithTrashed = true;
        this.itemService.get(this.uid)
            .pipe(finalize(() => {this.loading = false;}))
            .subscribe((response: any) => {
                this.itemModel.map(response.payload);
                this.form.patchValue(this.itemModel);
                const dateObject = new Date(response.payload.production_date);
                this.form.patchValue({
                    production_date: dateObject,
                });
                this.onProductChange({ id: this.form.value.product_id});
                response.payload.enzimeItems.forEach(element => {
                    const enzymeItem = this.formBuilder.group({
                        uid: [(element) ? element.uid : null],
                        enzime_id: [(element) ? element.enzime_id : null, Validators.required],
                        checked: [true]
                    });
                    this.formEnzymeItems().push(enzymeItem);
                });
                response.payload.coccidiostatsItems.forEach(element => {
                    const coccidiostatsItem = this.formBuilder.group({
                        uid: [(element) ? element.uid : null],
                        coccibiostatic_id: [(element) ? element.coccibiostatic_id : null, Validators.required],
                        checked: [true]
                    });
                    this.formCoccidiostatItems().push(coccidiostatsItem);
                });
            });
    }

    checkedBox(event: any, index: number, isEnzymes: boolean){
      if(isEnzymes){
          this.formEnzymeItems().at(index).patchValue({
              checked: event.checked
          });
      }
      else{
          this.formCoccidiostatItems().at(index).patchValue({
              checked: event.checked
          });
      }
    }

    removeUnselectedEnzymes(): void{
      const indexes = [];
      this.formEnzymeItems().controls.forEach((element, index) => {
          if(element.value.checked === false){
              indexes.push(index);
          }
         indexes.slice().reverse().forEach(current_index => {
             this.formEnzymeItems().removeAt(current_index);
         });
      });
    }

    removeUnselectedCoccidiostats(): void{
        const indexes = [];
        this.formCoccidiostatItems().controls.forEach((element, index) => {
            if(element.value.checked === false){
                indexes.push(index);
            }
            indexes.slice().reverse().forEach(current_index => {
                this.formCoccidiostatItems().removeAt(current_index);
            });
        });
    }


    checkIfFormValid(): boolean {
        if (this.form.invalid) {
            this.messageService.add({
                severity: 'error',
                summary: 'Formularul nu este valid!',
                detail: 'Vericficati toate campurile si incercati din nou.'
            });
            this.form.markAllAsTouched();
            return false;
        }
        return true;
    }

    onSave(): void {
        this.removeUnselectedEnzymes();
        this.removeUnselectedCoccidiostats();
        if (!this.checkIfFormValid()) {
            return;
        }
        this.loading = true;
        const data = this.form.getRawValue();
        this.itemService.set(data)
            .pipe(finalize(() => this.loading = false))
            .subscribe((response: any) => {
                this.itemModel.map(response.payload);
                this.form.patchValue(this.itemModel);
                this.messageService.add({
                    severity: 'success',
                    summary: 'Raportul de calitate a fost salvat cu success!'
                });
                this.router.navigate(['laboratory/quality-report/list'], {});
            }, err => {
                if (err instanceof HttpErrorResponse) {
                    this.validationMessageService.serverSideValidation(err, this.form, '', true);
                }
            });
    }
}
