import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';

@Component({
    selector: 'app-stt-textarea',
    templateUrl: './stt-textarea.component.html',
    styleUrls: ['./stt-textarea.component.scss']
})
export class SttTextareaComponent implements OnInit {
    @Input() label: string;
    @Input() icon: string;
    @Input() control: AbstractControl;
    @Input() inputId: string;
    @Input() minHeight = '6.4em';
    @Input() iconPosition = 'left';
    @Input() readOnly = false;

    convertedControl: UntypedFormControl;

    constructor() {
    }

    hasRequired(abstractControl: AbstractControl): boolean {
        if (abstractControl.validator) {
            const validator = abstractControl.validator({} as AbstractControl);
            if (validator && validator.required) {
                return true;
            }
        }
        return false;
    }

    ngOnInit(): void {
        this.convertedControl = this.control as UntypedFormControl;
    }

}
