import { BaseModel, BaseNomenclatorModel } from './base.model';

export class CustomerProductModel extends BaseNomenclatorModel {
    uid: string;
    name: string;
    code: string;
    product_packing_type_id: number;
    unit_measure_id: number;
    product_category_id: number;
}


