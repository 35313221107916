import { Component, OnInit } from '@angular/core';
import {SttTableComponent} from '../../../../shared/components/stt-table/stt-table.component';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {ProductCategoryModel} from '../../../../shared/entities/product-category.model';
import {ProductCategoriesAddEditComponent} from '../product-categories-add-edit/product-categories-add-edit.component';
import { ConfirmationService, MessageService, PrimeNGConfig } from 'primeng/api';
import { ValidationMessageService } from '../../../../shared/services/base/validation-message-handle';
import { ProductCategoriesService } from '../../../../shared/services/app/product-categories.service';
import { finalize } from "rxjs/operators";

@Component({
  selector: 'app-product-categories-action-table',
  templateUrl: './product-categories-action-table.component.html',
  styleUrls: ['./product-categories-action-table.component.scss']
})
export class ProductCategoriesActionTableComponent implements OnInit {

    public data: ProductCategoryModel;
    public table: SttTableComponent;
    public ref: DynamicDialogRef;
    public loading = true;

    constructor(
        public dialogService: DialogService,
        public confirmationService: ConfirmationService,
        public primengConfig: PrimeNGConfig,
        public itemService: ProductCategoriesService,
        public messageService: MessageService,
        private validationMessageService: ValidationMessageService,
    ) {

    }

    ngOnInit(): void {
    }

    setParams(entity: ProductCategoryModel): void {
        this.data = entity;
    }

    setTableReference(table: SttTableComponent): void
    {
        this.table = table;
    }


    showDialog() {
        this.ref = this.dialogService.open(ProductCategoriesAddEditComponent, {
            header: 'Modificare Categorie Produse',
            width: '30%',
            contentStyle: {'max-height': '70vh'},
            baseZIndex: 10000,
            closeOnEscape: false,
            data: this.data,
        });

        this.ref.onClose.subscribe((item: ProductCategoryModel) => {
            if (item) {
                this.table.refresh();
            }
        });
    }

    confirm() {
        this.confirmationService.confirm({
            header: 'Confirmare stergere Categorie Produse',
            message: 'Sunteti sigur ca doriti sa stergeti aceasta categorie de produse?',
            key: 'deleteProductCategory',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Da',
            rejectLabel: 'Nu',
            acceptButtonStyleClass: 'p-button-md p-button-success',
            rejectButtonStyleClass: 'p-button-md p-button-danger',
            accept: () => {
                this.deleteItem(this.data.uid);
            },
            reject: () => {
                this.confirmationService.close();
                this.table.refresh();
            }
        });
    }

    deleteItem(uid: string){
        this.itemService.delete(uid)
            .pipe(finalize(() => {this.loading = false;this.table.refresh();}))
            .subscribe((response: any) => {
                this.messageService.add({
                    severity: 'success',
                    summary: 'Categoria de produse a fost stearsa cu success!'
                });
            });
    }

}
