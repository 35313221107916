import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UserService } from '../user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ValidationMessageService } from '../../shared/services/base/validation-message-handle';
import { finalize } from 'rxjs/operators';
import { AuthRequestModel } from '../models/auth.model';
import { HttpErrorResponse } from '@angular/common/http';
import { MessageService } from 'primeng/api';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

    loginForm: UntypedFormGroup;
    returnUrl: string;
    loading = false;
    display: any = false;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private userService: UserService,
        private route: ActivatedRoute,
        private router: Router,
        private validationMessageService: ValidationMessageService,
        private messageService: MessageService
    ) {
    }

    ngOnInit(): void {

        const checkAuthStatus = this.userService.checkAuth();

        if (checkAuthStatus === true) {
            this.router.navigateByUrl(this.returnUrl);
        }


        this.loginForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', Validators.required]
        });

        this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
        this.loginForm.patchValue({ email: this.route.snapshot.queryParams.email || '' });
    }

    auth(): void {
        const authModel = this.loginForm.getRawValue();
        this.userService.setRedirectUrl(this.returnUrl);
        this.loading = true;
        this.userService.login(authModel)
            .pipe(finalize(() => this.loading = false))
            .subscribe((response) => {
                if (response.status === true) {
                    this.userService.setUserDetails(response.payload);
                    window.location.href = this.returnUrl;
                } else {
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Eroare autentificare',
                        detail: 'Acest cont nu este inca activ! <br> Te rugam sa astepti sa il valideze un administrator.'
                    });
                }
            }, err => {
                if (err instanceof HttpErrorResponse) {
                    this.validationMessageService.serverSideValidation(err, this.loginForm, '', true);
                }
            });


    }
}
