import { Component, OnInit } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SttTableService } from '../../../shared/components/stt-table/stt-table-service';
import {
    ProductionNoteActionTableComponent
} from './production-note-action-table/production-note-action-table.component';

@Component({
    selector: 'app-production-note',
    templateUrl: './production-note.component.html',
    styleUrls: ['./production-note.component.scss']
})
export class ProductionNoteComponent implements OnInit {
    public tableEndpoint = 'production-notes';
    public tableActionComponent = ProductionNoteActionTableComponent;
    public tableQuery: any;
    public ref: DynamicDialogRef;

    constructor(public dialogService: DialogService) {
        this.tableQuery = new SttTableService()
            .addInclude('fnc')
            .addInclude('product');
    }

    ngOnInit(): void {

    }
}
