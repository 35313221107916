import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { HttpApiService } from '../../../../shared/services/base/http-api.service';
import { ValidationMessageService } from '../../../../shared/services/base/validation-message-handle';
import { MessageService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { ReceptionNoteModel } from '../../../../shared/entities/reception-note.model';
import { ReceptionNoteService } from '../../../../shared/services/app/reception-note.service';
import { RecipeItemModel } from '../../../../shared/entities/recipe-item.model';
import { DatePipe } from '@angular/common';
import { TransportUnitService } from '../../../../shared/services/app/transport-unit.service';
import { SttTableService } from '../../../../shared/components/stt-table/stt-table-service';
import {
    TransportUnitActionTableComponent
} from '../../../nomenclature/transport/transport-units/transport-unit-action-table/transport-unit-action-table.component';
import { SttTableComponent } from '../../../../shared/components/stt-table/stt-table.component';


enum Status {
    Draft = 0,
    Validated = 1,
}

@Component({
    selector: 'app-reception-note-add-edit',
    templateUrl: './reception-note-add-edit.component.html',
    styleUrls: ['./reception-note-add-edit.component.scss']
})
export class ReceptionNoteAddEditComponent implements OnInit {
    @ViewChild('table', { static: false }) table: SttTableComponent;

    @Input() ceva: any;
    public tableEndpoint = 'transport-units/dropdown';
    public tableQuery: SttTableService;
    public tableActionComponent = TransportUnitActionTableComponent;

    public uid: string = null;

    public orderUID: string;
    public itemUID: string;
    public itemID: string;

    public itemModel: ReceptionNoteModel = new ReceptionNoteModel();
    public loading = false;
    public form: UntypedFormGroup;
    public minimumDate: Date = new Date();
    public fnc_id: number;
    public dropDownWithTrashed = false;
    public status: number;
    public readOnly = false;
    public showCalendar = true;
    public enumStatus = Status;
    public selectedUnit: any;
    public selectedUnitDisplayName: string;


    constructor(
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private formBuilder: UntypedFormBuilder,
        private httpApiService: HttpApiService,
        private validationMessageService: ValidationMessageService,
        private itemService: ReceptionNoteService,
        private messageService: MessageService,
        private route: ActivatedRoute,
        private router: Router,
        private datePipe: DatePipe,
        private transportUnitService: TransportUnitService,
    ) {
        this.uid = this.route.snapshot.queryParams.uid;
        this.tableQuery = new SttTableService()
            .addInclude('transportCompany')
            .addInclude('transportUnitDriver')
            .addInclude('transportUnitType')
            .addInclude('transportUnitBrand');
    }

    ngOnInit(): void {

        const localStorageData = JSON.parse(localStorage.getItem('user'));
        this.fnc_id = localStorageData.fnc_id;

        this.createForm();

        this.route.queryParams.subscribe(params => {
            this.orderUID = params.order;
            this.itemUID = params.item;
            this.itemID = params.itemID;
        });


        if (this.orderUID && this.itemUID) {
            this.getItemGenerate(this.orderUID, this.itemUID);
        }

        if (this.uid) {
            this.getItem();
        }

        return;
    }

    createForm(): void {
        this.form = this.formBuilder.group({
            uid: [this.itemModel.uid],
            supplier_id: [this.itemModel.supplier_id, Validators.required],
            supplier_address_id: [this.itemModel.supplier_id, Validators.required],
            fnc_id: [this.fnc_id, Validators.required],
            documentNo: [this.itemModel.documentNo, [Validators.required, Validators.maxLength(255)]],
            code: [this.itemModel.code, [Validators.required, Validators.maxLength(255)]],
            receptionDate: [this.itemModel.receptionDate, Validators.required],
            description: [this.itemModel.description, [Validators.maxLength(2000)]],
            gross_quantity: [this.itemModel.gross_quantity, [Validators.required, Validators.min(0)]],
            tar_quantity: [this.itemModel.tar_quantity, [Validators.required, Validators.min(0)]],
            net_quantity: [this.itemModel.net_quantity, [Validators.required, Validators.min(0)]],

            transport_unit_driver_id: [this.itemModel.transport_unit_driver_id],
            transport_unit_type_id: [this.itemModel.transport_unit_type_id],
            transport_company_id: [this.itemModel.transport_company_id],
            transport_unit_id: [this.itemModel.transport_unit_id, [Validators.required, Validators.min(0)]],
            transport_details: [this.itemModel.transport_details, [Validators.maxLength(2000)]],
            raw_material_id: [this.itemModel.raw_material_id, [Validators.required, Validators.min(0)]],
            lot: [this.itemModel.lot, [Validators.required]],
            storage_space_id: [this.itemModel.storage_space_id, [Validators.required, Validators.min(0)]],
            orderRawMaterialItem_id: [this.itemModel.orderRawMaterialItem_id]
        });


        this.calculateNetQuantity();
        this.form.get('gross_quantity').valueChanges.subscribe(() => {
            this.calculateNetQuantity();
        });
        this.form.get('tar_quantity').valueChanges.subscribe(() => {
            this.calculateNetQuantity();
        });
    }

    calculateNetQuantity(): void {
        const grossQuantity = this.form.get('gross_quantity').value;
        const transportWeight = this.form.get('tar_quantity').value;

        this.form.get('net_quantity').setValue(grossQuantity - transportWeight);
    }

    formReceptionNoteItems(): UntypedFormArray {
        return this.form.get('items') as UntypedFormArray;
    }

    addFormElement(item?: RecipeItemModel) {
        const receptionNoteItemForm = this.formBuilder.group({
            uid: [(item) ? item.uid : null],
            raw_material_id: [(item) ? item.raw_material_id : null, Validators.required],
            storage_id: [(item) ? item.storage_id : null, Validators.required],
            units: [null, [Validators.required, Validators.min(0)]],
            needDelete: [false]
        });
        this.formReceptionNoteItems().push(receptionNoteItemForm);
    }

    rowSelected(event: any){
        // on row unselected not working
        this.selectedUnit = event.data;
        this.selectedUnitDisplayName = this.selectedUnit.transportUnitDriver.firstName + ' ' + this.selectedUnit.transportUnitDriver.lastName + ' ' + this.selectedUnit.transportRegistrationNumber;
        this.form.patchValue({
            transport_unit_id: this.selectedUnit.id
        });
    }

    onCompanyChange(event: any){
        console.log(event);
    }

    getItemGenerate(orderUID: string, itemUID: string): void {
        this.loading = true;
        this.itemService.getOrder(orderUID)
            .pipe(finalize(() => this.loading = false))
            .subscribe((response: any) => {
                const payload = response.payload;
                this.form.patchValue({
                    supplier_id: payload.supplier_id,
                    fnc_id: payload.fnc_id,
                    description: payload.description,
                    orderRawMaterialItem_id: this.itemID
                });
                payload.items.forEach((item) => {
                    if (item.uid === itemUID) {
                        this.form.patchValue({
                            raw_material_id: item.raw_material_id,
                        });
                    }
                });
            });
    }

    getItem(): void {
        this.loading = true;
        this.dropDownWithTrashed = true;
        this.itemService.get(this.uid)
            .pipe(finalize(() => {this.loading = false; this.dropDownWithTrashed = false;}))
            .subscribe((response: any) => {
                this.itemModel.map(response.payload);
                this.form.patchValue(this.itemModel);
                const dateObject = new Date(response.payload.receptionDate);
                this.form.patchValue({
                    receptionDate: dateObject,
                });
                this.status = response.payload.status;
                if(this.status > Status.Draft){
                    this.readOnly = true;
                    this.showCalendar = false;
                }
            });
    }

    checkIfFormValid(): boolean {
        if (this.form.invalid) {
            this.messageService.add({
                severity: 'error',
                summary: 'Formularul nu este valid!',
                detail: 'Vericficati toate campurile si incercati din nou.'
            });
            this.form.markAllAsTouched();
            return false;
        }
        return true;
    }


    onSave(): void {
        if (!this.checkIfFormValid()) {
            return;
        }
        this.loading = true;
        const data = this.form.getRawValue();
        this.itemService.set(data)
            .pipe(finalize(() => this.loading = false))
            .subscribe((response: any) => {
                this.itemModel.map(response.payload);
                this.form.patchValue(this.itemModel);
                this.messageService.add({
                    severity: 'success',
                    summary: 'Nota de receptie a fost salvata cu success!'
                });
                this.router.navigate(['stock-management/reception-note/list'], {});
            }, err => {
                if (err instanceof HttpErrorResponse) {
                    this.validationMessageService.serverSideValidation(err, this.form, '', true);
                }
            });
    }

    validateForm(){
        this.status = this.enumStatus.Validated;
        const summary = 'Nota de receptie a fost validata cu success!!';
        this.changeStatus(summary);
    }

    changeStatus(summary: string){
        this.itemService.statusChange(this.status, this.uid)
            .pipe(finalize(() => this.loading = false))
            .subscribe((response: any) => {
                this.itemModel.map(response.payload);
                this.form.patchValue(this.itemModel);
                this.messageService.add({
                    severity: 'success',
                    summary
                });
                this.router.navigate(['stock-management/reception-note/list'], {});
            }, err => {
                if (err instanceof HttpErrorResponse) {
                    this.validationMessageService.serverSideValidation(err, this.form, '', true);
                }
            });
    }
}
