import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { HttpApiService } from '../../../../shared/services/base/http-api.service';
import { ValidationMessageService } from '../../../../shared/services/base/validation-message-handle';
import { MessageService } from 'primeng/api';
import { finalize } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { RecipeModel } from 'src/app/shared/entities/recipe.model';
import { RecipeService } from 'src/app/shared/services/app/recipe.service';
import { RecipeItemModel } from 'src/app/shared/entities/recipe-item.model';
import { RecipeNutrientsModel } from '../../../../shared/entities/recipe-nutrients.model';


@Component({
    selector: 'app-recipe-add-edit',
    templateUrl: './recipe-add-edit.component.html',
    styleUrls: ['./recipe-add-edit.component.scss']
})
export class RecipeAddEditComponent implements OnInit {

    public uid: string = null;
    public itemModel: RecipeModel = new RecipeModel();
    public loading = false;
    public form: UntypedFormGroup;
    public fncs: any;
    public fileImported = false;
    public errorArray = [];
    public selectedFile: any;
    public dropDownWithTrashed = false;
    public waitForFinalize = false;

    constructor(
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private formBuilder: UntypedFormBuilder,
        private httpApiService: HttpApiService,
        private validationMessageService: ValidationMessageService,
        private itemService: RecipeService,
        private messageService: MessageService,
    ) {
    }

    formRecipeItems(): UntypedFormArray {
        return this.form.get('items') as UntypedFormArray;
    }

    formRecipeNutrients(): UntypedFormArray {
        return this.form.get('nutrients') as UntypedFormArray;
    }

    ngOnInit(): void {
        this.uid = (this.config.data) ? this.config.data.uid : null;

        this.createForm();

        if (this.uid) {
            this.getItem();
        }

        return;

    }

    getStyleButton(){
        const itemsLength = this.formRecipeItems().value.length;
        let jsonStyle;
        if(this.uid){
            if(!this.waitForFinalize){
                return ;
            }
        }
        if((this.formRecipeItems().at(itemsLength -1).get('raw_material_id').invalid && this.formRecipeItems().at(itemsLength -1).get('raw_material_id').touched)
            || (this.formRecipeItems().at(itemsLength -1).get('units').invalid && this.formRecipeItems().at(itemsLength -1).get('units').touched)){
            jsonStyle = {'margin-bottom' : '44px'};
        }
        else{
            jsonStyle = {'margin-bottom' : '24px'};
        }
        return jsonStyle;
    }

    onCloseDialog(item: RecipeModel) {
        this.ref.close(item);
    }


    createForm(): void {
        this.form = this.formBuilder.group({
            uid: [this.itemModel.uid],
            name: [this.itemModel.name, [Validators.required, Validators.maxLength(200)]],
            code: [this.itemModel.code, [Validators.required, Validators.maxLength(200)]],
            items: this.formBuilder.array([]),
            nutrients: this.formBuilder.array([]),
        });

        if (!this.uid) {
            // Add empty line
            this.addFormItem();
        }


    }

    getItem(): void {
        this.loading = true;
        this.dropDownWithTrashed = true;
        this.itemService.get(this.uid)
            .pipe(finalize(() => {this.loading = false; this.waitForFinalize = true;}))
            .subscribe((response: any) => {
                this.itemModel.map(response.payload);
                this.form.patchValue(this.itemModel);
                const dateObject = new Date(response.payload.receptionDate);
                this.form.patchValue({
                    receptionDate: dateObject,
                });
                this.itemModel.items.forEach(item => {
                    this.addFormItem(item);
                });
                this.itemModel.nutrients.forEach(nutrient => {
                    this.addFormNutrient(nutrient);
                });
            });
    }

    addFormItem(item?: RecipeItemModel): void {
        const recipeItemForm = this.formBuilder.group({
            uid: [(item) ? item.uid : null],
            raw_material_id: [(item) ? item.raw_material_id : null, Validators.required],
            units: [(item) ? item.units : null, Validators.required],
            needDelete: [false]
        });
        this.formRecipeItems().push(recipeItemForm);
    }

    addFormNutrient(item?: RecipeNutrientsModel): void {
        const recipeItemForm = this.formBuilder.group({
            uid: [(item) ? item.uid : null],
            name: [(item) ? item.name : null],
            recipe_id: [(item) ? item.recipe_id : null],
            units: [(item) ? item.units : null],
            unit_measure: [(item) ? item.unit_measure : null],
            additives: [(item) ? item.additives : false],
        });
        this.formRecipeNutrients().push(recipeItemForm);
    }

    deleteFormItem(index: number): void {
        const formItem = this.formRecipeItems().at(index);
        formItem.patchValue({
            needDelete: true,
        });
    }

    onCancel(): void {
        this.ref.close(null);
    }

    onImportFile(event): void {
        const file: File = event.target.files[0];
        this.itemService.importFile({recipeDocument: file, fileName: file.name})
            .pipe(finalize(() => this.loading = false))
            .subscribe((response: any) => {
                let additives = false;
                let index = 0;
                // Map raw materials on form
                response.payload.raw_materials.forEach(element => {
                    if (element.raw_material_id){
                        if (index !== 0){
                            this.addFormItem();
                        }
                        this.formRecipeItems().at(index).patchValue({
                            raw_material_id: element.raw_material_id,
                            units: element.per,
                        });
                        index = index + 1;
                    }
                    else{
                        this.errorArray.push({name: element.name, code: element.code});
                    }
                });
                // Map nutrients on form
                response.payload.nutrients.forEach((nutrient_element,index_element) => {
                    // if (index_element !== 0){
                    //     this.addFormNutrient();
                    // }
                    this.addFormNutrient();
                    if( nutrient_element.name === 'Vit-A'){
                        additives = true;
                    }
                    let nutrientUnit;
                    if(Number(nutrient_element.actual)){
                        nutrientUnit = Number(nutrient_element.actual);
                    }
                    else{
                        nutrientUnit = 0;
                    }
                    this.formRecipeNutrients().at(index_element).patchValue({
                        name: nutrient_element.name,
                        units: nutrientUnit,
                        unit_measure: nutrient_element.units,
                        additives: additives,
                    });
                });
                this.fileImported = true;
            });
    }

    checkIfFormValid(): boolean {
        if (this.form.invalid) {
            this.messageService.add({
                severity: 'error',
                summary: 'Formularul nu este valid!',
                detail: 'Vericficati toate campurile si incercati din nou.'
            });
            this.form.markAllAsTouched();
            return false;
        }
        return true;
    }

    onSave(): void {
        if (!this.checkIfFormValid()) {
            return;
        }
        this.loading = true;
        const data = this.form.getRawValue();
        this.itemService.set(data)
            .pipe(finalize(() => this.loading = false))
            .subscribe((response: any) => {
                this.itemModel.map(response.payload);
                this.form.patchValue(this.itemModel);
                this.messageService.add({
                    severity: 'success',
                    summary: 'Reteta a fost salvat cu success!'
                });
                this.ref.close(this.itemModel);
            }, err => {
                if (err instanceof HttpErrorResponse) {
                    this.validationMessageService.serverSideValidation(err, this.form, '', true);
                }
            });
    }
}
