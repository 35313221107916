<p style="color: red">Valorile prezente nu pot fi modificate. Pentru a efectua modificari folositi butonul de
    editare.</p>
<p-divider></p-divider>
<form [formGroup]="form" autocomplete="off">
    <div class="grid fields-group">
        <div class="col-12">
            <div class="grid p-fluid">
                <div class="field col-12 md:col-6">
                    <app-stt-dropdown icon="pi pi-user" endPoint="suppliers" [readonly]="true"
                                      [formControlParent]="form.controls['supplier_id']"
                                      label="Furnizor"
                                      [dropDownWithTrashed]="dropDownWithTrashed"></app-stt-dropdown>
                </div>
                <div class="field col-12 md:col-6">
                    <app-stt-dropdown endPoint="fncs" [readonly]="true" icon="pi pi-cog"
                                      [formControlParent]="form.controls['fnc_id']" label="Fnc"></app-stt-dropdown>
                </div>
            </div>
            <div class="grid p-fluid">
                <div class="field col-12 md:col-5">
                    <app-stt-calendar label="Data" icon="pi pi-calendar" [minimumDate]="minimumDate"
                                      [disabledDays]="[0,1,2,3,4,5,6]" [showCalendar]="false"
                                      [control]="form.controls['orderDate']"></app-stt-calendar>
                    <br style="margin-bottom: 5em">
                    <app-stt-input inputId="code" [control]="form.controls['code']"
                                   icon="pi pi-key"
                                   label="Cod SAP"
                                   [readonly]="true"></app-stt-input>
                </div>
                <div class="field col-12 md:col-7" style="border-left: 1px solid lightgray">
                    <app-stt-textarea inputId="description" [control]="form.controls['description']"
                                      icon="pi pi-pencil"
                                      label="Descriere"
                                      [readOnly]="true"></app-stt-textarea>
                </div>
            </div>
            <p-divider>Selectare materii prime</p-divider>
            <p-table [value]="formRawMaterialOrderItems().controls" scrollHeight="248px" [autoLayout]="true"
                     styleClass="p-datatable-gridlines p-datatable-sm">
                <ng-template pTemplate="header">
                    <tr>
                        <th></th>
                        <th>Materie prima</th>
                        <th>Cantitate Totala</th>
                        <th>Cantitate Receptionata</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
                    <tr style="overflow: visible;">
                        <ng-container [formGroup]="rowData">
                            <td style="align-items: center;">
                                <div style="justify-content: center; display: flex;">
                                    <p-checkbox formControlName="receptionNoteReady" binary="true"
                                                (onChange)="checkedBox($event,rowIndex)"></p-checkbox>
                                </div>
                            </td>
                            <td>
                                <div class="grid p-fluid" style="width: 225px; margin-top: 1px;">
                                    <div class="col-12">
                                        <app-stt-dropdown endPoint="raw-materials" [readonly]="true"
                                                          style="width: 100%"
                                                          icon="pi pi-check-square"
                                                          [formControlParent]="rowData.controls['raw_material_id']"></app-stt-dropdown>
                                    </div>
                                </div>
                            </td>
                            <td>
                                {{ rowData.controls['units'].value }}
                            </td>
                            <td>
                                {{ rowData.controls['receivedUnits'].value }}
                            </td>
                        </ng-container>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>

    <p-progressBar mode="indeterminate" class="addEditProgressBar" *ngIf="loading"></p-progressBar>

    <div class="actions-area">
        <div class="actions-left">
            <button pButton pRipple type="button" label="Anulare" class="p-button-raised p-button-danger"
                    icon="pi pi-ban" [disabled]="loading" routerLink="/orders/raw-materials/list"
                    (click)="onCloseDialog()"></button>
        </div>

        <div class="actions-right">
            <button pButton pRipple type="button" label="Genereaza" class="p-button-raised p-button-success"
                    (click)="onGenerate()"
                    [disabled]="loading">
            </button>
        </div>
    </div>
</form>
