import { Component, OnInit } from '@angular/core';
import { SttTableComponent } from '../../../../shared/components/stt-table/stt-table.component';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ProductPackingTypeModel } from 'src/app/shared/entities/product-packing-type.model';
import {
    ProductPackingTypeAddEditComponent
} from '../product-packing-type-add-edit/product-packing-type-add-edit.component';
import { ConfirmationService, MessageService, PrimeNGConfig } from 'primeng/api';
import { ValidationMessageService } from '../../../../shared/services/base/validation-message-handle';
import { ProductPackingTypeService } from '../../../../shared/services/app/product-packing-type.service';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'app-product-packing-type-action-table',
    templateUrl: './product-packing-type-action-table.component.html',
    styleUrls: ['./product-packing-type-action-table.component.scss']
})
export class ProductPackingTypeActionTableComponent implements OnInit {

    public data: ProductPackingTypeModel;
    public table: SttTableComponent;
    public ref: DynamicDialogRef;
    public loading = true;

    constructor(
        public dialogService: DialogService,
        public confirmationService: ConfirmationService,
        public primengConfig: PrimeNGConfig,
        public itemService: ProductPackingTypeService,
        public messageService: MessageService,
        private validationMessageService: ValidationMessageService,
    ) {

    }

    ngOnInit(): void {
    }

    setParams(entity: ProductPackingTypeModel): void {
        this.data = entity;
    }

    setTableReference(table: SttTableComponent): void {
        this.table = table;
    }


    showDialog() {
        this.ref = this.dialogService.open(ProductPackingTypeAddEditComponent, {
            header: 'Modificare tip ambalaj',
            width: '30%',
            contentStyle: { 'max-height': '70vh' },
            baseZIndex: 10000,
            closeOnEscape: false,
            data: this.data,
        });

        this.ref.onClose.subscribe((product_packing_type: ProductPackingTypeModel) => {
            if (product_packing_type) {
                this.table.refresh();
            }
        });
    }

    confirm() {
        this.confirmationService.confirm({
            header: 'Confirmare stergere Tip de ambalare',
            message: 'Sunteti sigur ca doriti sa stergeti acest Tip de ambalare?',
            key: 'deleteProductPackingType',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Da',
            rejectLabel: 'Nu',
            acceptButtonStyleClass: 'p-button-md p-button-success',
            rejectButtonStyleClass: 'p-button-md p-button-danger',
            accept: () => {
                this.deleteItem(this.data.uid);
            },
            reject: () => {
                this.confirmationService.close();
                this.table.refresh();
            }
        });
    }

    deleteItem(uid: string){
        this.itemService.delete(uid)
            .pipe(finalize(() => {this.loading = false;this.table.refresh();}))
            .subscribe((response: any) => {
                this.messageService.add({
                    severity: 'success',
                    summary: 'Tipul de ambalare a fost sters cu success!'
                });
            });
    }
}
