<form [formGroup]="form" (ngSubmit)="onSave()" style="max-width: 100%!important; max-height: 100%;"
      autocomplete="false">
    <p-tabView>
        <p-tabPanel>
            <ng-template pTemplate="header">
                <span class="p-tabview-title ng-star-inserted"
                      [class.red-text]="checkClientDetails()">
                    Detalii client
                </span>
            </ng-template>
            <div class="grid fields-group">
                <div class="col-12">
                    <div class="grid p-fluid">
                        <div class="field col-12 md:col-6">
                            <app-stt-input inputId="name" [control]="form.controls['name']" icon="pi pi-users"
                                           label="Denumire"></app-stt-input>
                        </div>
                        <div class="field col-12 md:col-6">
                            <app-stt-input inputId="code" [control]="form.controls['code']" icon="pi pi-key"
                                           label="Cod SAP"></app-stt-input>
                        </div>
                    </div>
                    <div class="grid p-fluid">
                        <div class="field col-12 md:col-6">
                            <app-stt-input inputId="uniqueCode" [control]="form.controls['uniqueCode']"
                                           icon="pi pi-building"
                                           label="CUI"></app-stt-input>
                        </div>
                        <div class="field col-12 md:col-6">
                            <app-stt-input inputId="registrationNumber" [control]="form.controls['registrationNumber']"
                                           icon="pi pi-building"
                                           label="Numar de inregistrare"></app-stt-input>
                        </div>
                    </div>
                    <div class="grid p-fluid">

                        <div class="field col-12 md:col-6">
                            <app-stt-input inputId="email" [control]="form.controls['email']"
                                           icon="pi pi-envelope"
                                           label="Email"></app-stt-input>
                        </div>

                        <div class="field col-12 md:col-6">
                            <app-stt-input inputId="phone" [control]="form.controls['phone']"
                                           icon="pi pi-phone"
                                           label="Telefon"></app-stt-input>
                        </div>
                    </div>
                    <div class="grid p-fluid">
                        <div class="field col-12 md:col-6">
                            <app-stt-dropdown placeholder="Nu ati selectat un judet!" endPoint="counties" label="Judet"
                                              icon="pi pi-map"
                                              [formControlParent]="form.controls['county_id']">
                            </app-stt-dropdown>
                        </div>
                        <div class="field col-12 md:col-6">
                            <app-stt-dropdown placeholder="Nu ati selectat un oras!" endPoint="cities" label="Oras"
                                              formControlNameDependency="county_id"
                                              icon="pi pi-map"
                                              [formControlParent]="form.controls['city_id']">
                            </app-stt-dropdown>
                        </div>
                    </div>
                    <div class="grid p-fluid">
                        <div class="field col-12 md:col-12">
                            <app-stt-input inputId="address" [control]="form.controls['address']"
                                           icon="pi pi-home"
                                           label="Adresa"></app-stt-input>
                        </div>
                    </div>
                </div>
            </div>
        </p-tabPanel>
        <p-tabPanel [disabled]="(!customerModel.uid)" leftIcon="pi-location">
            <ng-template pTemplate="header">
                <span class="p-tabview-title ng-star-inserted"
                      [class.red-text]="form.controls['addresses'].invalid && form.controls['addresses'].touched">
                    Adrese de livrare
                </span>
                <p-badge [value]="form.controls['addresses'].value.length" severity="success"></p-badge>
            </ng-template>
            <app-customers-addresses [formGroup]="form" [addresses]="customerModel.addresses"
                                     *ngIf="customerModel.addresses"></app-customers-addresses>
        </p-tabPanel>
        <p-tabPanel header="Contacte" [disabled]="(!customerModel.uid)" leftIcon="pi-users">
            <ng-template pTemplate="header">
                <span class="p-tabview-title ng-star-inserted"
                      [class.red-text]="form.controls['contacts'].invalid && form.controls['contacts'].touched">
                    Contacte
                </span>
                <p-badge [value]="form.controls['contacts'].value.length" severity="success"></p-badge>
            </ng-template>
            <app-customers-contacts [formGroup]="form" [contacts]="customerModel.contacts"
                                    *ngIf="customerModel.contacts"></app-customers-contacts>
        </p-tabPanel>
    </p-tabView>

    <p-progressBar mode="indeterminate" class="addEditProgressBar" *ngIf="loading"></p-progressBar>

    <div class="actions-area">
        <div class="actions-left">
            <button pButton pRipple type="button" label="Anulare" class="p-button-raised p-button-danger"
                    icon="pi pi-ban" [disabled]="loading" (click)="onCancel()">
            </button>
        </div>

        <div class="actions-right">
            <button pButton pRipple type="submit" label="Salveaza" class="p-button-raised p-button-success"
                    icon="pi pi-save" [disabled]="loading">
            </button>
        </div>
    </div>
</form>
