import {
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
    /* eslint-disable @angular-eslint/component-selector */
    selector: '[app-menuitem]',
    /* eslint-enable @angular-eslint/component-selector */
    templateUrl: './app.menuitem.component.html',
    styleUrls: ['./app.menuitem.component.scss'],
})
export class AppMenuitemComponent implements OnInit {
    @Input() item: any;

    @Input() parentKey: string;

    active = false;

    inactiveDropdowns: boolean[] = [];

    activeLabel: string;

    key: string;

    constructor(
        public router: Router,
        private cd: ChangeDetectorRef,
    ) {
        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe((event: NavigationEnd) => {
                this.disableDropdowns();
                this.isTabActive();
            });
    }

    setActiveLink(label: string): void {
        this.activeLabel = label;
    }

    onClickDropdown(index: number): void {
        this.inactiveDropdowns[index] = false;
    }

    disableDropdowns(): void {
        for (let i = 0; i < this.inactiveDropdowns.length; i++) {
            this.inactiveDropdowns[i] = true;
        }
    }

    isTabActive(): void {
        let i = 0;
        const items = this.item.items;
        this.item.items.forEach(item => {
            if (item.items) {
                item.items.forEach(child => {
                    if (this.checkIfActive(child.routerLink[0], this.router.url)) {
                        this.active = true;
                        this.activeLabel = child.label;
                        this.inactiveDropdowns[i] = false;
                        this.cd.detectChanges();
                        return;
                    }
                });
            } else if (item.routerLink) {
                if (this.checkIfActive(item.routerLink[0], this.router.url)) {
                    this.active = true;
                    this.activeLabel = item.label;
                    return;
                }
            }
            i++;
        });

    }

    checkIfActive(itemArg: string, routerArg: string): boolean {
        const doi = 2;
        const itemUrl = itemArg.split('/');
        const currentUrl = routerArg.split('/');
        currentUrl.shift();
        itemUrl.shift();
        let value = true;
        for (let i = 0; i < doi; i++) {
            if (itemUrl[i] !== currentUrl[i]) {
                value = false;
            }
        }
        return value;
    }

    ngOnInit() {
        this.item.items.forEach(() => {
            this.inactiveDropdowns.push(true);
        });
        this.isTabActive();
    }
}
