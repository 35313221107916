<script src="../../../../app.component.ts"></script>
<a routerLink="/stock-management/production-note/list" style="color: #808080;font-size: 18px">
    <i class="fas fa-arrow-left"></i>
    <span>Inapoi la rapoartele de productie</span>
</a>
<br>
<br>

<!--        Editare        -->
<div *ngIf="uid">
    <form [formGroup]="form" autocomplete="off">
        <p-card>
                <div class="grid fields-group" style="padding: 0 1em">
                    <div class="col-12">
                        <div class="grid p-fluid">
                            <div class="col-6">
                                <app-stt-input inputId="code" [control]="form.controls['code']"
                                               icon="pi pi-key" type="text" [readonly]="readOnly"
                                               label="Cod SAP"></app-stt-input>
                            </div>
                            <div class="col-6">
                                <app-stt-input inputId="documentNo" [control]="form.controls['documentNo']"
                                               icon="pi pi-paperclip" type="text" [readonly]="readOnly"
                                               label="Serie document"></app-stt-input>
                            </div>
                        </div>
                        <br>
                        <div class="grid p-fluid">
                            <div class="col-6">
                                <app-stt-dropdown endPoint="products/dropdown"
                                                  label="Produs" icon="pi pi-check-square"
                                                  placeholder="Nu ati selectat produsul!"
                                                  [formControlParent]="form.controls['product_id']"
                                                  [dropDownWithTrashed]="dropDownWithTrashed" [readonly]="readOnly"
                                                  (changeEvent)="onProductChange($event)">
                                </app-stt-dropdown>
                            </div>
                            <div class="col-6">
                                <app-stt-input inputId="lot" [control]="form.controls['lot']"
                                               icon="pi pi-box" type="text" [readonly]="readOnly"
                                               label="Lot"></app-stt-input>
                            </div>
                        </div>
                        <br>
                        <div class="grid p-fluid justify-content-center">
                            <div class="col-4">
                                <ul>
                                    <li>
                                        <div class="grid">
                                            <div class="col-4">
                                                Cantitate produsa:
                                            </div>
                                            <div class="col-4">
                                                <app-stt-input type="number" [readonly]="readOnly"
                                                               [control]="form.controls['production_quantity']"
                                                               (change)="calculateQuantities()"></app-stt-input>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="grid p-fluid">
                            <div class="col-4">
                                <app-stt-calendar label="Data Incepere" icon="pi pi-calendar" [minimumDate]="minimumDate"
                                                  inputId="startDate" [showCalendar]="showCalendar"
                                                  [control]="form.controls['startDate']"></app-stt-calendar>
                            </div>
                            <div class="col-4">
                                <app-stt-calendar label="Data Finalizare" icon="pi pi-calendar" [minimumDate]="minimumDate"
                                                  inputId="endDate" [showCalendar]="showCalendar"
                                                  [control]="form.controls['endDate']"></app-stt-calendar>
                            </div>
                            <div class="col-4">
                                <app-stt-input inputId="sarja" [readonly]="readOnly"
                                               [control]="form.controls['sarja']"
                                               type="number"
                                               label="Sarja"
                                ></app-stt-input>
                            </div>
                        </div>

                        <p-divider>Comenzi asociate</p-divider>
                        <br>
                        <div class="grid p-fluid">
                            <div class="col-12">
                                <p-table [value]="formProductionNoteOrders().controls" [autoLayout]="true">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th></th>
                                            <th>Comanda</th>
                                            <th>Data livrarii</th>
                                            <th>Cantitate</th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-rowData>
                                        <tr>
                                            <ng-container [formGroup]="rowData">
                                                <td>
                                                    <p-checkbox formControlName="selected" binary="true" [readonly]="readOnly"></p-checkbox>
                                                </td>
                                                <td>
                                                    {{ rowData.controls['code'].value }}
                                                </td>
                                                <td>
                                                    {{ rowData.controls['deliveryDate'].value }}
                                                </td>
                                                <td>
                                                    {{ rowData.controls['quantity'].value }}
                                                </td>
                                            </ng-container>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="emptymessage">
                                        <tr>
                                            <td colspan="5">Nu sunt comenzi in progress asociate produsului selectat </td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                            </div>
                        </div>

                        <p-progressBar mode="indeterminate" class="addEditProgressBar" *ngIf="loading"></p-progressBar>

                        <br>
                        <p-divider>Materii prime asociate</p-divider>
                        <br>
                        <p-table [value]="formProductionNoteRawMaterials().controls" [autoLayout]="true">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th>Materie prima</th>
                                    <th>Stoc</th>
                                    <th>Cantitate utilizata</th>
                                    <th>Cantitate ramasa dupa productie</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
                                <ng-container [formGroup]="rowData">
                                    <tr>
                                        <td>
                                            <app-stt-dropdown endPoint="raw-materials/dropdown" [readonly]="readOnly"
                                                              [formControlParent]="rowData.controls.raw_material_id"
                                                              [dropDownWithTrashed]="dropDownWithTrashed"></app-stt-dropdown>
                                        </td>
                                        <td>
                                            {{ rowData.controls.current_stock_raw_material.value }}
                                        </td>
                                        <td>
                                            <app-stt-input type="number" [readonly]="readOnly"
                                                           [control]="rowData.controls.units"></app-stt-input>
                                        </td>
                                        <td>
                                            {{ rowData.controls.current_stock_raw_material.value - rowData.controls.units.value }}
                                        </td>
<!--                                        <td>-->
<!--                                            <button (click)="deleteFormItem(rowIndex)"-->
<!--                                                    class="p-button-raised p-button-danger text-right"-->
<!--                                                    icon="pi pi-minus" pButton pRipple type="button"></button>-->
<!--                                        </td>-->
                                    </tr>
                                </ng-container>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="5">Nu am gasit o reteta asociata pentru produsul ales </td>
                                </tr>
                            </ng-template>
                        </p-table>

                        <p-progressBar mode="indeterminate" class="addEditProgressBar" *ngIf="loading"></p-progressBar>
                    </div>
                </div>
        </p-card>

        <p-progressBar mode="indeterminate" class="addEditProgressBar" *ngIf="loading"></p-progressBar>

    </form>
    <div class="actions-area">
        <div class="actions-left" style="gap: 1em; display: flex">
            <button pButton pRipple type="button" label="Anulare" class="p-button-raised p-button-danger"
                    icon="pi pi-ban" [disabled]="loading"
                    routerLink="/stock-management/production-note/list"></button>
        </div>
        <div class="actions-right">
            <button pButton pRipple type="submit" label="Salveaza!" *ngIf="!status || status === enumStatus.Draft"
                    class="p-button-raised p-button-success"
                    icon="pi pi-save"
                    [disabled]="loading"
                    (click)="onSave()">
            </button>
            <p-button *ngIf="status === enumStatus.Draft" [label]="'Validare'" icon="pi pi-check" iconPos="right" (click)="validateForm()" [ngStyle]="{'margin-left' : '2px'}"></p-button>
            <p-button *ngIf="status === enumStatus.Validated" [label]="'Finalizare'" icon="pi pi-check" iconPos="right" (click)="addLiveDosage()" [ngStyle]="{'margin-left' : '2px'}"></p-button>
        </div>
    </div>
</div>



<!--         Adaugare         -->
<div *ngIf="!uid">
    <form [formGroup]="form" autocomplete="off" (ngSubmit)="onSave()">
        <p-card>
            <div style="padding: 0 1em">
                <p-steps [model]="items" [activeIndex]="currentTab"></p-steps>
                <div class="grid fields-group">
                    <div class="col-12" *ngIf="currentTab===0">
                        <div class="grid p-fluid">
                            <div class="col-6">
                                <app-stt-input inputId="code" [control]="form.controls['code']"
                                               icon="pi pi-key" type="text"
                                               label="Cod SAP"></app-stt-input>
                            </div>
                            <div class="col-6">
                                <app-stt-input inputId="documentNo" [control]="form.controls['documentNo']"
                                               icon="pi pi-paperclip" type="text"
                                               label="Serie document"></app-stt-input>
                            </div>
                        </div>
                        <br>
                        <div class="grid p-fluid">
                            <div class="col-6">
                                <app-stt-dropdown endPoint="products/dropdown"
                                                  label="Produs" icon="pi pi-check-square"
                                                  placeholder="Nu ati selectat produsul!"
                                                  [formControlParent]="form.controls['product_id']"
                                                  (changeEvent)="onProductChange($event)">
                                </app-stt-dropdown>
                            </div>
                            <div class="col-6">
                                <app-stt-input inputId="lot" [control]="form.controls['lot']"
                                               icon="pi pi-box" type="text"
                                               label="Lot"></app-stt-input>
                            </div>
                        </div>
                        <br>
                        <div class="grid p-fluid">
                            <div class="col-6">
                                <app-stt-calendar label="Data Incepere" icon="pi pi-calendar" [minimumDate]="minimumDate"
                                                  inputId="startDate"
                                                  [control]="form.controls['startDate']"></app-stt-calendar>
                            </div>
                            <div class="col-6">
                                <app-stt-calendar label="Data Finalizare" icon="pi pi-calendar" [minimumDate]="minimumDate"
                                                  inputId="endDate"
                                                  [control]="form.controls['endDate']"></app-stt-calendar>
                            </div>
                        </div>
                        <br>
                        <div class="grid p-fluid">
                            <div class="col-12">
                                <app-stt-dropdown endPoint="fncs" [formControlParent]="form.controls['fnc_id']" label="Fnc" icon="pi pi-cog"
                                                  placeholder="Nu ati selectat un fnc!"></app-stt-dropdown>
                            </div>
                        </div>
                        <p-divider>Selectare comanda client</p-divider>
                        <br>
                        <div class="grid p-fluid">
                            <div class="col-12">
                                <p-table [value]="formProductionNoteOrders().controls" [autoLayout]="true">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th></th>
                                            <th>Comanda</th>
                                            <th>Data livrarii</th>
                                            <th>Cantitate</th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-rowData>
                                        <tr>
                                            <ng-container [formGroup]="rowData">
                                                <td>
                                                    <p-checkbox formControlName="selected" binary="true"></p-checkbox>
                                                </td>
                                                <td>
                                                    {{ rowData.controls['code'].value }}
                                                </td>
                                                <td>
                                                    {{ rowData.controls['deliveryDate'].value }}
                                                </td>
                                                <td>
                                                    {{ rowData.controls['quantity'].value }}
                                                </td>
                                            </ng-container>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="emptymessage">
                                        <tr>
                                            <td colspan="5">Nu sunt comenzi in progres asociate produsului selectat </td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                            </div>
                        </div>
                    </div>
                    <div class="col-12" *ngIf="currentTab===1">
                        <div class="grid p-fluid justify-content-center">
                            <div class="col-4">
                                <app-stt-dropdown endPoint="products"
                                                  label="Produs" [readonly]="true"
                                                  icon="pi pi-box"
                                                  placeholder="Nu ati selectat produsul!"
                                                  [formControlParent]="form.controls['product_id']">
                                </app-stt-dropdown>
                            </div>
                        </div>
                        <div class="grid p-fluid justify-content-center">
                            <div class="col-4">
                                <ul>
                                    <li>
                                        <div class="grid">
                                            <div class="col-4">
                                                Stoc curent:
                                            </div>
                                            <div class="col-4">
                                                <span style="font-weight: bold;">{{ form.controls['current_stock'].value }}</span>
                                            </div>
                                        </div>
                                    </li>
                                    <br>
                                    <li>
                                        <div class="grid">
                                            <div class="col-4">
                                                Cantitate sugerata:
                                            </div>
                                            <div class="col-4">
                                                <app-stt-input type="number"
                                                               [control]="form.controls['production_quantity']"
                                                               (change)="calculateQuantities()"></app-stt-input>
                                            </div>
                                        </div>
                                    </li>
                                    <br>
                                    <li>
                                        <app-stt-input inputId="sarja"
                                                       [control]="form.controls['sarja']"
                                                       type="number"
                                                       label="Sarja"
                                        ></app-stt-input>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <p-divider>Materii prime necesare (Reteta)</p-divider>
                        <br>
                        <p-table [value]="formProductionNoteRawMaterials().controls" [autoLayout]="true">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th colspan="4" style="text-align: center">Reteta alocata</th>
                                </tr>
                                <tr>
                                    <th>Materie prima</th>
                                    <th>Stoc curent</th>
                                    <th>Cantitate necesara</th>
                                    <th>Cantitate ramasa dupa productie</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
                                <ng-container [formGroup]="rowData">
                                    <tr>
                                        <td>
                                            <app-stt-dropdown endPoint="raw-materials"
                                                              [formControlParent]="rowData.controls.raw_material_id"
                                                              (changeEvent)="onRawMaterialChange($event, rowIndex)"></app-stt-dropdown>
                                        </td>
                                        <td>
                                            {{ rowData.controls.current_stock_raw_material.value }}
                                        </td>
                                        <td>
                                            <app-stt-input type="number"
                                                           [control]="rowData.controls.units"></app-stt-input>
                                        </td>
                                        <td>
                                            {{ rowData.controls.current_stock_raw_material.value - rowData.controls.units.value }}
                                        </td>
                                        <td>
                                            <button (click)="deleteFormItem(rowIndex)"
                                                    class="p-button-raised p-button-danger text-right"
                                                    icon="pi pi-minus" pButton pRipple type="button"></button>
                                        </td>
                                    </tr>
                                </ng-container>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="5">Nu am gasit o reteta asociata pentru produsul ales </td>
                                </tr>
                            </ng-template>
                        </p-table>
                        <div class="flex-box" style="  display:flex;justify-content:end;padding-top:8px;">
                            <button (click)="addRawMaterial()"
                                    class="p-button-raised p-button-success text-right"
                                    icon="pi pi-plus"
                                    style="margin-bottom: 24px"
                                    label="Adauga" pButton
                                    pRipple type="button"></button>
                        </div>
                        <!--                    <div class="col-4 grid align-items-end" style="padding: 14px">-->
                        <!--                        <button (click)="addRawMaterial()"-->
                        <!--                                class="p-button-raised p-button-success text-right"-->
                        <!--                                icon="pi pi-plus"-->
                        <!--                                style="margin-bottom: 24px"-->
                        <!--                                label="Adauga" pButton-->
                        <!--                                pRipple type="button"></button>-->
                        <!--                    </div>-->
                    </div>
                    <div class="col-12" *ngIf="currentTab===2">
                        <div class="grid p-fluid justify-content-center">
                            <div class="col-4">
                                <h3 style="text-align: center">Doriti sa salvati modificarile?</h3>
                            </div>
                        </div>
                        <div class="grid p-fluid justify-content-center">
                            <div class="col-3">
                                <div class="actions-area">
                                    <div class="actions-left">
                                        <button pButton pRipple type="button" label="Nu, anuleaza!"
                                                class="p-button-raised p-button-danger"
                                                icon="pi pi-ban" [disabled]="loading"
                                                routerLink="/stock-management/production-note/list"></button>
                                    </div>
                                    <div class="actions-right">
                                        <button pButton pRipple type="submit" label="Da, salveaza!"
                                                class="p-button-raised p-button-success"
                                                icon="pi pi-save"
                                                [disabled]="loading">
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </p-card>

        <p-progressBar mode="indeterminate" class="addEditProgressBar" *ngIf="loading"></p-progressBar>

        <div class="actions-area">
            <div class="actions-left" style="gap: 1em; display: flex">
                <button pButton pRipple type="button" label="Anulare" class="p-button-raised p-button-danger"
                        icon="pi pi-ban" [disabled]="loading"
                        routerLink="/stock-management/production-note/list"></button>

                <button pButton pRipple type="button" label="Pasul anterior" class="p-button-raised"
                        [disabled]="loading || currentTab === 0" (click)="previousStep()"></button>
            </div>

            <div class="actions-right">
                <button pButton pRipple type="button" label="Pasul urmator" class="p-button-raised"
                        [disabled]="loading || currentTab === lastTabIndex" (click)="nextStep()">
                </button>
            </div>
        </div>
    </form>
</div>

