<form [formGroup]="form" style="max-width: 100%!important;" autocomplete="false">
    <div class="grid fields-group">
        <div class="col-12">
            <div class="grid p-fluid">
                <div class="field col-12 md:col-6">
                    <app-stt-input inputId="name" [control]="form.controls['name']"
                                   icon="pi pi-users"
                                   label="Denumire"></app-stt-input>
                </div>
                <div class="field col-12 md:col-6">
                    <app-stt-input inputId="code" [control]="form.controls['code']"
                                   icon="pi pi-key"
                                   label="Cod SAP"></app-stt-input>
                </div>
            </div>
            <div class="grid p-fluid">
                <div class="field col-6">
                    <app-stt-dropdown placeholder="Nu ati selectat unitatea de masura!" endPoint="unit-measures/dropdown"
                                      [formControlParent]="form.controls['unit_measure_id']"
                                      icon="pi pi-chart-pie"
                                      label="Unitate de masura"></app-stt-dropdown>
                </div>
                <div class="field col-6">
                    <app-stt-dropdown placeholder="Nu ati selectat specia!" endPoint="product-categories/dropdown"
                                      [formControlParent]="form.controls['product_category_id']"
                                      icon="pi pi-th-large"
                                      label="Specie"
                                      [dropDownWithTrashed]="dropDownWithTrashed"></app-stt-dropdown>
                </div>
            </div>
            <div class="grid p-fluid">
                <div class="field col-6">
                    <app-stt-dropdown endPoint="fncs/dropdown" [formControlParent]="form.controls['fnc_id']" label="Fnc"
                                      icon="pi pi-cog"
                                      placeholder="Nu ati selectat un FNC!"
                                      [dropDownWithTrashed]="dropDownWithTrashed"></app-stt-dropdown>
                </div>
                <div class="field col-6">
                    <app-stt-dropdown endPoint="recipes/dropdown" [formControlParent]="form.controls['recipe_id']" label="Reteta"
                                      icon="pi pi-cog"
                                      placeholder="Nu ati selectat o reteta!"></app-stt-dropdown>
                </div>
            </div>
            <div class="grid p-fluid">
                <div class="field col-6">
                    <app-stt-dropdown endPoint="customer-products/dropdown" [formControlParent]="form.controls['customer_product_id']" label="Produs Client"
                                      icon="pi pi-cog"
                                      placeholder="Nu ati selectat un produs!"
                                      [dropDownWithTrashed]="dropDownWithTrashed"></app-stt-dropdown>
                </div>
                <div class="field col-6">
                    <app-stt-dropdown placeholder="Nu ati selectat tipul de ambalare!" endPoint="product-packing-types/dropdown"
                                      [formControlParent]="form.controls['product_packing_type_id']"
                                      icon="pi pi-chart-pie"
                                      label="Tip ambalare"
                                      [dropDownWithTrashed]="dropDownWithTrashed"></app-stt-dropdown>
                </div>
            </div>
        </div>
    </div>
    <p-progressBar mode="indeterminate" class="addEditProgressBar" *ngIf="loading"></p-progressBar>

    <div class="actions-area">
        <div class="actions-left">
            <button pButton pRipple type="button" label="Anulare" class="p-button-raised p-button-danger"
                    icon="pi pi-ban" [disabled]="loading" (click)="onCancel()">
            </button>
        </div>

        <div class="actions-right">
            <button pButton pRipple type="button" label="Salveaza" class="p-button-raised p-button-success"
                    icon="pi pi-save" [disabled]="loading" (click)="onSave()">
            </button>
        </div>
    </div>
</form>
