<script src="../../../../app.component.ts"></script><a routerLink="/laboratory/quality-report/list"
                                                       style="color: #808080;font-size: 18px">
    <i class="fas fa-arrow-left"></i>
    <span>Inapoi la rapoartele de calitate</span>
</a>
<br>
<br>
<form [formGroup]="form" autocomplete="false" (ngSubmit)="onSave()">
    <p-card>
        <div class="grid fields-group" style="padding: 0 1em">
            <div class="col-12">
                <div class="grid p-fluid">
                    <div class="field col-12 md:col-4">
                        <app-stt-input inputId="productName" [control]="form.controls['product_name']"
                                       icon="pi pi-key"
                                       label="Denumire produs"></app-stt-input>
                    </div>
                    <div class="field col-12 md:col-4">
                        <app-stt-dropdown endPoint="products/dropdown"
                                          [formControlParent]="form.controls['product_id']"
                                          label="Cod Produs" icon="pi pi-th-large"
                                          placeholder="Nu ati selectat un produs!"
                                          [dropDownWithTrashed]="dropDownWithTrashed"
                                          (changeEvent)="onProductChange($event)"></app-stt-dropdown>
                    </div>
                    <div class="field col-12 md:col-4">
                        <app-stt-input inputId="lot" [control]="form.controls['lot']"
                                       icon="pi pi-list" type="string"
                                       label="Lot"></app-stt-input>
                    </div>
                </div>
                <br>
                <div class="grid p-fluid">
                    <div class="field col-12 md:col-4">
                        <app-stt-calendar label="Data fabricatie" icon="pi pi-calendar"
                                          [control]="form.controls['production_date']"></app-stt-calendar>
                        <br>
                        <app-stt-input inputId="validity" [control]="form.controls['validity']"
                                       icon="pi pi-list" type="number"
                                       label="Zile valabilitate de la data fabricatiei"></app-stt-input>
                    </div>
                    <div class="field col-12 md:col-8" style="border-left: 1px solid lightgray">
                        <app-stt-textarea inputId="description" [control]="form.controls['usage_instructions']"
                                          icon="pi pi-pencil"
                                          label="Instructiuni de utilizare"></app-stt-textarea>
                    </div>
                </div>


                <!-- Nutrienti si Aditivi-->
                <p-divider>Nutrienti si Aditivi</p-divider>
                <div class="grid fields-group" *ngIf="formRecipeNutrients().value.length > 0">
                    <div class="col-6 grid" style="flex-wrap: nowrap; gap: 2rem;">
                        <div class="container col-12" formArrayName="recipe_nutrients">
                            <ng-container
                                *ngFor="let recipeItemNutrient of formRecipeNutrients().controls; let index = index">
                                <ng-container [formGroup]="recipeItemNutrient" *ngIf="recipeItemNutrient.value.additives === false">
                                    <div class="grid p-fluid">
                                        <div class="field col-4">
                                            <app-stt-input inputId="name" [control]="recipeItemNutrient.controls['name']"
                                                           icon="pi pi-box" type="string"
                                                           readonly="true"
                                                           label="Denumire"></app-stt-input>
                                        </div>
                                        <div class="field col-4">
                                            <app-stt-input inputId="units" [control]="recipeItemNutrient.controls['units']"
                                                           icon="pi pi-box" type="number"
                                                           label="Cantitate"></app-stt-input>
                                        </div>
                                        <div class="field col-3">
                                            <app-stt-input inputId="unit_measure" [control]="recipeItemNutrient.controls['unit_measure']"
                                                           icon="pi pi-box" type="string"
                                                           readonly="true"
                                                           label="Unitate"></app-stt-input>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>
                        <div class="col-12 grid" style="flex-wrap: nowrap; gap: 2rem;border-left: 1px solid lightgray">
                            <div class="container col-12" formArrayName="recipe_nutrients">
                                <ng-container
                                    *ngFor="let recipeItemNutrient of formRecipeNutrients().controls; let index = index">
                                    <ng-container [formGroup]="recipeItemNutrient" *ngIf="recipeItemNutrient.value.additives === true">
                                        <div class="grid p-fluid">
                                            <div class="field col-4">
                                                <app-stt-input inputId="name" [control]="recipeItemNutrient.controls['name']"
                                                               icon="pi pi-box" type="string"
                                                               readonly="true"
                                                               label="Denumire"></app-stt-input>
                                            </div>
                                            <div class="field col-4">
                                                <app-stt-input inputId="units" [control]="recipeItemNutrient.controls['units']"
                                                               icon="pi pi-box" type="number"
                                                               readonly="true"
                                                               label="Cantitate"></app-stt-input>
                                            </div>
                                            <div class="field col-3">
                                                <app-stt-input inputId="unit_measure" [control]="recipeItemNutrient.controls['unit_measure']"
                                                               icon="pi pi-box" type="string"
                                                               readonly="true"
                                                               label="Unitate"></app-stt-input>
                                            </div>
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="grid fields-group" *ngIf="formRecipeNutrients().value.length === 0">
                    <div class="col-6 grid" style="flex-wrap: nowrap; gap: 2rem;">
                        <div class="container col-1">
                        </div>
                        <div class="container col-11">
                            Nu au fost gasiti nutrienti sau aditivi
                        </div>
                    </div>
                </div>
                <br>
                <br>


                <!--Materie prima-->
                <p-divider>Materie prima </p-divider>
                <div class="grid fields-group" *ngIf="formRecipeRawMaterials().value.length > 0">
                    <div class="col-12 grid" style="flex-wrap: nowrap; gap: 2rem;">
                        <div class="container col-8" formArrayName="recipe_raw_materials">
                            <ng-container
                                *ngFor="let recipeItemForm of formRecipeRawMaterials().controls; let index = index">
                                <ng-container [formGroup]="recipeItemForm">
                                    <div class="grid p-fluid">
                                        <div class="field col-7">
                                            <app-stt-dropdown endPoint="raw-materials/dropdown"
                                                              placeholder="Nu ati selectat materia prima!"
                                                              icon="pi pi-check-square"
                                                              [formControlParent]="recipeItemForm.controls['raw_material_id']"
                                                              label="Materia prima"
                                                              readonly="true"
                                                              [dropDownWithTrashed]="dropDownWithTrashed">
                                            </app-stt-dropdown>
                                        </div>
                                        <div class="field col-4">
                                            <app-stt-input inputId="units" [control]="recipeItemForm.controls['units']"
                                                           icon="pi pi-box" type="number"
                                                           readonly="true"
                                                           label="Unitati"></app-stt-input>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div class="grid fields-group" *ngIf="formRecipeRawMaterials().value.length === 0">
                    <div class="col-6 grid" style="flex-wrap: nowrap; gap: 2rem;">
                        <div class="container col-1">
                        </div>
                        <div class="container col-11">
                            Nu au fost gasite materii prime
                        </div>
                    </div>
                </div>
                <br>
                <br>


                <!--Enzime-->
                <p-divider>Enzime</p-divider>
                <br>
                <div class="grid p-fluid">
                    <div class="col-7">
                        <p-table [value]="formEnzymeItems().controls" [autoLayout]="true">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th></th>
                                    <th>Enzime</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-rowData let-rowIndex>
                                <tr>
                                    <ng-container [formGroup]="rowData">
                                        <td>
                                            <p-checkbox formControlName="checked" binary="true" ></p-checkbox>
                                        </td>
                                        <td>
                                            <app-stt-dropdown endPoint="enzymes/dropdown"
                                                              placeholder="Nu ati selectat o enzima!"
                                                              icon="pi pi-check-square"
                                                              [formControlParent]="rowData.controls['enzime_id']"
                                                              readonly="true"
                                                              [dropDownWithTrashed]="dropDownWithTrashed">
                                            </app-stt-dropdown>
                                        </td>
                                    </ng-container>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="5">Nu au fost gasite enzime</td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>


                <br>
                <br>
                <p-divider>Coccibiostatice</p-divider>
                <br>
                <div class="grid p-fluid">
                    <div class="col-7">
                        <p-table [value]="formCoccidiostatItems().controls" [autoLayout]="true">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th></th>
                                    <th>Coccibiostatice </th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-rowData let-rowIndex>
                                <tr>
                                    <ng-container [formGroup]="rowData">
                                        <td>
                                            <p-checkbox formControlName="checked" binary="true" ></p-checkbox>
                                        </td>
                                        <td>
                                            <app-stt-dropdown endPoint="coccidiostats/dropdown"
                                                              placeholder="Nu ati selectat o coccidiostatica!"
                                                              icon="pi pi-check-square"
                                                              [formControlParent]="rowData.controls['coccibiostatic_id']"
                                                              readonly="true"
                                                              [dropDownWithTrashed]="dropDownWithTrashed">
                                            </app-stt-dropdown>
                                        </td>
                                    </ng-container>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="5">Nu au fost gasite coccibiostatice</td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>


                <br>
                <br>
                <p-divider>Soia modificata genetic </p-divider>
                <div class="grid fields-group" style="padding: 0 1em">
                    <div class="col-12">
                        <app-stt-textarea inputId="geneticallyModifiedSoy" [control]="form.controls['genetically_modified_soy']"
                                          icon="pi pi-pencil"
                                          label="Soia modificata genetic "></app-stt-textarea>
                    </div>
                </div>


                <br>
                <br>
                <p-divider>Observatii</p-divider>
                <div class="grid fields-group" style="padding: 0 1em">
                    <div class="col-12">
                        <app-stt-textarea inputId="observations" [control]="form.controls['observations']"
                                          icon="pi pi-pencil"
                                          label="Observatii"></app-stt-textarea>
                    </div>
                </div>


            </div>
        </div>
    </p-card>

    <p-progressBar mode="indeterminate" class="addEditProgressBar" *ngIf="loading"></p-progressBar>

    <div class="actions-area">
        <div class="actions-left">
            <button pButton pRipple type="button" label="Anulare" class="p-button-raised p-button-danger"
                    icon="pi pi-ban" [disabled]="loading"
                    routerLink="/stock-management/reception-note/list"></button>
        </div>

        <div class="actions-right">
            <button pButton pRipple type="submit" label="Salveaza" class="p-button-raised p-button-success"
                    icon="pi pi-save" [disabled]="loading">
            </button>
        </div>
    </div>
</form>
