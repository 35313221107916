import {StorageSpaceModel} from '../../entities/storage-space.model';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpApiService} from '../base/http-api.service';
import {ApiResponseModel} from '../../entities/api-response.model';


@Injectable({
    providedIn: 'root'
})

export class StorageSpaceService {

    constructor(
        public httpApiService: HttpApiService,
    ) {

    }

    public get(uid): Observable<ApiResponseModel>
    {
        return this.httpApiService.httpGet('storage-spaces/view', {uid});
    }

    public set(storage: StorageSpaceModel): Observable<ApiResponseModel>
    {
        return this.httpApiService.httpPost('storage-spaces/store', storage);
    }

    public delete(uid): Observable<ApiResponseModel> {
        return this.httpApiService.httpDelete('storage-spaces/destroy', { uid });
    }

}


