import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ValidationMessageService } from '../../services/base/validation-message-handle';

@Component({
    selector: 'app-stt-validator-message',
    templateUrl: './stt-validator-message.component.html',
    styleUrls: ['./stt-validator-message.component.scss']
})
export class SttValidatorMessageComponent implements OnInit, OnDestroy {
    @Input() control: AbstractControl;
    @Input() inputType = 'string';
    convertedControl: UntypedFormControl;

    valueChangesSub: Subscription;

    noClientSideErrors = false;
    errorMessage = 'Acest camp este obligatoriu!';

    constructor(private validationMessageService: ValidationMessageService) {
    }

    ngOnInit(): void {
        this.convertedControl = this.control as UntypedFormControl;
        this.valueChangesSub = this.convertedControl.valueChanges.subscribe(() => {
            this.validationMessageService.getFormControlValidationErrors(this.convertedControl);
            this.setErrorMessage();
        });
    }

    ngOnDestroy(): void {
        this.valueChangesSub.unsubscribe();
    }

    setErrorMessage(): void {
        const errors = this.convertedControl.errors;
        if (!errors) {
            this.noClientSideErrors = true;
            return;
        }
        this.noClientSideErrors = false;
        if (errors.required && this.convertedControl.touched) {
            this.errorMessage = 'Acest camp este obligatoriu! ';
        }
        switch (this.inputType) {
            case 'string':
                if (errors.minlength) {
                    if (errors.minlength.requiredLength >= 20) {
                        this.errorMessage = `Acest camp permite ' +
                            'minim ${ this.convertedControl.errors.minlength.requiredLength } de caractere! `;
                    } else {
                        this.errorMessage = `Acest camp permite ' +
                            'minim ${ this.convertedControl.errors.minlength.requiredLength } caractere! `;
                    }
                } else if (errors.maxlength) {
                    if (errors.maxlength.requiredLength >= 20) {
                        this.errorMessage = `Acest camp permite ' +
                            'maxim ${ this.convertedControl.errors.maxlength.requiredLength } de caractere! `;
                    } else {
                        this.errorMessage = `Acest camp permite ' +
                            'maxim ${ this.convertedControl.errors.maxlength.requiredLength } caractere! `;
                    }
                } else if (errors.email) {
                    this.errorMessage = 'Acest email este invalid! ';
                } else if (errors.passwordsNotMatching) {
                    this.errorMessage = 'Parolele nu sunt similare. ';
                }
                break;
            case 'number':
                if (errors.min) {
                    this.errorMessage = `Acest camp trebuie sa aibe o valoare mai mare ca ${ this.convertedControl.errors.min.min }! `;
                } else if (errors.max) {
                    this.errorMessage = `Acest camp trebuie sa aibe o valoare mai mica ' +
                        'sau egala cu ${ this.convertedControl.errors.min.min }! `;
                }
        }
        this.errorMessage += '\r\n';
    }
}
