import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Params, Router } from '@angular/router';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { finalize } from 'rxjs/operators';
import { OrderRawMaterialModel } from '../../../../shared/entities/order-raw-material.model';
import { RecipeItemModel } from '../../../../shared/entities/recipe-item.model';
import { OrderRawMaterialService } from '../../../../shared/services/app/order-raw-material.service';
import { HttpApiService } from '../../../../shared/services/base/http-api.service';
import { ValidationMessageService } from '../../../../shared/services/base/validation-message-handle';
import { RawMaterialService } from '../../../../shared/services/app/raw-material.service';
import {
    OrderRawMaterialsActionTableComponent
} from '../order-raw-materials-action-table/order-raw-materials-action-table.component';
import { SttTableService } from '../../../../shared/components/stt-table/stt-table-service';


@Component({
    selector: 'app-orders-raw-materials-reception-note',
    templateUrl: './orders-raw-materials-reception-note.component.html',
    styleUrls: ['./orders-raw-materials-reception-note.component.scss']
})
export class OrdersRawMaterialsReceptionNoteComponent implements OnInit {
    public uid: string = null;
    public itemModel: OrderRawMaterialModel = new OrderRawMaterialModel();
    public loading = false;
    public form: UntypedFormGroup;
    public minimumDate: Date = new Date();
    public checkboxItems = [];
    public selectedItems: any = null;

    public tableEndpoint = 'raw-material-orders';
    public tableActionComponent = OrderRawMaterialsActionTableComponent;
    public tableQuery: any;
    public lastCheckedIndex: number;
    public dropDownWithTrashed = false;

    constructor(
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private formBuilder: UntypedFormBuilder,
        private httpApiService: HttpApiService,
        private validationMessageService: ValidationMessageService,
        private itemService: OrderRawMaterialService,
        private rawMaterialService: RawMaterialService,
        public dialogService: DialogService,
        private router: Router
    ) {
        this.tableQuery = new SttTableService()
            .addInclude('fnc')
            .addInclude('supplier');
    }

    ngOnInit(): void {
        this.uid = (this.config.data) ? this.config.data.uid : null;

        this.createForm();

        if (this.uid) {
            this.getItem();
        }
        return;
    }

    onCloseDialog() {
        this.ref.close(null);
    }

    createForm(): void {
        this.form = this.formBuilder.group({
            uid: [this.itemModel.uid],
            supplier_id: [this.itemModel.supplier_id],
            fnc_id: [this.itemModel.fnc_id],
            code: [this.itemModel.code],
            orderDate: [this.itemModel.orderDate],
            description: [this.itemModel.description],
            items: this.formBuilder.array([]),
        });
        if (!this.uid) {
            // Add empty line
            this.addFormElement();
        }
    }

    formRawMaterialOrderItems(): UntypedFormArray {
        return this.form.get('items') as UntypedFormArray;
    }


    deleteFormItem(index: number): void {
        const formItem = this.formRawMaterialOrderItems().at(index);
        formItem.patchValue({
            needDelete: true,
        });
    }


    addFormElement(item?: RecipeItemModel) {
        const rawMaterialOrderItemForm = this.formBuilder.group({
            uid: [(item) ? item.uid : null],
            id: [(item) ? item.id : null],
            raw_material_id: [(item) ? item.raw_material_id : null, Validators.required],
            units: [(item) ? item.units : null, Validators.required],
            receivedUnits: [(item) ? item.receivedUnits : 0, Validators.required],
            receptionNoteCreated: [false],
            receptionNoteReady: [{ value: false, disabled: false }],
            needDelete: [false]
        });
        this.formRawMaterialOrderItems().push(rawMaterialOrderItemForm);
    }

    getItem(): void {
        this.loading = true;
        this.dropDownWithTrashed = true;
        this.itemService.get(this.uid)
            .pipe(finalize(() => {
                this.loading = false;
                this.checkProducts();
            }))
            .subscribe((response: any) => {
                this.itemModel.map(response.payload);
                this.form.patchValue(this.itemModel);
                const dateParse = new Date(response.payload.orderDate);
                this.form.patchValue({
                    orderDate: dateParse,
                });
                this.itemModel.items.forEach(item => {
                    this.addFormElement(item);
                });
                this.rawMaterialService.getList()
                    .pipe(finalize(() => {this.loading = false; this.dropDownWithTrashed = false;}))
                    .subscribe((responseRawMaterial: any) => {
                        const rawMaterials = responseRawMaterial.payload.data;
                        response.payload.items.forEach(item => {
                            rawMaterials.forEach(material => {
                                if (material.id === item.id) {
                                    this.checkboxItems.push({ id: item.id, name: material.name, units: item.units });
                                }
                            });
                        });
                    });
            });
    }

    checkProducts(): void {
        const items = this.formRawMaterialOrderItems();
        items.controls.forEach((item: UntypedFormGroup) => {
            if (item.controls.receivedUnits.value >= item.controls.units.value) {
                item.controls.receptionNoteCreated.patchValue(true);
                item.controls.receptionNoteReady.patchValue(true);
                item.controls.receptionNoteReady.disable();
            }
        });
    }

    onGenerate() {
        let itemUID: string;
        let itemId: number;
        this.formRawMaterialOrderItems().value.forEach((item) => {
            if (item.receptionNoteReady === true && item.receptionNoteCreated === false) {
                itemUID = item.uid;
                itemId = item.id;
            }
        });
        const params: Params = { order: this.form.get('uid').value, item: itemUID, itemID: itemId };
        this.router.navigate(['stock-management/reception-note/add-edit'], {
            queryParams: params,
            queryParamsHandling: 'merge'
        }).then(() => {
            this.ref.close();
        });
    }

    checkedBox(event, index: number) {
        if (this.lastCheckedIndex !== undefined) {
            this.formRawMaterialOrderItems().at(this.lastCheckedIndex).patchValue({
                receptionNoteReady: false,
            });
        }
        this.formRawMaterialOrderItems().at(index).patchValue({
            receptionNoteReady: true,
        });
        this.lastCheckedIndex = index;
    }
}
