import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SelectableRow, Table } from 'primeng/table';
import { HttpApiService } from '../../services/base/http-api.service';
import { finalize } from 'rxjs/operators';
import { SttTableService } from './stt-table-service';
import { LazyLoadEvent } from 'primeng/api';
import { DatePipe, DecimalPipe } from '@angular/common';
import { ApiResponseModel } from '../../entities/api-response.model';


@Component({
    selector: 'app-stt-table',
    templateUrl: './stt-table.component.html',
    styleUrls: ['./stt-table.component.scss']
})
export class SttTableComponent implements OnInit, AfterViewInit {

    @Input() query: SttTableService;
    @Input() endpoint: string;
    @Input() tableColumnsEndpoint: string;
    @Input() actionTemplate: any;
    @Input() selectionMode = 'single';
    @Input() enableSelection = false;
    @Input() expandComponent: any = null;
    @Input() requiresFNC = false;
    @Input() startDate = null;
    @Input() endDate = null;
    @Input() tableStyle = 'p-datatable-striped p-datatable-sm';

    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() onLazyLoadEvent = new EventEmitter<LazyLoadEvent>();
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() onItemsLoadEvent = new EventEmitter<any>();
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() onRowSelectEvent = new EventEmitter<SelectableRow>();
    @Output() emitExpandReference = new EventEmitter<any>();

    @ViewChild('grid', { static: false }) grid: Table;
    @ViewChild('container', { static: false }) container: any;
    @ViewChild('expandReference', { static: false }) expandReference: any;

    // public tableColumns: Array<any> = null;
    public tableColumns: Array<any> = [];
    public tableItems: Array<any> = [];
    public lastLazyLoadEvent: LazyLoadEvent;
    public loading = false;
    public tableLoading = false;
    public expandedRowKeys: any;
    public fnc_id: number;

    public selectedItems: Array<any> = [];

    public containerWith: number = null;
    public expandComputedMargin = 0;

    constructor(
        private httpApiService: HttpApiService,
        private datePipe: DatePipe,
        private decimalPipe: DecimalPipe
    ) {
    }

    setup(value, id) {
        if (value != null) {
            this.grid.filters[id][0].value = value;
        }
    }

    ngOnInit(): void {
        this.emitExpandReference.emit(this.emitExpandReference);
        this.getTableColumns();
    }

    ngAfterViewInit(): void {
        // Fix margin left of expand container
        const querySelector = this.grid.el.nativeElement.querySelector('.p-datatable-wrapper');
        const context = this;
        querySelector.addEventListener('scroll', e => {
            context.expandComputedMargin = querySelector.scrollLeft;
        });
    }

    alignEndColumn(col: any): boolean {
        return col.format === 'int' || col.format === 'float' || col.format === 'datetime' || col.name === 'actions';
    }


    getTableColumns(): void {
        this.loading = true;


        this.httpApiService.httpGet(this.endpoint, { onlyColumns: true })
            .pipe(finalize(() => this.loading = false))
            .subscribe((response: any) => {
                this.tableColumns = response.payload;
                this.containerWith = this.container.nativeElement.offsetWidth - 50;
                // console.log(this.grid.el.nativeElement.offsetWidth);
                // console.log(this.grid.el.nativeElement.scrollLeft);
            });
    }

    getTableItems(params): void {
        this.tableLoading = true;
        let parameters;
        if(this.requiresFNC){
            const data = JSON.parse(localStorage.getItem('user'));
            if (this.startDate && this.endDate){
                parameters  = this.query.getParams(this.lastLazyLoadEvent, data.fnc_id , this.startDate, this.endDate);
            }
           else{
                parameters  = this.query.getParams(this.lastLazyLoadEvent, data.fnc_id);
            }
        }
        else{
            parameters  = this.query.getParams(this.lastLazyLoadEvent);
        }
        this.httpApiService.httpPost(this.endpoint, parameters)
            .pipe(finalize(() => this.tableLoading = false))
            .subscribe((response: ApiResponseModel) => {
                this.query.total = response.payload.total;
                this.tableItems = response.payload.data;
                this.onItemsLoadEvent.subscribe(this.tableItems);

            });
    }

    clear(table: Table): void {
        table.clear();
    }


    convertDateToString(event){
        return this.datePipe.transform(event, 'dd/MM/yyyy');
    }

    onLazyLoad($event: LazyLoadEvent) {
        this.lastLazyLoadEvent = $event;
        this.getTableItems(this.query.getParams($event));
        this.onLazyLoadEvent.subscribe($event);
    }

    getElementValue(obj: any, is: any, value?: string): any {

        try {

            if (typeof is === 'string') {
                return this.getElementValue(obj, is.split('.'), value);
            } else if (is.length === 1 && value !== undefined) {
                return obj[is[0]] = value;
            } else if (is.length === 0) {
                return obj;
            } else {
                return this.getElementValue(obj[is[0]], is.slice(1), value);
            }

        } catch (e) {
            return ' - ';
        }
    }

    formatValue(value: any, format: any): any {
        switch (format) {
            case 'translate':
                // TODO Translate implementation
                return value + ' - trans';
            case 'datetime':
                return this.datePipe.transform(value, 'yyyy-MM-dd HH:mm');
            case 'date':
                return this.datePipe.transform(value, 'yyyy-MM-dd');
            case 'float':
                return this.decimalPipe.transform(value, '1.2-2');
            case 'boolean':
                return (value === true || value === 1) ? 'Da' : 'Nu';
            case 'boolean_null':
                return (value) ? 'Nu' : 'Da';
            default:
                return value;
        }

    }

    renderValue(rowValue, column): string {
        const value = this.getElementValue(rowValue, column.field);
        return this.formatValue(value, column.format);
    }

    onApplyFilters($event: any): void {
        // this.filters = $event.filters; //
    }

    getFiledType(col: any): string {
        switch (col.format) {
            case 'int': {
                return 'numeric';
                break;
            }
            case 'date': {
                return 'date';
                break;
            }
            case 'datetime': {
                return 'date';
                break;
            }
            case 'string': {
                return 'text';
                break;
            }
            default: {
                return 'text';
                break;
            }
        }
    }

    public refresh(): void {
        this.onLazyLoad(this.lastLazyLoadEvent);
    }

    onRowSelect($event: any) {
        this.onRowSelectEvent.emit($event);
    }
}
