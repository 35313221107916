import { Component, Directive, OnInit, ViewChild } from '@angular/core';
import { SttTableService } from '../../../../shared/components/stt-table/stt-table-service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SttTableComponent } from '../../../../shared/components/stt-table/stt-table.component';
import { OrdersCustomerActionTableComponent } from '../orders-customer-action-table/orders-customer-action-table.component';
import { finalize } from 'rxjs/operators';
import { OrderCustomerService } from '../../../../shared/services/app/order-customer.service';
import { OrderCustomerModel } from '../../../../shared/entities/order-customer.model';
import { AbstractControl, Form, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { OrderCustomerTransportService } from '../../../../shared/services/app/order-customer-transport.service';
import { MessageService } from 'primeng/api';
import { ValidationMessageService } from '../../../../shared/services/base/validation-message-handle';
import { OrderCustomerTransportModel } from '../../../../shared/entities/order-customer-transport.model';
import { DatePipe} from '@angular/common';
import { Router } from '@angular/router';


@Component({
    selector: 'app-orders-customers-transport',
    templateUrl: './orders-customers-transport.component.html',
    styleUrls: ['./orders-customers-transport.component.scss']
})

export class OrdersCustomersTransportComponent implements OnInit {

    @ViewChild('table', { static: false }) table: SttTableComponent;

    public tableEndpoint = 'customer-orders';
    public tableActionComponent = OrdersCustomerActionTableComponent;
    public tableQuery: SttTableService;
    public ref: DynamicDialogRef;
    public orderCustomerModel: any;
    public tableIsReadyToLoad = false;

    public loading = false;
    public canSave = true;
    public uid: string;
    public form: UntypedFormGroup;
    public weekDays = ['Duminica', 'Luni', 'Marti', 'Miercuri', 'Joi', 'Vineri', 'Sambata'];
    public previousTabIndex: number;
    public itemModel: OrderCustomerTransportModel = new OrderCustomerTransportModel();
    public productsExist: boolean;

    constructor(public dialogService: DialogService,
                public orderCustomerService: OrderCustomerService,
                private formBuilder: UntypedFormBuilder,
                private orderCustomerTransportService: OrderCustomerTransportService,
                private messageService: MessageService,
                private validationMessageService: ValidationMessageService,
                private datePipe: DatePipe,
                private router: Router,
    ) {

        this.tableQuery = new SttTableService().addInclude('customer-order');
    }

    ngOnInit(): void {

        this.uid = this.orderCustomerModel.uid;

        this.createForm();

        this.getItem();

    }


    getStyleAccordion(isDone: boolean) {
        if (isDone == null) {
            return null;
        }
        let jsonStyle;
        if (isDone) {
            jsonStyle = {
                background: 'rgb(187, 239, 182)',
                'border-radius': '3px 3px 3px 3px',
                border: '1px solid rgba(134, 183, 125, 0.7)'
            };
        } else {
            jsonStyle = {
                background: '#F6F9FE',
                'border-radius': '3px 3px 3px 3px',
                border: '1px solid rgba(228, 229, 229)'
            };
        }
        return jsonStyle;
    }

    getStyleTabView(weekDayDone: boolean) {
        if (weekDayDone == null) {
            return null;
        }
        let jsonStyle;
        if (weekDayDone) {
            jsonStyle = {
                background: 'rgb(187, 239, 182)',
                border: '1px solid rgba(134, 183, 125, 0.7)'
            };
        } else {
            jsonStyle = {
                background: '#F6F9FE',
                border: '1px solid rgba(228, 229, 229)'
            };
        }
        return jsonStyle;
    }


    formProducts(): UntypedFormArray {
        return this.form.get('products') as UntypedFormArray;
    }

    formProductsDate(index: number): UntypedFormArray {
        return this.formProducts().at(index)?.get('dates') as UntypedFormArray;
    }

    formProductsDateTransport(indexProduct: number, indexDay: number): UntypedFormArray {
        return this.formProductsDate(indexProduct).at(indexDay).get('transports') as UntypedFormArray;
    }

    createForm(): void {
        this.form = this.formBuilder.group({
            id: [this.itemModel.id],
            uid: [this.itemModel.uid],
            fnc_id: [this.itemModel.fnc_id],
            products: this.formBuilder.array([]),
        });
    }

    addProduct(data: any): UntypedFormGroup {
        return this.formBuilder.group({
            product_id: [data.product_id, Validators.required],
            name: [data.product.name],
            isDone: [false],
            dates: this.formBuilder.array([]),
        });
    }

    addProductDate(data: any): UntypedFormGroup {
        const day = new Date(data.deliveryDate).getDay();
        const date = new Date(data.deliveryDate);
        return this.formBuilder.group({
            deliveryDate: [date],
            deliveryDateString: [data.deliveryDate],
            deliveryDay: [day],
            quantity: [data.quantity, Validators.required],
            quantityTransportTotal: [0],
            weekDayDone: [false],
            transports: this.formBuilder.array([])
        });
    }


    addTransport(): UntypedFormGroup {
        return this.formBuilder.group({
            uid: [null],
            transport_company_id: [null],
            transport_unit_type_id: [null],
            transport_unit_driver_id: [null],
            quantityPerTransport: [null, Validators.required],
            needDelete: [true]
        });
    }

    addFormTransport() {
        for (let indexProducts = 0; indexProducts < this.formProducts().length; indexProducts++) {
            for (let indexWeekDays = 0; indexWeekDays < this.formProductsDate(indexProducts).length; indexWeekDays++) {
                this.formProductsDateTransport(indexProducts, indexWeekDays).push(this.addTransport());
            }
        }
    }

    getItem(): void {
        this.loading = true;
        this.canSave = false;
        let productExist = false;
        this.orderCustomerService.get(this.uid)
            .pipe(finalize(() => {
                this.loading = false;
                this.canSave = true;
                this.productsExist = productExist;
            }))
            .subscribe((response: any) => {
                // Set id and uid on form
                this.itemModel.map(response.payload);
                this.form.patchValue(this.itemModel);
                // Add products and dates on the form
                response.payload.customerOrderItems.forEach(element => {
                    const index = this.formProducts().value.findIndex(object => object.product_id === element.product_id);
                    if (element.noTransport === false) {
                        if (index === -1) {
                            this.formProducts().push(this.addProduct(element));
                            const lastIndex = this.formProducts().value.length;
                            this.formProductsDate(lastIndex - 1).push(this.addProductDate(element));
                        }
                        else {
                            this.formProductsDate(index).push(this.addProductDate(element));
                        }
                        productExist = true;
                    }
                });
                this.addFormTransport();
                // Map the transport already allocated
                if (response.payload.customerOrderTransportItems.length > 0) {
                    this.mapTransportOnForm(response.payload);
                }
            });
    }

    mapTransportOnForm(data: any) {
        data.customerOrderTransportItems.forEach(element => {
            for (let i = 0; i < this.formProducts().value.length; i++) {
                // Find current product
                if (this.formProducts().at(i).value.product_id === element.product_id) {
                    // Iterate through the weekdays
                    for (let j = 0; j < this.formProductsDate(i).value.length; j++) {
                        const day = new Date(element.delivery_date).getDay();
                        // Find current weekday in the product
                        if (this.formProductsDate(i).at(j).value.deliveryDay === day) {
                        // let deliveryDay; (comentariul merge in locul if-ului de mai sus)
                        // if (this.formProductsDate(i).at(j).value.deliveryDay === 0 ){
                        //     deliveryDay = 6;
                        // }
                        // else{
                        //     deliveryDay = this.formProductsDate(i).at(j).value.deliveryDay - 1;
                        // }
                        // if (deliveryDay === day) {
                            let indexOfTransport = 0;
                            // If the first position of the transport is mapped then push another transport and save index
                            if (this.formProductsDateTransport(i, j).at(0).value.transport_company_id != null) {
                                this.formProductsDateTransport(i, j).push(this.addTransport());
                                indexOfTransport = this.formProductsDateTransport(i, j).value.length - 1;
                            }
                            // Map transport on index (either 0 or current index)
                            this.formProductsDateTransport(i, j).at(indexOfTransport).patchValue({
                                uid: element.uid,
                                transport_company_id: element.transport_company_id,
                                transport_unit_type_id: element.transport_unit_type_id,
                                transport_unit_driver_id: element.transport_unit_driver_id,
                                quantityPerTransport: element.transport_quantity,
                                needDelete: false
                            });
                            // Sum the quantityTransportTotal
                            if (indexOfTransport === 0) {
                                this.formProductsDate(i).at(j).patchValue({
                                    quantityTransportTotal: element.transport_quantity,
                                });
                            } else {
                                const newQuantityTotal = element.transport_quantity
                                    + this.formProductsDate(i).at(j).value.quantityTransportTotal;
                                this.formProductsDate(i).at(j).patchValue({
                                    quantityTransportTotal: newQuantityTotal,
                                });
                            }
                            // Check if the week is done
                            if (this.formProductsDate(i).at(j).value.quantity <= this.formProductsDate(i).at(j).value.quantityTransportTotal) {
                                this.formProductsDate(i).at(j).patchValue({
                                    weekDayDone: true,
                                });
                            }
                        }
                    }
                }
            }
        });
        // Check if product is done
        for (let indexProduct = 0; indexProduct < this.formProducts().length; indexProduct++) {
            let productDone = true;
            for (let indexWeekDays = 0; indexWeekDays < this.formProductsDate(indexProduct).length; indexWeekDays++) {
                if (this.formProductsDate(indexProduct).at(indexWeekDays).value.weekDayDone === false) {
                    productDone = false;
                }
                if (this.formProductsDate(indexProduct).at(indexWeekDays).value.weekDayDone
                    === false && this.formProductsDate(indexProduct).at(indexWeekDays).value.quantityTransportTotal !== 0) {
                    this.formProductsDateTransport(indexProduct, indexWeekDays).push(this.addTransport());
                }
            }
            if (productDone === true) {
                this.formProducts().at(indexProduct).patchValue({
                    isDone: true
                });
            }
        }
    }

    onOpenProduct(data: any) {
        if (this.previousTabIndex !== undefined) {
            this.onCloseProduct(data);
        }
        this.previousTabIndex = data.index;
    }


    onCloseProduct(data: any) {
        for (let indexWeekDay = 0; indexWeekDay < this.formProductsDate(data.index).value.length; indexWeekDay++) {
            for (let indexTransport = 0;
                 indexTransport < this.formProductsDateTransport(data.index, indexWeekDay).value.length;
                 indexTransport++) {
                if (this.formProductsDateTransport(data.index, indexWeekDay).at(indexTransport).value.needDelete === true) {
                    this.formProductsDateTransport(data.index, indexWeekDay).at(indexTransport).patchValue({
                        transport_company_id: null,
                        transport_unit_type_id: null,
                        transport_unit_driver_id: null,
                        quantityPerTransport: null,
                    });
                }
                this.formProductsDateTransport(data.index, indexWeekDay).at(indexTransport);
            }
        }

    }

    getDateFromControl(control: AbstractControl): string {
        return this.datePipe.transform(control.value.deliveryDate, 'dd-MM-yyyy');
        // const date = control.value.deliveryDateString.split('T')[0];
        // return date.split('-').reverse().join('-');
    }

    checkIfProductDone(productIndex: number): boolean {
        return this.formProducts().at(productIndex).value.isDone;
    }

    checkIfWeekDone(productIndex: number, weekIndex: number): boolean {
        // return this.validDays[weekIndex];
        return this.formProductsDate(productIndex).at(weekIndex).value.weekDayDone;
    }

    setParams(data: any): void {
        this.orderCustomerModel = new OrderCustomerModel().map(data);
        this.tableQuery.complexFilters = { ['uid']: { matchMode: 'equals', value: this.orderCustomerModel.uid } };
        this.tableIsReadyToLoad = true;
    }

    save() {
        // if endtime - starttime < 4 ore -> toast('interval orar minim 4 ore')
        this.loading = true;
        const data = this.form.getRawValue();
        this.orderCustomerTransportService.set(data)
            .pipe(finalize(() => {
                this.loading = false;
                this.canSave = true;
            }))
            .subscribe((response: any) => {
                this.itemModel.map(response.payload);
                this.form.patchValue(this.itemModel);
                this.messageService.add({
                    severity: 'success',
                    summary: 'Transport alocat cu success!'
                });
                window.location.reload();
                // this.router.navigate(['orders/customer/list'], {});
            }, err => {
                if (err instanceof HttpErrorResponse) {
                    this.validationMessageService.serverSideValidation(err, this.form, '', true);
                }
            });
    }
}
