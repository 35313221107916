<div class="actions-area">
    <button (click)="addFormItem()" class="p-button-raised p-button-success text-right" icon="pi pi-plus"
            label="Adauga adresa" pButton
            pRipple type="button"></button>
</div>
<div [formGroup]="formGroup" class="grid fields-group">
    <ng-container formArrayName="addresses">
        <ng-container *ngFor="let addressForm of addressesFormArray.controls; let i = index">
            <div class="col-12 according-wrapper">
                <p-accordion>
                    <p-accordionTab [formGroup]="addressForm"
                                    [class.isDeleted]="addressForm.controls.needDelete.value"
                                    [header]="'Adresa: ' + (addressForm.controls.address.value) ? addressForm.controls.address.value : ' - '"
                                    [selected]="!addressForm.controls.address.value">
                        <div class="col-12" *ngIf="!addressForm.controls.needDelete.value">
                            <div class="grid p-fluid">
                                <div class="field col-12 md:col-6">
                                    <app-stt-dropdown [formControlParent]="addressForm.controls['county_id']"
                                                      placeholder="Nu ati selectat un judet!"
                                                      endPoint="counties"
                                                      icon="pi pi-map"
                                                      [required]="true"
                                                      label="Judet"></app-stt-dropdown>
                                </div>
                                <div class="field col-12 md:col-6">
                                    <app-stt-dropdown [formControlParent]="addressForm.controls['city_id']"
                                                      endPoint="cities"
                                                      icon="pi pi-map"
                                                      placeholder="Nu ati selectat un oras!"
                                                      [required]="true"
                                                      label="Oras"></app-stt-dropdown>
                                </div>
                            </div>
                            <div class="grid p-fluid">
                                <div class="field col-12 md:col-6">
                                    <app-stt-dropdown [formControlParent]="addressForm.controls['fnc_id']"
                                                      endPoint="fncs"
                                                      [required]="true"
                                                      icon="pi pi-cog"
                                                      placeholder="Nu ati selectat un FNC!"
                                                      label="FNC"></app-stt-dropdown>
                                </div>
                            </div>
                            <div class="grid p-fluid">
                                <div class="field col-12 md:col-12">
                                    <app-stt-input inputId="address" [control]="addressForm.controls['address']"
                                                   icon="pi pi-home" [required]="true"
                                                   label="Adresa"></app-stt-input>
                                </div>
                            </div>
                            <div class="actions-area">
                                <div class="actions-left">
                                    <button (click)="removeFormItem(i)" class="p-button-raised p-button-danger"
                                            icon="pi pi-trash" label="Sterge adresa"
                                            pButton pRipple
                                            type="button"></button>
                                </div>
                            </div>
                        </div>
                        <div class="col-12" *ngIf="addressForm.controls.needDelete.value" class="col-12">
                            <p>Adresa va fi sters dupa apasarea butonului "Salveaza"</p>
                        </div>
                    </p-accordionTab>
                </p-accordion>
            </div>
        </ng-container>
    </ng-container>
</div>

<h3 *ngIf="addressesFormArray.length === 0" class="text-center"> Nu sunt adrese in sistem pentru acest client </h3>
