import { Component, OnInit } from '@angular/core';
import {SttTableComponent} from '../../../../../shared/components/stt-table/stt-table.component';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {StorageSpaceModel} from '../../../../../shared/entities/storage-space.model';
import {StorageSpaceAddEditComponent} from '../storage-space-add-edit/storage-space-add-edit.component';
import { ConfirmationService, MessageService, PrimeNGConfig } from 'primeng/api';
import { ValidationMessageService } from '../../../../../shared/services/base/validation-message-handle';
import { StorageSpaceService } from '../../../../../shared/services/app/storage-space.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-storage-space-table-action',
  templateUrl: './storage-space-table-action.component.html',
  styleUrls: ['./storage-space-table-action.component.scss']
})
export class StorageSpaceTableActionComponent implements OnInit {

    public data: StorageSpaceModel;
    public table: SttTableComponent;
    public ref: DynamicDialogRef;
    public loading = true;

    constructor(
        public dialogService: DialogService,
        public confirmationService: ConfirmationService,
        public primengConfig: PrimeNGConfig,
        public itemService: StorageSpaceService,
        public messageService: MessageService,
        private validationMessageService: ValidationMessageService,
    ) {

    }

    ngOnInit(): void {
    }

    setParams(entity: StorageSpaceModel): void {
        this.data = entity;
    }

    setTableReference(table: SttTableComponent): void
    {
        this.table = table;
    }


    showDialog() {
        this.ref = this.dialogService.open(StorageSpaceAddEditComponent, {
            header: 'Modificare Spatiu de Stocare',
            width: '70%',
            contentStyle: {'max-height': '70vh'},
            baseZIndex: 10000,
            closeOnEscape: false,
            data: this.data,
        });

        this.ref.onClose.subscribe((fnc: StorageSpaceModel) => {
            if (fnc) {
                this.table.refresh();
            }
        });
    }

    confirm() {
        this.confirmationService.confirm({
            header: 'Confirmare stergere Spatiu de stocare',
            message: 'Sunteti sigur ca doriti sa stergeti acest Spatiu de stocare?',
            key: 'deleteStorageSpace',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Da',
            rejectLabel: 'Nu',
            acceptButtonStyleClass: 'p-button-md p-button-success',
            rejectButtonStyleClass: 'p-button-md p-button-danger',
            accept: () => {
                this.deleteItem(this.data.uid);
            },
            reject: () => {
                this.confirmationService.close();
                this.table.refresh();
            }
        });
    }

    deleteItem(uid: string){
        this.itemService.delete(uid)
            .pipe(finalize(() => {this.loading = false;this.table.refresh();}))
            .subscribe((response: any) => {
                this.messageService.add({
                    severity: 'success',
                    summary: 'Spatiul de stocare a fost sters cu success!'
                });
            });
    }

}
