<br>
<form [formGroup]="form" autocomplete="off" (ngSubmit)="onSave()">
    <div class="grid">
        <div class="col-12">
            <div class="grid p-fluid">
                <div class="field col-12 md:col-6">
                    <app-stt-input inputId="email" [control]="form.controls['email']"
                                   icon="pi pi-envelope" type="email"
                                   label="Email"></app-stt-input>
                </div>
                <div class="field col-12 md:col-6">
                    <app-stt-input inputId="code" [control]="form.controls['code']"
                                   icon="pi pi-key" type="text"
                                   label="Cod SAP"></app-stt-input>
                </div>
            </div>
            <div class="grid p-fluid">
                <div class="field col-12 md:col-6">
                    <app-stt-input inputId="lastName" [control]="form.controls['lastName']"
                                   icon="pi pi-user" type="text"
                                   label="Prenume"></app-stt-input>
                </div>

                <div class="field col-12 md:col-6">
                    <app-stt-input inputId="firstName" [control]="form.controls['firstName']"
                                   icon="pi pi-user" type="text"
                                   label="Nume"></app-stt-input>
                </div>
            </div>
            <div class="grid p-fluid">
                <div class="field col-12 md:col-6">
                    <app-stt-dropdown placeholder="Nu ati selectat un rol!" endPoint="roles"
                                      icon="pi pi-id-card"
                                      [formControlParent]="form.controls['role_id']" label="Rol"></app-stt-dropdown>
                </div>
                <div class="field col-12 md:col-6">
                    <app-stt-dropdown endPoint="fncs/dropdown" [formControlParent]="form.controls['fnc_id']" label="Fnc" icon="pi pi-cog"
                                      placeholder="Nu ati selectat un fnc!"></app-stt-dropdown>
                </div>
<!--                <div class="field col-12 md:col-6">-->
<!--                    <app-stt-dropdown placeholder="Nu ati selectat un client asociat!" endPoint="customers"-->
<!--                                      [formControlParent]="form.controls['customer_id']"-->
<!--                                      icon="pi pi-user"-->
<!--                                      label="Client asociat"></app-stt-dropdown>-->
<!--                </div>-->
            </div>
            <div class="grid p-fluid">
                <div class="field col-12 md:col-6">
                    <app-stt-input inputId="password" [control]="form.controls['password']"
                                   icon="pi pi-lock" type="password"
                                   label="Parola"></app-stt-input>
                </div>
                <div class="field col-12 md:col-6">
                    <app-stt-input inputId="password_confirmation" [control]="form.controls['password_confirmation']"
                                   icon="pi pi-lock" type="password"
                                   label="Confirmare parola"></app-stt-input>
                </div>
            </div>

<!--            <div class="grid p-fluid">-->
<!--                <div class="field col-12 md:col-3">-->
<!--                    <p-toggleButton formControlName="isActive"-->
<!--                                    onLabel="Activ" offLabel="Inactiv"-->
<!--                                    onIcon="pi pi-check" offIcon="pi pi-times"-->
<!--                                    [ngModel]="form.get('isActive').value">-->
<!--                    </p-toggleButton>-->
<!--                </div>-->
<!--            </div>-->

        </div>
    </div>

    <p-progressBar mode="indeterminate" class="addEditProgressBar" *ngIf="loading"></p-progressBar>

    <div class="actions-area">
        <div class="actions-left">
            <button pButton pRipple type="button" label="Anulare" class="p-button-raised p-button-danger"
                    icon="pi pi-ban" [disabled]="loading" (click)="onCancel()">
            </button>
        </div>

        <div class="actions-right">
            <button pButton pRipple type="submit" label="Salveaza" class="p-button-raised p-button-success"
                    icon="pi pi-save" [disabled]="loading">
            </button>
        </div>
    </div>
</form>
