import { Component, OnInit } from '@angular/core';
import { CustomerModel } from '../../../../shared/entities/customer.model';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { HttpApiService } from '../../../../shared/services/base/http-api.service';
import { ValidationMessageService } from '../../../../shared/services/base/validation-message-handle';
import { MessageService } from 'primeng/api';
import { finalize } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { FncService } from '../../../../shared/services/app/fnc.service';
import { FncModel } from '../../../../shared/entities/fnc.model';

@Component({
    selector: 'app-fnc-add-edit',
    templateUrl: './fnc-add-edit.component.html',
    styleUrls: ['./fnc-add-edit.component.scss']
})
export class FncAddEditComponent implements OnInit {

    public uid: string = null;
    public fncModel: FncModel = new FncModel();
    public loading = false;
    public form: UntypedFormGroup;
    public readonly = false;

    constructor(
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private formBuilder: UntypedFormBuilder,
        private httpApiService: HttpApiService,
        private validationMessageService: ValidationMessageService,
        private fncService: FncService,
        private messageService: MessageService
    ) {
    }

    ngOnInit(): void {
        this.uid = (this.config.data) ? this.config.data.uid : null;

        this.createForm();

        if (this.uid) {
            this.getItem();
        }

    }

    onCloseDialog(fnc: FncModel) {
        this.ref.close(fnc);
    }

    createForm(): void {
        this.form = this.formBuilder.group({
            uid: [this.fncModel.uid],
            code: [this.fncModel.code, [Validators.required, Validators.maxLength(100)]],
            name: [this.fncModel.name, [Validators.required, Validators.maxLength(200)]],
            address: [this.fncModel.address, [Validators.required, Validators.maxLength(200), Validators.minLength(5)]],
            sarja: [this.fncModel.sarja, Validators.required],
            hasPlc: [this.fncModel.hasPlc, Validators.required],
            ipPlc: [this.fncModel.ipPlc, Validators.required],
            county_id: [this.fncModel.county.id, Validators.required],
            city_id: [this.fncModel.city.id, Validators.required],
        });
    }

    getItem(): void {
        this.loading = true;
        this.fncService.get(this.uid)
            .pipe(finalize(() => this.loading = false))
            .subscribe((response: any) => {
                this.fncModel.map(response.payload);
                this.form.patchValue(this.fncModel);
                this.disableField();
            });
    }


    disableField(): void {
        if(this.form.value.hasPlc === false || this.form.value.hasPlc === 0){
            this.form.patchValue({
                ipPlc: null
            });
            this.form.get('ipPlc').clearValidators();
            this.form.get('ipPlc').updateValueAndValidity();
            this.readonly = true;
        }
        else{
            this.form.get('ipPlc').setValidators(Validators.required);
            this.readonly = false;
        }
    }

    onCancel(): void {
        this.ref.close(null);
    }

    checkIfFormValid(): boolean {
        if (this.form.invalid) {
            this.messageService.add({
                severity: 'error',
                summary: 'Formularul nu este valid!',
                detail: 'Vericficati toate campurile si incercati din nou.'
            });
            this.form.markAllAsTouched();
            return false;
        }
        return true;
    }

    onSave(): void {
        if (!this.checkIfFormValid()) {
            return;
        }
        this.loading = true;
        const data = this.form.getRawValue();
        this.fncService.set(data)
            .pipe(finalize(() => this.loading = false))
            .subscribe((response: any) => {
                this.fncModel.map(response.payload);
                this.form.patchValue(this.fncModel);
                this.messageService.add({
                    severity: 'success',
                    summary: 'FNC-ul a fost salvat cu success!'
                });
                this.ref.close(this.fncModel);
            }, err => {
                if (err instanceof HttpErrorResponse) {
                    this.validationMessageService.serverSideValidation(err, this.form, '', true);
                }
            });
    }

}
