import { Injectable } from '@angular/core';
import {LazyLoadEvent} from 'primeng/api/lazyloadevent';

@Injectable()

export class SttTableService {

    public includes = [];
    public perPage = 15;
    public currentPage = 1;
    public total = 0;
    public complexFilters = {};

    constructor() {

    }

    public addInclude(relation: string): SttTableService {
        this.includes.push(relation);
        return this;
    }

    getCurrentPageNumber(param: LazyLoadEvent): number {
        return (param.first / param.rows) + 1;
    }

    getParams(param: LazyLoadEvent, fncId?: number, startDate?: any, endDate?: any){
        return {
            perPage: (param.rows) ? param.rows : this.perPage,
            sortField: param.sortField,
            sortOrder: param.sortOrder,
            multiSortMeta: param.multiSortMeta,
            complexFilters: Object.assign(param.filters, this.complexFilters),
            globalFilter: param.globalFilter,
            include: this.includes.join(','),
            page: this.getCurrentPageNumber(param),
            fnc_id: fncId,
            startDate,
            endDate
        };
    }

}
