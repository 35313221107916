import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CustomerContactModel } from '../../../../../shared/entities/customer-contact.model';

@Component({
    selector: 'app-customers-contacts',
    templateUrl: './customers-contacts.component.html',
    styleUrls: ['./customers-contacts.component.scss']
})
export class CustomersContactsComponent implements OnInit {

    @Input() formGroup: UntypedFormGroup;
    @Input() contacts: Array<CustomerContactModel>;

    constructor(private formBuilder: UntypedFormBuilder) {
    }

    get contactsFormArray() {
        return this.formGroup.controls.contacts as UntypedFormArray;
    }

    ngOnInit(): void {
        this.contacts.forEach(contact => {
            this.addFormItem(contact);
        });
    }

    addFormItem(contact?: CustomerContactModel): void {
        const contactForm = this.formBuilder.group({
            uid: [(contact) ? contact.uid : null],
            firstName: [(contact) ? contact.firstName : null, [Validators.required, Validators.maxLength(200)]],
            lastName: [(contact) ? contact.lastName : null, [Validators.required, Validators.maxLength(200)]],
            email: [(contact) ? contact.email : null, [Validators.required, Validators.maxLength(200)]],
            phone: [(contact) ? contact.phone : null, [Validators.required, Validators.maxLength(200)]],
            position: [(contact) ? contact.position : null, [Validators.required, Validators.maxLength(200)]],
            user_id: [(contact) ? contact.user_id : null, Validators.required],
            needDelete: [false, Validators.required],
        });
        this.contactsFormArray.push(contactForm);
    }

    removeFormItem(formIndex: number): void {
        const form = this.contactsFormArray.at(formIndex) as UntypedFormControl;
        form.patchValue({ needDelete: true });
    }

}
