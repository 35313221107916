import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../../../auth/user.service';
import { UsersActionsTemplateComponent } from './users-actions-template/users-actions-template.component';
import { UsersAddEditComponent } from './users-add-edit/users-add-edit.component';
import { UserModel } from '../../../auth/models/user.model';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SttTableComponent } from '../../../shared/components/stt-table/stt-table.component';
import { SttTableService } from '../../../shared/components/stt-table/stt-table-service';

@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss']
})

export class UsersComponent implements OnInit {
    @ViewChild('table', { static: false }) table: SttTableComponent;

    public tableEndpoint = 'users';
    public tableActionComponent = UsersActionsTemplateComponent;
    public tableQuery: SttTableService;
    public ref: DynamicDialogRef;

    constructor(private userService: UserService, public dialogService: DialogService) {
        this.tableQuery = new SttTableService();
    }

    ngOnInit(): void {
        //
    }

    addNewEntity() {
        this.ref = this.dialogService.open(UsersAddEditComponent, {
            header: 'Adaugare utilizator',
            width: '70%',
            contentStyle: { 'max-height': '70vh' },
            baseZIndex: 10000,
            closeOnEscape: false,
            data: null,
        });

        this.ref.onClose.subscribe((user: UserModel) => {
            if (user) {
                this.table.refresh();
            }
        });
    }
}
