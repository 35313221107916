<ng-container>
    <p-accordion [multiple]="true">
        <p-accordionTab [selected]="active">
            <ng-template pTemplate="header" class="header">
                <span [class.active-header]="active === true" class="center-vertically">
                    <i [ngClass]="item.icon" [class.active-header]="active === true" style="font-size: x-large"></i>
                    <p>{{ item.label }}</p>
                </span>
            </ng-template>
            <div *ngFor="let elem of item.items; let i = index" class="item">
                <div *ngIf="!elem.items">
                    <a [routerLink]="elem.routerLink[0]" class="accordion-item-link"
                       [class.selected]="activeLabel === elem.label"
                       (click)="setActiveLink(elem.label)">
                        <i [ngClass]="elem.icon" [class.selected]="activeLabel === elem.label"
                           style="font-size: medium"></i>
                        <p>{{ elem.label }}</p>
                    </a>
                </div>
                <div *ngIf="elem.items">
                    <div class="dropdown" (click)="onClickDropdown(i)">
                        <p-panel [toggleable]="true" [collapsed]="inactiveDropdowns[i]" toggler="header">
                            <ng-template pTemplate="header">
                                <div class="dropdown-header">
                                    <i [ngClass]="elem.icon" style="font-size: medium"></i>
                                    <p>{{ elem.label }}</p>
                                </div>
                            </ng-template>
                            <div *ngFor="let elem2 of elem.items">
                                <a [routerLink]="elem2.routerLink[0]" class="accordion-item-link"
                                   [class.selected]="activeLabel === elem2.label"
                                   (click)="setActiveLink(elem2.label)">
                                    <i [ngClass]="elem2.icon"
                                       [class.selected]="activeLabel === elem2.label"
                                       style="font-size: medium"></i>
                                    <p>{{ elem2.label }}</p>
                                </a>
                            </div>
                        </p-panel>
                    </div>
                </div>
            </div>
        </p-accordionTab>
    </p-accordion>
</ng-container>
