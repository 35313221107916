<form [formGroup]="form" style="max-width: 100%!important;" autocomplete="false">
    <p-tabView>
        <p-tabPanel header="Detalii furnizor">
            <div class="grid fields-group">
                <div class="col-12">
                    <div class="grid p-fluid">
                        <div class="field col-12 md:col-6">
                            <app-stt-input inputId="name" [control]="form.controls['name']"
                                           icon="pi pi-users"
                                           label="Denumire"></app-stt-input>
                        </div>
                        <div class="field col-12 md:col-6">
                            <app-stt-input inputId="code" [control]="form.controls['code']"
                                           icon="pi pi-key"
                                           label="Cod SAP"></app-stt-input>
                        </div>
                    </div>
                    <div class="grid p-fluid">
                        <div class="field col-12 md:col-6">
                            <app-stt-input inputId="uniqueCode" [control]="form.controls['uniqueCode']"
                                           icon="pi pi-building"
                                           label="CUI"></app-stt-input>
                        </div>
                        <div class="field col-12 md:col-6">
                            <app-stt-input inputId="registrationNumber" [control]="form.controls['registrationNumber']"
                                           icon="pi pi-building"
                                           label="Numar de inregistrare"></app-stt-input>
                        </div>
                    </div>
                    <div class="grid p-fluid">

                        <div class="field col-12 md:col-6">
                            <app-stt-input inputId="email" [control]="form.controls['email']"
                                           icon="pi pi-envelope"  type="email"
                                           label="Email"></app-stt-input>
                        </div>

                        <div class="field col-12 md:col-6">
                            <app-stt-input inputId="phone" [control]="form.controls['phone']"
                                           icon="pi pi-phone"
                                           label="Telefon"></app-stt-input>
                        </div>
                    </div>
                    <div class="grid p-fluid">
                        <div class="field col-12 md:col-6">
                            <app-stt-dropdown endPoint="counties" placeholder="Nu ati selectat un judet!"
                                              [formControlParent]="form.controls['county_id']"
                                               icon="pi pi-map"
                                              label="Judet"></app-stt-dropdown>
                        </div>
                        <div class="field col-12 md:col-6">
                            <app-stt-dropdown endPoint="cities" placeholder="Nu ati selectat un oras!"
                                              [formControlParent]="form.controls['city_id']"
                                              formControlNameDependency="county_id"
                                               icon="pi pi-map"
                                              label="Oras"></app-stt-dropdown>
                        </div>
                    </div>
                    <div class="grid p-fluid">
                        <div class="field col-12 md:col-12">
                            <app-stt-input inputId="address" [control]="form.controls['address']"
                                           icon="pi pi-home"
                                           label="Adresa"></app-stt-input>
                        </div>
                    </div>
                </div>
            </div>
        </p-tabPanel>
        <p-tabPanel [disabled]="(!supplierModel.uid)" leftIcon="pi-location">
            <ng-template pTemplate="header">
                <span class="p-tabview-title ng-star-inserted">
                    Adresa de colectare
                </span>
                <p-badge [value]="form.controls['addresses'].value.length" severity="success"></p-badge>
            </ng-template>
            <app-supplier-addresses [formGroup]="form" [addresses]="supplierModel.addresses"
                                    *ngIf="supplierModel.addresses"></app-supplier-addresses>
        </p-tabPanel>
        <p-tabPanel header="Contacte" [disabled]="(!supplierModel.uid)" leftIcon="pi-users">
            <ng-template pTemplate="header">
                <span class="p-tabview-title ng-star-inserted">
                    Contacte
                </span>
                <p-badge [value]="form.controls['contacts'].value.length" severity="success"></p-badge>
            </ng-template>
            <app-supplier-contacts [formGroup]="form" [contacts]="supplierModel.contacts"
                                   *ngIf="supplierModel.contacts"></app-supplier-contacts>
        </p-tabPanel>
    </p-tabView>

    <p-progressBar mode="indeterminate" class="addEditProgressBar" *ngIf="loading"></p-progressBar>

    <div class="actions-area">
        <div class="actions-left">
            <button pButton pRipple type="button" label="Anulare" class="p-button-raised p-button-danger"
                    icon="pi pi-ban" [disabled]="loading" (click)="onCancel()">
            </button>
        </div>

        <div class="actions-right">
            <button pButton pRipple type="button" label="Salveaza" class="p-button-raised p-button-success"
                    icon="pi pi-save" [disabled]="loading" (click)="onSave()">
            </button>
        </div>
    </div>
</form>
