import { Component, OnInit } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SttTableService } from '../../../shared/components/stt-table/stt-table-service';
import { DeliveryNoteActionTableComponent } from './delivery-note-action-table/delivery-note-action-table.component';

@Component({
    selector: 'app-delivery-note',
    templateUrl: './delivery-note.component.html',
    styleUrls: ['./delivery-note.component.scss']
})
export class DeliveryNoteComponent implements OnInit {
    public tableEndpoint = 'delivery-notes';
    public tableActionComponent = DeliveryNoteActionTableComponent;
    public tableQuery: any;
    public ref: DynamicDialogRef;

    constructor(public dialogService: DialogService) {
        this.tableQuery = new SttTableService()
            .addInclude('fnc')
            .addInclude('customer')
            .addInclude('product');

    }

    ngOnInit(): void {

    }
}
