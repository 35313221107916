import { Component, OnInit } from '@angular/core';
import { SttTableComponent } from '../../../../shared/components/stt-table/stt-table.component';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { OrderCustomerModel } from '../../../../shared/entities/order-customer.model';
import { OrdersCustomerAddEditComponent } from '../orders-customer-add-edit/orders-customer-add-edit.component';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { ConfirmationService, MessageService, PrimeNGConfig } from 'primeng/api';
import { ValidationMessageService } from '../../../../shared/services/base/validation-message-handle';
import { OrderCustomerService } from '../../../../shared/services/app/order-customer.service';

@Component({
  selector: 'app-orders-customer-action-table',
  templateUrl: './orders-customer-action-table.component.html',
  styleUrls: ['./orders-customer-action-table.component.scss']
})
export class OrdersCustomerActionTableComponent implements OnInit {

    public data: OrderCustomerModel;
    public table: SttTableComponent;
    public ref: DynamicDialogRef;
    public loading = true;

    constructor(
        public dialogService: DialogService,
        public router: Router,
        public confirmationService: ConfirmationService,
        public primengConfig: PrimeNGConfig,
        public itemService: OrderCustomerService,
        public messageService: MessageService,
        private validationMessageService: ValidationMessageService,
    ) {

    }

    ngOnInit(): void {
    }

    setParams(entity: OrderCustomerModel): void {
        this.data = entity;
    }

    setTableReference(table: SttTableComponent): void
    {
        this.table = table;
    }

    showDialog(){
        this.router.navigate(
            ['orders/customer/add-edit'],
            { queryParams: { uid: this.data.uid } }
        );
    }

    confirm() {
        this.confirmationService.confirm({
            header: 'Confirmare stergere Comanda',
            message: 'Sunteti sigur ca doriti sa stergeti aceasta comanda?',
            key: 'deleteCustomerOrder',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Da',
            rejectLabel: 'Nu',
            acceptButtonStyleClass: 'p-button-md p-button-success',
            rejectButtonStyleClass: 'p-button-md p-button-danger',
            accept: () => {
                this.deleteItem(this.data.uid);
            },
            reject: () => {
                this.confirmationService.close();
                this.table.refresh();
            }
        });
    }

    deleteItem(uid: string){
        this.itemService.delete(uid)
            .pipe(finalize(() => {this.loading = false;this.table.refresh();}))
            .subscribe((response: any) => {
                this.messageService.add({
                    severity: 'success',
                    summary: 'Comanda a fost stearsa cu success!'
                });
            });
    }

}
