import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { finalize, map, switchMap, tap } from 'rxjs/operators';
import { DeliveryNoteModel } from '../../../../shared/entities/delivery-note.model';
import { DeliveryNoteLotModel } from '../../../../shared/entities/delivery-note-lot.model';
import { DeliveryNoteService } from '../../../../shared/services/app/delivery-note.service';
import { HttpApiService } from '../../../../shared/services/base/http-api.service';
import { ValidationMessageService } from '../../../../shared/services/base/validation-message-handle';
import { OrderCustomerService } from '../../../../shared/services/app/order-customer.service';

enum Status {
    Draft = 0,
    Validated = 1,
}

@Component({
    selector: 'app-delivery-note-add-edit',
    templateUrl: './delivery-note-add-edit.component.html',
    styleUrls: ['./delivery-note-add-edit.component.scss']
})
export class DeliveryNoteAddEditComponent implements OnInit {
    public uid: string = null;
    public generated = false;
    public transport_uid: string;

    public orderUID: string;
    public productUID: string;

    public itemModel: DeliveryNoteModel = new DeliveryNoteModel();
    public loading = false;
    public form: UntypedFormGroup;
    public minimumDate: Date = new Date();
    public fnc_id: number;
    public dropDownWithTrashed = false;
    public status: number;
    public readOnly = false;
    public showCalendar = true;
    public enumStatus = Status;
    public lastSelectedIndex: number;
    public orderForm: UntypedFormGroup;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private router: Router,
        private httpApiService: HttpApiService,
        private validationMessageService: ValidationMessageService,
        private itemService: DeliveryNoteService,
        private messageService: MessageService,
        private route: ActivatedRoute,
        public orderCustomerService: OrderCustomerService,
    ) {
        this.uid = this.route.snapshot.queryParams.uid;
        if (this.uid) {
            if (this.route.snapshot.queryParams.generated) {
                this.generated = this.route.snapshot.queryParams.generated;
                this.transport_uid = this.route.snapshot.queryParams.transport_uid;
            }
        }
    }

    ngOnInit(): void {

        const localStorageData = JSON.parse(localStorage.getItem('user'));
        this.fnc_id = localStorageData.fnc_id;

        this.createForm();

        this.orderForm = this.formBuilder.group({
            orders: this.formBuilder.array([])
        });

        if (this.uid) {
            if (this.generated) {
                this.getItemGenerate();
            } else {
                this.getItem();
            }
        }
        else{
            this.getOrderItems();
        }


        return;
    }

    createForm(): void {
        this.form = this.formBuilder.group({
            uid: [this.itemModel.uid],
            documentNo: [this.itemModel.documentNo, [Validators.required, Validators.minLength(3), Validators.maxLength(255)]],
            code: [this.itemModel.code, [Validators.required, Validators.maxLength(100)]],

            product_category_id: [this.itemModel.product_category_id, [Validators.required]],
            product_id: [this.itemModel.product_id, [Validators.required]],
            units: [0, [Validators.required, Validators.min(0)]],
            brute_quantity: [this.itemModel.gross_quantity, [Validators.required, Validators.min(0)]],
            tar_quantity: [this.itemModel.tar_quantity, [Validators.required, Validators.min(0)]],

            customer_id: [this.itemModel.customer_id, Validators.required],
            customer_address_id: [this.itemModel.customer_address_id, [Validators.required]],

            deliveryDate: [this.itemModel.deliveryDate, [Validators.required]],
            description: [this.itemModel.description, [Validators.maxLength(2000)]],

            transport_company_id: [this.itemModel.transport_company_id],
            transport_unit_type_id: [this.itemModel.transport_unit_type_id],
            transport_unit_driver_id: [this.itemModel.transport_unit_driver_id],

            customer_order_item_id: [null, [Validators.required]],

            fnc_id: [this.fnc_id, [Validators.required]],
            items: this.formBuilder.array([])
        });

        this.calculateNetQuantity();
        this.form.get('brute_quantity').valueChanges.subscribe(() => {
            this.calculateNetQuantity();
        });
        this.form.get('tar_quantity').valueChanges.subscribe(() => {
            this.calculateNetQuantity();
        });
    }

    calculateNetQuantity(): void {
        const bruteQuantity = this.form.get('brute_quantity').value;
        const transportWeight = this.form.get('tar_quantity').value;

        this.form.get('units').setValue(bruteQuantity - transportWeight);
    }

    formDeliveryNoteLots(): UntypedFormArray {
        return this.form.get('items') as UntypedFormArray;
    }

    addFormLot(item?: DeliveryNoteLotModel) {
        const deliveryNoteWeekday = this.formBuilder.group({
            uid: [(item) ? item.uid : null],
            lot: [(item) ? item.lot : null],
            stock_units: [(item) ? item.stock_units : null],
            alocated_units: [(item) ? item.alocated_units : null]
        });
        this.formDeliveryNoteLots().push(deliveryNoteWeekday);
    }

    removeUnusedLots(): void {
        const lots = this.formDeliveryNoteLots();
        for (let i = 0; i < lots.controls.length; i++) {
            const lot = lots.controls[i] as UntypedFormGroup;
            if (lot.controls.alocated_units.value === 0 || lot.controls.alocated_units.value === '' || lot.controls.alocated_units.value === null) {
                this.formDeliveryNoteLots().controls.splice(i, 1);
                i--;
            }
        }
    }

    checkAllocation(): boolean {
        const quantity = this.form.controls.units.value;
        const lots = this.formDeliveryNoteLots();
        let sumOfLots = 0;
        lots.controls.forEach((lot: UntypedFormGroup) => {
            sumOfLots += Number(lot.controls.alocated_units.value);
        });
        return sumOfLots === Number(quantity);
    }


    getItemGenerate(): void {
        this.loading = true;
        this.orderCustomerService.get(this.uid)
            .pipe(finalize(() => {
                this.loading = false;
            }))
            .subscribe((response: any) => {
                this.form.patchValue({
                    customer_id: response.payload.customer_id,
                    customer_address_id: response.payload.customer_address_id,
                    product_category_id: response.payload.product_category_id,
                    fnc_id: response.payload.fnc_id,
                });
                response.payload.customerOrderTransportItems.forEach(element => {
                    if (element.uid === this.transport_uid) {
                        const dateParse = new Date(element.delivery_date);
                        this.form.patchValue({
                            deliveryDate: dateParse,
                            product_id: element.product_id,
                            transport_company_id: element.transport_company_id,
                            transport_unit_type_id: element.transport_unit_type_id,
                            transport_unit_driver_id: element.transport_unit_driver_id,
                            // units: element.transport_quantity
                        });
                        this.onProductChange({id: element.product_id});
                    }
                });
                response.payload.customerOrderItems.forEach(element => {
                    const orderItemDeliveryDate = new Date(element.deliveryDate);
                    if(element.product_id === this.form.value.product_id && orderItemDeliveryDate.getDate() === this.form.value.deliveryDate.getDate()){
                        const order = this.formBuilder.group({
                            id: [element.id],
                            code: [response.payload.code],
                            deliveryDate: [orderItemDeliveryDate],
                            quantity: [element.quantity],
                            product_name: [element.product.name],
                            selected: [true]
                        });
                        this.formOrders().push(order);
                    }
                });
            });
    }


    getItem(): void {
        this.loading = true;
        this.dropDownWithTrashed = true;
        this.itemService.get(this.uid)
            .pipe(finalize(() => {this.loading = false; this.dropDownWithTrashed = false;}))
            .subscribe((response: any) => {
                this.itemModel.map(response.payload);
                this.formDeliveryNoteLots().clear();
                response.payload.items.forEach(item => {
                    this.addFormLot(item);
                });
                this.form.patchValue(this.itemModel);
                const dateParse = new Date(response.payload.deliveryDate);
                this.form.patchValue({
                    deliveryDate: dateParse,
                });
                this.status = response.payload.status;
                if(this.status > Status.Draft){
                    this.readOnly = true;
                    this.showCalendar = false;
                }
                // Map the order_item in the orderForm(visual purpose)
                const customer_order_item = response.payload.customerOrderItem;
                const dateObject = new Date(customer_order_item.deliveryDate);
                const order = this.formBuilder.group({
                    id: [(customer_order_item) ? customer_order_item.id : null],
                    code: [(customer_order_item) ? customer_order_item.code : null],
                    deliveryDate: [(customer_order_item) ? dateObject : null],
                    quantity: [(customer_order_item) ? customer_order_item.quantity : null],
                    product_name: [(customer_order_item) ? customer_order_item.product.name : null],
                    selected: [true]
                });
                this.formOrders().push(order);
            });
    }

    checkIfFormValid(): boolean {
        if (this.form.invalid) {
            this.messageService.add({
                severity: 'error',
                summary: 'Formularul nu este valid!',
                detail: 'Vericficati toate campurile si incercati din nou.'
            });
            this.form.markAllAsTouched();
            return false;
        }
        if (this.checkAllocation() === false) {
            this.messageService.add({
                severity: 'error',
                summary: 'Alocarea pe loturi este invalida!',
                detail: 'Cantitatea alocata pe loturi trebuie sa fie egala cu cantitatea de pe transport. ' +
                    'Verificati valorile si incercati din nou.'
            });
            return false;
        }
        return true;
    }

    onProductChange(event){
        this.loading = true;
        if (event){
            this.itemService.getLots(event.id)
                .pipe(finalize(() => this.loading = false))
                .subscribe((response: any) => {
                    this.formDeliveryNoteLots().clear();
                    response.payload.forEach((element, index) => {
                        this.addFormLot();
                        this.formDeliveryNoteLots().at(index).patchValue({
                            lot: element.lot,
                            stock_units: element.total_stock,
                        });
                    });
                });
        }
    }

    formOrders(): UntypedFormArray {
        return this.orderForm.get('orders') as UntypedFormArray;
    }

    getOrderItems(){
        this.orderCustomerService.getItems()
            .pipe(finalize(() => this.loading = false))
            .subscribe((response: any) => {
                response.payload.forEach(element => {
                    const dateObject = new Date(element.deliveryDate);
                    const order = this.formBuilder.group({
                        id: [(element) ? element.id : null],
                        code: [(element) ? element.code : null],
                        deliveryDate: [(element) ? dateObject : null],
                        quantity: [(element) ? element.quantity : null],
                        product_name: [(element) ? element.name : null],
                        selected: [false]
                    });
                    this.formOrders().push(order);
                });
            });
    }

    checkedBox(event: any, index: number){
        if (this.lastSelectedIndex !== undefined) {
            this.formOrders().at(this.lastSelectedIndex).patchValue({
                selected: false,
            });
        }
        this.formOrders().at(index).patchValue({
            selected: true,
        });
        this.lastSelectedIndex = index;
    }


    onSave(): void {
        // Map customer_order_item_id on form(checkbox is selectable until save)
        this.formOrders().controls.forEach(element => {
           if(element.value.selected){
               this.form.patchValue({
                   customer_order_item_id: element.value.id
               });
           }
        });
        if (!this.checkIfFormValid()) {
            return;
        }
        this.removeUnusedLots();
        this.loading = true;
        const data = this.form.getRawValue();
        this.itemService.set(data)
            .pipe(finalize(() => this.loading = false))
            .subscribe((response: any) => {
                this.itemModel.map(response.payload);
                this.form.patchValue(this.itemModel);
                this.router.navigate(['stock-management/delivery-note/list'], {}).then(() => {
                    this.messageService.add({
                        severity: 'success',
                        summary: 'Avizul de livrare a fost salvat cu success!'
                    });
                });
            }, err => {
                if (err instanceof HttpErrorResponse) {
                    this.validationMessageService.serverSideValidation(err, this.form, '', true);
                }
            });
    }

    validateForm(){
        this.status = this.enumStatus.Validated;
        const summary = 'Avizul de livrare a fost validat cu success!!';
        this.changeStatus(summary);
    }

    changeStatus(summary: string){
        this.itemService.statusChange(this.status, this.uid)
            .pipe(finalize(() => this.loading = false))
            .subscribe((response: any) => {
                this.itemModel.map(response.payload);
                this.form.patchValue(this.itemModel);
                this.messageService.add({
                    severity: 'success',
                    summary
                });
                this.router.navigate(['stock-management/delivery-note/list'], {});
            }, err => {
                if (err instanceof HttpErrorResponse) {
                    this.validationMessageService.serverSideValidation(err, this.form, '', true);
                }
            });
    }
}
