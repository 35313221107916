import { Component, OnInit, ViewChild } from '@angular/core';
import { SttTableComponent } from '../../../shared/components/stt-table/stt-table.component';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SttTableService } from '../../../shared/components/stt-table/stt-table-service';
import { EnzymesActionTableComponent } from './enzymes-action-table/enzymes-action-table.component';
import { EnzymesService } from '../../../shared/services/app/enzymes.service';
import { EnzymesModel } from '../../../shared/entities/enzimes.model';
import { EnzymesAddEditComponent } from './enzymes-add-edit/enzymes-add-edit.component';

@Component({
  selector: 'app-enzymes',
  templateUrl: './enzymes.component.html',
  styleUrls: ['./enzymes.component.scss']
})
export class EnzymesComponent implements OnInit {
    @ViewChild('table', { static: false }) table: SttTableComponent;

    public tableEndpoint = 'enzymes';
    public tableActionComponent = EnzymesActionTableComponent;
    public tableQuery: any;
    public ref: DynamicDialogRef;

    constructor(private enzymesService: EnzymesService, public dialogService: DialogService) {
        this.tableQuery = new SttTableService();
    }

    ngOnInit(): void {

    }

    addNewEntity() {
        this.ref = this.dialogService.open(EnzymesAddEditComponent, {
            header: 'Adaugare Enzime',
            width: '30%',
            contentStyle: { 'max-height': '70vh' },
            baseZIndex: 10000,
            closeOnEscape: false,
            data: null,
        });

        this.ref.onClose.subscribe((enzyme: EnzymesModel) => {
            if (enzyme) {
                this.table.refresh();
            }
        });
    }
}
