<div class="login-body auth-login">
    <h1>Vitall</h1>
    <div class="login-wrapper">
        <div class="login-panel">
            <div class="login-header">
                <h2>Autentificare</h2>
                <p-divider></p-divider>
            </div>
            <div class="login-form" [formGroup]="loginForm">
                <input type="text" id="email" autocomplete="off" pInputText placeholder="Email" formControlName="email">
                <input type="password" autocomplete="off" pInputText placeholder="Password" formControlName="password">

                <div class="grid">
                    <div class="col"></div>
                    <div class="col"></div>
                </div>

                <button pButton pRipple label="LOGIN" type="button" (click)="auth()"></button>
            </div>
        </div>
    </div>
</div>

