import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { ValidationMessageService } from '../../../../shared/services/base/validation-message-handle';
import { ProductionNoteService } from '../../../../shared/services/app/production-note.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { HttpErrorResponse } from '@angular/common/http';
import { MessageService } from 'primeng/api';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-production-note-live-dosing',
  templateUrl: './production-note-live-dosing.component.html',
  styleUrls: ['./production-note-live-dosing.component.scss']
})
export class ProductionNoteLiveDosingComponent implements OnInit {

    public uid: string;
    public loading = true;
    public liveDosageData = [];
    public liveDosageFile: File;
    public form: UntypedFormGroup;
    public view = false;


  constructor(
                private validationMessageService: ValidationMessageService,
                private itemService: ProductionNoteService,
                public ref: DynamicDialogRef,
                public config: DynamicDialogConfig,
                private router: Router,
                private messageService: MessageService
            ) { }

  ngOnInit(): void {
      if(this.config.data.uid){
          this.uid = (this.config.data.uid) ? this.config.data.uid : null;
          this.view = true;
          this.getFileData();
      }
      else{
          this.uid = (this.config.data) ? this.config.data : null;
      }

  }

    onImportFile(event): void {
        this.liveDosageFile = event.target.files[0];
        this.itemService.importFile({liveDosageDocument: this.liveDosageFile, fileName: this.liveDosageFile.name, status: 2, uid: this.uid})
            .pipe(finalize(() => this.loading = false))
            .subscribe((response: any) => {
                response.payload.forEach(element => {
                    if(element[0] !== ''){
                        this.liveDosageData.push(element);
                    }

                });
            });
    }

    onCancel(): void {
        this.ref.close(null);
    }

    getStyle(rowIndex: number, index: number){
          let jsonStyle;
          if(this.loading === false){
            if(index === this.liveDosageData[rowIndex].length - 1){
                const recipeQuantity = Number(this.liveDosageData[rowIndex][index - 1]);
                const realQuantity = Number(this.liveDosageData[rowIndex][index]);
                if(recipeQuantity === realQuantity){
                    // Green cells if the quantities are equal
                    jsonStyle = {border : '1px solid', 'background-color' : '#90EE90'};
                }
                else{
                    const diff = recipeQuantity > realQuantity ? recipeQuantity - realQuantity : realQuantity - recipeQuantity;
                    // At the moment the check is for greater than 2%
                    if(diff > (recipeQuantity * 2)/100){
                        // Red cells if the quantities have a difference > 5%
                        jsonStyle = {border : '1px solid', 'background-color' : '#FF6347'};
                    }
                    else{
                        // Yellow cells if the quantities have a difference < 5%
                        jsonStyle = {border : '1px solid', 'background-color' : '#FFD700'};
                    }
                }
            }
            else{
                jsonStyle = {border : '1px solid'};
            }
          }
          return jsonStyle;
    }

    onSave(){
        this.itemService.statusFinalized({liveDosageDocument: this.liveDosageFile, fileName: this.liveDosageFile.name, status: 2, uid: this.uid})
            .pipe(finalize(() => this.loading = false))
            .subscribe((response: any) => {
                this.messageService.add({
                    severity: 'success',
                    summary: 'Fisierul de Dozare Live a fost salvat cu success!'
                });
                this.ref.close();
                this.router.navigate(['stock-management/production-note/list'], {});
            }, err => {
                if (err instanceof HttpErrorResponse) {
                    this.validationMessageService.serverSideValidation(err, this.form, '', true);
                }
            });
    }

    getFileData(){
        this.itemService.getLiveDosage(this.uid)
            .pipe(finalize(() => this.loading = false))
            .subscribe((response: any) => {
                response.payload.forEach(element => {
                    if(element[0] !== ''){
                        this.liveDosageData.push(element);
                    }
                });
            }, err => {
                if (err instanceof HttpErrorResponse) {
                    this.validationMessageService.serverSideValidation(err, this.form, '', true);
                }
            });
    }
}
