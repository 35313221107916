import { Component, OnInit } from '@angular/core';
import { SttTableComponent } from '../../../../../shared/components/stt-table/stt-table.component';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TransportUnitTModel } from '../../../../../shared/entities/transport-unit.model';
import { TransportUnitAddEditComponent } from '../transport-unit-add-edit/transport-unit-add-edit.component';
import { ConfirmationService, MessageService, PrimeNGConfig } from "primeng/api";
import { ValidationMessageService } from "../../../../../shared/services/base/validation-message-handle";
import { TransportUnitService } from "../../../../../shared/services/app/transport-unit.service";
import { finalize } from "rxjs/operators";

@Component({
    selector: 'app-transport-unit-action-table',
    templateUrl: './transport-unit-action-table.component.html',
    styleUrls: ['./transport-unit-action-table.component.scss']
})
export class TransportUnitActionTableComponent implements OnInit {

    public data: any;
    public table: SttTableComponent;
    public ref: DynamicDialogRef;
    public loading = true;

    constructor(
        public dialogService: DialogService,
        public confirmationService: ConfirmationService,
        public primengConfig: PrimeNGConfig,
        public itemService: TransportUnitService,
        public messageService: MessageService,
        private validationMessageService: ValidationMessageService,
    ) {

    }

    ngOnInit(): void {
    }

    setParams(entity: TransportUnitTModel): void {
        this.data = entity;
    }

    setTableReference(table: SttTableComponent): void {
        this.table = table;
    }

    showDialog(): void {
        this.ref = this.dialogService.open(TransportUnitAddEditComponent, {
            header: 'Modificare unitate de transport',
            width: '70%',
            contentStyle: { 'max-height': '70vh' },
            baseZIndex: 10000,
            closeOnEscape: false,
            data: this.data,
        });

        this.ref.onClose.subscribe((item: TransportUnitTModel) => {
            if (item) {
                this.table.refresh();
            }
        });
    }

    confirm() {
        this.confirmationService.confirm({
            header: 'Confirmare stergere Unitate de transport',
            message: 'Sunteti sigur ca doriti sa stergeti aceasta unitate de transport?',
            key: 'deleteUnits',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Da',
            rejectLabel: 'Nu',
            acceptButtonStyleClass: 'p-button-md p-button-success',
            rejectButtonStyleClass: 'p-button-md p-button-danger',
            accept: () => {
                this.deleteItem(this.data.uid);
            },
            reject: () => {
                this.confirmationService.close();
                this.table.refresh();
            }
        });
    }

    deleteItem(uid: string){
        this.itemService.delete(uid)
            .pipe(finalize(() => {this.loading = false;this.table.refresh();}))
            .subscribe((response: any) => {
                this.messageService.add({
                    severity: 'success',
                    summary: 'Unitatea de transport a fost stearsa cu success!'
                });
            });
    }
}
