import { NgModule } from '@angular/core';
import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PasswordModule } from 'primeng/password';
import { StringFilterByPipe } from './pipes/StringFilterByPipe';
import { PaginatorModule } from 'primeng/paginator';
import { TableModule } from 'primeng/table';
import { SttTableComponent } from './components/stt-table/stt-table.component';
import { SttTableService } from './components/stt-table/stt-table-service';
import { SttTableActionsComponent } from './components/stt-table/stt-table-actions/stt-table-actions.component';
import { TableCommunicationService } from './services/base/table-communication.service';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import {
    SttTableFieldFiltersComponent
} from './components/stt-table/stt-table-field-filters/stt-table-field-filters.component';
import { ProgressBarModule } from 'primeng/progressbar';
import { SttOverlayTableComponent } from './components/stt-overlay-table/stt-overlay-table.component';
import { DropdownModule } from 'primeng/dropdown';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ButtonModule } from 'primeng/button';
import { SttDropdownComponent } from './components/stt-dropdown/stt-dropdown.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { InputTextModule } from 'primeng/inputtext';
import { RippleModule } from 'primeng/ripple';
import { SttTableRawExpandComponent } from './components/stt-table/stt-table-raw-expand/stt-table-raw-expand.component';
import { DividerModule } from 'primeng/divider';
import { StyleClassModule } from 'primeng/styleclass';
import { SttValidatorMessageComponent } from './components/stt-validator-message/stt-validator-message.component';
import { SttInputComponent } from './components/stt-input/stt-input.component';
import { SttCalendarComponent } from './components/stt-calendar/stt-calendar.component';
import { CalendarModule } from 'primeng/calendar';
import { SttTextareaComponent } from './components/stt-textarea/stt-textarea.component';
import { InputTextareaModule } from 'primeng/inputtextarea';

@NgModule({
    declarations: [
        StringFilterByPipe,
        SttTableComponent,
        SttTableActionsComponent,
        SttTableFieldFiltersComponent,
        SttOverlayTableComponent,
        SttDropdownComponent,
        SttTableRawExpandComponent,
        SttValidatorMessageComponent,
        SttInputComponent,
        SttCalendarComponent,
        SttTextareaComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        PaginatorModule,
        TableModule,
        ProgressBarModule,
        DropdownModule,
        OverlayPanelModule,
        ButtonModule,
        NgSelectModule,
        InputTextModule,
        RippleModule,
        DividerModule,
        StyleClassModule,
        CalendarModule,
        InputTextareaModule,
        PasswordModule,
    ],
    providers: [
        DatePipe,
        DecimalPipe,
        SttTableService,
        TableCommunicationService,
        DialogService,
        ConfirmationService,
        ConfirmDialogModule,
        DynamicDialogRef,
        DynamicDialogConfig
    ],
    exports: [
        SttTableComponent,
        SttOverlayTableComponent,
        SttDropdownComponent,
        SttValidatorMessageComponent,
        SttInputComponent,
        SttCalendarComponent,
        SttTextareaComponent
    ]
})

export class SharedModule {
}



