<a routerLink="/orders/customer/list" style="color: #808080;font-size: 18px">
    <i class="fas fa-arrow-left"></i>
    <span>Inapoi la lista de comenzi</span>
</a>
<br>
<br>
<form [formGroup]="form" autocomplete="false">
    <p-card>
        <div class="grid fields-group">
            <div class="col-12">
                <div class="grid p-fluid">
                    <div class="field col">
                        <app-stt-dropdown
                            endPoint="customers/dropdown"
                            placeholder="Nu ati selectat un client!"
                            icon="pi pi-user"
                            [formControlParent]="form.controls['customer_id']"
                            label="Client" [readonly]="readOnly"
                            [dropDownWithTrashed]="dropDownWithTrashed">
                        </app-stt-dropdown>
                    </div>
                    <div class="field col">
                        <app-stt-dropdown
                            endPoint="customers/getAddresses"
                            placeholder="Nu ati selectat o adresa!"
                            formControlNameDependency="customer_id"
                            [fields]="{ text: 'address', value: 'id'}"
                            icon="pi pi-home" iconPosition="left"
                            [formControlParent]="form.controls['customer_address_id']"
                            label="Adresa de livrare"
                            [readonly]="readOnly"
                            [dropDownWithTrashed]="dropDownWithTrashed">
                        </app-stt-dropdown>
                    </div>
                    <div class="field col">
                        <app-stt-dropdown endPoint="product-categories/dropdown"
                                          [formControlParent]="form.controls['product_category_id']"
                                          label="Specie" icon="pi-th-large pi"
                                          placeholder="Nu ati selectat o specie!"
                                          [readonly]="readOnly"
                                          [dropDownWithTrashed]="dropDownWithTrashed"></app-stt-dropdown>
                    </div>
                    <div class="field col">
                        <app-stt-input inputId="code" [control]="form.controls['code']"
                                       icon="pi pi-key" [readonly]="readOnly"
                                       label="Cod SAP"></app-stt-input>
                    </div>
                    <div class="field col">
                        <app-stt-dropdown placeholder="Nu ati selectat un FNC!" endPoint="fncs/dropdown"
                                          icon="pi pi-cog" [readonly]="readOnly"
                                          [formControlParent]="form.controls['fnc_id']" label="Fnc"></app-stt-dropdown>
                    </div>
                </div>
                <p-divider></p-divider>
                <br>
                <div class="container" style="max-width: 100%">
                    <div formArrayName="weekOrder">
                        <p-table [value]="formWeek().controls" [scrollable]="true" scrollDirection="horizontal"
                                 styleClass="p-datatable-gridlines">
                            <ng-template pTemplate="header" style="background-color: white">
                                <tr style="background: white">
                                    <th class="frozen-header checkbox-col" pFrozenColumn
                                        style="background: white; flex: 1 1 0">
                                        Fara transport
                                    </th>

                                    <th class="frozen-header" pFrozenColumn
                                        style="background: white; max-width: 20vw; flex: 1 1 0">Data Livrare
                                    </th>
                                    <th class="unfrozen-header" style="background: white; max-width: 25vw; flex: 1 1 0">
                                        Interval Orar (minim 4 ore)
                                    </th>
                                    <th class="unfrozen-header" style="background: white; max-width: 25vw; flex: 1 1 0">
                                        Tip unitate transport (cererea clientului)
                                    </th>
                                    <th *ngFor="let prod of formProducts(0).controls;let i=index"
                                        class="unfrozen-header"
                                        style="background: white; max-width: 25vw; flex: 1 1 0" pResizableColumn>
                                        Produs {{i + 1}}</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
                                <tr style="overflow: visible;" [formGroupName]="rowIndex">
                                    <ng-container [formGroup]="rowData">
                                        <td class="checkbox-col justify-content-center" pFrozenColumn
                                            alignFrozen="left">
                                            <div *ngIf="rowIndex === 0" style="width: 10px">
                                                <div class="grid"
                                                     style="margin-top: 10px;">
                                                    <div class="col-12"></div>
                                                </div>
                                                <div style="margin-top: 48px; position: relative; left: -0.4rem">
                                                    <p-checkbox formControlName="noTransport" [readonly]="readOnly"
                                                                binary="true" (onChange)="noTransportChange(rowIndex, $event)"></p-checkbox>
                                                </div>
                                            </div>
                                            <div *ngIf="rowIndex > 0">
                                                <div class="grid"
                                                     style="margin-top: 10px;">
                                                    <div class="col">
                                                        <p-checkbox formControlName="noTransport" [readonly]="readOnly"
                                                                    binary="true" (onChange)="noTransportChange(rowIndex, $event)"></p-checkbox>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>

                                        <td class="first-col justify-content-center" pFrozenColumn alignFrozen="left"
                                            style="max-width: 15%; flex: 1 1 0">
                                            <div *ngIf="rowIndex === 0">
                                                <div class="grid p-fluid justify-content-start"
                                                     style="margin-top: 10px;">
                                                    <div class="col-12"></div>
                                                </div>
                                                <div class="grid p-fluid  align-items-center" style="margin-top: 48px;">
                                                    <div class="col-2" style="width:5px; font-weight: bold;">
                                                        <label>{{rowData.value.day}}</label>
                                                    </div>
                                                    <div class="col-10">
                                                        <app-stt-calendar label="" icon="pi pi-calendar"
                                                                          [minimumDate]="minimumDate" [showCalendar]="showCalendar"
                                                                          [disabledDays]="rowData.value.currentAvailableDay"
                                                                          [control]="rowData.controls['deliveryDate']"
                                                                          (changeEvent)="onDateChange($event, rowIndex)"></app-stt-calendar>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="rowIndex > 0">
                                                <div class="grid p-fluid justify-content-start align-items-center"
                                                     style="margin-top: 10px;">
                                                    <div class="col-2" style="width:5px; font-weight: bold;">
                                                        <label>{{rowData.value.day}}</label>
                                                    </div>
                                                    <div class="col-10">
                                                        <app-stt-calendar label="" icon="pi pi-calendar"
                                                                          [minimumDate]="minimumDate" [showCalendar]="showCalendar"
                                                                          [disabledDays]="rowData.value.currentAvailableDay"
                                                                          [control]="rowData.controls['deliveryDate']"
                                                                          (changeEvent)="onDateChange($event, rowIndex)"></app-stt-calendar>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="second-col justify-content-center"
                                            style="max-width: 15%; flex: 1 1 0">
                                            <div *ngIf="rowIndex === 0">
                                                <div class="grid p-fluid justify-content-start"
                                                     style="margin-top: 10px;">
                                                    <div class="col-6">De la</div>
                                                    <div class="col-6">Pana la</div>
                                                </div>
                                                <div class="grid p-fluid justify-content-start"
                                                     style="margin-top: 28px;">
                                                    <div class="col-6">
                                                        <p-calendar (onSelect)="onSelectTimeStart($event,rowIndex)" [showTime]="showCalendar"
                                                                    (onInput)="onTimeInput(rowIndex)"
                                                                    formControlName="deliveryHourStart" timeOnly="true"
                                                                    hourFormat="24" appendTo="body"></p-calendar>
                                                    </div>
                                                    <div class="col-6">
                                                        <p-calendar (onSelect)="onSelectTimeEnd($event,rowIndex)" [showTime]="showCalendar"
                                                                    (onInput)="onTimeInput(rowIndex)"
                                                                    formControlName="deliveryHourEnd" timeOnly="true"
                                                                    hourFormat="24" appendTo="body"></p-calendar>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="rowIndex > 0">
                                                <div class="grid p-fluid justify-content-start"
                                                     style="margin-top: 10px;">
                                                    <div class="col-6">
                                                        <p-calendar (onInput)="onTimeInput(rowIndex)" [showTime]="showCalendar"
                                                                    (onSelect)="onSelectTimeStart($event,rowIndex)"
                                                                    formControlName="deliveryHourStart" timeOnly="true"
                                                                    hourFormat="24" appendTo="body"></p-calendar>
                                                    </div>
                                                    <div class="col-6">
                                                        <p-calendar (onSelect)="onSelectTimeEnd($event,rowIndex)" [showTime]="showCalendar"
                                                                    (onInput)="onTimeInput(rowIndex)"
                                                                    formControlName="deliveryHourEnd" timeOnly="true"
                                                                    hourFormat="24" appendTo="body"></p-calendar>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="third-col justify-content-center"
                                            style="max-width: 20%; flex: 1 1 0">
                                            <div *ngIf="rowIndex === 0" style="width: 100%">
                                                <div class="grid p-fluid justify-content-center"
                                                     style="margin-top: 26px;">
                                                    <div class="col-12"></div>
                                                </div>
                                                <div class="grid p-fluid justify-content-center"
                                                     style="margin-top: 28px;">
                                                    <div class="col-10">
                                                        <app-stt-dropdown endPoint="transport-unit-types/dropdown"
                                                                          [formControlParent]="rowData.controls['transport_unit_type_id']"
                                                                          label="Tip unitate transport"
                                                                          placeholder="Nu ati selectat un tip unitate transport!"
                                                                          [dropDownWithTrashed]="dropDownWithTrashed"
                                                                          appendTo="body"
                                                                          [readonly]="(readonlyTransport[rowIndex] || readOnly)"></app-stt-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="rowIndex > 0" style="width: 100%">
                                                <div class="grid p-fluid justify-content-center"
                                                     style="margin-top: 10px;">
                                                    <div class="col-10">
                                                        <app-stt-dropdown endPoint="transport-unit-types/dropdown"
                                                                          [formControlParent]="rowData.controls['transport_unit_type_id']"
                                                                          label="Tip unitate transport"
                                                                          placeholder="Nu ati selectat un tip unitate transport!"
                                                                          [dropDownWithTrashed]="dropDownWithTrashed"
                                                                          appendTo="body"
                                                                          [readonly]="(readonlyTransport[rowIndex] || readOnly)"></app-stt-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td *ngFor="let prod of formProducts(rowIndex).controls;let i=index"
                                            class="products-cols justify-content-center"
                                            style="max-width: 20%; flex: 1 1 0">
                                            <div *ngIf="rowIndex === 0" style="width: 100%">
                                                <div class="grid p-fluid justify-content-start"
                                                     style="margin-top: 10px;">
                                                    <div class="col-12">
                                                        <ng-container [formGroup]="prod">
                                                            <app-stt-dropdown endPoint="products/dropdown"
                                                                              [returnProductId]="returnProductId"
                                                                              (setProductIdEvent)="setProductId($event)"
                                                                              [indexOfProductId]="i"
                                                                              [formControlParent]="prod.controls['product_id']"
                                                                              formControlNameDependency="product_category_id"
                                                                              label="Produs" icon="pi pi-box"
                                                                              placeholder="Nu ati selectat un produs!" [readonly]="readOnly"
                                                                              [dropDownWithTrashed]="dropDownWithTrashed"></app-stt-dropdown>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                                <div class="grid p-fluid justify-content-start"
                                                     style="margin-top: 10px;">
                                                    <div class="col-4">
                                                        <ng-container [formGroup]="prod">
                                                            <app-stt-input inputId="quantity"
                                                                           [control]="prod.controls['quantity']"
                                                                           type="number" [readonly]="readOnly"
                                                                           label="Cantitate"></app-stt-input>
                                                        </ng-container>
                                                    </div>
                                                    <div class="col-8">
                                                        <app-stt-dropdown endPoint="product-packing-types/dropdown"
                                                                          [formControlParent]="prod.controls['product_packing_type_id']"
                                                                          label="Ambalaj Produs" [readonly]="readOnly"
                                                                          placeholder="Nu ati selectat un ambalaj!"
                                                                          [dropDownWithTrashed]="dropDownWithTrashed"></app-stt-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="rowIndex > 0" style="width: 100%">
                                                <div class="grid p-fluid justify-content-start"
                                                     style="margin-top: 10px;">
                                                    <div class="col-4">
                                                        <ng-container [formGroup]="prod">
                                                            <app-stt-input inputId="quantity"
                                                                           [control]="prod.controls['quantity']"
                                                                           type="number" [readonly]="readOnly"
                                                                           label="Cantitate"></app-stt-input>
                                                        </ng-container>
                                                    </div>
                                                    <div class="col-8">
                                                        <app-stt-dropdown endPoint="product-packing-types/dropdown"
                                                                          [formControlParent]="prod.controls['product_packing_type_id']"
                                                                          label="Ambalaj Produs"
                                                                          placeholder="Nu ati selectat un ambalaj!"
                                                                          [dropDownWithTrashed]="dropDownWithTrashed" [readonly]="readOnly"
                                                                          (changeEvent)="onProductChange($event, rowIndex)"></app-stt-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </ng-container>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
                <br>


            </div>

        </div>
    </p-card>
    <p-progressBar mode="indeterminate" class="addEditProgressBar" *ngIf="loading"></p-progressBar>

    <div class="actions-area">
        <div class="actions-left"></div>
        <div class="actions-right">
            <p-button *ngIf="!status || status === enumStatus.Draft" label="Adauga produs" icon="pi pi-plus" iconPos="right" (click)="addProduct()" [disabled]="readOnly"></p-button>
        </div>
    </div>


    <div class="actions-area">

        <div class="actions-left">
            <button pButton pRipple type="button" label="Anulare" class="p-button-raised p-button-danger"
                    icon="pi pi-ban" [disabled]="loading" routerLink="/orders/customer/list"></button>
        </div>

        <div class="actions-right">
            <button *ngIf="!status || status === enumStatus.Draft" pButton pRipple type="button" [label]="saveButtonLabel" class="p-button-raised p-button-success"
                    icon="pi pi-save" [disabled]="!canSave" (click)="onSave()">
            </button>
            <p-button *ngIf="status === enumStatus.Draft" [label]="'Validare'" icon="pi pi-check" iconPos="right" (click)="validateForm()" [ngStyle]="{'margin-left' : '2px'}"></p-button>
            <p-button *ngIf="status === enumStatus.Validated" [label]="'Finalizare'" icon="pi pi-check" iconPos="right" (click)="finishForm()" [ngStyle]="{'margin-left' : '2px'}"></p-button>
        </div>
    </div>
</form>
