import { Component, OnInit, ViewChild } from '@angular/core';
import { SttTableComponent } from '../../../shared/components/stt-table/stt-table.component';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SttTableService } from '../../../shared/components/stt-table/stt-table-service';
import {
    CoccibiostaticsActionTableComponent
} from './coccibiostatics-action-table/coccibiostatics-action-table.component';
import { CoccidiostatsService } from '../../../shared/services/app/coccidiostats.service';
import { CoccibiostaticsAddEditComponent } from './coccibiostatics-add-edit/coccibiostatics-add-edit.component';
import { CoccidiostatsModel } from '../../../shared/entities/coccidiostats.model';

@Component({
  selector: 'app-coccibiostatics',
  templateUrl: './coccibiostatics.component.html',
  styleUrls: ['./coccibiostatics.component.scss']
})
export class CoccibiostaticsComponent implements OnInit {
    @ViewChild('table', { static: false }) table: SttTableComponent;

    public tableEndpoint = 'coccidiostats';
    public tableActionComponent = CoccibiostaticsActionTableComponent;
    public tableQuery: any;
    public ref: DynamicDialogRef;

    constructor(private coccibiostaticsService: CoccidiostatsService, public dialogService: DialogService) {
        this.tableQuery = new SttTableService();
    }

    ngOnInit(): void {

    }

    addNewEntity() {
        this.ref = this.dialogService.open(CoccibiostaticsAddEditComponent, {
            header: 'Adaugare Coccidiostatice',
            width: '30%',
            contentStyle: { 'max-height': '70vh' },
            baseZIndex: 10000,
            closeOnEscape: false,
            data: null,
        });

        this.ref.onClose.subscribe((coccidiostats: CoccidiostatsModel) => {
            if (coccidiostats) {
                this.table.refresh();
            }
        });
    }
}
