import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TabMenuModule } from 'primeng/tabmenu';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { ProgressBarModule } from 'primeng/progressbar';
import { FieldsetModule } from 'primeng/fieldset';
import { CalendarModule } from 'primeng/calendar';
import { SharedModule } from '../../shared/shared.module';
import { MenubarModule } from 'primeng/menubar';
import { TableModule } from 'primeng/table';
import { DividerModule } from 'primeng/divider';
import { MenuModule } from 'primeng/menu';
import { RouterModule, Routes } from '@angular/router';
import { RippleModule } from 'primeng/ripple';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { AccordionModule } from 'primeng/accordion';
import { CheckboxModule } from 'primeng/checkbox';
import { TabViewModule } from 'primeng/tabview';
import { CardModule } from 'primeng/card';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { QualityReportComponent } from './quality-report/quality-report.component';
import { QualityReportActionTableComponent } from './quality-report/quality-report-action-table/quality-report-action-table.component';
import { AppMainComponent } from '../../app.main.component';
import { AuthGuardService } from '../../guards/auth-guard.service';
import { QualityReportAddEditComponent } from './quality-report/quality-report-add-edit/quality-report-add-edit.component';

const routes: Routes = [
    { path: 'laboratory', redirectTo: 'laboratory/quality-report', pathMatch: 'full' },
    {
        path: 'laboratory', component: AppMainComponent,
        children: [
            {path: 'quality-report', canActivate: [AuthGuardService], data: {roles: ['Administrator', 'Laborant']},
                children: [
                    { path: 'list', component: QualityReportComponent, canActivate: [AuthGuardService], data: {roles : ['Administrator', 'Laborant']}},
                    { path: 'add-edit', component: QualityReportAddEditComponent, canActivate: [AuthGuardService], data: {roles : ['Administrator', 'Laborant']}},
                    { path: '', redirectTo: 'list', pathMatch: 'full' },
                ]
            }
        ]
    }];


@NgModule({
    declarations: [
        QualityReportComponent,
        QualityReportActionTableComponent,
        QualityReportAddEditComponent
  ],
    imports: [
        CommonModule,
        ButtonModule,
        FormsModule,
        TabMenuModule,
        DropdownModule,
        InputTextModule,
        ProgressBarModule,
        FieldsetModule,
        ReactiveFormsModule,
        CalendarModule,
        SharedModule,
        MenubarModule,
        TableModule,
        DividerModule,
        MenuModule,
        RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'}),
        RippleModule,
        InputTextareaModule,
        AccordionModule,
        CheckboxModule,
        TabViewModule,
        CardModule,
        ConfirmDialogModule,
    ]
})
export class LaboratoryModule { }
