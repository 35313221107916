import { BaseNomenclatorModel } from './base.model';
import { RecipeItemModel } from './recipe-item.model';

export class ReceptionNoteModel extends BaseNomenclatorModel {
    uid: string;
    code: string;
    receptionDate: Date;
    documentNo: string;
    description: string;
    supplier_id: number;
    supplier_address_id: number;
    fnc_id: number;
    transport_company_id: number;
    transport_unit_driver_id: number;
    transport_unit_type_id: number;
    transport_unit_id: number;
    gross_quantity: number;
    net_quantity: number;
    tar_quantity: number;
    raw_material_id: number;
    lot: string;
    transport_details: string;
    storage_space_id: number;
    orderRawMaterialItem_id: number;

    // items: Array<RecipeItemModel> = [];
}
