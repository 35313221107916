import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpApiService } from '../base/http-api.service';
import { ApiResponseModel } from '../../entities/api-response.model';
import { TransportUnitDriverModel } from '../../entities/transport-unit-driver.model';

@Injectable({
    providedIn: 'root'
})
export class TransportUnitDriverService {

    constructor(
        public httpApiService: HttpApiService,
    ) {

    }

    public get(uid): Observable<ApiResponseModel> {
        return this.httpApiService.httpGet('transport-unit-drivers/view', { uid });
    }

    public set(item: TransportUnitDriverModel): Observable<ApiResponseModel> {
        return this.httpApiService.httpPost('transport-unit-drivers/store', item);
    }

    public delete(uid): Observable<ApiResponseModel> {
        return this.httpApiService.httpDelete('transport-unit-drivers/destroy', { uid });
    }

}
