import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpApiService} from '../base/http-api.service';
import {ApiResponseModel} from '../../entities/api-response.model';
import {SupplierModel} from '../../entities/supplier.model';

@Injectable({
    providedIn: 'root'
})

export class SupplierService {

    constructor(
        public httpApiService: HttpApiService,
    ) {

    }

    public get(uid): Observable<ApiResponseModel>
    {
        return this.httpApiService.httpGet('suppliers/view', {uid});
    }

    public set(supplier: SupplierModel): Observable<ApiResponseModel>
    {
        return this.httpApiService.httpPost('suppliers/store', supplier);
    }

    public delete(uid): Observable<ApiResponseModel> {
        return this.httpApiService.httpDelete('suppliers/destroy', { uid });
    }

}


