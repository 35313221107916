import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { HttpApiService } from '../../../../shared/services/base/http-api.service';
import { ValidationMessageService } from '../../../../shared/services/base/validation-message-handle';
import { CustomerService } from '../../../../shared/services/app/customer.service';
import { MessageService } from 'primeng/api';
import { finalize } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { CustomerModel } from '../../../../shared/entities/customer.model';

@Component({
    selector: 'app-customers-add-edit',
    templateUrl: './customers-add-edit.component.html',
    styleUrls: ['./customers-add-edit.component.scss']
})

export class CustomersAddEditComponent implements OnInit {

    public uid: string = null;
    public customerModel: CustomerModel = new CustomerModel();
    public loading = false;
    public form: UntypedFormGroup;

    constructor(
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private formBuilder: UntypedFormBuilder,
        private httpApiService: HttpApiService,
        private validationMessageService: ValidationMessageService,
        private customerService: CustomerService,
        private messageService: MessageService,
        private cd: ChangeDetectorRef
    ) {
    }

    ngOnInit(): void {
        this.uid = (this.config.data) ? this.config.data.uid : null;

        this.createForm();

        if (this.uid) {
            this.getItem();
        }
    }

    checkClientDetails(): boolean {
        return (this.form.controls.address.invalid && this.form.controls.address.touched)
            || (this.form.controls.city_id.invalid && this.form.controls.city_id.touched)
            || (this.form.controls.code.invalid && this.form.controls.code.touched)
            || (this.form.controls.county_id.invalid && this.form.controls.county_id.touched)
            || (this.form.controls.email.invalid && this.form.controls.email.touched)
            || (this.form.controls.name.invalid && this.form.controls.name.touched)
            || (this.form.controls.phone.invalid && this.form.controls.phone.touched)
            || (this.form.controls.registrationNumber.invalid && this.form.controls.registrationNumber.touched)
            || (this.form.controls.uid.invalid && this.form.controls.uid.touched)
            || (this.form.controls.uniqueCode.invalid && this.form.controls.uniqueCode.touched);
    }

    onCloseDialog(user: CustomerModel) {
        this.ref.close(user);
    }

    createForm(): void {
        this.form = this.formBuilder.group({
            uid: [this.customerModel.uid],
            code: [this.customerModel.code, [Validators.required, Validators.maxLength(100)]],
            name: [this.customerModel.name, [Validators.required, Validators.maxLength(200)]],
            uniqueCode: [this.customerModel.uniqueCode, [Validators.required, Validators.maxLength(200)]],
            registrationNumber: [this.customerModel.registrationNumber, [Validators.required, Validators.maxLength(200)]],
            address: [this.customerModel.address, [Validators.required, Validators.maxLength(1000)]],
            email: [this.customerModel.email, [Validators.required, Validators.maxLength(200), Validators.email]],
            phone: [this.customerModel.phone, [Validators.required, Validators.maxLength(200)]],
            county_id: [this.customerModel.county.id, Validators.required],
            city_id: [this.customerModel.city.id, Validators.required],
            addresses: this.formBuilder.array([]),
            contacts: this.formBuilder.array([])
        });
    }

    getItem(): void {
        this.loading = true;
        this.customerService.get(this.uid)
            .pipe(finalize(() => this.loading = false))
            .subscribe((response: any) => {
                this.customerModel.map(response.payload);
                this.form.patchValue(this.customerModel);
            });
    }

    onCancel(): void {
        this.ref.close(null);
    }

    checkIfFormValid(): boolean {
        if (this.form.invalid) {
            this.messageService.add({
                severity: 'error',
                summary: 'Formularul nu este valid!',
                detail: 'Vericficati toate campurile si incercati din nou.'
            });
            this.form.markAllAsTouched();
            return false;
        }
        return true;
    }


    onSave(): void {
        if (!this.checkIfFormValid()) {
            return;
        }
        this.loading = true;
        const data = this.form.getRawValue();
        if (data.addresses.length === 0) {
            delete (data.addresses);
        }
        if (data.contacts.length === 0) {
            delete (data.contacts);
        }
        this.customerService.set(data)
            .pipe(finalize(() => this.loading = false))
            .subscribe((response: any) => {
                this.customerModel.map(response.payload);
                this.form.patchValue(this.customerModel);
                this.messageService.add({
                    severity: 'success',
                    summary: 'Clientul a fost salvat cu success!'
                });
                this.ref.close(this.customerModel);
            }, err => {
                if (err instanceof HttpErrorResponse) {
                    this.validationMessageService.serverSideValidation(err, this.form, '', true);
                }
            });
    }

}
