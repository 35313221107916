import { Injectable } from '@angular/core';
import { HttpApiService } from '../base/http-api.service';
import { Observable } from 'rxjs';
import { ApiResponseModel } from '../../entities/api-response.model';
import { OrderRawMaterialModel } from '../../entities/order-raw-material.model';

@Injectable({
  providedIn: 'root'
})
export class OrderRawMaterialService {

    constructor(
        public httpApiService: HttpApiService,
    ) {

    }

    public get(uid): Observable<ApiResponseModel> {
        return this.httpApiService.httpGet('raw-material-orders/view', {uid});
    }

    public set(item: OrderRawMaterialModel): Observable<ApiResponseModel> {
        return this.httpApiService.httpPost('raw-material-orders/store', item);
    }

    public delete(uid): Observable<ApiResponseModel> {
        return this.httpApiService.httpDelete('raw-material-orders/destroy', { uid });
    }

    public statusChange(status: number, uid): Observable<ApiResponseModel> {
        return this.httpApiService.httpGet('raw-material-orders/change-status', {status, uid});
    }
}
