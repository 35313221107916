import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SupplierAddressModel } from '../../../../../shared/entities/supplier-address.model';

@Component({
    selector: 'app-supplier-addresses',
    templateUrl: './supplier-addresses.component.html',
    styleUrls: ['./supplier-addresses.component.scss']
})
export class SupplierAddressesComponent implements OnInit {

    @Input() formGroup: UntypedFormGroup;
    @Input() addresses: Array<SupplierAddressModel>;

    constructor(private formBuilder: UntypedFormBuilder) {
    }

    get addressesFormArray() {
        return this.formGroup.controls.addresses as UntypedFormArray;
    }

    ngOnInit(): void {
        this.addresses.forEach(address => {
            this.addFormItem(address);
        });
    }

    addFormItem(address?: SupplierAddressModel): void {
        const addressForm = this.formBuilder.group({
            uid: [(address) ? address.uid : null],
            address: [(address) ? address.address : null, Validators.required],
            county_id: [(address) ? address.county_id : null, Validators.required],
            city_id: [(address) ? address.city_id : null, Validators.required],
            needDelete: [false],
        });
        this.addressesFormArray.push(addressForm);
    }

    removeFormItem(formIndex: number): void {
        const form = this.addressesFormArray.at(formIndex) as UntypedFormControl;
        form.patchValue({ needDelete: true });
    }

}
