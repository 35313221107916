import { Component, OnInit } from '@angular/core';
import { ConsumptionTicketModel } from '../../../../shared/entities/consumption-ticket.model';
import { SttTableComponent } from '../../../../shared/components/stt-table/stt-table.component';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService, PrimeNGConfig } from 'primeng/api';
import { ValidationMessageService } from '../../../../shared/services/base/validation-message-handle';
import { ProductionNoteService } from '../../../../shared/services/app/production-note.service';
import { finalize } from 'rxjs/operators';
import {
    ProductionNoteLiveDosingComponent
} from '../production-note-live-dosing/production-note-live-dosing.component';

enum Status {
    Draft = 0,
    Validated = 1,
    Finished = 2,
}

@Component({
  selector: 'app-production-note-action-table',
  templateUrl: './production-note-action-table.component.html',
  styleUrls: ['./production-note-action-table.component.scss']
})
export class ProductionNoteActionTableComponent implements OnInit {

    public data: ConsumptionTicketModel;
    public table: SttTableComponent;
    public ref: DynamicDialogRef;
    public loading = true;
    public enumStatus = Status;

  constructor(
      public dialogService: DialogService,
      public router: Router,
      public confirmationService: ConfirmationService,
      public primengConfig: PrimeNGConfig,
      public itemService: ProductionNoteService,
      public messageService: MessageService,
      private validationMessageService: ValidationMessageService,
  ) {}

  ngOnInit(): void {
  }

    setParams(entity: ConsumptionTicketModel): void {
        this.data = entity;
    }

    setTableReference(table: SttTableComponent): void
    {
        this.table = table;
    }

    showDialog(){
        this.router.navigate(
            ['stock-management/production-note/add-edit'],
            { queryParams: { uid: this.data.uid } }
        );
    }

    confirm() {
        this.confirmationService.confirm({
            header: 'Confirmare stergere Raport de productie',
            message: 'Sunteti sigur ca doriti sa stergeti acest Raport de productie?',
            key: 'deleteProductionNote',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Da',
            rejectLabel: 'Nu',
            acceptButtonStyleClass: 'p-button-md p-button-success',
            rejectButtonStyleClass: 'p-button-md p-button-danger',
            accept: () => {
                this.deleteItem(this.data.uid);
            },
            reject: () => {
                this.confirmationService.close();
                this.table.refresh();
            }
        });
    }

    deleteItem(uid: string){
        this.itemService.delete(uid)
            .pipe(finalize(() => {this.loading = false;this.table.refresh();}))
            .subscribe((response: any) => {
                this.messageService.add({
                    severity: 'success',
                    summary: 'Raportul de productie a fost sters cu success!'
                });
            });
    }

    liveDosage() {
        this.ref = this.dialogService.open(ProductionNoteLiveDosingComponent, {
            header: 'Dozare Live',
            width: '70%',
            contentStyle: { 'max-height': '70vh' },
            baseZIndex: 10000,
            closeOnEscape: false,
            data: this.data
        });
    }
}
