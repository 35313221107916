import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { HttpApiService } from '../../../../shared/services/base/http-api.service';
import { ValidationMessageService } from '../../../../shared/services/base/validation-message-handle';
import { FncService } from '../../../../shared/services/app/fnc.service';
import { MessageService } from 'primeng/api';
import { finalize } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { OrderRawMaterialModel } from '../../../../shared/entities/order-raw-material.model';
import { RecipeItemModel } from '../../../../shared/entities/recipe-item.model';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderRawMaterialService } from '../../../../shared/services/app/order-raw-material.service';
import { DatePipe } from '@angular/common';

enum Status {
    Draft = 0,
    Validated = 1,
    Finished = 2,
}

@Component({
    selector: 'app-order-raw-material-add-edit',
    templateUrl: './order-raw-material-add-edit.component.html',
    styleUrls: ['./order-raw-material-add-edit.component.scss']
})


export class OrderRawMaterialAddEditComponent implements OnInit {

    public uid: string = null;
    public itemModel: OrderRawMaterialModel = new OrderRawMaterialModel();
    public loading = false;
    public form: UntypedFormGroup;
    public minimumDate: Date = new Date();
    public fnc_id: number;
    public dropDownWithTrashed = false;
    public waitForFinalize = false;
    public status: number;
    public readOnly = false;
    public showCalendar = true;
    public enumStatus = Status;

    constructor(
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private formBuilder: UntypedFormBuilder,
        private httpApiService: HttpApiService,
        private validationMessageService: ValidationMessageService,
        private itemService: OrderRawMaterialService,
        private messageService: MessageService,
        private route: ActivatedRoute,
        private datePipe: DatePipe,
        private router: Router,
    ) {
        this.uid = this.route.snapshot.queryParams.uid;
    }

    ngOnInit(): void {

        const localStorageData = JSON.parse(localStorage.getItem('user'));
        this.fnc_id = localStorageData.fnc_id;

        this.createForm();

        if (this.uid) {
            this.getItem();
        }

        return;
    }

    getStyleButton(){
        const itemsLength = this.formRawMaterialOrderItems().value.length;
        let jsonStyle;
        if(this.uid){
            if(!this.waitForFinalize){
                return ;
            }
        }
        if((this.formRawMaterialOrderItems().at(itemsLength -1).get('raw_material_id').invalid && this.formRawMaterialOrderItems().at(itemsLength -1).get('raw_material_id').touched)
            || (this.formRawMaterialOrderItems().at(itemsLength -1).get('units').invalid && this.formRawMaterialOrderItems().at(itemsLength -1).get('units').touched)){
            jsonStyle = {'margin-bottom' : '44px'};
        }
        else{
            jsonStyle = {'margin-bottom' : '24px'};
        }
        return jsonStyle;
    }

    onCloseDialog(item: OrderRawMaterialModel) {
        this.ref.close(item);
    }


    createForm(): void {
        this.form = this.formBuilder.group({
            uid: [this.itemModel.uid],
            supplier_id: [this.itemModel.supplier_id, Validators.required],
            fnc_id: [this.fnc_id, Validators.required],
            code: [this.itemModel.code, Validators.required],
            orderDate: [this.itemModel.orderDate, Validators.required],
            description: [this.itemModel.description],
            items: this.formBuilder.array([]),
        });
        if (!this.uid) {
            this.addFormElement();
        }
    }

    formRawMaterialOrderItems(): UntypedFormArray {
        return this.form.get('items') as UntypedFormArray;
    }


    deleteFormItem(index: number): void {
        const formItem = this.formRawMaterialOrderItems().at(index);
        formItem.patchValue({
            needDelete: true,
        });
        this.clearValidatorsOnItem(index);
    }

    clearValidatorsOnItem(index: number): void{
        this.formRawMaterialOrderItems().at(index).get('raw_material_id').clearValidators();
        this.formRawMaterialOrderItems().at(index).get('units').clearValidators();
        this.formRawMaterialOrderItems().at(index).get('raw_material_id').updateValueAndValidity();
        this.formRawMaterialOrderItems().at(index).get('units').updateValueAndValidity();
    }

    addFormElement(item?: RecipeItemModel) {
        const rawMaterialOrderItemForm = this.formBuilder.group({
            uid: [(item) ? item.uid : null],
            raw_material_id: [(item) ? item.raw_material_id : null,Validators.required],
            units: [(item) ? item.units : null,[Validators.required, Validators.min(0)]],
            receivedUnits: [(item) ? item.receivedUnits : 0, Validators.required],
            needDelete: [false]
        });
        this.formRawMaterialOrderItems().push(rawMaterialOrderItemForm);
    }

    getItem(): void {
        this.loading = true;
        this.dropDownWithTrashed = true;
        this.itemService.get(this.uid)
            .pipe(finalize(() => {this.loading = false; this.dropDownWithTrashed = false; this.waitForFinalize = true;}))
            .subscribe((response: any) => {
                this.itemModel.map(response.payload);
                this.form.patchValue(this.itemModel);
                const dateObject = new Date(response.payload.orderDate);
                this.form.patchValue({
                    orderDate: dateObject,
                });
                this.itemModel.items.forEach(item => {
                    this.addFormElement(item);
                });
                this.status = response.payload.status;
                if(this.status > Status.Draft){
                    this.readOnly = true;
                    this.showCalendar = false;
                }
            });
    }

    checkIfFormValid(): boolean {
        if (this.form.invalid) {
            this.messageService.add({
                severity: 'error',
                summary: 'Formularul nu este valid!',
                detail: 'Vericficati toate campurile si incercati din nou.'
            });
            this.form.markAllAsTouched();
            return false;
        }
        return true;
    }


    onSave(): void {
        if (!this.checkIfFormValid()) {
            return;
        }
        this.loading = true;
        const data = this.form.getRawValue();
        this.itemService.set(data)
            .pipe(finalize(() => this.loading = false))
            .subscribe((response: any) => {
                this.itemModel.map(response.payload);
                this.form.patchValue(this.itemModel);
                this.messageService.add({
                    severity: 'success',
                    summary: 'Comanda Materii prime a fost salvata cu success!'
                });
                this.router.navigate(['orders/raw-materials/list'], {});
            }, err => {
                if (err instanceof HttpErrorResponse) {
                    this.validationMessageService.serverSideValidation(err, this.form, '', true);
                }
            });
    }

    validateForm(){
        this.status = this.enumStatus.Validated;
        const summary = 'Comanda Materii prime a fost validata cu success!';
        this.changeStatus(summary);
    }

    finishForm(){
        this.status = this.enumStatus.Finished;
        const summary = 'Comanda Materii prime a fost finalizata cu success!';
        this.changeStatus(summary);
    }

    changeStatus(summary: string){
        this.itemService.statusChange(this.status, this.uid)
            .pipe(finalize(() => this.loading = false))
            .subscribe((response: any) => {
                this.itemModel.map(response.payload);
                this.form.patchValue(this.itemModel);
                this.messageService.add({
                    severity: 'success',
                    summary
                });
                this.router.navigate(['orders/raw-materials/list'], {});
            }, err => {
                if (err instanceof HttpErrorResponse) {
                    this.validationMessageService.serverSideValidation(err, this.form, '', true);
                }
            });
    }
}
