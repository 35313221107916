<a routerLink="/stock-management/inventory" style="color: #808080;font-size: 18px">
    <i class="fas fa-arrow-left"></i>
    <span>Inapoi la inventar</span>
</a>

<br>
<br>
<form [formGroup]="form" autocomplete="off">
    <p-card>
        <div class="grid fields-group" style="padding: 0 1em;">
            <div class="col-12">
                <div class="grid p-fluid">
                    <div class="field col-12 md:col-4">
                        <app-stt-dropdown endPoint="fncs/dropdown" [formControlParent]="form.controls['fnc_id']"
                                          placeholder="Nu ati selectat un FNC!"
                                          icon="pi pi-cog"
                                          style="min-width: 100%"
                                          label="Fnc" [readonly]="readOnly"
                                          [dropDownWithTrashed]="dropDownWithTrashed"></app-stt-dropdown>
                    </div>
                    <div class="field col-12 md:col-4">
                        <div class="p-input-icon-right" style="margin-top: -23px">
                            <label class="inventory-type-label">Tip inventar</label>
                            <ng-select class="p-element p-inputwrapper p-inputwrapper-filled"
                                       style="margin-top: 4px"
                                       (change)="onSwitchInventoryType()"
                                       [items]="inventoryDropdownItems" [(ngModel)]="selectedInventory"
                                       [ngModelOptions]="{standalone: true}" [clearable]="false"
                                        [readonly]="readonlyInventoryType" >
                            </ng-select>
                        </div>
                    </div>
                    <div class="field col-12 md:col-4">
                        <app-stt-input label="Cod Sap" [control]="this.form.controls['code']" icon="pi pi-key" [readonly]="readOnly"
                                       type="text" inputId="code"></app-stt-input>
                    </div>
                </div>
                <p-divider></p-divider>
                <br>
                <div class="grid p-fluid">
                    <div class="field col-5">
                        <app-stt-input label="Numar Document" [control]="this.form.controls['documentNo']"
                                       icon="pi pi-paperclip" [readonly]="readOnly"
                                       type="text" inputId="documentNo"></app-stt-input>
                        <br>
                        <app-stt-calendar [control]="form.controls['documentDate']" inputId="documentDate" [showCalendar]="showCalendar"
                                          icon="pi pi-calendar"></app-stt-calendar>
                    </div>
                    <div class="field col-7" style="border-left: 1px solid lightgray">
                        <app-stt-textarea [control]="form.controls['description']" label="Descriere" [readOnly]="readOnly"
                                          icon="pi pi-pencil" inputId="description"></app-stt-textarea>
                    </div>
                </div>

                <p-divider></p-divider>
                <br>
                <div class="grid fields-group">
                    <div class="col-12 grid" style="flex-wrap: nowrap; gap: 2rem;">

                        <!--MATERIE PRIMA-->

                        <div class="container col-10" formArrayName="itemsRawMaterials"  *ngIf="product_selected === false">
                            <ng-container
                                *ngFor="let recipeRawMaterialItemForm of formInventoryRawMaterialItems().controls; let index = index">
                                <ng-container [formGroup]="recipeRawMaterialItemForm">
                                    <div class="grid p-fluid" *ngIf="!recipeRawMaterialItemForm.controls.needDelete.value">
                                        <div class="field col-3">
                                            <app-stt-dropdown endPoint="raw-materials/dropdown"
                                                              [formControlParent]="recipeRawMaterialItemForm.controls['raw_material_id']"
                                                              icon="pi pi-check-square"
                                                              placeholder="Nu ati selectat materia prima!"
                                                              label="Materia prima" [readonly]="readonlyInventoryType"
                                                              [dropDownWithTrashed]="dropDownWithTrashed"
                                                              (changeEvent)="onRawMaterialChange($event, index)">
                                            </app-stt-dropdown>
                                        </div>
                                        <div class="field col-3">
                                            <app-stt-input label="Unitati" [control]="recipeRawMaterialItemForm.controls['units']"
                                                           icon="pi pi-box" (change)="calculateStockRest(index)" [readonly]="readOnly"
                                                           type="number" inputId="rawMaterialUnits"></app-stt-input>
                                        </div>
                                        <div class="field col-2">
                                            <app-stt-input label="Stock" [control]="recipeRawMaterialItemForm.controls['stock']"
                                                           icon="pi pi-box" [readonly]="true"
                                                           type="number" inputId="rawMaterialUnits"></app-stt-input>

                                        </div>
                                        <div class="field col-2">
                                            <app-stt-input label="Diferenta stoc" [control]="recipeRawMaterialItemForm.controls['stockRest']"
                                                           icon="pi pi-box" [readonly]="true"
                                                           type="number" inputId="rawMaterialUnits"></app-stt-input>

                                        </div>
                                        <div class="field col-1">
                                            <button (click)="deleteFormItem(index)"
                                                    class="p-button-raised p-button-danger text-right"
                                                    icon="pi pi-minus" pButton pRipple type="button"></button>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>

                        <!--PRODUS-->

                        <div class="container col-11" formArrayName="itemsProducts"
                             *ngIf="product_selected === true">
                            <ng-container
                                *ngFor="let recipeProductItemForm of formInventoryProductItems().controls; let index = index">
                                <ng-container [formGroup]="recipeProductItemForm">
                                    <div class="grid p-fluid" *ngIf="!recipeProductItemForm.controls.needDelete.value">
                                        <div class="field col-3">
                                            <app-stt-dropdown endPoint="products/dropdown"
                                                              [formControlParent]="recipeProductItemForm.controls['product_id']"
                                                              [required]="true" icon="pi pi-check-square"
                                                              placeholder="Nu ati selectat produsul!"
                                                              label="Produs" [readonly]="readonlyInventoryType"
                                                              [dropDownWithTrashed]="dropDownWithTrashed"
                                                              (changeEvent)="onProductChange($event, index)">
                                            </app-stt-dropdown>
                                        </div>
                                        <div class="field col-2" style="padding: 14px 14px 14px 0px">
                                            <app-stt-input label="Lot" [control]="recipeProductItemForm.controls['lot']"
                                                           icon="pi pi-box" (change)="onLotChange(index)" [readonly]="readonlyInventoryType"
                                                           type="string" inputId="productLot"></app-stt-input>
                                        </div>
                                        <div class="field col-2" style="padding: 14px 14px 14px 0px">
                                            <app-stt-input label="Unitati" [control]="recipeProductItemForm.controls['units']"
                                                           icon="pi pi-box" (change)="calculateStockProductRest(index)" [readonly]="readOnly"
                                                           type="number" inputId="productUnits"></app-stt-input>
                                        </div>
                                        <div class="field col-2" style="padding: 14px 14px 0px 0px">
                                            <app-stt-input label="Stoc" [control]="recipeProductItemForm.controls['stock']"
                                                           icon="pi pi-box" [readonly]="true"
                                                           type="number" inputId="rawMaterialUnits"></app-stt-input>

                                        </div>
                                        <div class="field col-2" style="padding: 14px 14px 0px 0px">
                                            <app-stt-input label="Diferenta stoc" [control]="recipeProductItemForm.controls['stockRest']"
                                                           icon="pi pi-box" [readonly]="true"
                                                           type="number" inputId="rawMaterialUnits"></app-stt-input>

                                        </div>
                                        <div class="field col-1" style="padding: 14px 0px 0px 0px">
                                            <button (click)="deleteFormItem(index)"
                                                    class="p-button-raised p-button-danger text-right" [disabled]="readOnly"
                                                    icon="pi pi-minus" pButton pRipple type="button"></button>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>

                        <div class="col-2 grid align-items-end">
                            <button (click)="addFormElement()"
                                    class="p-button-raised p-button-success text-right"
                                    icon="pi pi-plus"
                                    [style]="getStyleButton()"
                                    label="Adauga" pButton [disabled]="readOnly"
                                    pRipple type="button"></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </p-card>
    <p-progressBar mode="indeterminate" class="addEditProgressBar" *ngIf="loading"></p-progressBar>

    <div class="actions-area">
        <div class="actions-left">
            <!--<button pButton pRipple type="button" label="Anulare" class="p-button-raised p-button-danger" icon="pi pi-ban" [disabled]="loading" (click)="onCancel()">
            </button>-->
            <button pButton pRipple type="button" label="Anulare" class="p-button-raised p-button-danger"
                    icon="pi pi-ban" [disabled]="loading" routerLink="/stock-management/inventory/raw-materials"></button>
        </div>

        <div class="actions-right">
            <button pButton pRipple type="button" label="Salveaza" class="p-button-raised p-button-success"
                    icon="pi pi-save" [disabled]="loading" (click)="onSave()">
            </button>
            <p-button *ngIf="status === enumStatus.Draft" [label]="'Validare'" icon="pi pi-check" iconPos="right" (click)="validateForm()" [ngStyle]="{'margin-left' : '2px'}"></p-button>
        </div>
    </div>
</form>

