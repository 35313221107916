<p-divider></p-divider>
<div class="flex-box" style="  display:flex;justify-content:space-between;">
    <p style="font-size: 20px">Achizitii  /  Materie prima VS Produs Finit</p>
</div>
<p-divider></p-divider>

<form [formGroup]="form" autocomplete="false">
    <p-card>
        <div class="grid fields-group" style="padding: 0 1em">
            <div class="col-12">
                <div class="grid p-fluid">
                    <div class="field col-12 md:col-3">
                        <span>Selectati o reteta si cantitatea produsului</span>
                    </div>
                </div>
                <br>
                <div class="grid p-fluid">
                    <div class="field col-12 md:col-3">
                            <app-stt-dropdown endPoint="recipes/dropdown" [formControlParent]="form.controls['recipe_id']" label="Reteta"
                                              icon="pi pi-cog"
                                              placeholder="Nu ati selectat o reteta!"></app-stt-dropdown>
                    </div>
                    <div class="field col-12 md:col-2">
                        <app-stt-input inputId="units" [control]="form.controls['units']"
                                       icon="pi pi-paperclip"
                                       type="number"
                                       label="Cantitate"></app-stt-input>
                    </div>
                    <div class="field col-12 md:col-2">
                        <app-stt-dropdown endPoint="unit-measures/dropdown" [formControlParent]="form.controls['unit_measure_id']" label="Unitate de masura"
                                          icon="pi pi-cog" [readonly]="true" placeholder="Unitate de masura"></app-stt-dropdown>
                    </div>
                    <div class="field col-12 md:col-1">
                        <button pButton pRipple type="submit" label="Submit" class="p-button-raised p-button-success" (click)="submit()" [disabled]="form.invalid">
                        </button>
                    </div>
                </div>
            </div>
            <div class="container" style="max-width: 100%">
                <p-table [value]="fields" [scrollable]="true" scrollDirection="horizontal"
                         styleClass="p-datatable-gridlines">
                    <ng-template pTemplate="header" style="background-color: white">
                        <tr>
                            <th *ngFor="let header of headers" style="min-width: 10em;">{{header}}</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
                        <tr style="overflow: visible;">
                            <td *ngFor="let field of rowData" style="width: 5em;">{{field}}</td>
                            <td style="min-width: 10em;">{{getTotal(rowData)}}</td>
                            <td style="min-width: 10em;">{{stock[rowIndex]}}</td>
                            <td style="min-width: 10em;">{{unit_measure[rowIndex]}}</td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>


        </div>
    </p-card>
    <p-progressBar mode="indeterminate" class="addEditProgressBar" *ngIf="loading"></p-progressBar>
</form>
