<form [formGroup]="form" style="max-width: 100%!important;" autocomplete="false" (ngSubmit)="onSave()">
    <div class="grid fields-group">
        <div class="col-12">
            <div class="grid p-fluid">
                <div class="field col-12 md:col-6">
                    <app-stt-input inputId="name" [control]="form.controls['name']"
                                   icon="pi pi-users"
                                   label="Denumire"></app-stt-input>
                </div>
                <div class="field col-12 md:col-6">
                    <app-stt-input inputId="code" [control]="form.controls['code']"
                                   icon="pi pi-key"
                                   label="Cod SAP"></app-stt-input>
                </div>
            </div>
            <div class="grid p-fluid">
                <div class="field col-12 md:col-6">
                    <span class="p-float-label p-input-icon-right">
                        <input type="file" id="importedfile" pInputText (change)="onImportFile($event)" >
                        <i class="pi pi-upload"></i>
                    </span>
                </div>
            </div>
            <p-divider>Materie prima </p-divider>
            <div class="grid fields-group">
                <div class="col-12 grid" style="flex-wrap: nowrap; gap: 2rem;">
                    <div class="container col-8" formArrayName="items">
                        <ng-container
                            *ngFor="let recipeItemForm of formRecipeItems().controls; let index = index">
                            <ng-container [formGroup]="recipeItemForm">
                                <div class="grid p-fluid" *ngIf="!recipeItemForm.controls.needDelete.value">
                                    <div class="field col-7">
                                        <app-stt-dropdown endPoint="raw-materials/dropdown"
                                                          placeholder="Nu ati selectat materia prima!"
                                                          icon="pi pi-check-square"
                                                          [formControlParent]="recipeItemForm.controls['raw_material_id']"
                                                          label="Materia prima"
                                                          [dropDownWithTrashed]="dropDownWithTrashed">
                                        </app-stt-dropdown>
                                    </div>
                                    <div class="field col-4">
                                        <app-stt-input inputId="units" [control]="recipeItemForm.controls['units']"
                                                       icon="pi pi-box" type="number"
                                                       label="Unitati"></app-stt-input>
                                    </div>
                                    <div class="field col-1">
                                        <button (click)="deleteFormItem(index)"
                                                class="p-button-raised p-button-danger text-right"
                                                icon="pi pi-minus" pButton pRipple type="button"></button>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
                    <div class="col-4 grid align-items-end" style="padding: 14px">
                        <button (click)="addFormItem()"
                                class="p-button-raised p-button-success text-right"
                                icon="pi pi-plus"
                                [style]="getStyleButton()"
                                label="Adauga" pButton
                                pRipple type="button"></button>
                    </div>
                </div>
            </div>
            <div *ngIf="fileImported === true">
                <div *ngFor="let error of errorArray">
                    <span style="color: red;">Materia prima cu codul {{error.code}}({{error.name}}) nu a fost gasita in nomenclatoare!</span>
                </div>
            </div>




            <p-divider>Nutrienti si Aditivi</p-divider>
            <div class="grid fields-group" *ngIf="formRecipeNutrients().value.length > 0">
                <div class="col-6 grid" style="flex-wrap: nowrap; gap: 2rem;">
                    <div class="container col-12" formArrayName="nutrients">
                        <ng-container
                            *ngFor="let recipeItemNutrient of formRecipeNutrients().controls; let index = index">
                            <ng-container [formGroup]="recipeItemNutrient" *ngIf="recipeItemNutrient.value.additives === false">
                                <div class="grid p-fluid">
                                    <div class="field col-4">
                                        <app-stt-input inputId="name" [control]="recipeItemNutrient.controls['name']"
                                                       icon="pi pi-box" type="string"
                                                       label="Denumire"></app-stt-input>
                                    </div>
                                    <div class="field col-4">
                                        <app-stt-input inputId="units" [control]="recipeItemNutrient.controls['units']"
                                                       icon="pi pi-box" type="number"
                                                       label="Cantitate"></app-stt-input>
                                    </div>
                                    <div class="field col-3">
                                        <app-stt-input inputId="unit_measure" [control]="recipeItemNutrient.controls['unit_measure']"
                                                       icon="pi pi-box" type="string"
                                                       label="Unitate"></app-stt-input>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
<!--                    <div class="col-4 grid align-items-end" style="padding: 14px">-->
<!--                        <button (click)="addFormItem()"-->
<!--                                class="p-button-raised p-button-success text-right"-->
<!--                                icon="pi pi-plus"-->
<!--                                [style]="getStyleButton()"-->
<!--                                label="Adauga" pButton-->
<!--                                pRipple type="button"></button>-->
<!--                    </div>-->
                    <div class="col-12 grid" style="flex-wrap: nowrap; gap: 2rem;border-left: 1px solid lightgray">
                        <div class="container col-12" formArrayName="nutrients">
                            <ng-container
                                *ngFor="let recipeItemNutrient of formRecipeNutrients().controls; let index = index">
                                <ng-container [formGroup]="recipeItemNutrient" *ngIf="recipeItemNutrient.value.additives === true">
                                    <div class="grid p-fluid">
                                        <div class="field col-4">
                                            <app-stt-input inputId="name" [control]="recipeItemNutrient.controls['name']"
                                                           icon="pi pi-box" type="string"
                                                           label="Denumire"></app-stt-input>
                                        </div>
                                        <div class="field col-4">
                                            <app-stt-input inputId="units" [control]="recipeItemNutrient.controls['units']"
                                                           icon="pi pi-box" type="number"
                                                           label="Cantitate"></app-stt-input>
                                        </div>
                                        <div class="field col-3">
                                            <app-stt-input inputId="unit_measure" [control]="recipeItemNutrient.controls['unit_measure']"
                                                           icon="pi pi-box" type="string"
                                                           label="Unitate"></app-stt-input>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
            <div class="grid fields-group" *ngIf="formRecipeNutrients().value.length === 0">
                <div class="col-6 grid" style="flex-wrap: nowrap; gap: 2rem;">
                    <div class="container col-12">
                        Nu au fost gasiti nutrienti sau aditivi
                    </div>
                </div>
            </div>


        </div>
    </div>

    <p-progressBar mode="indeterminate" class="addEditProgressBar" *ngIf="loading"></p-progressBar>

    <div class="actions-area">
        <div class="actions-left">
            <button pButton pRipple type="button" label="Anulare" class="p-button-raised p-button-danger"
                    icon="pi pi-ban" [disabled]="loading" (click)="onCancel()">
            </button>
        </div>

        <div class="actions-right">
            <button pButton pRipple type="submit" label="Salveaza" class="p-button-raised p-button-success"
                    icon="pi pi-save" [disabled]="loading">
            </button>
        </div>
    </div>
</form>


