import { Component, OnInit } from '@angular/core';
import { SttTableComponent } from '../../../../shared/components/stt-table/stt-table.component';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { RawMaterialModel } from 'src/app/shared/entities/raw-material.model';
import { RawMaterialsAddEditComponent } from '../raw-materials-add-edit/raw-materials-add-edit.component';
import { ConfirmationService, MessageService, PrimeNGConfig } from 'primeng/api';
import { ValidationMessageService } from '../../../../shared/services/base/validation-message-handle';
import { finalize } from 'rxjs/operators';
import { RawMaterialGroupService } from '../../../../shared/services/app/raw-material-group.service';
import { RawMaterialGroupModel } from '../../../../shared/entities/raw-material-group.model';
import { RawMaterialService } from "../../../../shared/services/app/raw-material.service";

@Component({
    selector: 'app-raw-materials-action-table',
    templateUrl: './raw-materials-action-table.component.html',
    styleUrls: ['./raw-materials-action-table.component.scss']
})
export class RawMaterialsActionTableComponent implements OnInit {

    public data: RawMaterialGroupModel;
    public table: SttTableComponent;
    public ref: DynamicDialogRef;
    public loading = true;

    constructor(
        public dialogService: DialogService,
        public confirmationService: ConfirmationService,
        public primengConfig: PrimeNGConfig,
        public itemService: RawMaterialService,
        public messageService: MessageService,
        private validationMessageService: ValidationMessageService,
    ) {

    }

    ngOnInit(): void {
    }

    setParams(entity: RawMaterialModel): void {
        this.data = entity;
    }

    setTableReference(table: SttTableComponent): void {
        this.table = table;
    }


    showDialog() {
        this.ref = this.dialogService.open(RawMaterialsAddEditComponent, {
            header: 'Modificare Grup Materie Prima',
            width: '70%',
            contentStyle: { 'max-height': '70vh' },
            baseZIndex: 10000,
            closeOnEscape: false,
            data: this.data,
        });

        this.ref.onClose.subscribe((raw_material: RawMaterialModel) => {
            if (raw_material) {
                this.table.refresh();
            }
        });
    }

    confirm() {
        this.confirmationService.confirm({
            header: 'Confirmare stergere Materie prima',
            message: 'Sunteti sigur ca doriti sa stergeti aceasta Materie prima?',
            key: 'deleteRawMaterial',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Da',
            rejectLabel: 'Nu',
            acceptButtonStyleClass: 'p-button-md p-button-success',
            rejectButtonStyleClass: 'p-button-md p-button-danger',
            accept: () => {
                this.deleteItem(this.data.uid);
            },
            reject: () => {
                this.confirmationService.close();
                this.table.refresh();
            }
        });
    }

    deleteItem(uid: string){
        this.itemService.delete(uid)
            .pipe(finalize(() => {this.loading = false;this.table.refresh();}))
            .subscribe((response: any) => {
                this.messageService.add({
                    severity: 'success',
                    summary: 'Materia prima a fost stearsa cu success!'
                });
            });
    }



}
