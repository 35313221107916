import { Component, OnInit, ViewChild } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SttTableComponent } from '../../../shared/components/stt-table/stt-table.component';
import { SttTableService } from '../../../shared/components/stt-table/stt-table-service';
import {
    ProductPackingTypeActionTableComponent
} from './product-packing-type-action-table/product-packing-type-action-table.component';
import {
    ProductPackingTypeAddEditComponent
} from './product-packing-type-add-edit/product-packing-type-add-edit.component';
import { ProductPackingTypeModel } from 'src/app/shared/entities/product-packing-type.model';


@Component({
    selector: 'app-product-packing-type',
    templateUrl: './product-packing-type.component.html',
    styleUrls: ['./product-packing-type.component.scss']
})
export class ProductPackingTypeComponent implements OnInit {
    @ViewChild('table', { static: false }) table: SttTableComponent;

    public tableEndpoint = 'product-packing-types';
    public tableActionComponent = ProductPackingTypeActionTableComponent;
    public tableQuery: any;
    public ref: DynamicDialogRef;

    constructor(public dialogService: DialogService) {
        this.tableQuery = new SttTableService();
    }

    ngOnInit(): void {

    }

    addNewEntity() {
        this.ref = this.dialogService.open(ProductPackingTypeAddEditComponent, {
            header: 'Adaugare Tip ambalaj',
            width: '30%',
            contentStyle: { 'max-height': '70vh' },
            baseZIndex: 10000,
            closeOnEscape: false,
            data: null,
        });

        this.ref.onClose.subscribe((product_packing_type: ProductPackingTypeModel) => {
            if (product_packing_type) {
                this.table.refresh();
            }
        });
    }
}
