import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';


@Component({
    selector: 'app-order-customers-transport-progressbar',
    templateUrl: './order-customers-transport-progressbar.component.html',
    styleUrls: ['./order-customers-transport-progressbar.component.scss']
})
export class OrderCustomersTransportProgressbarComponent implements OnInit {

    @Input() form: any;
    @Input() i: number;
    @Input() i_weekday: number;
    public progressValue: number;
    public day: any;
    public weekDays = ['Duminica', 'Luni', 'Marti', 'Miercuri', 'Joi', 'Vineri', 'Sambata'];

    constructor(private formBuilder: UntypedFormBuilder) {
    }

    ngOnInit(): void {
        this.day = this.formProductsDate(this.i).at(this.i_weekday);

        this.calculateQuantityTransport();
    }


    calculateQuantityTransport(): void {
        const value = (this.formProductsDate(this.i).at(this.i_weekday).value.quantityTransportTotal * 100)
            / this.formProductsDate(this.i).at(this.i_weekday).value.quantity;
        const factor = 10 ** 2;
        this.progressValue = Math.round(value * factor) / factor;
    }


    formProducts(): UntypedFormArray {
        return this.form.get('products') as UntypedFormArray;
    }

    formProductsDate(index: number): UntypedFormArray {
        return this.formProducts().at(index).get('dates') as UntypedFormArray;
    }

    formProductsDateTransport(indexProduct: number, indexDay: number): UntypedFormArray {
        return this.formProductsDate(indexProduct).at(indexDay).get('transports') as UntypedFormArray;
    }

    onSave(indexProduct: number, indexWeek: number, indexTransport: number) {
        if (this.formProductsDateTransport(indexProduct, indexWeek).at(indexTransport).invalid) {
            return;
        }
        // Calculate new quantity total on current product and map it on the quantityTransportTotal
        let newTotalQuantityTransport = 0;
        this.formProductsDateTransport(indexProduct, indexWeek).value.forEach(element => {
            newTotalQuantityTransport = newTotalQuantityTransport + Number(element.quantityPerTransport);
        });
        this.formProductsDate(indexProduct).at(indexWeek).patchValue({
            quantityTransportTotal: newTotalQuantityTransport
        });

        // Check if whole required quantity has been mapped , if not add new transport
        if (this.formProductsDate(indexProduct).at(indexWeek).value.quantityTransportTotal
            < this.formProductsDate(indexProduct).at(indexWeek).value.quantity) {
            this.formProductsDateTransport(indexProduct, indexWeek).push(this.addTransport());
        }

        // Get percentage of mapped quantity on transport
        this.calculateQuantityTransport();
        // Save data on the form
        this.formProductsDateTransport(indexProduct, indexWeek).at(indexTransport).patchValue({
            needDelete: false
        });
        // Check if the week is done
        // eslint-disable-next-line max-len
        if (this.formProductsDate(indexProduct).at(indexWeek).value.quantity <= this.formProductsDate(indexProduct).at(indexWeek).value.quantityTransportTotal) {
            this.formProductsDate(indexProduct).at(indexWeek).patchValue({
                weekDayDone: true
            });
        }
        // Check if product is done
        let productDone = true;
        for (let indexWeekDays = 0; indexWeekDays < this.formProductsDate(indexProduct).length; indexWeekDays++) {
            if (this.formProductsDate(indexProduct).at(indexWeekDays).value.weekDayDone === false) {
                productDone = false;
            }
        }
        if (productDone === true) {
            this.formProducts().at(indexProduct).patchValue({
                isDone: true
            });
        }
    }

    addTransport(): UntypedFormGroup {
        return this.formBuilder.group({
            transport_company_id: [null],
            transport_unit_type_id: [null],
            transport_unit_driver_id: [null],
            quantityPerTransport: [null, Validators.required],
            needDelete: [true]
        });
    }

    addFormTransport() {
        for (let indexProducts = 0; indexProducts < this.formProducts().length; indexProducts++) {
            for (let indexWeekDays = 0; indexWeekDays < this.formProductsDate(indexProducts).length; indexWeekDays++) {
                this.formProductsDateTransport(indexProducts, indexWeekDays).push(this.addTransport());
            }
        }
    }
}
