import { Component, OnInit, ViewChild } from '@angular/core';
import { FncModel } from '../../../shared/entities/fnc.model';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SttTableComponent } from '../../../shared/components/stt-table/stt-table.component';
import { CustomerProductsAddEditComponent } from './customer-products-add-edit/customer-products-add-edit.component';
import { SttTableService } from '../../../shared/components/stt-table/stt-table-service';
import { CustomerProducsActionTableComponent } from './customer-producs-action-table/customer-producs-action-table.component';

@Component({
  selector: 'app-customer-products',
  templateUrl: './customer-products.component.html',
  styleUrls: ['./customer-products.component.scss']
})
export class CustomerProductsComponent implements OnInit {

    public ref: DynamicDialogRef;
    @ViewChild('table', { static: false }) table: SttTableComponent;
    public tableEndpoint = 'customer-products';
    public tableActionComponent = CustomerProducsActionTableComponent;
    public tableQuery: any;

  constructor(public dialogService: DialogService) {
      this.tableQuery = new SttTableService()
          .addInclude('productPackingType')
          .addInclude('category');
  }

  ngOnInit(): void {
  }

    addNewEntity() {
        this.ref = this.dialogService.open(CustomerProductsAddEditComponent, {
            header: 'Adaugare Produs',
            width: '70%',
            contentStyle: { 'max-height': '70vh' },
            baseZIndex: 10000,
            closeOnEscape: false,
            data: null,
        });

        this.ref.onClose.subscribe((fnc: FncModel) => {
            if (fnc) {
                this.table.refresh();
            }
        });
    }

}
