import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { HttpApiService } from '../../../../shared/services/base/http-api.service';
import { ValidationMessageService } from '../../../../shared/services/base/validation-message-handle';
import { MessageService } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { InventoryModel } from '../../../../shared/entities/inventory.model';
import { TicketItemModel } from '../../../../shared/entities/ticket-item.model';
import { InventoryService } from '../../../../shared/services/app/inventory.service';

enum Status {
    Draft = 0,
    Validated = 1,
}

@Component({
    selector: 'app-inventory-add-edit',
    templateUrl: './inventory-add-edit.component.html',
    styleUrls: ['./inventory-add-edit.component.scss']
})
export class InventoryAddEditComponent implements OnInit {

    public uid: string = null;
    public itemModel: InventoryModel = new InventoryModel();
    public loading = false;
    public form: UntypedFormGroup;
    public minimumDate: Date = new Date();
    public dropDownWithTrashed = false;

    public inventoryDropdownItems = ['Produs', 'Materie prima'];
    public selectedInventory = 'Produs';
    public product_selected = true;
    public readonlyInventoryType = false;
    public fnc_id: number;
    public waitForFinalize = false;
    public status: number;
    public readOnly = false;
    public showCalendar = true;
    public enumStatus = Status;


    constructor(
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private formBuilder: UntypedFormBuilder,
        private httpApiService: HttpApiService,
        private validationMessageService: ValidationMessageService,
        private itemService: InventoryService,
        private messageService: MessageService,
        private route: ActivatedRoute,
        public router: Router,
    ) {
        this.uid = this.route.snapshot.queryParams.uid;
    }

    ngOnInit(): void {

        const localStorageData = JSON.parse(localStorage.getItem('user'));
        this.fnc_id = localStorageData.fnc_id;

        this.createForm();

        if (this.uid) {
            this.getItem();
            this.readonlyInventoryType = true;
        }

        return;
    }


    createForm(): void {
        this.form = this.formBuilder.group({
            uid: [this.itemModel.uid],
            fnc_id: [this.fnc_id , Validators.required],
            code: [this.itemModel.code, Validators.required],
            documentDate: [this.itemModel.documentDate, Validators.required],
            documentNo: [this.itemModel.documentNo, Validators.required],
            description: [this.itemModel.description, [Validators.maxLength(2000)]],
            product_selected: [true],
            itemsRawMaterials: this.formBuilder.array([]),
            itemsProducts: this.formBuilder.array([]),
        });

        if (!this.uid) {
            // Add empty line
            this.addFormRawMaterialElement();
            this.addFormProductElement();
        }
    }

    formInventoryRawMaterialItems(): UntypedFormArray {
        return this.form.get('itemsRawMaterials') as UntypedFormArray;
    }

    formInventoryProductItems(): UntypedFormArray {
        return this.form.get('itemsProducts') as UntypedFormArray;
    }

    getStyleButton(){
        if(this.form.value.product_selected){
            const itemsLength = this.formInventoryProductItems().value.length;
            let jsonStyle;
            if(this.uid){
                if(!this.waitForFinalize){
                    return ;
                }
            }
            if((this.formInventoryProductItems().at(itemsLength -1).get('product_id').invalid && this.formInventoryProductItems().at(itemsLength -1).get('product_id').touched)
                || (this.formInventoryProductItems().at(itemsLength -1).get('lot').invalid && this.formInventoryProductItems().at(itemsLength -1).get('lot').touched)
                || (this.formInventoryProductItems().at(itemsLength -1).get('units').invalid && this.formInventoryProductItems().at(itemsLength -1).get('units').touched)){
                jsonStyle = {'margin-bottom' : '44px'};
            }
            else{
                jsonStyle = {'margin-bottom' : '24px'};
            }
            return jsonStyle;
        }
        else{
            const itemsLength = this.formInventoryRawMaterialItems().value.length;
            let jsonStyle;
            if((this.formInventoryRawMaterialItems().at(itemsLength -1).get('raw_material_id').invalid && this.formInventoryRawMaterialItems().at(itemsLength -1).get('raw_material_id').touched)
                || (this.formInventoryRawMaterialItems().at(itemsLength -1).get('units').invalid && this.formInventoryRawMaterialItems().at(itemsLength -1).get('units').touched)){
                jsonStyle = {'margin-bottom' : '44px'};
            }
            else{
                jsonStyle = {'margin-bottom' : '24px'};
            }
            return jsonStyle;
        }

    }


    deleteFormItem(index: number): void {
        let formItem;
        if(this.product_selected === true){
            formItem = this.formInventoryProductItems().at(index);
            this.clearProductValidators(index);
        }
        else{
            formItem = this.formInventoryRawMaterialItems().at(index);
            this.clearRawMaterialValidators(index);
        }
        formItem.patchValue({
            needDelete: true,
        });

    }

    addFormRawMaterialElement(item?: TicketItemModel) {
        const inventoryRawMaterialForm = this.formBuilder.group({
            uid: [(item) ? item.uid : null],
            raw_material_id: [(item) ? item.item_id : null, Validators.required],
            units: [(item) ? item.units : null, Validators.required],
            stock: [0],
            stockRest: [0],
            needDelete: [false]
        });
        this.formInventoryRawMaterialItems().push(inventoryRawMaterialForm);
    }

    addFormProductElement(item?: any) {
        const inventoryProductForm = this.formBuilder.group({
            uid: [(item) ? item.uid : null],
            product_id: [(item) ? item.item_id : null, Validators.required],
            units: [(item) ? item.units : null, Validators.required],
            lot: [null, Validators.required],
            stock: [0],
            stockRest: [0],
            needDelete: [false]
        });
        this.formInventoryProductItems().push(inventoryProductForm);
    }

    addFormElement(item?: TicketItemModel){
        if(this.product_selected === true) {
            const inventoryItemForm = this.formBuilder.group({
                uid: [(item) ? item.uid : null],
                product_id: [(item) ? item.item_id : null, Validators.required],
                units: [(item) ? item.units : null, Validators.required],
                lot: [null, Validators.required],
                stock: [0],
                stockRest: [0],
                needDelete: [false]
            });
            this.formInventoryProductItems().push(inventoryItemForm);
        }else{
            const inventoryItemForm = this.formBuilder.group({
                uid: [(item) ? item.uid : null],
                raw_material_id: [(item) ? item.item_id : null, Validators.required],
                units: [(item) ? item.units : null, Validators.required],
                stock: [0],
                stockRest: [0],
                needDelete: [false]
            });
            this.formInventoryRawMaterialItems().push(inventoryItemForm);
        }
    }

    getItem(): void {
        this.loading = true;
        this.dropDownWithTrashed = true;
        this.itemService.get(this.uid)
            .pipe(finalize(() => {this.loading = false; this.waitForFinalize = true;}))
            .subscribe((response: any) => {
                this.itemModel.map(response.payload);
                this.form.patchValue(this.itemModel);
                const dateParse = new Date(response.payload.documentDate);
                this.form.patchValue({
                    documentDate: dateParse,
                });
                if(response.payload.product_selected){
                    response.payload.productItems.forEach((element, index) => {
                        this.product_selected = true;
                        this.selectedInventory = 'Produs';
                        this.addFormProductElement();
                        this.formInventoryProductItems().at(index).patchValue({
                            uid: element.uid,
                            product_id: element.product_id,
                            lot: element.lot,
                            units: element.units,
                            stockRest: element.stockRest,
                            stock: element.stock,
                        });
                    });
                }
                else {
                    response.payload.items.forEach((element, index) => {
                        this.product_selected = false;
                        this.selectedInventory = 'Materie prima';
                        this.addFormRawMaterialElement();
                        this.formInventoryRawMaterialItems().at(index).patchValue({
                            uid: element.uid,
                            raw_material_id: element.raw_material_id,
                            units: element.units,
                            stockRest: element.stockRest,
                            stock: element.stock,
                        });
                    });
                }
                this.status = response.payload.status;
                if(this.status > Status.Draft){
                    this.readOnly = true;
                    this.showCalendar = false;
                }
            });
    }

    onSwitchInventoryType(): void {
        if (this.selectedInventory === 'Produs'){
            this.product_selected = true;
            this.formInventoryRawMaterialItems().clear();
            this.addFormRawMaterialElement();
        }
        else{
            this.product_selected = false;
            this.formInventoryProductItems().clear();
            this.addFormProductElement();
        }
        this.form.patchValue({
            product_selected: this.product_selected
        });
    }

    onProductChange(data: any, index: number){
        if(data){
            this.itemService.getProductStock(data.id, this.formInventoryProductItems().at(index).value)
                .pipe(finalize(() => this.calculateStockProductRest(index)))
                .subscribe((response: any) => {
                    this.formInventoryProductItems().at(index).patchValue({
                        stock: response.payload
                    });
                });
        }
    }

    onRawMaterialChange(data: any, index: number){
        let fncId = this.form.value.fnc_id;
        if (!this.form.value.fnc_id){
            fncId = this.fnc_id;
        }
        if(data){
            this.itemService.getRawMaterialStock(data.id, fncId)
                .pipe(finalize(() => this.calculateStockRest(index)))
                .subscribe((response: any) => {
                    this.formInventoryRawMaterialItems().at(index).patchValue({
                        stock: response.payload.total_stock
                    });
                });
        }
    }

    onLotChange(index: number){
        if(this.formInventoryProductItems().at(index).value.product_id){
            this.itemService.getProductStock(this.formInventoryProductItems().at(index).value.product_id, this.formInventoryProductItems().at(index).value.lot)
                .pipe(finalize(() => this.calculateStockProductRest(index)))
                .subscribe((response: any) => {
                    this.formInventoryProductItems().at(index).patchValue({
                        stock: response.payload
                    });
                });
        }
    }


    calculateStockProductRest(index: number): void {
        const units = this.formInventoryProductItems().at(index).value.units;
        const stock = this.formInventoryProductItems().at(index).value.stock;

        this.formInventoryProductItems().at(index).patchValue({
            stockRest: units - stock});
    }

    calculateStockRest(index: number): void {
        const units = this.formInventoryRawMaterialItems().at(index).value.units;
        const stock = this.formInventoryRawMaterialItems().at(index).value.stock;

        this.formInventoryRawMaterialItems().at(index).patchValue({
            stockRest: units - stock});
    }

    checkIfFormValid(): boolean {
        if (this.form.invalid) {
            this.messageService.add({
                severity: 'error',
                summary: 'Formularul nu este valid!',
                detail: 'Vericficati toate campurile si incercati din nou.'
            });
            this.form.markAllAsTouched();
            return false;
        }
        return true;
    }

    clearRawMaterialValidators(index: number){
        this.formInventoryRawMaterialItems().at(index).get('raw_material_id').clearValidators();
        this.formInventoryRawMaterialItems().at(index).get('raw_material_id').updateValueAndValidity();
        this.formInventoryRawMaterialItems().at(index).get('units').clearValidators();
        this.formInventoryRawMaterialItems().at(index).get('units').updateValueAndValidity();
    }

    clearProductValidators(index: number){
        this.formInventoryProductItems().at(index).get('product_id').clearValidators();
        this.formInventoryProductItems().at(index).get('product_id').updateValueAndValidity();
        this.formInventoryProductItems().at(index).get('units').clearValidators();
        this.formInventoryProductItems().at(index).get('units').updateValueAndValidity();
        this.formInventoryProductItems().at(index).get('lot').clearValidators();
        this.formInventoryProductItems().at(index).get('lot').updateValueAndValidity();
    }

    clearValidators(){
        if(this.form.value.product_selected)
        {
            this.formInventoryRawMaterialItems().value.forEach((element, index) =>{
                this.clearRawMaterialValidators(index);
            });
        }
        else{
            this.formInventoryProductItems().value.forEach((element, index) =>{
                this.clearProductValidators(index);
            });
        }
    }

    onSave(): void {
        this.clearValidators();
        if (!this.checkIfFormValid()) {
            return;
        }
        this.loading = true;
        const data = this.form.getRawValue();
        this.itemService.set(data)
            .pipe(finalize(() => this.loading = false))
            .subscribe((response: any) => {
                this.itemModel.map(response.payload);
                this.form.patchValue(this.itemModel);
                this.messageService.add({
                    severity: 'success',
                    summary: 'Inventarul a fost actualizat cu success!'
                });
                this.router.navigate(['stock-management/inventory/raw-materials'], {});
            }, err => {
                if (err instanceof HttpErrorResponse) {
                    this.validationMessageService.serverSideValidation(err, this.form, '', true);
                }
            });
    }

    validateForm(){
        this.status = this.enumStatus.Validated;
        const summary = 'Inventarul a fost validat cu success!!';
        this.changeStatus(summary);
    }

    changeStatus(summary: string){
        this.itemService.statusChange(this.status, this.uid)
            .pipe(finalize(() => this.loading = false))
            .subscribe((response: any) => {
                this.itemModel.map(response.payload);
                this.form.patchValue(this.itemModel);
                this.messageService.add({
                    severity: 'success',
                    summary
                });
                this.router.navigate(['stock-management/inventory/raw-materials'], {});
            }, err => {
                if (err instanceof HttpErrorResponse) {
                    this.validationMessageService.serverSideValidation(err, this.form, '', true);
                }
            });
    }
}
