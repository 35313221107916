import { Injectable } from '@angular/core';
import { HttpApiService } from '../base/http-api.service';
import { Observable } from 'rxjs';
import { ApiResponseModel } from '../../entities/api-response.model';
import { QualityReportModel } from '../../entities/quality-report.model';

@Injectable({
  providedIn: 'root'
})
export class QualityReportService {

    constructor(
        public httpApiService: HttpApiService,
    ) {}

    public get(uid): Observable<ApiResponseModel> {
        return this.httpApiService.httpGet('quality-report/view', { uid });
    }

    public set(quality_report: QualityReportModel): Observable<ApiResponseModel> {
        return this.httpApiService.httpPost('quality-report/store', quality_report);
    }

    public delete(uid): Observable<ApiResponseModel> {
        return this.httpApiService.httpDelete('quality-report/destroy', { uid });
    }
}
