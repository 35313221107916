import {BaseModel, BaseNomenclatorModel} from './base.model';
import {FncModel} from './fnc.model';

export class StorageSpaceModel extends BaseNomenclatorModel {

    id: number;
    uid: string;
    name: string;
    storage_type_id: number;
    fnc_id: number;
    fnc: FncModel;
    storageType: StorageTypeModel;
}

export class StorageTypeModel extends BaseNomenclatorModel{

    id: number;
    uid: string;
    name: string;
}
