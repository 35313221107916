import { Component, OnInit, ViewChild } from '@angular/core';
import { SttTableComponent } from '../../../shared/components/stt-table/stt-table.component';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SttTableService } from '../../../shared/components/stt-table/stt-table-service';
import {
    RawMaterialGroupActionTableComponent
} from './raw-material-group-action-table/raw-material-group-action-table.component';
import { RawMaterialGroupModel } from '../../../shared/entities/raw-material-group.model';
import { RawMaterialGroupAddEditComponent } from './raw-material-group-add-edit/raw-material-group-add-edit.component';

@Component({
  selector: 'app-raw-material-group',
  templateUrl: './raw-material-group.component.html',
  styleUrls: ['./raw-material-group.component.scss']
})
export class RawMaterialGroupComponent implements OnInit {

    @ViewChild('table', { static: false }) table: SttTableComponent;

    public tableEndpoint = 'raw-material-groups';
    public tableActionComponent = RawMaterialGroupActionTableComponent;
    public tableQuery: any;
    public ref: DynamicDialogRef;

    constructor(public dialogService: DialogService) {
        this.tableQuery = new SttTableService();
    }

    ngOnInit(): void {

    }

    addNewEntity() {
        this.ref = this.dialogService.open(RawMaterialGroupAddEditComponent, {
            header: 'Adaugare Grup Materie prima',
            width: '30%',
            contentStyle: { 'max-height': '70vh' },
            baseZIndex: 10000,
            closeOnEscape: false,
            data: null,
        });

        this.ref.onClose.subscribe((rawMaterialGroup: RawMaterialGroupModel) => {
            if (rawMaterialGroup) {
                this.table.refresh();
            }
        });
    }

}
