import {Component, OnInit} from '@angular/core';
import {Chart} from 'chart.js';
import { DatePipe } from '@angular/common';
import { FncService } from '../../shared/services/app/fnc.service';
import { finalize, map, switchMap } from 'rxjs/operators';
import { ProductService } from '../../shared/services/app/product.service';
import { InventoryService } from '../../shared/services/app/inventory.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

    public data= [];
    public chart: any;
    public now = new Date();
    public dates = [];
    public tempdates = [];
    public loading = true;
    public products: any;
    public initialProductId = -1;

  constructor(public datePipe: DatePipe,
              public fncService: FncService,
              public productService: ProductService,
              public inventoryService: InventoryService) {}

  ngOnInit(): void {
      // this.getData().subscribe(
      //     data => this.data = data
      // );

      // this.getData();




  }

  getData(){

      this.loading = true;
      this.productService.getProductsStock()
          .pipe(finalize(() => {this.loading = false;this.createChart();}))
          .subscribe((response: any) => {
              response.payload.data.forEach(element => {
                  if(this.initialProductId !== element.product_id){
                      this.tempdates.push(element.product_id);
                      this.initialProductId = element.product_id;
                  }
              });
              this.tempdates.forEach((product, index) => {
                 response.payload.data.forEach((element, indexElement)=> {
                     if (index === 0){
                         this.data.push({label: element.lot, data: [], backgroundColor: 'blue'});
                         this.data[indexElement].data.push(element.total_units);
                     }
                     else{

                     }
                 });
                 // this.data.push({label: "da" + String(index), data: [], backgroundColor: 'blue'});
                 // this.data[index].data.push(sum);
              });
          });
  }



  createPastWeek(){
      for (let i = 8; i >= -8; i--) {
          const date =  new Date(this.now.getFullYear(), this.now.getMonth(), this.now.getDate() - i);
          this.dates.push(this.datePipe.transform(date, 'yyyy-MM-dd'));
      }
  }

    createChart(){

    this.createPastWeek();

    this.chart = new Chart("Vanzari", {
        type: 'bar', //this denotes tha type of chart

        data: {// values on X-Axis
            labels: ['Produs 1', 'Produs 2','Produs 3','Produs 4','Produs 5','Produs 6'],
            datasets: [
                {
                    label: "Sales",
                    data: ['467','0', '572', '79', '92',
                        '574', '573', '576'],
                    backgroundColor: 'blue'
                },
                {
                    label: "Profit",
                    data: ['542', '542', '536', '327', '17',
                        '0.00', '538', '541'],
                    backgroundColor: 'limegreen'
                }
            ]
        }
        ,
        options: {
            aspectRatio:2.5
        }

    });
    }

}
