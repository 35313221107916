import { BaseNomenclatorModel } from './base.model';
import {TransportCompanyModel} from './transport-company.model';
import {TransportUnitDriverModel} from './transport-unit-driver.model';

export class TransportUnitTModel extends BaseNomenclatorModel {
    uid: string;
    hasTrail = true;
    transport_company_id: number;
    transport_unit_type_id: number;
    transport_unit_driver_id: number;
    transport_unit_brand_id: number;
    transportRegistrationNumber: string;
    transportTrailRegistrationNumber: string;
    transportCompany: TransportCompanyModel;
    transportUnitDriver: TransportUnitDriverModel;
    transportUnitBrand: any;
    transportUnitType: TransportUnitTypeModel;

}

export class TransportUnitTypeModel extends BaseNomenclatorModel {
    uid: string;
    name: string;
    code: string;
}
