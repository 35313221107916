import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { HttpApiService } from '../../../../shared/services/base/http-api.service';
import { ValidationMessageService } from '../../../../shared/services/base/validation-message-handle';
import { MessageService } from 'primeng/api';
import { finalize } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { CoccidiostatsModel } from '../../../../shared/entities/coccidiostats.model';
import { CoccidiostatsService } from '../../../../shared/services/app/coccidiostats.service';

@Component({
  selector: 'app-coccibiostatics-add-edit',
  templateUrl: './coccibiostatics-add-edit.component.html',
  styleUrls: ['./coccibiostatics-add-edit.component.scss']
})
export class CoccibiostaticsAddEditComponent implements OnInit {

    public uid: string = null;
    public coccidiostatsModel: CoccidiostatsModel = new CoccidiostatsModel();
    public loading = false;
    public form: UntypedFormGroup;

    constructor(
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private formBuilder: UntypedFormBuilder,
        private httpApiService: HttpApiService,
        private validationMessageService: ValidationMessageService,
        private coccidiostatsService: CoccidiostatsService,
        private messageService: MessageService
    ) {
    }

    ngOnInit(): void {
        this.uid = (this.config.data) ? this.config.data.uid : null;

        this.createForm();

        if (this.uid) {
            this.getItem();
        }

    }

    onCloseDialog(coccidiostats: CoccidiostatsModel) {
        this.ref.close(coccidiostats);
    }

    createForm(): void {
        this.form = this.formBuilder.group({
            uid: [this.coccidiostatsModel.uid],
            name: [this.coccidiostatsModel.name, [Validators.required, Validators.maxLength(200)]],
            security_sentence: [this.coccidiostatsModel.name, [Validators.required, Validators.maxLength(800)]],
        });
    }

    getItem(): void {
        this.loading = true;
        this.coccidiostatsService.get(this.uid)
            .pipe(finalize(() => this.loading = false))
            .subscribe((response: any) => {
                this.coccidiostatsModel.map(response.payload);
                this.form.patchValue(this.coccidiostatsModel);
            });
    }


    onCancel(): void {
        this.ref.close(null);
    }

    checkIfFormValid(): boolean {
        if (this.form.invalid) {
            this.messageService.add({
                severity: 'error',
                summary: 'Formularul nu este valid!',
                detail: 'Vericficati toate campurile si incercati din nou.'
            });
            this.form.markAllAsTouched();
            return false;
        }
        return true;
    }

    onSave(): void {
        if (!this.checkIfFormValid()) {
            return;
        }
        this.loading = true;
        const data = this.form.getRawValue();
        this.coccidiostatsService.set(data)
            .pipe(finalize(() => this.loading = false))
            .subscribe((response: any) => {
                this.coccidiostatsModel.map(response.payload);
                this.form.patchValue(this.coccidiostatsModel);
                this.messageService.add({
                    severity: 'success',
                    summary: 'Coccidiostatica a fost salvat cu success!'
                });
                this.ref.close(this.coccidiostatsModel);
            }, err => {
                if (err instanceof HttpErrorResponse) {
                    this.validationMessageService.serverSideValidation(err, this.form, '', true);
                }
            });
    }
}
