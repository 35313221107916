import { Component, OnInit } from '@angular/core';
import { SttTableComponent } from '../../../../shared/components/stt-table/stt-table.component';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Router } from '@angular/router';
import { LossTicketModel } from '../../../../shared/entities/loss-ticket.model';
import { finalize } from 'rxjs/operators';
import { ConfirmationService, MessageService, PrimeNGConfig } from 'primeng/api';
import { ValidationMessageService } from '../../../../shared/services/base/validation-message-handle';
import { LossTicketService } from '../../../../shared/services/app/loss-ticket.service';

@Component({
  selector: 'app-loss-ticket-action-table',
  templateUrl: './loss-ticket-action-table.component.html',
  styleUrls: ['./loss-ticket-action-table.component.scss']
})
export class LossTicketActionTableComponent implements OnInit {

    public data: LossTicketModel;
    public table: SttTableComponent;
    public ref: DynamicDialogRef;
    public loading = true;

    constructor(
        public dialogService: DialogService,
        public router: Router,
        public confirmationService: ConfirmationService,
        public primengConfig: PrimeNGConfig,
        public itemService: LossTicketService,
        public messageService: MessageService,
        private validationMessageService: ValidationMessageService,
    ) {

    }

    ngOnInit(): void {
    }

    setParams(entity: LossTicketModel): void {
        this.data = entity;
    }

    setTableReference(table: SttTableComponent): void
    {
        this.table = table;
    }

    showDialog(){
        this.router.navigate(
            ['stock-management/loss-ticket/add-edit'],
            { queryParams: { uid: this.data.uid } }
        );
    }

    confirm() {
        this.confirmationService.confirm({
            header: 'Confirmare stergere Bon consum',
            message: 'Sunteti sigur ca doriti sa stergeti acest bon de consum?',
            key: 'deleteLossTicket',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Da',
            rejectLabel: 'Nu',
            acceptButtonStyleClass: 'p-button-md p-button-success',
            rejectButtonStyleClass: 'p-button-md p-button-danger',
            accept: () => {
                this.deleteItem(this.data.uid);
            },
            reject: () => {
                this.confirmationService.close();
                this.table.refresh();
            }
        });
    }

    deleteItem(uid: string){
        this.itemService.delete(uid)
            .pipe(finalize(() => {this.loading = false;this.table.refresh();}))
            .subscribe((response: any) => {
                this.messageService.add({
                    severity: 'success',
                    summary: 'Bonul de consum a fost sters cu success!'
                });
            });
    }

}
