import { Injectable } from '@angular/core';
import { HttpApiService } from '../base/http-api.service';
import { Observable } from 'rxjs';
import { ApiResponseModel } from '../../entities/api-response.model';
import { ReceptionNoteModel } from '../../entities/reception-note.model';

@Injectable({
    providedIn: 'root'
})
export class ReceptionNoteService {

    constructor(
        public httpApiService: HttpApiService,
    ) {

    }

    public getOrder(uid): Observable<ApiResponseModel> {
        return this.httpApiService.httpGet('raw-material-orders/view', { uid });
    }

    public get(uid): Observable<ApiResponseModel> {
        return this.httpApiService.httpGet('reception-notes/view', { uid });
    }

    public set(item: ReceptionNoteModel): Observable<ApiResponseModel> {
        return this.httpApiService.httpPost('reception-notes/store', item);
    }

    public delete(uid): Observable<ApiResponseModel> {
        return this.httpApiService.httpDelete('reception-notes/destroy', { uid });
    }

    public statusChange(status: number, uid): Observable<ApiResponseModel> {
        return this.httpApiService.httpGet('reception-notes/change-status', {status, uid});
    }
}
