import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpApiService } from '../base/http-api.service';
import { ApiResponseModel } from '../../entities/api-response.model';
import { RawMaterialModel } from '../../entities/raw-material.model';
import { TableService } from 'primeng/table';

@Injectable({
    providedIn: 'root'
})
export class RawMaterialService {

    constructor(
        public httpApiService: HttpApiService,
    ) {

    }

    public get(uid): Observable<ApiResponseModel> {
        return this.httpApiService.httpGet('raw-materials/view', { uid });
    }

    public set(product: RawMaterialModel): Observable<ApiResponseModel> {
        return this.httpApiService.httpPost('raw-materials/store', product);
    }

    public getList(params?: TableService): Observable<ApiResponseModel> {
        return this.httpApiService.httpGet('raw-materials', params);
    }

    public delete(uid): Observable<ApiResponseModel> {
        return this.httpApiService.httpDelete('raw-materials/destroy', { uid });
    }
}
