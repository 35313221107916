import { Component, OnInit } from '@angular/core';
import { SttTableComponent } from '../../../../../shared/components/stt-table/stt-table.component';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CustomersAddEditComponent } from '../../../customers/customers-add-edit/customers-add-edit.component';
import { TransportCompanyModel } from '../../../../../shared/entities/transport-company.model';
import { TransportCompanyAddEditComponent } from '../transport-company-add-edit/transport-company-add-edit.component';
import {
    TransportUnitAddEditComponent
} from '../../transport-units/transport-unit-add-edit/transport-unit-add-edit.component';
import { TransportUnitTModel } from '../../../../../shared/entities/transport-unit.model';
import { ConfirmationService, MessageService, PrimeNGConfig } from 'primeng/api';
import { ValidationMessageService } from '../../../../../shared/services/base/validation-message-handle';
import { TransportCompanyService } from '../../../../../shared/services/app/transport-company.service';
import { finalize } from 'rxjs/operators';

@Component({
    selector: 'app-transport-company-table-action',
    templateUrl: './transport-company-table-action.component.html',
    styleUrls: ['./transport-company-table-action.component.scss']
})
export class TransportCompanyTableActionComponent implements OnInit {

    public data: any;
    public table: SttTableComponent;
    public ref: DynamicDialogRef;
    public loading = true;

    constructor(
        public dialogService: DialogService,
        public confirmationService: ConfirmationService,
        public primengConfig: PrimeNGConfig,
        public itemService: TransportCompanyService,
        public messageService: MessageService,
        private validationMessageService: ValidationMessageService,
    ) {

    }

    ngOnInit(): void {
    }

    setParams(entity: TransportCompanyModel): void {
        this.data = entity;
    }

    setTableReference(table: SttTableComponent): void {
        this.table = table;
    }

    showDialog(): void {
        this.ref = this.dialogService.open(TransportCompanyAddEditComponent, {
            header: 'Modificare client',
            width: '70%',
            contentStyle: { 'max-height': '70vh' },
            baseZIndex: 10000,
            closeOnEscape: false,
            data: this.data,
        });

        this.ref.onClose.subscribe((transportCompany: CustomersAddEditComponent) => {
            if (transportCompany) {
                this.table.refresh();
            }
        });
    }

    showDialogTransportUnit(): void {
        this.ref = this.dialogService.open(TransportUnitAddEditComponent, {
            header: 'Adaugare unitate de transport',
            width: '70%',
            contentStyle: { 'max-height': '70vh' },
            baseZIndex: 10000,
            closeOnEscape: false,
            data: {
                uid: null,
                companyId: this.data.id,
            },
        });

        this.ref.onClose.subscribe((item: TransportUnitTModel) => {
            if (item) {
                this.table.refresh();
            }
        });
    }

    confirm() {
        this.confirmationService.confirm({
            header: 'Confirmare stergere Companie de transport',
            message: 'Sunteti sigur ca doriti sa stergeti aceasta companie de transport?',
            key: 'deleteTransportCompany',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Da',
            rejectLabel: 'Nu',
            acceptButtonStyleClass: 'p-button-md p-button-success',
            rejectButtonStyleClass: 'p-button-md p-button-danger',
            accept: () => {
                this.deleteItem(this.data.uid);
            },
            reject: () => {
                this.confirmationService.close();
                this.table.refresh();
            }
        });
    }

    deleteItem(uid: string){
        this.itemService.delete(uid)
            .pipe(finalize(() => {this.loading = false;this.table.refresh();}))
            .subscribe((response: any) => {
                this.messageService.add({
                    severity: 'success',
                    summary: 'Compania de transport a fost stearsa cu success!'
                });
            });
    }
}
