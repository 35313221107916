<a routerLink="/orders/customer/list" style="color: #808080;font-size: 18px">
    <i class="fas fa-arrow-left"></i>
    <span>Inapoi la lista de comenzi</span>
</a>
<!--<!<a routerLink="/orders/customer/list" style="color: #808080;font-size: 18px"> this is interesting worth a talk!!!!!!!!!!!!!-->
<p-divider></p-divider>
<div class="flex-box" style="display:flex;justify-content:space-between;">
    <p style="font-size: 20px">Comenzi  /  Client  /  Mapare Transport</p>
</div>
<p-divider></p-divider>



<div class="grid">
    <div class="col-12">
        <div class="card">
            <form [formGroup]="form">
                <div class="grid fields-group" style="padding: 0 1em">
                    <span style="font-size: 17px">Filtrare Interval</span>
                    <div class="col-12" style="margin-top: 1em">
                        <div class="grid p-fluid">
                            <div class="field col-12 md:col-2">
                                <app-stt-calendar label="De la" icon="pi pi-calendar"
                                                  [control]="form.controls['startDate']"></app-stt-calendar>
                            </div>
                            <div class="field col-12 md:col-2">
                                <app-stt-calendar label="Pana la" icon="pi pi-calendar"
                                                  [control]="form.controls['endDate']"></app-stt-calendar>
                            </div>
                            <div class="field col-12 md:col-1">
                                <button pButton pRipple type="submit" label="Submit" class="p-button-raised p-button-success" (click)="setDateFilter()">
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <br>
            <app-stt-table #table
                           [requiresFNC]="true" [expandComponent]="expandComponent"
                           [endpoint]="tableEndpoint" [startDate]="this.form.value.startDate" [endDate]="this.form.value.endDate"
                           [query]="tableQuery">

            </app-stt-table>
        </div>
    </div>
</div>
