<p style="color: red">Valorile prezente nu pot fi modificate. Pentru a efectua modificari folositi butonul de
    editare.</p>
<form [formGroup]="form" autocomplete="off" (ngSubmit)="onGenerate()">
    <h5>Detalii comanda</h5>
    <p-divider></p-divider>
    <div class="grid fields-group">
        <div class="col-12">
            <div class="grid p-fluid">
                <div class="field col-12 md:col-6">
                    <app-stt-input inputId="code" [control]="form.controls['code']" [readonly]="true"
                                   icon="pi pi-key" label="Cod SAP" type="text"></app-stt-input>
                </div>
                <div class="field col-12 md:col-6">
                    <app-stt-dropdown endPoint="customers"
                                      [formControlParent]="form.controls['customer_id']"
                                      [readonly]="true" icon="pi pi-user"
                                      label="Client">
                    </app-stt-dropdown>
                </div>
            </div>
            <div class="grid p-fluid">
                <div class="field col-12 md:col-6">
                    <app-stt-dropdown
                        endPoint="customers/getAddresses"
                        [fields]="{ text: 'address', value: 'id'}"
                        [readonly]="true" icon="pi pi-home"
                        [formControlParent]="form.controls['customer_address_id']"
                        label="Adresa de livrare">
                    </app-stt-dropdown>
                </div>
                <div class="field col-12 md:col-6">
                    <app-stt-calendar label="Data" icon="pi pi-calendar"
                                      [control]="form.controls['deliveryDate']"></app-stt-calendar>
                </div>
            </div>
            <div class="grid p-fluid">
                <div class="field col-12 md:col-6">
                    <app-stt-dropdown endPoint="products"
                                      [formControlParent]="form.controls['product_id']"
                                      label="Produs" [readonly]="true" icon="pi pi-check-square">
                    </app-stt-dropdown>
                </div>
                <div class="field col-12 md:col-6">
                    <app-stt-dropdown endPoint="product-categories"
                                      [formControlParent]="form.controls['product_category_id']"
                                      label="Categorie produs" [readonly]="true" icon="pi pi-th-large">
                    </app-stt-dropdown>
                </div>
            </div>
            <div class="grid p-fluid">
                <div class="field col-12 md:col-12">
                    <app-stt-dropdown placeholder="Nu ati selectat un FNC!" endPoint="fncs"
                                      icon="pi pi-cog" [readonly]="true"
                                      [formControlParent]="form.controls['fnc_id']" label="Fnc"></app-stt-dropdown>
                </div>
            </div>
        </div>
    </div>

    <h5>Detalii transport</h5>
    <p-divider></p-divider>
    <div class="grid fields-group">
        <div class="col-12">
            <div class="grid p-fluid">
                <div class="field col-12 md:col-6">
                    <app-stt-dropdown
                        endPoint="transport-companies"
                        [readonly]="true" icon="pi pi-building"
                        [formControlParent]="form.controls['transport_company_id']"
                        label="Companie Transport">
                    </app-stt-dropdown>
                </div>
                <div class="field col-12 md:col-6">
                    <app-stt-dropdown
                        endPoint="transport-unit-types" [readonly]="true" icon="pi pi-car"
                        [formControlParent]="form .controls['transport_unit_type_id']"
                        label="Tip unitate Transport">
                    </app-stt-dropdown>
                </div>
            </div>
            <div class="grid p-fluid">
                <div class="field col-12 md:col-6">
                    <app-stt-dropdown
                        endPoint="transport-unit-drivers" [readonly]="true" icon="pi pi-user"
                        [fields]=" { text: 'firstName', value: 'id'}"
                        [formControlParent]="form.controls['transport_unit_driver_id']"
                        label="Sofer Transport">
                    </app-stt-dropdown>
                </div>
                <div class="field col-12 md:col-6">
                    <app-stt-input inputId="transport_quantity" [control]="form.controls['transport_quantity']"
                                   [readonly]="true"
                                   icon="pi pi-box" label="Cantitate pe transport" type="number"></app-stt-input>
                </div>
            </div>
        </div>
    </div>

    <div class="actions-area">
        <div class="actions-left">
            <button pButton pRipple type="button" label="Anulare" class="p-button-raised p-button-danger"
                    icon="pi pi-ban" (click)="onCancel()">
            </button>
        </div>

        <div class="actions-right">
            <button pButton pRipple type="submit" label="Genereaza" class="p-button-raised p-button-success"
                    icon="pi pi-save">
            </button>
        </div>
    </div>
</form>
