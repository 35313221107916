import { Component, OnInit } from '@angular/core';
import {SttTableComponent} from '../../../../shared/components/stt-table/stt-table.component';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {FncModel} from '../../../../shared/entities/fnc.model';
import {FncAddEditComponent} from '../fnc-add-edit/fnc-add-edit.component';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import { finalize } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { FncService } from '../../../../shared/services/app/fnc.service';
import { ValidationMessageService } from '../../../../shared/services/base/validation-message-handle';

@Component({
  selector: 'app-fnc-table-action',
  templateUrl: './fnc-table-action.component.html',
  styleUrls: ['./fnc-table-action.component.scss']
})
export class FncTableActionComponent implements OnInit {

    public data: FncModel;
    public table: SttTableComponent;
    public ref: DynamicDialogRef;
    public loading = true;

    constructor(
        public dialogService: DialogService,
        public confirmationService: ConfirmationService,
        public primengConfig: PrimeNGConfig,
        public itemService: FncService,
        public messageService: MessageService,
        private validationMessageService: ValidationMessageService,
    ) {

    }

    ngOnInit(): void {
        this.primengConfig.ripple = true;
    }

    setParams(entity: FncModel): void {
        this.data = entity;
    }

    setTableReference(table: SttTableComponent): void
    {
        this.table = table;
    }


    showDialog() {
        this.ref = this.dialogService.open(FncAddEditComponent, {
            header: 'Modificare FNC',
            width: '70%',
            contentStyle: {'max-height': '70vh'},
            baseZIndex: 10000,
            closeOnEscape: false,
            data: this.data,
        });

        this.ref.onClose.subscribe((fnc: FncModel) => {
            if (fnc) {
                this.table.refresh();
            }
        });
    }


    confirm() {
        this.confirmationService.confirm({
            header: 'Confirmare stergere FNC',
            message: 'Sunteti sigur ca doriti sa stergeti acest FNC?',
            key: 'deleteFNC',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Da',
            rejectLabel: 'Nu',
            acceptButtonStyleClass: 'p-button-md p-button-success',
            rejectButtonStyleClass: 'p-button-md p-button-danger',
            accept: () => {
                this.deleteItem(this.data.uid);
            },
            reject: () => {
                this.confirmationService.close();
                this.table.refresh();
            }
        });
    }

    deleteItem(uid: string){
        this.itemService.delete(uid)
            .pipe(finalize(() => {this.loading = false;this.table.refresh();}))
            .subscribe((response: any) => {
                this.messageService.add({
                    severity: 'success',
                    summary: 'FNC-ul a fost sters cu success!'
                });
            });
    }
}
