import { Component, OnInit, ViewChild } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SttTableComponent } from '../../../shared/components/stt-table/stt-table.component';
import { SttTableService } from '../../../shared/components/stt-table/stt-table-service';
import { SuppliersAddEditComponent } from './suppliers-add-edit/suppliers-add-edit.component';
import { SupplierModel } from '../../../shared/entities/supplier.model';
import { SuppliersActionTableComponent } from './suppliers-action-table/suppliers-action-table.component';
import { SupplierService } from '../../../shared/services/app/supplier.service';

@Component({
    selector: 'app-suppliers',
    templateUrl: './suppliers.component.html',
    styleUrls: ['./suppliers.component.scss']
})
export class SuppliersComponent implements OnInit {

    @ViewChild('table', { static: false }) table: SttTableComponent;

    public tableEndpoint = 'suppliers';
    public tableActionComponent = SuppliersActionTableComponent;
    public tableQuery: any;
    public ref: DynamicDialogRef;

    constructor(public dialogService: DialogService) {
        this.tableQuery = new SttTableService()
            .addInclude('county')
            .addInclude('city');
    }

    ngOnInit(): void {

    }

    addNewEntity() {
        this.ref = this.dialogService.open(SuppliersAddEditComponent, {
            header: 'Adaugare Furnizor',
            width: '70%',
            contentStyle: { 'max-height': '70vh' },
            baseZIndex: 10000,
            closeOnEscape: false,
            data: null,
        });

        this.ref.onClose.subscribe((supplier: SupplierModel) => {
            if (supplier) {
                this.table.refresh();
            }
        });
    }
}
