import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpApiService } from '../base/http-api.service';
import { ApiResponseModel } from '../../entities/api-response.model';
import { RecipeModel } from '../../entities/recipe.model';

@Injectable({
  providedIn: 'root'
})
export class RecipeService {
  constructor(
    public httpApiService: HttpApiService,
) {

}

    public get(uid): Observable<ApiResponseModel>
    {
        return this.httpApiService.httpGet('recipes/view', {uid});
    }

    public set(item: RecipeModel): Observable<ApiResponseModel>
    {
        return this.httpApiService.httpPost('recipes/store', item);
    }

    public importFile(file: any): Observable<ApiResponseModel>
    {
        return this.httpApiService.httpPost('recipes/import-file', file);
    }

    public getRecipesByProductId(productId): Observable<ApiResponseModel>
    {
        return this.httpApiService.httpGet('recipes/view-by-product', {productId});
    }

    public getRecipesById(id): Observable<ApiResponseModel>
    {
        return this.httpApiService.httpGet('recipes/view-by-id', {id});
    }

    public getStockOnRawMaterial(id): Observable<ApiResponseModel>
    {
        return this.httpApiService.httpGet('stock/view-by-id', {id});
    }

    public delete(uid): Observable<ApiResponseModel> {
        return this.httpApiService.httpDelete('recipes/destroy', { uid });
    }
}
