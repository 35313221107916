import { Injectable } from '@angular/core';
import { HttpApiService } from '../base/http-api.service';
import { Observable } from 'rxjs';
import { ApiResponseModel } from '../../entities/api-response.model';
import { InventoryModel } from '../../entities/inventory.model';

@Injectable({
  providedIn: 'root'
})
export class  InventoryService {

    constructor(
        public httpApiService: HttpApiService,
    ) {

    }

    public get(uid): Observable<ApiResponseModel>
    {
        return this.httpApiService.httpGet('inventory/view', {uid});
    }

    public getRawMaterialStock(rawMaterialId, fncId): Observable<ApiResponseModel>
    {
        return this.httpApiService.httpGet('inventory/view-raw-material-stock', {rawMaterialId, fncId});
    }

    public getProductStock(productId, lot): Observable<ApiResponseModel> {
        return this.httpApiService.httpGet('inventory/view-product-stock', {productId, lot});
    }

    public set(item: InventoryModel): Observable<ApiResponseModel>
    {
        return this.httpApiService.httpPost('inventory/store', item);
    }

    public delete(uid): Observable<ApiResponseModel> {
        return this.httpApiService.httpDelete('inventory/destroy', { uid });
    }

    public statusChange(status: number, uid): Observable<ApiResponseModel> {
        return this.httpApiService.httpGet('inventory/change-status', {status, uid});
    }

}
