import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpApiService } from '../base/http-api.service';
import { ApiResponseModel } from '../../entities/api-response.model';
import { TransportCompanyTypesModel } from '../../entities/transport-company-type.model';

@Injectable({
    providedIn: 'root'
})
export class TransportCompanyTypeService {

    constructor(
        public httpApiService: HttpApiService,
    ) {

    }

    public get(uid): Observable<ApiResponseModel> {
        return this.httpApiService.httpGet('transport-company-types/view', { uid });
    }

    public set(transportCompanyType: TransportCompanyTypesModel): Observable<ApiResponseModel> {
        return this.httpApiService.httpPost('transport-company-types/store', transportCompanyType);
    }

    public delete(uid): Observable<ApiResponseModel> {
        return this.httpApiService.httpDelete('transport-company-types/destroy', { uid });
    }
}
