import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-stt-table-field-filters',
  templateUrl: './stt-table-field-filters.component.html',
  styleUrls: ['./stt-table-field-filters.component.scss']
})
export class SttTableFieldFiltersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
