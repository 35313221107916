import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormGroup, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem, MessageService } from 'primeng/api';
import { finalize, mergeMap, switchMap, map, tap } from 'rxjs/operators';
import { ProductionNoteOpenOrderModel } from '../../../../shared/entities/production-note-open-order.model';
import { ProductionNoteRawMaterialModel } from '../../../../shared/entities/production-note-raw-material.model';
import { ProductionNoteModel } from '../../../../shared/entities/production-note.model';
import { ProductionNoteService } from '../../../../shared/services/app/production-note.service';
import { HttpApiService } from '../../../../shared/services/base/http-api.service';
import { ValidationMessageService } from '../../../../shared/services/base/validation-message-handle';
import { OrderCustomerService } from '../../../../shared/services/app/order-customer.service';
import { RecipeService } from '../../../../shared/services/app/recipe.service';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ProductService } from '../../../../shared/services/app/product.service';
import { FncService } from '../../../../shared/services/app/fnc.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import {
    ProductionNoteLiveDosingComponent
} from '../production-note-live-dosing/production-note-live-dosing.component';

enum Status {
    Draft = 0,
    Validated = 1,
    Finished = 2
}

@Component({
    selector: 'app-production-note-add-edit',
    templateUrl: './production-note-add-edit.component.html',
    styleUrls: ['./production-note-add-edit.component.scss']
})
export class ProductionNoteAddEditComponent implements OnInit {
    public uid: string = null;
    public data: any;
    public dataDialog: ProductionNoteModel;

    public items: MenuItem[] = [];
    public currentTab = 0;
    public lastTabIndex = 2;

    public itemModel: ProductionNoteModel = new ProductionNoteModel();
    public loading = false;
    public form: UntypedFormGroup;
    public minimumDate: Date = new Date();
    public fnc_id: number;
    public firstTimeTabIndexZero = true;
    public dropDownWithTrashed = false;
    public status: number;
    public readOnly = false;
    public showCalendar = true;
    public enumStatus = Status;
    public ref: DynamicDialogRef;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private httpApiService: HttpApiService,
        private validationMessageService: ValidationMessageService,
        private itemService: ProductionNoteService,
        private messageService: MessageService,
        private route: ActivatedRoute,
        private router: Router,
        private customerOrderService: OrderCustomerService,
        private recipeService: RecipeService,
        private productService: ProductService,
        private fncService: FncService,
        public dialogService: DialogService
    ) {
        this.uid = this.route.snapshot.queryParams.uid;
    }

    ngOnInit(): void {
        this.items = [
            { label: 'Pasul 1' },
            { label: 'Pasul 2' },
            { label: 'Pasul 3' }
        ];

        // Get fnc_id of the user from local storage
        const localStorageData = JSON.parse(localStorage.getItem('user'));
        this.fnc_id = localStorageData.fnc_id;

        this.createForm();

        // If fnc_id gets updated set the capacity(sarja) field
        this.form.controls.fnc_id.valueChanges.subscribe(value => {
            if(value){
                this.fncService.getById(value)
                    .pipe(finalize(() => this.loading = false))
                    .subscribe((response: any) => {
                        this.form.patchValue({
                            sarja: response.payload.sarja,
                        });
                    });
            }
        });

        // Map fnc_id instead of initialize it so that valueChanges gets called
        this.form.patchValue({
            fnc_id: this.fnc_id,
        });

        if (this.uid) {
            this.getItem().subscribe(
                data => this.data = data
            );
        }



        // this.productChanges();

    }

    patchRecipeItem(element: any, index: number){
        this.formProductionNoteRawMaterials().at(index).patchValue({
            raw_material_id_recipe: element.raw_material_id,
            currentStock_recipe: 5,
            requiredQuantity_recipe: element.units,
            raw_material_id: element.raw_material_id,
            current_stock_raw_material: 5,
            units: element.units,
        });
    }

    patchOrder(element: any, index: number): void {
        const dataParse = new Date(element.deliveryDate);
        this.formProductionNoteOrders().at(index).patchValue({
            uid: element.uid,
            customer_order_id: element.customer_order_id,
            customer_item_id: element.id,
            code: element.customerOrder.code,
            deliveryDate: dataParse,
            quantity: element.quantity
        });

    }

    onRawMaterialChange(event, index){
        let fncId = this.form.value.fnc_id;
        if (!this.form.value.fnc_id){
            fncId = this.fnc_id;
        }
        if(event !== undefined){
            this.loading = true;
            this.itemService.getRawMaterialStock(event.id, fncId)
                .pipe(finalize(() => this.loading = false))
                .subscribe((response: any) => {
                    this.formProductionNoteRawMaterials().at(index).patchValue({
                        current_stock_raw_material: response.payload.total_stock
                    });
                });
        }

    }

    onProductChange(event: any){
        // The dropdown will be read only but just to e sure :)))
        if (this.uid){
            return ;
        }
        this.formProductionNoteOrders().clear();
        this.formProductionNoteRawMaterials().clear();
        this.form.patchValue({
            suggestedQuantity: null,
        });
        if (event !== undefined || event) {
            this.getItemOnProductChange(event).subscribe(
                data => this.data = data
            );
        }
    }

    getItemOnProductChange(event: any){
        let fncId = this.form.value.fnc_id;
        if (!this.form.value.fnc_id){
            fncId = this.fnc_id;
        }
        const productId = event.id;
        const recipeId = event.recipe_id;
        this.loading = true;
        return this.recipeService.getRecipesById(recipeId).pipe(
            switchMap(recipe => this.customerOrderService.getOrdersByProductId(productId).pipe(
                switchMap(orders => this.itemService.getProductStock(productId, this.form.value.fnc_id).pipe(
                    switchMap(productStock => this.itemService.getRecipeRawMaterialStock(recipe.payload.id, fncId).pipe(
                                map(recipeStock => this.mapFormOnProductChange(recipe, orders, productStock, recipeStock))
                    ))
                ))
            )),
            tap(() => this.loading = false)
        );
    }

    mapFormOnProductChange(recipe: any, orders: any, productStock: any, recipeStock: any){
        orders.payload.forEach((order, index) => {
            this.addOpenOrder();
            this.patchOrder(order, index);
        });

        this.form.patchValue({
            current_stock: productStock.payload,
            recipe_id: recipe.payload.id
        });

        recipe.payload.items.forEach((element, index) => {
            let stock;
            recipeStock.payload.forEach(rawMaterialStock => {
                if (rawMaterialStock.raw_material_id === element.raw_material_id){
                    stock = rawMaterialStock.total_stock;
                }
            });
            this.addRawMaterial();
            this.formProductionNoteRawMaterials().at(index).patchValue({
                raw_material_id_recipe: element.raw_material_id,
                currentStock_recipe: stock,
                requiredQuantity_recipe: element.units * this.form.value.production_quantity,
                raw_material_id: element.raw_material_id,
                current_stock_raw_material: stock,
                units: element.units * this.form.value.production_quantity
            });
        });
    }


    createForm(): void {
        this.form = this.formBuilder.group({
            uid: [this.itemModel.uid],
            code: [this.itemModel.code, [Validators.required]],
            documentNo: [this.itemModel.documentNo, [Validators.required, Validators.maxLength(255)]],
            product_id: [this.itemModel.product_id, [Validators.required]],
            lot: [this.itemModel.lot, [Validators.required]],
            recipe_id: [null],
            fnc_id: [this.itemModel.fnc_id, [Validators.required]],
            startDate: [this.itemModel.startDate],
            endDate: [this.itemModel.endDate],
            sarja: [this.itemModel.sarja],

            selectedOrderItems: this.formBuilder.array([]),
            recipeItems: this.formBuilder.array([]),
            production_quantity: [1, [Validators.required]],
            current_stock: [null],
        });
    }

    formProductionNoteOrders(): UntypedFormArray {
        return this.form.get('selectedOrderItems') as UntypedFormArray;
    }

    formProductionNoteRawMaterials(): UntypedFormArray {
        return this.form.get('recipeItems') as UntypedFormArray;
    }


    addOpenOrder(item?: ProductionNoteOpenOrderModel): void {
        const openOrder = this.formBuilder.group({
            uid: [null],
            production_note_id: [(item) ? item.production_note_id : null],
            customer_order_id: [(item) ? item.customer_order_id : null],
            customer_item_id: [(item) ? item.customer_item_id : null],
            code: [(item) ? item.code : null],
            selected: [(item) ? item.selected : null],
            deliveryDate: [(item) ? item.deliveryDate : null],
            quantity: [(item) ? item.quantity : null]
        });
        this.formProductionNoteOrders().push(openOrder);
    }

    addRawMaterial(item?: ProductionNoteRawMaterialModel): void {
        const rawMaterial = this.formBuilder.group({
            uid: [null],
            production_note_id: [(item) ? item.production_note_id : null],
            raw_material_id_recipe: [(item) ? item.raw_material_id : null],
            currentStock_recipe: [(item) ? item.currentStock : null],
            requiredQuantity_recipe: [(item) ? item.requiredQuantity : null],
            raw_material_id: [(item) ? item.raw_material_id : null],
            current_stock_raw_material: [(item) ? item.currentStock : null],
            units: [null],
        });
        this.formProductionNoteRawMaterials().push(rawMaterial);
    }



    getItem() {
        let fncId = this.form.value.fnc_id;
        if (!this.form.value.fnc_id){
            fncId = this.fnc_id;
        }
        this.loading = true;
        this.dropDownWithTrashed = true;
        return this.itemService.get(this.uid).pipe(
            switchMap(productioNote => this.customerOrderService.getOrdersByProductId(productioNote.payload.product_id).pipe(
                switchMap(orders => this.itemService.getProductStock(productioNote.payload.product_id, null).pipe(
                    switchMap(productStock => this.productService.getById(productioNote.payload.product_id).pipe(
                        switchMap(product => this.recipeService.getRecipesById(product.payload.recipe_id).pipe(
                            switchMap(recipes => this.itemService.getRecipeRawMaterialStock(productioNote.payload.product_id, fncId).pipe(
                                switchMap(recipeStock => this.itemService.getPNRawMaterialStock(recipes.payload.id).pipe(
                                        map(newRecipeStock => this.mapForm(orders, recipes, productioNote, productStock, recipeStock, newRecipeStock))
                                ))
                            ))
                        ))
                    ))
                ))
            )),
            tap(() => this.loading = false)
        );

    }


    mapForm(orders: any, recipe: any, productioNote: any, productStock: any, recipeStock: any , newRecipeStock: any): any {

        this.itemModel.map(productioNote.payload);
        // Map form data
        this.form.patchValue(this.itemModel);
        this.form.patchValue({
            current_stock: productioNote.payload.current_stock,
            recipe_id: recipe.payload.id
        });

        let startDate = null;
        let endDate = null;
        if(this.itemModel.startDate){
            startDate = new Date(this.itemModel.startDate);
        }
        if (this.itemModel.endDate){
            endDate = new Date(this.itemModel.endDate);
        }
        this.form.patchValue({
            startDate,
            endDate
        });

        // Map order items and selected order items
        // orders.payload.forEach((order, index) => {
        //     let isSelected = false;
        //     productioNote.payload.productionNoteCustomerOrderItem.forEach(selectedOrder => {
        //         if (selectedOrder.customer_item_id === order.id){
        //             isSelected = true;
        //         }
        //     });
        //     if (isSelected){
        //         this.addOpenOrder();
        //         this.patchOrder(order, index);
        //         this.formProductionNoteOrders().at(index).patchValue({selected: true});
        //     }
        // });

        productioNote.payload.productionNoteCustomerOrderItem.forEach((selectedOrder, index) => {
            this.addOpenOrder();
            this.patchProductionNoteOrders(selectedOrder, index);
            this.formProductionNoteOrders().at(index).patchValue({selected: true});
        });


        // Map recipe items and custom recipe items
        productioNote.payload.productionNoteItems.forEach((element, index) => {
            this.addRawMaterial();
            this.formProductionNoteRawMaterials().at(index).patchValue({
                uid: element.uid,
                production_note_id: element.production_note_id,
                raw_material_id: element.raw_material_id,
                current_stock_raw_material: element.current_stock_raw_material,
                units: element.units
            });
        });
        recipe.payload.items.forEach((element, index) => {
            let stock;
            if (index <= this.formProductionNoteRawMaterials().value.length){
                recipeStock.payload.forEach(rawMaterialStock => {
                    if (rawMaterialStock.raw_material_id === element.raw_material_id){
                        stock = rawMaterialStock.total_stock;
                    }
                });
                this.formProductionNoteRawMaterials().at(index).patchValue({
                    raw_material_id_recipe: element.raw_material_id,
                    currentStock_recipe: stock,
                    requiredQuantity_recipe: element.units
                });
            }
        });
        this.status = productioNote.payload.status;
        if(this.status > Status.Draft){
            this.readOnly = true;
            this.showCalendar = false;
        }
        // this.calculateQuantities();

        return this.form;
    }

    patchProductionNoteOrders(element: any, index: number){
        const dataParse = new Date(element.deliveryDate);
        this.formProductionNoteOrders().at(index).patchValue({
            uid: element.uid,
            customer_order_id: element.customer_order_id,
            customer_item_id: element.customer_item_id,
            code: element.code,
            deliveryDate: dataParse,
            quantity: element.quantity
        });
    }





    deleteFormItem(index: number): void {
        this.formProductionNoteRawMaterials().removeAt(index);
    }

    nextStep(): void {
        if (this.currentTab < this.lastTabIndex) {
            this.currentTab++;
            this.firstTimeTabIndexZero = true;
        }
        // The recommended value of production_quantity should be mapped only the first time
        if (this.firstTimeTabIndexZero){
            let quantity = 0;
            this.formProductionNoteOrders().value.forEach(element => {
                if (element.selected) {
                    quantity = quantity + element.quantity;
                }
            });
            if (quantity !== 0){
                this.form.patchValue({
                    production_quantity: quantity,
                });
                this.calculateQuantities();
            }
        }
    }

    previousStep(): void {
        if (this.currentTab > 0) {
            this.currentTab--;
        }
    }

    calculateQuantities(){
        const production_quantity = this.form.value.production_quantity;
        this.formProductionNoteRawMaterials().value.forEach((element, index) => {
            if (element.raw_material_id_recipe){
                this.formProductionNoteRawMaterials().at(index).patchValue({
                    units: production_quantity * element.requiredQuantity_recipe
                });
            }
        });
    }

    prepareFormBeforeSave(){
        const ordersSelected = [];
        this.formProductionNoteOrders().value.forEach(element => {
            if (element.selected) {
                ordersSelected.push(element);
            }
        });
        if (ordersSelected.length > 0){
            this.formProductionNoteOrders().clear();
            ordersSelected.forEach((element, index) => {
                this.addOpenOrder();
                this.formProductionNoteOrders().at(index).patchValue({
                    customer_order_id: element.customer_order_id,
                    customer_item_id: element.customer_item_id,
                    code: element.code,
                    deliveryDate: element.deliveryDate,
                    quantity: element.quantity,
                    selected: true
                });
            });
        }
        else{
            this.formProductionNoteOrders().clear();
        }
    }

    checkIfFormValid(): boolean {
        if (this.form.invalid) {
            this.messageService.add({
                severity: 'error',
                summary: 'Formularul nu este valid!',
                detail: 'Vericficati toate campurile si incercati din nou.'
            });
            this.form.markAllAsTouched();
            return false;
        }
        return true;
    }

    onSave(): void {
        if(!this.uid){
            this.prepareFormBeforeSave();
        }
        if (!this.checkIfFormValid()) {
            return;
        }
        this.loading = true;
        const data = this.form.getRawValue();
        this.itemService.set(data)
            .pipe(finalize(() => this.loading = false))
            .subscribe((response: any) => {
                this.itemModel.map(response.payload);
                this.form.patchValue(this.itemModel);
                this.messageService.add({
                    severity: 'success',
                    summary: 'Nota de productie a fost salvata cu success!'
                });
                this.router.navigate(['stock-management/production-note/list'], {});
            }, err => {
                if (err instanceof HttpErrorResponse) {
                    this.validationMessageService.serverSideValidation(err, this.form, '', true);
                }
            });
    }

    validateForm(){
        this.status = this.enumStatus.Validated;
        const summary = 'Raportul de productie a fost validat cu success!!';
        this.changeStatus(summary);
    }

    finishForm(){
        this.status = this.enumStatus.Finished;
        const summary = 'Raportul de productie a fost finalizat cu success!';
        this.changeStatus(summary);
    }

    changeStatus(summary: string){
        this.itemService.statusChange(this.status, this.uid)
            .pipe(finalize(() => this.loading = false))
            .subscribe((response: any) => {
                this.itemModel.map(response.payload);
                this.form.patchValue(this.itemModel);
                this.messageService.add({
                    severity: 'success',
                    summary
                });
                this.router.navigate(['stock-management/production-note/list'], {});
            }, err => {
                if (err instanceof HttpErrorResponse) {
                    this.validationMessageService.serverSideValidation(err, this.form, '', true);
                }
            });
    }

    addLiveDosage() {
        this.ref = this.dialogService.open(ProductionNoteLiveDosingComponent, {
            header: 'Dozare Live',
            width: '70%',
            contentStyle: { 'max-height': '70vh' },
            baseZIndex: 10000,
            closeOnEscape: false,
            data: this.form.value.uid,
        });
    }

    setParams(entity: ProductionNoteModel): void {
        this.dataDialog = entity;
    }
}
