import { BaseModel, BaseNomenclatorModel } from './base.model';
import { UserModel } from '../../auth/models/user.model';
import { CountyModel } from './county.model';
import { CityModel } from './city.model';
import { SupplierContactModel } from './supplier-contact.model';
import { SupplierAddressModel } from './supplier-address.model';

export class SupplierModel extends BaseNomenclatorModel {

    id: number;
    name: string;
    uid: string;
    code: string;
    uniqueCode: string;
    registrationNumber: any;
    phone: string;
    address: string;
    email: string;
    county: CountyModel = new CountyModel();
    city: CityModel = new CityModel();
    contacts: Array<SupplierContactModel>;
    addresses: Array<SupplierAddressModel>;
}
