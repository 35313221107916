<script src="../../../../app.component.ts"></script>
<a routerLink="/stock-management/delivery-note/list" style="color: #808080;font-size: 18px">
    <i class="fas fa-arrow-left"></i>
    <span>Inapoi la lista aviz livrare</span>
</a>
<br>
<br>
<form [formGroup]="form" autocomplete="false" (ngSubmit)="onSave()">
    <p-card>
        <div class="grid fields-group" style="padding: 0 1em">
            <div class="col-12">
                <div class="grid p-fluid">
                    <div class="field col-6">
                        <app-stt-input inputId="documentNo" [control]="form.controls['documentNo']"
                                       icon="pi pi-paperclip" type="text" [readonly]="readOnly"
                                       label="Numar Document"></app-stt-input>
                    </div>
                    <div class="field col-6">
                        <app-stt-input inputId="code" [control]="form.controls['code']"
                                       icon="pi pi-key" type="text" [readonly]="readOnly"
                                       label="Cod SAP"></app-stt-input>
                    </div>
                </div>
                <p-divider></p-divider>
                <br>

                <div class="grid p-fluid">
                    <div class="col-7">
                        <div class="grid p-fluid">
                            <div class="col-6">
                                <app-stt-dropdown endPoint="product-categories/dropdown"
                                                  [formControlParent]="form.controls['product_category_id']"
                                                  label="Categorie produs" icon="pi pi-th-large"
                                                  [readonly]="(productUID && orderUID) || readOnly"
                                                  placeholder="Nu ati selectat o categorie de produs!"
                                                  [dropDownWithTrashed]="dropDownWithTrashed"></app-stt-dropdown>
                            </div>
                            <div class="col-6">
                                <app-stt-dropdown endPoint="products/dropdown"
                                                  [formControlParent]="form.controls['product_id']"
                                                  formControlNameDependency="product_category_id"
                                                  label="Produs" icon="pi pi-box"
                                                  [readonly]="(productUID && orderUID) || readOnly"
                                                  placeholder="Nu ati selectat un produs!"
                                                  [dropDownWithTrashed]="dropDownWithTrashed"
                                                  (changeEvent)="onProductChange($event)"></app-stt-dropdown>
                            </div>
                        </div>
                    </div>
                    <div class="col-5" style="border-left: 1px solid lightgray">
                        <app-stt-dropdown endPoint="customers/dropdown"
                                          [formControlParent]="form.controls['customer_id']"
                                          icon="pi pi-user"
                                          [readonly]="(productUID && orderUID) || readOnly"
                                          placeholder="Nu ati selectat un client!"
                                          label="Client"
                                          [dropDownWithTrashed]="dropDownWithTrashed"></app-stt-dropdown>
                    </div>
                </div>
                <div class="grid p-fluid">
                    <div class="col-7">
                        <div class="grid p-fluid">
                            <div class="col-12">
                                <app-stt-dropdown endPoint="fncs/dropdown" [formControlParent]="form.controls['fnc_id']" label="Fnc" icon="pi pi-cog" [readonly]="readOnly"
                                                  placeholder="Nu ati selectat un fnc!"></app-stt-dropdown>
                            </div>
                        </div>
                    </div>
                    <div class="col-5" style="border-left: 1px solid lightgray">
                        <app-stt-dropdown
                            endPoint="customers/getAddresses" [readonly]="readOnly"
                            [fields]="{ text: 'address', value: 'id'}" icon="pi pi-home"
                            [formControlParent]="form.controls['customer_address_id']"
                            formControlNameDependency="customer_id"
                            placeholder="Nu ati selectat o adresa!"
                            label="Adresa de livrare">
                        </app-stt-dropdown>
                    </div>
                </div>
                <p-divider></p-divider>
                <br>
                <div class="grid p-fluid">
                    <div class="col-12 md:col-4">
                        <app-stt-input inputId="bruteQuantity" [control]="form.controls['brute_quantity']"
                                       icon="pi pi-list" type="number" [readonly]="readOnly"
                                       label="Cantitate Bruta"></app-stt-input>
                    </div>
                    <div class="col-12 md:col-4">
                        <app-stt-input inputId="transportWeight" [control]="form.controls['tar_quantity']"
                                       icon="pi pi-list" type="number" [readonly]="readOnly"
                                       label="Cantitate Tara"></app-stt-input>
                    </div>
                    <div class="col-12 md:col-4">
                        <app-stt-input inputId="units" [control]="form.controls['units']"
                                       icon="pi pi-box" type="number" [readonly]="true"
                                       label="Cantitate neta"></app-stt-input>
                    </div>
                </div>
                <p-divider></p-divider>
                <br>
                <div class="grid p-fluid">
                    <div class="col-6">
                        <app-stt-calendar label="Data" icon="pi pi-calendar" [minimumDate]="minimumDate"
                                          inputId="deliveryDate" [showCalendar]="showCalendar"
                                          [control]="form.controls['deliveryDate']"></app-stt-calendar>
                        <br>
                        <app-stt-textarea inputId="description" [control]="form.controls['description']"
                                          icon="pi pi-pencil" [readOnly]="readOnly"
                                          label="Descriere"></app-stt-textarea>
                    </div>
                    <div class="col-6" style="border-left: 1px solid lightgray">
                        <app-stt-dropdown endPoint="transport-companies/dropdown"
                                          label="Companie transport"
                                          icon="pi pi-building" [readonly]="readOnly"
                                          placeholder="Nu ati selectat compania de transport!"
                                          [formControlParent]="form.controls['transport_company_id']"
                                          [dropDownWithTrashed]="dropDownWithTrashed">
                        </app-stt-dropdown>
                        <br>
                        <br>
                        <app-stt-dropdown endPoint="transport-unit-types/dropdown"
                                          label="Tip transport"
                                          icon="pi pi-th-large" [readonly]="readOnly"
                                          placeholder="Nu ati selectat tipul de transport!"
                                          [formControlParent]="form.controls['transport_unit_type_id']"
                                          [dropDownWithTrashed]="dropDownWithTrashed">
                        </app-stt-dropdown>
                        <br>
                        <br>
                        <app-stt-dropdown endPoint="transport-unit-drivers/dropdown"
                                          label="Sofer"
                                          icon="pi pi-user" [readonly]="readOnly"
                                          placeholder="Nu ati selectat soferul!"
                                          [fields]=" { text: 'firstName', value: 'id'}"
                                          [formControlParent]="form.controls['transport_unit_driver_id']"
                                          [dropDownWithTrashed]="dropDownWithTrashed">
                        </app-stt-dropdown>
                    </div>
                </div>
                <p-divider>Alocare loturi</p-divider>
                <br>
                <p-table [value]="formDeliveryNoteLots().controls" [autoLayout]="true">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>Lot</th>
                            <th>Cantitate totala stoc</th>
                            <th>Cantitate alocata</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
                        <ng-container [formGroup]="rowData">
                            <tr>
                                <td>
                                    {{ rowData.controls['lot'].value }}
                                </td>
                                <td>
                                    {{ rowData.controls['stock_units'].value }}
                                </td>
                                <td>
                                    <app-stt-input inputId="alocated_units" [readonly]="readOnly"
                                                   [control]="rowData.controls['alocated_units']"
                                                   type="number"
                                    ></app-stt-input>
                                </td>
                            </tr>
                        </ng-container>
                    </ng-template>
                </p-table>
                <br>
                <br>
                <p-divider>Comanda asociata</p-divider>
                <br>
                <div class="grid p-fluid">
                    <div class="col-12">
                        <p-table [value]="formOrders().controls" [autoLayout]="true">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th></th>
                                    <th>Comanda</th>
                                    <th>Data livrarii</th>
                                    <th>Produs</th>
                                    <th>Cantitate</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-rowData let-rowIndex="rowIndex">
                                <tr>
                                    <ng-container [formGroup]="rowData">
                                        <td>
                                            <p-checkbox formControlName="selected" binary="true"
                                                        (onChange)="checkedBox($event,rowIndex)"></p-checkbox>
                                        </td>
                                        <td>
                                            {{ rowData.controls['code'].value }}
                                        </td>
                                        <td>
                                            {{ rowData.controls['deliveryDate'].value }}
                                        </td>
                                        <td>
                                            {{ rowData.controls['product_name'].value }}
                                        </td>
                                        <td>
                                            {{ rowData.controls['quantity'].value }}
                                        </td>
                                    </ng-container>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td colspan="5">Nu sunt comenzi in progress asociate produsului selectat </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
            </div>
        </div>
    </p-card>

    <p-progressBar mode="indeterminate" class="addEditProgressBar" *ngIf="loading"></p-progressBar>

    <div class="actions-area">
        <div class="actions-left">
            <button pButton pRipple type="button" label="Anulare" class="p-button-raised p-button-danger"
                    icon="pi pi-ban" [disabled]="loading"
                    routerLink="/stock-management/delivery-note/list"></button>
        </div>

        <div class="actions-right">
            <button *ngIf="!status || status === enumStatus.Draft" pButton pRipple type="submit" label="Salveaza" class="p-button-raised p-button-success"
                    icon="pi pi-save" [disabled]="loading">
            </button>
            <p-button *ngIf="status === enumStatus.Draft" [label]="'Validare'" icon="pi pi-check" iconPos="right" (click)="validateForm()" [ngStyle]="{'margin-left' : '2px'}"></p-button>
        </div>
    </div>
    <div *ngIf="form.controls['documentNo'].errors">
        {{ form.controls['documentNo'].errors.maxLength}}
    </div>
</form>
