<p-divider></p-divider>
<div class="flex-box" style="  display:flex;justify-content:space-between;">
    <p style="font-size: 20px">Comenzi</p>
    <p-button [label]="'Adauga Comanda'" icon="pi pi-plus" iconPos="right" routerLink="/orders/products/add-edit"></p-button>
</div>
<p-divider></p-divider>


<br>
<br>
<br>
<br>
<p>Lista Comenzi!</p>
<!--
<p style="font-size: 20px">Comenzi</p>
<p-button [label]="'Adauga Comanda'" icon="pi pi-plus" iconPos="right" (click)="addNewEntity()"></p-button>
<p-button [label]="'Adauga Comanda 2'" icon="pi pi-plus" iconPos="right" routerLink="/orders/add-edit"></p-button>
-->
