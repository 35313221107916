import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-stock',
  templateUrl: './stock.component.html',
  styleUrls: ['./stock.component.scss']
})
export class StockComponent implements OnInit {
  public items: MenuItem[];

  constructor() { }

  ngOnInit(): void {
      this.items = [
          {
              label: 'Materii Prime',
              icon: 'fas fa-industry',
              routerLink: ['/stock-management/stock/raw-materials']
          },
          { label: 'Produse', icon: 'fas fa-users', routerLink: ['/stock-management/stock/products'] },
      ];
  }

}
