
import { Component, OnInit } from '@angular/core';
import { SttTableComponent } from '../../../../../shared/components/stt-table/stt-table.component';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { OrderCustomerModel } from '../../../../../shared/entities/order-customer.model';
import { Router } from '@angular/router';
import { FncAddEditComponent } from '../../../../nomenclature/fnc/fnc-add-edit/fnc-add-edit.component';
import { FncModel } from '../../../../../shared/entities/fnc.model';
import {
    OrdersCustomersTransportViewAddEditComponent
} from '../orders-customers-transport-view-add-edit/orders-customers-transport-view-add-edit.component';

@Component({
    selector: 'app-orders-customer-transport-view-action-table',
    templateUrl: './orders-customers-transport-view-action-table.component.html',
    styleUrls: ['./orders-customers-transport-view-action-table.component.scss']
})
export class OrdersCustomersTransportViewActionTableComponent implements OnInit {

    public data: OrderCustomerModel;
    public table: SttTableComponent;
    public ref: DynamicDialogRef;

    constructor(
        public dialogService: DialogService,
        public router: Router,
    ) {

    }

    ngOnInit(): void {
    }

    setParams(entity: OrderCustomerModel): void {
        this.data = entity;
    }

    setTableReference(table: SttTableComponent): void
    {
        this.table = table;
    }

    showDialog(){
        this.ref = this.dialogService.open(OrdersCustomersTransportViewAddEditComponent, {
            header: 'Generare nota de livrare',
            width: '70%',
            contentStyle: {'max-height': '70vh'},
            baseZIndex: 10000,
            closeOnEscape: false,
            data: this.data,
        });
        // To add on close ref
    }

}
