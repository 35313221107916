import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { RippleModule } from 'primeng/ripple';
import { StepsModule } from 'primeng/steps';
import { TableModule } from 'primeng/table';
import { AppMainComponent } from '../../app.main.component';
import { AuthGuardService } from '../../guards/auth-guard.service';
import { ButtonModule } from 'primeng/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { DividerModule } from 'primeng/divider';
import { SharedModule } from '../../shared/shared.module';
import { CalendarModule } from 'primeng/calendar';
import { FieldsetModule } from 'primeng/fieldset';
import { ProgressBarModule } from 'primeng/progressbar';
import { StockManagementComponent } from './stock-management.component';
import { ReceptionNoteComponent } from './reception-note/reception-note.component';
import {
    ReceptionNoteActionTableComponent
} from './reception-note/reception-note-action-table/reception-note-action-table.component';
import {
    ReceptionNoteAddEditComponent
} from './reception-note/reception-note-add-edit/reception-note-add-edit.component';
import { ConsumptionTicketComponent } from './consumption-ticket/consumption-ticket.component';
import {
    ConsumptionTicketAddEditComponent
} from './consumption-ticket/consumption-ticket-add-edit/consumption-ticket-add-edit.component';
import {
    ConsumptionTicketActionTableComponent
} from './consumption-ticket/consumption-ticket-action-table/consumption-ticket-action-table.component';
import { LossTicketComponent } from './loss-ticket/loss-ticket.component';
import {
    LossTicketActionTableComponent
} from './loss-ticket/loss-ticket-action-table/loss-ticket-action-table.component';
import { LossTicketAddEditComponent } from './loss-ticket/loss-ticket-add-edit/loss-ticket-add-edit.component';
import { InventoryActionTableComponent } from './inventory/inventory-action-table/inventory-action-table.component';
import { InventoryAddEditComponent } from './inventory/inventory-add-edit/inventory-add-edit.component';
import { DeliveryNoteComponent } from './delivery-note/delivery-note.component';
import { DeliveryNoteAddEditComponent } from './delivery-note/delivery-note-add-edit/delivery-note-add-edit.component';
import {
    DeliveryNoteActionTableComponent
} from './delivery-note/delivery-note-action-table/delivery-note-action-table.component';
import { ProductionNoteComponent } from './production-note/production-note.component';
import {
    ProductionNoteAddEditComponent
} from './production-note/production-note-add-edit/production-note-add-edit.component';
import {
    ProductionNoteActionTableComponent
} from './production-note/production-note-action-table/production-note-action-table.component';
import { InventoryRawMaterialsComponent } from './inventory/inventory-raw-materials/inventory-raw-materials.component';
import { InventoryProductsComponent } from './inventory/inventory-products/inventory-products.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { MenubarModule } from 'primeng/menubar';
import { StockComponent } from './stock/stock.component';
import { StockProductsComponent } from './stock/stock-products/stock-products.component';
import { StockRawMaterialsComponent } from './stock/stock-raw-materials/stock-raw-materials.component';
import { RawMaterialVsProductComponent } from './raw-material-vs-product/raw-material-vs-product.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ProductionNoteLiveDosingComponent } from './production-note/production-note-live-dosing/production-note-live-dosing.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';


const routes: Routes = [
    { path: 'stock-management', redirectTo: 'stock-management/reception-note', pathMatch: 'full' },
    {
        path: 'stock-management', component: AppMainComponent,
        children: [
            {
                path: 'reception-note', canActivate: [AuthGuardService], data: {roles : ['Administrator', 'Gestionar', ]},
                children: [
                    // eslint-disable-next-line max-len
                    { path: 'list', component: ReceptionNoteComponent, canActivate: [AuthGuardService], data: {roles : ['Administrator', 'Gestionar']}},
                    // eslint-disable-next-line max-len
                    { path: 'add-edit', component: ReceptionNoteAddEditComponent, canActivate: [AuthGuardService], data: {roles : ['Administrator', 'Gestionar']}},
                    { path: '', redirectTo: 'list', pathMatch: 'full' },
                ]
            },
            {
                path: 'delivery-note', canActivate: [AuthGuardService], data: {roles : ['Administrator', 'Gestionar', ]},
                children: [
                    // eslint-disable-next-line max-len
                    { path: 'list', component: DeliveryNoteComponent, canActivate: [AuthGuardService], data: {roles : ['Administrator', 'Gestionar', ]}},
                    // eslint-disable-next-line max-len
                    { path: 'add-edit', component: DeliveryNoteAddEditComponent, canActivate: [AuthGuardService], data: {roles : ['Administrator', 'Gestionar', ]}},
                    { path: '', redirectTo: 'list', pathMatch: 'full' },
                ]
            },
            {
                // eslint-disable-next-line max-len
                path: 'production-note', canActivate: [AuthGuardService], data: {roles : ['Administrator', 'Gestionar', 'Laborant', 'Productie']},
                children: [
                    // eslint-disable-next-line max-len
                    { path: 'list', component: ProductionNoteComponent, canActivate: [AuthGuardService], data: {roles : ['Administrator', 'Gestionar', 'Laborant', 'Productie']}},
                    // eslint-disable-next-line max-len
                    { path: 'add-edit', component: ProductionNoteAddEditComponent, canActivate: [AuthGuardService], data: {roles : ['Administrator', 'Gestionar', 'Laborant', 'Productie']}},
                    { path: '', redirectTo: 'list', pathMatch: 'full' },
                ]
            },
            // {
            //     path: 'consumption-ticket', canActivate: [AuthGuardService], data: {roles : ['Administrator', 'Gestionar', ]},
            //     children: [
            //         // eslint-disable-next-line max-len
            //         { path: 'list', component: ConsumptionTicketComponent, canActivate: [AuthGuardService], data: {roles : ['Administrator', 'Gestionar', ]}},
            //         // eslint-disable-next-line max-len
            //         { path: 'add-edit', component: ConsumptionTicketAddEditComponent, canActivate: [AuthGuardService], data: {roles : ['Administrator', 'Gestionar', ]}},
            //         { path: '', redirectTo: 'list', pathMatch: 'full' },
            //     ]
            // },
            {
                path: 'loss-ticket', canActivate: [AuthGuardService], data: {roles : ['Administrator', 'Gestionar', ]},
                children: [
                    // eslint-disable-next-line max-len
                    { path: 'list', component: LossTicketComponent, canActivate: [AuthGuardService], data: {roles : ['Administrator', 'Gestionar', ]}},
                    // eslint-disable-next-line max-len
                    { path: 'add-edit', component: LossTicketAddEditComponent, canActivate: [AuthGuardService], data: {roles : ['Administrator', 'Gestionar', ]}},
                    { path: '', redirectTo: 'list', pathMatch: 'full' },
                ]
            },
            {
                path: 'inventory', canActivate: [AuthGuardService], data: {roles : ['Administrator', 'Gestionar', ]},
                children: [
                    // eslint-disable-next-line max-len
                    { path: 'add-edit', component: InventoryAddEditComponent, canActivate: [AuthGuardService], data: {roles : ['Administrator', 'Gestionar', ]}},
                    // eslint-disable-next-line max-len
                    { path: 'products', component: InventoryProductsComponent, canActivate: [AuthGuardService], data: {roles : ['Administrator', 'Gestionar', ]}},
                    // eslint-disable-next-line max-len
                    { path: 'raw-materials', component: InventoryRawMaterialsComponent, canActivate: [AuthGuardService], data: {roles : ['Administrator', 'Gestionar', ]}},
                    { path: '', redirectTo: 'raw-materials', pathMatch: 'full' },
                ]
            },
            {
                path: 'stock', canActivate: [AuthGuardService], data: {roles : ['Administrator', 'Gestionar', 'Achizitii']},
                children: [
                    // eslint-disable-next-line max-len
                    { path: 'add-edit', component: InventoryAddEditComponent, canActivate: [AuthGuardService], data: {roles : ['Administrator', 'Gestionar', 'Achizitii' ]}},
                    // eslint-disable-next-line max-len
                    { path: 'products', component: StockProductsComponent, canActivate: [AuthGuardService], data: {roles : ['Administrator', 'Gestionar', 'Achizitii' ]}},
                    // eslint-disable-next-line max-len
                    { path: 'raw-materials',  component: StockRawMaterialsComponent, canActivate: [AuthGuardService], data: {roles : ['Administrator', 'Gestionar', 'Achizitii' ]}},
                    { path: '', redirectTo: 'raw-materials', pathMatch: 'full' },
                ]
            },
            {
                path: 'raw-material-vs-product', canActivate: [AuthGuardService], data: {roles : ['Administrator', 'Achizitii']},
                children: [
                    // eslint-disable-next-line max-len
                    { path: '', component: RawMaterialVsProductComponent, canActivate: [AuthGuardService], data: {roles : ['Administrator', 'Achizitii']}},
                ]
            },
        ]
    },
];

@NgModule({
    declarations: [
        StockManagementComponent,
        ReceptionNoteComponent,
        ReceptionNoteActionTableComponent,
        ReceptionNoteAddEditComponent,
        ConsumptionTicketComponent,
        ConsumptionTicketAddEditComponent,
        ConsumptionTicketActionTableComponent,
        LossTicketComponent,
        LossTicketActionTableComponent,
        LossTicketAddEditComponent,
        InventoryActionTableComponent,
        InventoryAddEditComponent,
        DeliveryNoteComponent,
        DeliveryNoteAddEditComponent,
        DeliveryNoteActionTableComponent,
        ProductionNoteComponent,
        ProductionNoteAddEditComponent,
        ProductionNoteActionTableComponent,
        InventoryRawMaterialsComponent,
        InventoryProductsComponent,
        StockComponent,
        StockProductsComponent,
        StockRawMaterialsComponent,
        RawMaterialVsProductComponent,
        ProductionNoteLiveDosingComponent
    ],
    imports: [
        CommonModule,
        DividerModule,
        ButtonModule,
        FormsModule,
        FieldsetModule,
        RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'}),
        SharedModule,
        CalendarModule,
        ReactiveFormsModule,
        ProgressBarModule,
        InputTextareaModule,
        InputTextModule,
        CardModule,
        RippleModule,
        TableModule,
        StepsModule,
        CheckboxModule,
        NgSelectModule,
        MenubarModule,
        ConfirmDialogModule,
        OverlayPanelModule,
    ]
})
export class StockManagementModule {
}
