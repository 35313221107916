import { BaseNomenclatorModel } from './base.model';
import { FncModel } from './fnc.model';
import { RawMaterialTypesModel } from './raw-material-types.model';

export class RawMaterialTypeFncRelationModel extends BaseNomenclatorModel {
    fnc_id: number;
    fnc: FncModel;
    raw_material_type_id: number;
    raw_material_type: RawMaterialTypesModel;
}
