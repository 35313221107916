import { Component, OnInit } from '@angular/core';
import {SttTableComponent} from '../../../../../shared/components/stt-table/stt-table.component';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import { TransportCompanyTypesModel } from 'src/app/shared/entities/transport-company-type.model';
import { TransportUnitTypeAddEditComponent } from '../transport-unit-type-add-edit/transport-unit-type-add-edit.component';
import { finalize } from 'rxjs/operators';
import { ConfirmationService, MessageService, PrimeNGConfig } from 'primeng/api';
import { ValidationMessageService } from '../../../../../shared/services/base/validation-message-handle';
import { TransportUnitTypeService } from '../../../../../shared/services/app/transport-unit-type.service';

@Component({
  selector: 'app-transport-unit-type-action-table',
  templateUrl: './transport-unit-type-action-table.component.html',
  styleUrls: ['./transport-unit-type-action-table.component.scss']
})
export class TransportUnitTypeActionTableComponent implements OnInit {

  public data: TransportCompanyTypesModel;
  public table: SttTableComponent;
  public ref: DynamicDialogRef;
  public loading = true;

  constructor(
      public dialogService: DialogService,
      public confirmationService: ConfirmationService,
      public primengConfig: PrimeNGConfig,
      public itemService: TransportUnitTypeService,
      public messageService: MessageService,
      private validationMessageService: ValidationMessageService,
  ) {

  }

  ngOnInit(): void {
  }

  setParams(entity: TransportCompanyTypesModel): void {
      this.data = entity;
  }

  setTableReference(table: SttTableComponent): void
  {
      this.table = table;
  }


  showDialog() {
      this.ref = this.dialogService.open(TransportUnitTypeAddEditComponent, {
          header: 'Modificare Tip unitate transport',
          width: '30%',
          contentStyle: {'max-height': '70vh'},
          baseZIndex: 10000,
          closeOnEscape: false,
          data: this.data,
      });

      this.ref.onClose.subscribe((item: TransportCompanyTypesModel) => {
          if (item) {
              this.table.refresh();
          }
      });
  }

    confirm() {
        this.confirmationService.confirm({
            header: 'Confirmare stergere Tip unitate de transport',
            message: 'Sunteti sigur ca doriti sa stergeti acest tip unitate de transport?',
            key: 'deleteTransportUnitType',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Da',
            rejectLabel: 'Nu',
            acceptButtonStyleClass: 'p-button-md p-button-success',
            rejectButtonStyleClass: 'p-button-md p-button-danger',
            accept: () => {
                this.deleteItem(this.data.uid);
            },
            reject: () => {
                this.confirmationService.close();
                this.table.refresh();
            }
        });
    }

    deleteItem(uid: string){
        this.itemService.delete(uid)
            .pipe(finalize(() => {this.loading = false;this.table.refresh();}))
            .subscribe((response: any) => {
                this.messageService.add({
                    severity: 'success',
                    summary: 'Tipul unitatii de transport a fost sters cu success!'
                });
            });
    }

}
