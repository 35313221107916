<form [formGroup]="form" style="max-width: 100%!important;" autocomplete="false" (ngSubmit)="onSave()">
    <div class="grid fields-group">
        <div class="col-12">
            <div class="grid p-fluid">
                <div class="field col-12 md:col-6">
                    <app-stt-input inputId="name" [control]="form.controls['name']"
                                   icon="pi pi-users"
                                   label="Denumire"></app-stt-input>
                </div>
                <div class="field col-12 md:col-6">
                    <app-stt-input inputId="code" [control]="form.controls['code']"
                                   icon="pi pi-key"
                                   label="Cod SAP"></app-stt-input>
                </div>
            </div>
            <div class="grid p-fluid">
                <div class="field col-12 md:col-6">
                    <app-stt-dropdown placeholder="Nu ati selectat unitatea de masura!" endPoint="unit-measures/dropdown"
                                      [formControlParent]="form.controls['unit_measure_id']"
                                      icon="pi pi-chart-pie"
                                      label="Unitate de masura"></app-stt-dropdown>
                </div>
                <div class="field col-12 md:col-6">
                    <app-stt-dropdown placeholder="Nu ati selectat grup de materie prima!" endPoint="raw-material-groups/dropdown"
                                      [formControlParent]="form.controls['raw_material_group_id']"
                                      icon="pi pi-chart-pie"
                                      label="Grup de materie prima"
                                      [dropDownWithTrashed]="dropDownWithTrashed"></app-stt-dropdown>
                </div>
            </div>
            <p-fieldset legend="FNC per tip materie prima" [transitionOptions]="'100ms'">
                <div class="grid fields-group">
                    <div class="col-12">
                        <div class="col-12" formArrayName="fncTypes">
                            <ng-container *ngIf="rawMaterialModel.fncTypes || !uid">
                                <ng-container *ngFor="let fncForm of formFncTypes.controls; let i = index">
                                    <div class="grid p-fluid">
                                        <div class="field col-12 md:col-2">
                                            <div>{{fncForm.value.name}}</div>
                                        </div>
                                        <div class="field col-12 md:col-4">
                                            <app-stt-dropdown endPoint="raw-material-types/dropdown"
                                                              placeholder="Nu ati selectat tipul!"
                                                              icon="pi pi-th-large"
                                                              [formControlParent]="fncForm.controls['raw_material_type_id']"
                                                              [dropDownWithTrashed]="dropDownWithTrashed"
                                                              label="Tip materia prima">
                                            </app-stt-dropdown>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </p-fieldset>


        </div>
    </div>

    <p-progressBar mode="indeterminate" class="addEditProgressBar" *ngIf="loading"></p-progressBar>

    <div class="actions-area">
        <div class="actions-left">
            <button pButton pRipple type="button" label="Anulare" class="p-button-raised p-button-danger"
                    icon="pi pi-ban" [disabled]="loading" (click)="onCancel()">
            </button>
        </div>

        <div class="actions-right">
            <button pButton pRipple type="submit" label="Salveaza" class="p-button-raised p-button-success"
                    icon="pi pi-save" [disabled]="loading">
            </button>
        </div>
    </div>
</form>
