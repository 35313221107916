import { Component, OnInit } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SttTableService } from '../../../shared/components/stt-table/stt-table-service';
import { OrderRawMaterialsActionTableComponent } from './order-raw-materials-action-table/order-raw-materials-action-table.component';

@Component({
  selector: 'app-orders-raw-materials',
  templateUrl: './orders-raw-materials.component.html',
  styleUrls: ['./orders-raw-materials.component.scss']
})
export class OrdersRawMaterialsComponent implements OnInit {
    public tableEndpoint = 'raw-material-orders';
    public tableActionComponent = OrderRawMaterialsActionTableComponent;
    public tableQuery: any;
    public ref: DynamicDialogRef;

    constructor(public dialogService: DialogService) {
        this.tableQuery = new SttTableService()
            .addInclude('fnc')
            .addInclude('supplier');
    }

    ngOnInit(): void {

    }
}
