import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AppComponent } from '../../app.component';
import { AppMainComponent } from '../../app.main.component';
import { UserModel } from '../../auth/models/user.model';
import { UserService } from '../../auth/user.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html',
    styleUrls: ['./app.topbar.component.scss']
})
export class AppTopBarComponent implements OnInit {
    form: UntypedFormGroup;
    user: UserModel = null;
    public readonly = false;

    constructor(
        public appMain: AppMainComponent,
        public app: AppComponent,
        public userService: UserService,
        private formBuilder: UntypedFormBuilder,
        public router: Router,
    ) {
        this.user = this.userService.getUserFromLocalStorage();
    }

    ngOnInit() {
        this.createForm();
        const userRole = this.user.roles[0].name;
        if(userRole === 'Gestionar' || userRole === 'Laborant' || userRole === 'Productie'){
            this.readonly = true;
        }
    }

    createForm(): void {
        this.form = this.formBuilder.group({
            uid: [this.user.uid],
            fnc_id: [this.user.fnc_id, [Validators.required]]
        });
    }

    setFnc(): void {
        if (this.form.invalid) {
            return;
        }
        this.user.fnc_id = this.form.controls.fnc_id.value;
        localStorage.setItem('user', JSON.stringify(this.user));
        // To be changed in near future
        // window.location.reload();
        this.router.navigate(
            ['dashboard'],
        );

    }

}
