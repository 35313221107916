<div class="p-inputgroup">
    <span class="p-inputgroup-addon" *ngIf="icon && iconPosition==='left'">
        <i [ngClass]="icon"></i>
    </span>
    <div class="p-float-label">
        <ng-select
            typeToSearchText="Introduceti cel putin doua caractere"
            class="p-element p-inputwrapper p-inputwrapper-filled" [class.icon-left]="iconPosition==='left'"
            [class.icon-right]="iconPosition==='right'"
            [items]="items" [bindLabel]="fields.text" [bindValue]="fields.value" [formControl]="formControl"
            [searchWhileComposing]="false"
            [minTermLength]="2" [searchable]="true" [loading]="loading" [clearable]="true"
            (change)="onChange($event)" (search)="onSearch($event)" [placeholder]="placeholder"
            [readonly]="readonly" [appendTo]="appendTo">
        </ng-select>
        <label>{{label}} <span *ngIf="hasRequired(formControlParent) && label"
                               style="color: red; font-style: italic">*</span>
        </label>
    </div>
    <span class="p-inputgroup-addon" *ngIf="icon && iconPosition==='right'">
        <i [ngClass]="icon"></i>
    </span>
</div>
<app-stt-validator-message [control]="formControlParent"></app-stt-validator-message>
