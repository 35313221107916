import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { MenuItem } from 'primeng/api';

@Injectable()
export class AppBreadcrumbService {

    itemsHandler: Observable<MenuItem[]>;

    private itemsSource = new Subject<MenuItem[]>();

    constructor() {
        this.itemsHandler = this.itemsSource.asObservable();
    }

    setItems(items: MenuItem[]) {
        this.itemsSource.next(items);
    }
}
