import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

    public roleAs: string;

    public isLogin = false;

    constructor(private router: Router) {

    }

    // eslint-disable-next-line max-len
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const url: string = state.url;
        return this.checkUserLogin(route, url);


        // if (localStorage.getItem('auth-token')) {
        //     // Validate token
        //     return true;
        // }
        // // not logged in so redirect to login page with the return url
        // this.router.navigate(['/auth/login'], {queryParams: {returnUrl: state.url}});
        // return false;
    }

    getRole() {
        const role = JSON.parse(localStorage.getItem('user'));
        this.roleAs = role.roles[0].name;
        return this.roleAs;
    }

    checkUserLogin(route: ActivatedRouteSnapshot, url: any): boolean {
        if (this.isLoggedIn()) {
            const userRole = this.getRole();
                if(route.data.roles.indexOf(userRole) === -1){
                    this.router.navigate(['/not-found']);
                    return false;
                }
                return true;
        }
        this.router.navigate(['/auth/login']);
        return false;
    }


    isLoggedIn() {
        return !!localStorage.getItem('auth-token');
    }
}
