import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpApiService } from '../base/http-api.service';
import { ApiResponseModel } from '../../entities/api-response.model';
import { ProductPackingTypeModel } from '../../entities/product-packing-type.model';

@Injectable({
    providedIn: 'root'
})
export class ProductPackingTypeService {


    constructor(
        public httpApiService: HttpApiService,
    ) {

    }

    public get(uid): Observable<ApiResponseModel> {
        return this.httpApiService.httpGet('product-packing-types/view', {uid});
    }

    public set(productPackingType: ProductPackingTypeModel): Observable<ApiResponseModel> {
        return this.httpApiService.httpPost('product-packing-types/store', productPackingType);
    }

    public delete(uid): Observable<ApiResponseModel> {
        return this.httpApiService.httpDelete('product-packing-types/destroy', { uid });
    }
}
