import { Component, OnInit } from '@angular/core';
import { InventoryActionTableComponent } from '../inventory-action-table/inventory-action-table.component';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Router } from '@angular/router';
import { SttTableService } from '../../../../shared/components/stt-table/stt-table-service';

@Component({
    selector: 'app-inventory-products',
    templateUrl: './inventory-products.component.html',
    styleUrls: ['./inventory-products.component.scss']
})
export class InventoryProductsComponent implements OnInit {
    public tableEndpoint = 'inventory/products';
    public tableActionComponent = InventoryActionTableComponent;
    public tableQuery: any;
    public ref: DynamicDialogRef;

    constructor(public dialogService: DialogService,
                public router: Router) {
        this.tableQuery = new SttTableService()
            .addInclude('fnc');
    }

    ngOnInit(): void {
    }

}
