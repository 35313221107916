import { Component, OnInit, ViewChild } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SttTableComponent } from '../../../../shared/components/stt-table/stt-table.component';
import { SttTableService } from '../../../../shared/components/stt-table/stt-table-service';
import { StorageTypeModel } from '../../../../shared/entities/storage-space.model';
import { StorageTypeService } from '../../../../shared/services/app/storage-type.service';
import { StorageTypeTableActionComponent } from './storage-type-table-action/storage-type-table-action.component';
import { StorageTypeAddEditComponent } from './storage-type-add-edit/storage-type-add-edit.component';

@Component({
    selector: 'app-storage-types',
    templateUrl: './storage-types.component.html',
    styleUrls: ['./storage-types.component.scss']
})
export class StorageTypesComponent implements OnInit {

    @ViewChild('table', { static: false }) table: SttTableComponent;

    public tableEndpoint = 'storage-types';
    public tableActionComponent = StorageTypeTableActionComponent;
    public tableQuery: any;
    public ref: DynamicDialogRef;

    constructor(private storageTypeService: StorageTypeService, public dialogService: DialogService) {
        this.tableQuery = new SttTableService();
    }

    ngOnInit(): void {
    }

    addNewEntity() {
        this.ref = this.dialogService.open(StorageTypeAddEditComponent, {
            header: 'Adaugare Tip Spatiu de Stocare',
            width: '30%',
            contentStyle: { 'max-height': '70vh' },
            baseZIndex: 10000,
            closeOnEscape: false,
            data: null,
        });

        this.ref.onClose.subscribe((storage: StorageTypeModel) => {
            if (storage) {
                this.table.refresh();
            }
        });
    }
}
