import { Component, OnInit } from '@angular/core';
import {SttTableComponent} from '../../../../../shared/components/stt-table/stt-table.component';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import { TransportCompanyTypesModel } from 'src/app/shared/entities/transport-company-type.model';
import { TransportCompanyTypesAddEditComponent } from '../transport-company-types-add-edit/transport-company-types-add-edit.component';
import { ConfirmationService, MessageService, PrimeNGConfig } from 'primeng/api';
import { ValidationMessageService } from '../../../../../shared/services/base/validation-message-handle';
import { TransportCompanyTypeService } from '../../../../../shared/services/app/transport-company-type.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-transport-company-types-action-table',
  templateUrl: './transport-company-types-action-table.component.html',
  styleUrls: ['./transport-company-types-action-table.component.scss']
})
export class TransportCompanyTypesActionTableComponent implements OnInit {

  public data: TransportCompanyTypesModel;
  public table: SttTableComponent;
  public ref: DynamicDialogRef;
    public loading = true;

  constructor(
      public dialogService: DialogService,
      public confirmationService: ConfirmationService,
      public primengConfig: PrimeNGConfig,
      public itemService: TransportCompanyTypeService,
      public messageService: MessageService,
      private validationMessageService: ValidationMessageService,
  ) {

  }

  ngOnInit(): void {
  }

  setParams(entity: TransportCompanyTypesModel): void {
      this.data = entity;
  }

  setTableReference(table: SttTableComponent): void
  {
      this.table = table;
  }


  showDialog() {
      this.ref = this.dialogService.open(TransportCompanyTypesAddEditComponent, {
          header: 'Modificare tip companie transoprt',
          width: '30%',
          contentStyle: {'max-height': '70vh'},
          baseZIndex: 10000,
          closeOnEscape: false,
          data: this.data,
      });

      this.ref.onClose.subscribe((transport_company_types: TransportCompanyTypesModel) => {
          if (transport_company_types) {
              this.table.refresh();
          }
      });
  }

    confirm() {
        this.confirmationService.confirm({
            header: 'Confirmare stergere Tip companie transport',
            message: 'Sunteti sigur ca doriti sa stergeti acest tip companie de transport?',
            key: 'deleteTransportCompanyTypes',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Da',
            rejectLabel: 'Nu',
            acceptButtonStyleClass: 'p-button-md p-button-success',
            rejectButtonStyleClass: 'p-button-md p-button-danger',
            accept: () => {
                this.deleteItem(this.data.uid);
            },
            reject: () => {
                this.confirmationService.close();
                this.table.refresh();
            }
        });
    }

    deleteItem(uid: string){
        this.itemService.delete(uid)
            .pipe(finalize(() => {this.loading = false;this.table.refresh();}))
            .subscribe((response: any) => {
                this.messageService.add({
                    severity: 'success',
                    summary: 'Tipul companiei de transport a fost sters cu success!'
                });
            });
    }

}
