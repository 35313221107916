import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportsComponent } from './reports.component';
import { RouterModule, Routes } from '@angular/router';
import {AppMainComponent} from '../../app.main.component';
import {AuthGuardService} from '../../guards/auth-guard.service';

const routes: Routes = [
    {path: 'reports', redirectTo: 'reports/list', pathMatch: 'full'},
    {
        path: 'reports', component: AppMainComponent,
        children: [
            {path: 'list', component: ReportsComponent, canActivate: [AuthGuardService]},
        ]
    },
];

@NgModule({
  declarations: [
    ReportsComponent
  ],
  imports: [
    CommonModule,
      RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'}),
  ]
})
export class ReportsModule { }
