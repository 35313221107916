import { Component, OnInit } from '@angular/core';
import { CustomerModel } from '../../../../shared/entities/customer.model';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { HttpApiService } from '../../../../shared/services/base/http-api.service';
import { ValidationMessageService } from '../../../../shared/services/base/validation-message-handle';
import { MessageService } from 'primeng/api';
import { finalize } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { ProductService } from '../../../../shared/services/app/product.service';
import { ProductModel } from '../../../../shared/entities/product.model';

@Component({
    selector: 'app-products-add-edit',
    templateUrl: './products-add-edit.component.html',
    styleUrls: ['./products-add-edit.component.scss']
})
export class ProductsAddEditComponent implements OnInit {

    public uid: string = null;
    public productModel: ProductModel = new ProductModel();
    public loading = false;
    public form: UntypedFormGroup;
    public dropDownWithTrashed = false;


    constructor(
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private formBuilder: UntypedFormBuilder,
        private httpApiService: HttpApiService,
        private validationMessageService: ValidationMessageService,
        private productService: ProductService,
        private messageService: MessageService
    ) {
    }

    ngOnInit(): void {
        this.uid = (this.config.data) ? this.config.data.uid : null;

        this.createForm();

        if (this.uid) {
            this.getItem();
        }

        return;
    }

    onCloseDialog(product: ProductModel) {
        this.ref.close(product);
    }


    createForm(): void {
        this.form = this.formBuilder.group({
            uid: [this.productModel.uid],
            name: [this.productModel.name, [Validators.required, Validators.maxLength(200)]],
            code: [this.productModel.code, [Validators.required, Validators.maxLength(100)]],
            customer_product_id: [this.productModel.customer_product_id],
            product_packing_type_id: [this.productModel.product_packing_type_id, Validators.required],
            product_category_id: [this.productModel.product_category_id, Validators.required],
            fnc_id: [this.productModel.fnc_id, Validators.required],
            unit_measure_id: [this.productModel.unit_measure_id, Validators.required],
            recipe_id: [this.productModel.recipe_id, Validators.required]
        });
    }

    getItem(): void {
        this.loading = true;
        this.dropDownWithTrashed = true;
        this.productService.get(this.uid)
            .pipe(finalize(() => {this.loading = false; this.dropDownWithTrashed = false;}))
            .subscribe((response: any) => {
                this.productModel.map(response.payload);
                this.form.patchValue(this.productModel);
                // Temporary resolve
                this.form.patchValue({
                    name: response.payload.productName
                });
            });
    }


    onCancel(): void {
        this.ref.close(null);
    }

    checkIfFormValid(): boolean {
        if (this.form.invalid) {
            this.messageService.add({
                severity: 'error',
                summary: 'Formularul nu este valid!',
                detail: 'Vericficati toate campurile si incercati din nou.'
            });
            this.form.markAllAsTouched();
            return false;
        }
        return true;
    }

    onSave(): void {
        if (!this.checkIfFormValid()) {
            return;
        }
        this.loading = true;
        const data = this.form.getRawValue();
        this.productService.set(data)
            .pipe(finalize(() => this.loading = false))
            .subscribe((response: any) => {
                this.productModel.map(response.payload);
                this.form.patchValue(this.productModel);
                this.messageService.add({
                    severity: 'success',
                    summary: 'Produsul a fost salvat cu success!'
                });
                this.ref.close(this.productModel);
            }, err => {
                if (err instanceof HttpErrorResponse) {

                    this.validationMessageService.serverSideValidation(err, this.form, '', true);
                }
            });
    }

}
