import { Component, OnInit, ViewChild } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SttTableComponent } from '../../../shared/components/stt-table/stt-table.component';
import { SttTableService } from '../../../shared/components/stt-table/stt-table-service';
import { UnitMeasureActionTableComponent } from './unit-measure-action-table/unit-measure-action-table.component';
import { UnitMeasureAddEditComponent } from './unit-measure-add-edit/unit-measure-add-edit.component';
import { UnitMeasureModel } from 'src/app/shared/entities/unit-measure.model';
import { UnitMeasureService } from 'src/app/shared/services/app/unit-measure.service';

@Component({
    selector: 'app-unit-measures',
    templateUrl: './unit-measures.component.html',
    styleUrls: ['./unit-measures.component.scss']
})
export class UnitMeasuresComponent implements OnInit {

    @ViewChild('table', { static: false }) table: SttTableComponent;

    public tableEndpoint = 'unit-measures';
    public tableActionComponent = UnitMeasureActionTableComponent;
    public tableQuery: any;
    public ref: DynamicDialogRef;

    constructor(private unitMeasureService: UnitMeasureService, public dialogService: DialogService) {
        this.tableQuery = new SttTableService();
    }

    ngOnInit(): void {

    }

    addNewEntity() {
        this.ref = this.dialogService.open(UnitMeasureAddEditComponent, {
            header: 'Adaugare Unitati de masura',
            width: '30%',
            contentStyle: { 'max-height': '70vh' },
            baseZIndex: 10000,
            closeOnEscape: false,
            data: null,
        });

        this.ref.onClose.subscribe((unitMeasure: UnitMeasureModel) => {
            if (unitMeasure) {
                this.table.refresh();
            }
        });
    }
}
