<p-divider></p-divider>
<div class="flex-box" style="  display:flex;justify-content:space-between;">
    <p style="font-size: 20px">Comenzi  /  Client</p>
    <div style="display: flex; gap: 0.5em">
        <p-button *ngIf="userRole === 'Administrator' || userRole === 'Gestionar'" [label]="'Vizualizare Transport'" iconPos="right" (onClick)="goToTransportView()"></p-button>
        <p-button *ngIf="userRole === 'Administrator' || userRole === 'Logistica'" [label]="'Mapare Transport'" iconPos="right" (onClick)="goToTransportMap()"></p-button>
        <p-button [label]="'Adauga Comanda'" icon="pi pi-plus" iconPos="right"
                  routerLink="/orders/customer/add-edit"></p-button>
    </div>

</div>
<p-divider></p-divider>

<div class="grid">
    <div class="col-12">
        <div class="card">
            <app-stt-table #table
                           [requiresFNC]="true"
                           [endpoint]="tableEndpoint" [expandComponent]="expandComponent"
                           [actionTemplate]="tableActionComponent"
                           [query]="tableQuery">
            </app-stt-table>
        </div>
    </div>
</div>

<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" appendTo="body" closeOnEscape="true" dismissableMask="true" key="deleteCustomerOrder" ></p-confirmDialog>
