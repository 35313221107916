import { BaseModel } from './base.model';
import { QualityReportEnzymeItemsModel } from './quality-report-enzyme-items.model';
import { QualityReportCoccidiostatsItemsModel } from './quality-report-coccidiostats-items.model';

export class QualityReportModel extends BaseModel{
    uid: string;
    product_name: string;
    product_id: number;
    lot: string;
    production_date: Date;
    validity: number;
    usage_instructions: string;
    recipe_id: number;
    enzyme_items: Array<QualityReportEnzymeItemsModel>;
    coccidiostats_items: Array<QualityReportCoccidiostatsItemsModel>;
    genetically_modified_soy: string;
}
