import { Component, OnInit, ViewChild } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SttTableComponent } from '../../../shared/components/stt-table/stt-table.component';
import { SttTableService } from '../../../shared/components/stt-table/stt-table-service';
import { FncTableActionComponent } from './fnc-table-action/fnc-table-action.component';
import { FncService } from '../../../shared/services/app/fnc.service';
import { FncAddEditComponent } from './fnc-add-edit/fnc-add-edit.component';
import { FncModel } from '../../../shared/entities/fnc.model';

@Component({
    selector: 'app-fnc',
    templateUrl: './fnc.component.html',
    styleUrls: ['./fnc.component.scss']
})
export class FncComponent implements OnInit {
    @ViewChild('table', { static: false }) table: SttTableComponent;

    public tableEndpoint = 'fncs';
    public tableActionComponent = FncTableActionComponent;
    public tableQuery: any;
    public ref: DynamicDialogRef;

    constructor(private fncService: FncService, public dialogService: DialogService) {
        this.tableQuery = new SttTableService()
            .addInclude('county')
            .addInclude('city');
    }

    ngOnInit(): void {

    }

    addNewEntity() {
        this.ref = this.dialogService.open(FncAddEditComponent, {
            header: 'Adaugare FNC',
            width: '70%',
            contentStyle: { 'max-height': '70vh' },
            baseZIndex: 10000,
            closeOnEscape: false,
            data: null,
        });

        this.ref.onClose.subscribe((fnc: FncModel) => {
            if (fnc) {
                this.table.refresh();
            }
        });
    }
}
