import { Injectable } from '@angular/core';
import { HttpApiService } from '../base/http-api.service';
import { Observable } from 'rxjs';
import { ApiResponseModel } from '../../entities/api-response.model';
import { OrderCustomerModel } from '../../entities/order-customer.model';

@Injectable({
  providedIn: 'root'
})
export class OrderCustomerService {

    constructor(
        public httpApiService: HttpApiService,
    ) {

    }

    public get(uid): Observable<ApiResponseModel>
    {
        return this.httpApiService.httpGet('customer-orders/view', {uid});
    }

    public getById(id): Observable<ApiResponseModel>
    {
        return this.httpApiService.httpGet('customer-orders/view-by-id', {id});
    }

    public set(item: OrderCustomerModel): Observable<ApiResponseModel>
    {
        return this.httpApiService.httpPost('customer-orders/store', item);
    }

    public getOrdersByProductId(productId): Observable<ApiResponseModel>
    {
        return this.httpApiService.httpGet('customer-orders/view-by-product', {productId});
    }

    public delete(uid): Observable<ApiResponseModel> {
        return this.httpApiService.httpDelete('customer-orders/destroy', { uid });
    }

    public statusChange(status: number, uid): Observable<ApiResponseModel> {
        return this.httpApiService.httpPost('customer-orders/change-status', {status, uid});
    }

    public getItems(): Observable<ApiResponseModel> {
        return this.httpApiService.httpGet('customer-orders/get-items');
    }

}
