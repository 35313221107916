import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { RecipeService } from '../../../shared/services/app/recipe.service';
import { finalize, map, switchMap, tap } from 'rxjs/operators';
import { ProductionNoteService } from '../../../shared/services/app/production-note.service';
import { ProductService } from '../../../shared/services/app/product.service';

@Component({
  selector: 'app-raw-material-vs-product',
  templateUrl: './raw-material-vs-product.component.html',
  styleUrls: ['./raw-material-vs-product.component.scss']
})
export class RawMaterialVsProductComponent implements OnInit {
    public form: UntypedFormGroup;
    public loading = false;
    public headers = [];
    public fields = [];
    public stock = [];
    public unit_measure = [];
    public data: any;
    public fnc_id: number;

    constructor(
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private formBuilder: UntypedFormBuilder,
        private recipeService: RecipeService,
        public productionNoteService: ProductionNoteService,
        public productService: ProductService,
    ) {}

  ngOnInit(): void {
      const localStorageData = JSON.parse(localStorage.getItem('user'));
      this.fnc_id = localStorageData.fnc_id;

      this.createForm();
  }


    createForm(): void {
        this.form = this.formBuilder.group({
            recipe_id: [null, [Validators.required]],
            units: [null, [Validators.required]],
            unit_measure_id: [null],
        });
    }


    addRecipe(recipe: any, recipeStock: any){
        // Add new recipe Items
        recipe.forEach(element =>{
            let stockExists = false;
            this.fields.push([element.rawMaterial.name, element.units * this.form.value.units]);
            // Add stock to raw material items (into the stock array)
            recipeStock.forEach(recipeStockElement => {
                if (recipeStockElement.raw_material_id === element.raw_material_id && stockExists === false){
                    this.stock.push(recipeStockElement.total_stock);
                    stockExists = true;
                }
            });
            // If stock does not exist then push 0 (into the stock array)
            if(stockExists === false){
                this.stock.push(0);
            }
            this.unit_measure.push(element.rawMaterial.unitMeasure.name);
        });
    }

    addNewRecipe(recipe: any, recipeStock: any){
        // Add 0 to a new column on all the existing rows
        this.fields.forEach(element =>{
            element.splice(element.length, 0, 0);
        });
        // Check if raw material is already in list or add the new raw material
        recipe.forEach(recipeItem =>{
            let rawMaterialExists = false;
            this.fields.forEach(fieldItem =>{
                // Raw material already exists , update the required units
                if (recipeItem.rawMaterial.name === fieldItem[0]){
                    fieldItem[fieldItem.length - 1] = recipeItem.units * this.form.value.units;
                    rawMaterialExists = true;
                }
            });
            // Raw material does not exist in list, add new raw material
            if (rawMaterialExists === false){
                this.fields.push([recipeItem.rawMaterial.name]);
                const fieldsLength = this.fields.length;
                // Push 0's on the existing columns
                for (let i = 0; i < this.headers.length - 5; i++){
                    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                    this.fields[fieldsLength - 1].push(0);
                }
                // Update the last column with required units
                this.fields[fieldsLength - 1].push(recipeItem.units * this.form.value.units);
                // Add stock to the stock array
                recipeStock.forEach(recipeStockItem =>{
                   if(recipeItem.raw_material_id === recipeStockItem.raw_material_id){
                       this.stock.push(recipeStockItem.total_stock);
                   }
                });
                this.unit_measure.push(recipeItem.rawMaterial.unitMeasure.name);
            }
        });
    }

    submit(){
        this.loading = true;
        this.getRecipeData().subscribe(
            data => this.data = data
        );
    }

    getRecipeData(){
        return this.recipeService.getRecipesById(this.form.value.recipe_id).pipe(
            switchMap(recipe => this.productionNoteService.getRecipeRawMaterialStock(this.form.value.recipe_id, this.fnc_id).pipe(
                    switchMap(recipeStock => this.productService.getByRecipeId(this.form.value.recipe_id).pipe(
                        map(product => this.mapRecipeData(recipe, recipeStock, product))
                    ))
            )),
            finalize(() => this.loading = false)
        );
    }

    mapRecipeData(recipe, recipeStock, product){
        // Map unit_measure_id of product(associated with recipe)
        this.form.patchValue({
            unit_measure_id: product.payload.unit_measure_id,
        });

        // Add all recipe items
        if (this.fields.length === 0){
            this.addRecipe(recipe.payload.items, recipeStock.payload);
            this.headers.push('Materie prima');
            this.headers.push(recipe.payload.name + '(' + 'Cantitate : ' + this.form.value.units.toString() + ' ' + product.payload.unitMeasure.name+ ')');
            this.headers.push('Total');
            this.headers.push('Stoc');
            this.headers.push('Unitate de masura');
        }
        // Update recipe items
        else{
            const recipeHeader = recipe.payload.name + '(' + 'Cantitate : ' + this.form.value.units.toString() + ' ' + product.payload.unitMeasure.name+ ')';
            this.headers.splice(this.headers.length - 3,0, recipeHeader);
            this.addNewRecipe(recipe.payload.items, recipeStock.payload);
        }
    }

    getTotal(data: any){
        let total = 0;
        for (let i = 1; i < data.length; i++){
            total = total + data[i];
        }
        return total;

    }

}
