import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { RouterModule, Routes } from '@angular/router';
import { AuthComponent } from './auth.component';
import {ButtonModule} from 'primeng/button';
import {RippleModule} from 'primeng/ripple';
import {InputTextModule} from 'primeng/inputtext';
import {DividerModule} from 'primeng/divider';
import {SplitterModule} from 'primeng/splitter';
import {InputSwitchModule} from 'primeng/inputswitch';
import {UntypedFormBuilder, ReactiveFormsModule} from '@angular/forms';
import {MessageService} from 'primeng/api';
import {MessagesModule} from 'primeng/messages';
import {DialogModule} from 'primeng/dialog';
import {ToastModule} from 'primeng/toast';


const routes: Routes = [
    {path: 'auth', redirectTo: 'auth/login', pathMatch: 'full'},
    {
        path: 'auth', component: AuthComponent,
        children: [
            {path: 'login', component: LoginComponent},
        ]
    },
];

@NgModule({
    declarations: [
        LoginComponent,
        AuthComponent
    ],
    imports: [
        CommonModule,
        RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'}),
        ButtonModule,
        RippleModule,
        InputTextModule,
        DividerModule,
        SplitterModule,
        InputSwitchModule,
        MessagesModule,
        DialogModule,
        ToastModule,
        ReactiveFormsModule,
    ],
    providers: [
        UntypedFormBuilder,
        MessageService
    ]
})
export class AuthModule { }
