import { BaseModel } from '../../shared/entities/base.model';
import { FncModel } from '../../shared/entities/fnc.model';
import { UserRoleModel } from "../../shared/entities/user-role.model";

export class UserModel extends BaseModel {
    uid: string;
    code: string;
    firstName: string;
    lastName: string;
    fnc_id: number;
    fnc: FncModel;
    email: string;
    password: string;
    password_confirmation: string;
    isAdmin: boolean;
    customer: any;
    role: any;
    created_at: Date;
    updated_at: Date;
    deleted_at: Date;
    isActive: boolean;
    roles: UserRoleModel;

    getFullName(): string {
        return this.lastName + ' ' + this.firstName;
    }

}
