import { Component, OnInit } from '@angular/core';
import { OrderCustomerModel } from '../../../../shared/entities/order-customer.model';
import { HttpErrorResponse } from '@angular/common/http';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { HttpApiService } from '../../../../shared/services/base/http-api.service';
import { ValidationMessageService } from '../../../../shared/services/base/validation-message-handle';
import { OrderCustomerService } from '../../../../shared/services/app/order-customer.service';
import { MessageService } from 'primeng/api';
import { finalize } from 'rxjs/operators';
import { CustomerService } from '../../../../shared/services/app/customer.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';

enum Status {
    Draft = 0,
    Validated = 1,
    Finished = 2
}

@Component({
    selector: 'app-orders-customer-add-edit',
    templateUrl: './orders-customer-add-edit.component.html',
    styleUrls: ['./orders-customer-add-edit.component.scss']
})
export class OrdersCustomerAddEditComponent implements OnInit {

    public uid: string;
    public itemModel: OrderCustomerModel = new OrderCustomerModel();
    public loading = false;
    public canSave = true;
    public saveButtonLabel = 'Salveaza';
    public form: UntypedFormGroup;
    // New added
    public minimumDate = new Date();
    public returnProductId = true;
    public selected: any;
    public fnc_id: number;
    public dropDownWithTrashed = false;
    public readonlyTransport  = [false, false, false, false, false, false, false];
    public status: number;
    public readOnly = false;
    public showCalendar = true;
    public enumStatus = Status;


    constructor(
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private formBuilder: UntypedFormBuilder,
        private httpApiService: HttpApiService,
        private validationMessageService: ValidationMessageService,
        private orderCustomerService: OrderCustomerService,
        private messageService: MessageService,
        private customerService: CustomerService,
        private route: ActivatedRoute,
        public router: Router,
        public datePipe: DatePipe,
    ) {
        this.uid = this.route.snapshot.queryParams.uid;
    }

    ngOnInit(): void {

        const localStorageData = JSON.parse(localStorage.getItem('user'));
        this.fnc_id = localStorageData.fnc_id;

        this.createForm();

        if (this.uid) {
            this.getItem();
        }

        this.form.controls.product_category_id.valueChanges.subscribe(value => {
            for (let i = -1; i < this.formWeek().length; i++) {
                for (let j = 0; j < this.formProducts(i).length; j++){
                    this.formProducts(i).at(j).patchValue({
                        product_category_id: this.form.value.product_category_id
                    });
                }
            }
        });
    }

    createForm(): void {

        this.form = this.formBuilder.group({
            uid: [this.itemModel.uid],
            customer_id: [this.itemModel.customer_id, Validators.required],
            customer_address_id: [this.itemModel.customer_address_id, Validators.required],
            code: [this.itemModel.code, [Validators.required, Validators.maxLength(100)]],
            product_category_id: [this.itemModel.product_category_id, Validators.required],
            fnc_id: [this.fnc_id, Validators.required],
            weekOrder: this.formBuilder.array([])
        });


        // this.formWeek().push(this.newWeekDay(null, null));
        this.formWeek().push(this.newWeekDay('L', [0, 2, 3, 4, 5, 6]));
        this.formWeek().push(this.newWeekDay('M', [0, 1, 3, 4, 5, 6]));
        this.formWeek().push(this.newWeekDay('M', [0, 1, 2, 4, 5, 6]));
        this.formWeek().push(this.newWeekDay('J', [0, 1, 2, 3, 5, 6]));
        this.formWeek().push(this.newWeekDay('V', [0, 1, 2, 3, 4, 6]));
        this.formWeek().push(this.newWeekDay('S', [0, 1, 2, 3, 4, 5]));
        this.formWeek().push(this.newWeekDay('D', [1, 2, 3, 4, 5, 6]));

        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < this.formWeek().length; i++) {
            this.formProducts(i).push(this.newProduct());
        }
    }

    formWeek(): UntypedFormArray {
        return this.form.get('weekOrder') as UntypedFormArray;
    }

    formProducts(index: number): UntypedFormArray {
        return this.formWeek().at(index).get('products') as UntypedFormArray;
    }

    newWeekDay(day?, currentAvailableDay?): UntypedFormGroup {
        // const hourStart = new Date();
        // const hourEnd = new Date();
        // hourStart.setHours(8,0,0);
        // hourEnd.setHours(17,0,0);
        return this.formBuilder.group({
            day: [day],
            currentAvailableDay: [currentAvailableDay],
            deliveryDate: [null],
            deliveryHourStart: [null],
            deliveryHourEnd: [null],
            transport_unit_type_id: [null],
            noTransport: [false],
            products: this.formBuilder.array([]),
        });
    }

    newProduct(): UntypedFormGroup {
        return this.formBuilder.group({
            uid: [null],
            product_category_id: [(this.form.value.product_category_id) ? this.form.value.product_category_id : null],
            product_id: [null],
            quantity: [null,Validators.min(0)],
            product_packing_type_id: [null],
        });
    }


    addProduct(): void {
        for (let i = 0; i < this.formWeek().length; i++) {
            this.formProducts(i).push(this.newProduct());
        }
    }

    setProductId(event, firstIndex = 1): void {
        for (let i = firstIndex; i < this.formWeek().length; i++) {
            const formItem = this.formProducts(i);
            formItem.at(event.index).patchValue({
                product_id: event.id
            });
        }
    }

    onTimeInput(index): void {
        const formOrder = this.formWeek().at(index);
        const deliveryHourStart: Date = formOrder.value.deliveryHourStart;
        const deliveryHourEnd: Date = formOrder.value.deliveryHourEnd;

        if (deliveryHourEnd.getHours() - deliveryHourStart.getHours() < 4) {
            this.canSave = false;
            this.saveButtonLabel = `Interval orar invalid pe randul ${ index + 1 }`;
        } else {
            this.canSave = true;
            this.saveButtonLabel = 'Salveaza';
        }

    }

    onSelectTimeStart(event, index): void {
        const formOrder = this.formWeek().at(index);
        const deliveryHourStart: Date = formOrder.value.deliveryHourStart;
        let deliveryHourEnd: Date = formOrder.value.deliveryHourEnd;

        if (!deliveryHourEnd) {
            const newDate = new Date();
            newDate.setHours(deliveryHourStart.getHours() + 4);
            newDate.setMinutes(deliveryHourStart.getMinutes());
            formOrder.patchValue({
                deliveryHourEnd: newDate,
            });
            deliveryHourEnd = formOrder.value.deliveryHourEnd;
        }

        if (deliveryHourStart.getHours() > 19) {
            const newEndHour: Date = deliveryHourEnd;
            const newStartHour: Date = deliveryHourStart;
            newStartHour.setHours(19);
            newStartHour.setMinutes(0);
            newEndHour.setHours(23);
            newEndHour.setMinutes(0);
            formOrder.patchValue({
                deliveryHourEnd: newEndHour,
            });
            formOrder.patchValue({
                deliveryHourStart: newStartHour,
            });
        } else if (deliveryHourEnd.getHours() - deliveryHourStart.getHours() < 4) {
            const newHour: Date = deliveryHourEnd;
            newHour.setHours(event.getHours() + 4);
            newHour.setMinutes(event.getMinutes());
            formOrder.patchValue({
                deliveryHourEnd: newHour,
            });
        }
        this.canSave = true;
        this.saveButtonLabel = 'Salveaza';

    }

    onSelectTimeEnd(event, index): void {
        const formOrder = this.formWeek().at(index);

        let deliveryHourStart: Date = formOrder.value.deliveryHourStart;
        const deliveryHourEnd: Date = formOrder.value.deliveryHourEnd;

        if (!deliveryHourStart) {
            const newDate = new Date();
            newDate.setHours(deliveryHourEnd.getHours() - 4);
            newDate.setMinutes(deliveryHourEnd.getMinutes());
            formOrder.patchValue({
                deliveryHourStart: newDate,
            });
            deliveryHourStart = formOrder.value.deliveryHourStart;
        }

        if (deliveryHourEnd.getHours() < 4) {
            const newEndHour: Date = deliveryHourEnd;
            const newStartHour: Date = deliveryHourStart;
            newStartHour.setHours(0);
            newStartHour.setMinutes(0);
            newEndHour.setHours(4);
            newEndHour.setMinutes(0);
            formOrder.patchValue({
                deliveryHourEnd: newEndHour,
            });
            formOrder.patchValue({
                deliveryHourStart: newStartHour,
            });
        } else if (deliveryHourEnd.getHours() - deliveryHourStart.getHours() < 4) {
            const newHour: Date = deliveryHourStart;
            newHour.setHours(event.getHours() - 4);
            newHour.setMinutes(event.getMinutes());
            formOrder.patchValue({
                deliveryHourStart: newHour,
            });
        }
        this.canSave = true;
        this.saveButtonLabel = 'Salveaza';
    }

    getItem(): void {
        let flagProductItem = false;
        let indexProductItem;
        this.loading = true;
        this.canSave = false;
        this.dropDownWithTrashed = true;
        this.orderCustomerService.get(this.uid)
            .pipe(finalize(() => {
                this.loading = false;
                this.canSave = true;
                this.dropDownWithTrashed = false;
            }))
            .subscribe((response: any) => {
                this.itemModel.map(response.payload);
                this.form.patchValue(this.itemModel);

                // Set data for the delivery date and hour interval(overwrite not a problem)
                response.payload.customerOrderItems.forEach(element => {
                    let dateIndex = new Date(element.deliveryDate).getDay();
                    if (dateIndex > 0) {
                        dateIndex--;
                    } else {
                        dateIndex = 6;
                    }
                    const dateParse = new Date(element.deliveryDate);
                    const hourStartParse = new Date(element.deliveryHourStart);
                    const hourEndParse = new Date(element.deliveryHourEnd);
                    if (element.noTransport === true){
                        this.formWeek().at(dateIndex).patchValue({
                            noTransport: true
                        });
                        this.readonlyTransport[dateIndex] = true;
                    }
                    this.formWeek().at(dateIndex).patchValue({
                        deliveryDate: dateParse,
                        deliveryHourStart: hourStartParse,
                        deliveryHourEnd: hourEndParse,
                        transport_unit_type_id: element.transport_unit_type_id
                    });
                    flagProductItem = false;
                    this.formProducts(dateIndex).value.forEach((productItem, index) => {
                        if (productItem.product_id === element.product_id) {
                            flagProductItem = true;
                            indexProductItem = index;

                        }
                    });
                    if (flagProductItem) {
                        this.formProducts(dateIndex).at(indexProductItem).patchValue({
                            uid: element.uid,
                            product_packing_type_id: element.product_packing_type_id,
                            quantity: element.quantity,
                        });
                    } else {
                        this.addProduct();
                        indexProductItem = this.formProducts(dateIndex).length - 1;
                        this.setProductId({ index: indexProductItem, id: element.product_id }, 0);
                        this.formProducts(dateIndex).at(indexProductItem).patchValue({
                            uid: element.uid,
                            product_packing_type_id: element.product_packing_type_id,
                            quantity: element.quantity,
                        });
                    }
                });
                for (let i = 0; i < this.formWeek().length; i++) {
                    const formItem = this.formProducts(i);
                    formItem.removeAt(0);
                }
                this.status = response.payload.status;
                if(this.status > Status.Draft){
                    this.readOnly = true;
                    this.showCalendar = false;
                }
            });
    }

    onDateChange(event: any, index: number){
        // The dropdown will be read only but just to be sure :)))
        if(event){
            const dateHourStart = new Date();
            const dateHourEnd = new Date();
            dateHourStart.setHours(8,0,0);
            dateHourEnd.setHours(17,0,0);
            this.formWeek().at(index).patchValue({
                deliveryHourStart: dateHourStart,
                deliveryHourEnd: dateHourEnd
            });
            this.formWeek().at(index).get('deliveryHourStart').setValidators([Validators.required]);
            this.formWeek().at(index).get('deliveryHourEnd').setValidators([Validators.required]);
        }
        else{
            this.formWeek().at(index).patchValue({
                deliveryDate: null,
                deliveryHourStart: null,
                deliveryHourEnd: null
            });
            this.formWeek().at(index).get('deliveryHourStart').clearValidators();
            this.formWeek().at(index).get('deliveryHourEnd').clearValidators();
        }
    }

    noTransportChange(index: number, event: any): void{
        this.readonlyTransport[index] = event.checked;
        if (event.checked === true){
            this.formWeek().at(index).patchValue({
                transport_unit_type_id: null
            });
        }
    }

    checkIfFormValid(): boolean {
        if (this.form.invalid) {
            this.messageService.add({
                severity: 'error',
                summary: 'Formularul nu este valid!',
                detail: 'Vericficati toate campurile si incercati din nou.'
            });
            this.form.markAllAsTouched();
            return false;
        }
        return true;
    }

    onSave(): void {
        if (!this.checkIfFormValid()) {
            return;
        }
        // if endtime - starttime < 4 ore -> toast('interval orar minim 4 ore')
        this.loading = true;
        this.canSave = false;
        const data = this.form.getRawValue() as OrderCustomerModel;
        data.weekOrder.forEach(element => {
            delete element.day;
            delete element.currentAvailableDay;
        });
        this.orderCustomerService.set(data)
            .pipe(finalize(() => {
                this.loading = false;
                this.canSave = true;
            }))
            .subscribe((response: any) => {
                this.itemModel.map(response.payload);
                this.form.patchValue(this.itemModel);
                this.messageService.add({
                    severity: 'success',
                    summary: 'Comanda a fost plasata cu success!'
                });
                this.router.navigate(['orders/customer/list'], {});
            }, err => {
                if (err instanceof HttpErrorResponse) {
                    this.validationMessageService.serverSideValidation(err, this.form, '', true);
                }
            });
    }
    validateForm(){
        this.status = this.enumStatus.Validated;
        const summary = 'Comanda a fost validata cu success!';
        this.changeStatus(summary);
    }

    finishForm(){
        this.status = this.enumStatus.Finished;
        const summary = 'Comanda a fost finalizata cu success!';
        this.changeStatus(summary);
    }

    changeStatus(summary: string){
        this.orderCustomerService.statusChange(this.status, this.uid)
            .pipe(finalize(() => this.loading = false))
            .subscribe((response: any) => {
                this.itemModel.map(response.payload);
                this.form.patchValue(this.itemModel);
                this.messageService.add({
                    severity: 'success',
                    summary
                });
                this.router.navigate(['orders/customer/list'], {});
            }, err => {
                if (err instanceof HttpErrorResponse) {
                    this.validationMessageService.serverSideValidation(err, this.form, '', true);
                }
            });
    }
}
