<div [formGroup]="form" class="grid fields-group">
    <ng-container>
        <div class="col-12" style="padding: 0px 10px 0px 15px;" >
            <div *ngIf="productsExist === true">
                <p-accordion [multiple]="false" (onOpen)="onOpenProduct($event)" (onClose)="onCloseProduct($event)" [formGroup]="productForm"
                             *ngFor="let productForm of formProducts().controls; let i = index" [style]="getStyleAccordion(productForm?.controls?.isDone.value)">
                    <!--                <div *ngIf="productForm.controls.isDone.value ? changeAccordionColor(productForm) : changeAccordionColor2(productForm)"></div>-->
                    <p-accordionTab >
                        <ng-template pTemplate="header" style="border-width: 0 !important;" >
                            <span>{{productForm?.controls?.name.value}}</span>
                            <span class="checkmark" *ngIf="checkIfProductDone(i)">
                            <div class="checkmark_stem"></div>
                            <div class="checkmark_kick"></div>
                        </span>
                        </ng-template>
                        <p-tabView >
                            <p-tabPanel *ngFor="let day of formProductsDate(i).controls; let i_weekday = index" headerStyleClass="weekDone" [formGroup]="day" [headerStyle]="getStyleTabView(day?.controls?.weekDayDone.value)">
                                <ng-template pTemplate="header">
                                <span class="p-tabview-title">
                                    {{weekDays[day.value.deliveryDay]}}: {{getDateFromControl(day)}}
                                </span>
                                </ng-template>
                                <div class="grid p-fluid justify-content-start" style="margin-top: 10px;">
                                    <div>{{formProductsDate(i).at(i_weekday).value.quantityTransportTotal}}
                                        / {{formProductsDate(i).at(i_weekday).value.quantity}}</div>
                                    <!--                                    <div *ngIf="!day.value.quantityTransportTotal">-->
                                    <!--                                        <div>0 / {{formProductsDate(i).at(i_weekday).value.quantity}}</div>-->
                                    <!--                                    </div>-->
                                </div>
                                <app-order-customers-transport-progressbar [form]="form" [i]="i"
                                                                           [i_weekday]="i_weekday"></app-order-customers-transport-progressbar>
                                <!--                            <p-progressBar [value]="progressValue"></p-progressBar>-->
                            </p-tabPanel>
                        </p-tabView>
                    </p-accordionTab>
                </p-accordion>
                <p-button styleClass="p-button-success" class="saveButton" (click)="save()"> Aloca Transport</p-button>
            </div>
            <div *ngIf="productsExist === false">
                <p>Nu exista produse cu transport alocat</p>
            </div>
            <div class="card" *ngIf="loading">
                <p-progressBar mode="indeterminate" class="addEditProgressBar"></p-progressBar>
            </div>
        </div>
    </ng-container>
</div>




