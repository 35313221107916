<form [formGroup]="form" style="max-width: 100%!important;" autocomplete="false" (ngSubmit)="onSave()">
    <div class="grid fields-group">
        <div class="col-12">
            <div class="grid p-fluid">
                <div class="field col-12 md:col-6">
                    <app-stt-input inputId="name" [control]="form.controls['name']"
                                   icon="pi pi-users"
                                   label="Denumire"></app-stt-input>
                </div>
                <div class="field col-12 md:col-6">
                    <app-stt-input inputId="code" [control]="form.controls['code']"
                                   icon="pi pi-key"
                                   label="Cod SAP"></app-stt-input>
                </div>
            </div>


            <div class="grid p-fluid">
                <div class="field col-12 md:col-6">
                    <app-stt-input inputId="address" [control]="form.controls['address']"
                                   icon="pi pi-home"
                                   label="Adresa"></app-stt-input>
                </div>
                <div class="field col-12 md:col-6">
                    <app-stt-input inputId="sarja" [control]="form.controls['sarja']"
                                   icon="pi pi-th-large" type="number"
                                   label="Capacitate Sarja (KG)"></app-stt-input>
                </div>
            </div>


            <div class="grid p-fluid">
                <div class="field col-12 md:col-6">
                    <app-stt-dropdown placeholder="Nu ati selectat un judet!" endPoint="counties"
                                      icon="pi pi-map"
                                      [formControlParent]="form.controls['county_id']" label="Judet"></app-stt-dropdown>
                </div>
                <div class="field col-12 md:col-6">
                    <app-stt-dropdown placeholder="Nu ati selectat un oras!" endPoint="cities"
                                      icon="pi pi-map"
                                      formControlNameDependency="county_id"
                                      [formControlParent]="form.controls['city_id']" label="Oras"></app-stt-dropdown>
                </div>
            </div>


            <div class="grid p-fluid">
                <div class="field col-12 md:col-6">
                    <p-toggleButton formControlName="hasPlc"
                                    onLabel="Cu PLC" offLabel="Fara PLC"
                                    (click)="disableField()"
                                    onIcon="pi pi-check" offIcon="pi pi-times">
                    </p-toggleButton>
                </div>

                <div class="field col-12 md:col-6">
                    <app-stt-input inputId="ipPlc" [control]="form.controls['ipPlc']"
                                   icon="pi pi-globe" [readonly]="readonly"
                                   label="IP Plc"></app-stt-input>
                </div>
            </div>

        </div>
    </div>

    <p-progressBar mode="indeterminate" class="addEditProgressBar" *ngIf="loading"></p-progressBar>

    <div class="actions-area">
        <div class="actions-left">
            <button pButton pRipple type="button" label="Anulare" class="p-button-raised p-button-danger"
                    icon="pi pi-ban" [disabled]="loading" (click)="onCancel()">
            </button>
        </div>

        <div class="actions-right">
            <button pButton pRipple type="submit" label="Salveaza" class="p-button-raised p-button-success"
                    icon="pi pi-save" [disabled]="loading">
            </button>
        </div>
    </div>
</form>
