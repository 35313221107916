import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpApiService } from '../base/http-api.service';
import { ApiResponseModel } from '../../entities/api-response.model';
import { UnitMeasureModel } from '../../entities/unit-measure.model';

@Injectable({
    providedIn: 'root'
})
export class UnitMeasureService {

    constructor(
        public httpApiService: HttpApiService,
    ) {

    }

    public get(uid): Observable<ApiResponseModel> {
        return this.httpApiService.httpGet('unit-measures/view', { uid });
    }

    public set(unitMeasure: UnitMeasureModel): Observable<ApiResponseModel> {
        return this.httpApiService.httpPost('unit-measures/store', unitMeasure);
    }

    public delete(uid): Observable<ApiResponseModel> {
        return this.httpApiService.httpDelete('unit-measures/destroy', { uid });
    }

}
