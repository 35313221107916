<div class="col-12" #container style="overflow:visible">

    <p-progressBar mode="indeterminate" *ngIf="loading"></p-progressBar>

    <p-table #grid dataKey="id" [styleClass]="tableStyle"
             (onFilter)="onApplyFilters($event)" rowExpandMode="single"
             [columns]="tableColumns" [lazy]="true" [loading]="loading" [rowHover]="true" [showCurrentPageReport]="true" (onLazyLoad)="onLazyLoad($event)"
             [value]="tableItems"  [totalRecords]='query.total' [responsive]='false' [rows]='query.perPage'  [paginator]='true'
             [rowsPerPageOptions]='[5,10,15,20,50,100]' [selectionMode]="selectionMode" [(selection)]="selectedItems" (onRowSelect)="onRowSelect($event)"
             [scrollable]="true" [autoLayout]="true" scrollDirection="Horizontal">
            <ng-template pTemplate="header">
                <tr>
                    <th *ngIf="expandComponent" [frozen]="true" pFrozenColumn [alignFrozen]="'left'" style="max-width: 50px"></th>
                    <th *ngFor="let col of tableColumns" [pSortableColumn]='(col.sortable === true) ? col.field : null' [frozen]="(col.frozen)" pFrozenColumn [alignFrozen]="col.frozen" style="width:200px; display: flex;"
                    [class.align-right-column]="alignEndColumn(col)">
                        {{col.header}}
                        <p-sortIcon [field]="col.field" *ngIf="col.sortable"></p-sortIcon>
                    </th>
                </tr>

                <tr>
                    <th *ngIf="expandComponent" [frozen]="true" pFrozenColumn [alignFrozen]="'left'" style="max-width: 50px;"></th>
                    <th *ngFor="let col of tableColumns" [frozen]="(col.frozen)" pFrozenColumn [alignFrozen]="col.frozen" style="width:200px;">
                        <div *ngIf="getFiledType(col) !== 'date'">
                            <p-columnFilter
                                pStyleClass="p-inputtext-sm"
                                matchMode="contains"
                                [type]="getFiledType(col)"
                                [showMatchModes]="true"
                                [showOperator]="true"
                                [showMenu]="true"
                                [field]="col.field" *ngIf="col.filterable"
                                >
                            </p-columnFilter>
                        </div>
                        <div *ngIf="getFiledType(col) === 'date'">
                            <p-columnFilter
                                pStyleClass="p-inputtext-sm"
                                matchMode="is"
                                [type]="getFiledType(col)"
                                [showMatchModes]="true"
                                [showOperator]="true"
                                [showMenu]="true"
                                [field]="col.field" *ngIf="col.filterable"
                            >
                                <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                    <p-calendar #calendar [ngModel]="value" dataType="string" dateFormat="dd/mm/yy" [showIcon]="false" appendTo="body" (onSelect)="filter(convertDateToString($event))" (onInput)="setup(calendar.value, col.field)"></p-calendar>
                                </ng-template>
                            </p-columnFilter>
                        </div>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item let-expanded="expanded">

                <tr [pSelectableRowDisabled]="!enableSelection" [pSelectableRow]="item">

                    <td *ngIf="expandComponent" [frozen]="true" pFrozenColumn [alignFrozen]="'left'" style="max-width: 50px">
                        <button type="button" pButton pRipple [pRowToggler]="item" class="p-button-text p-button-rounded p-button-plain" [icon]="expanded ? 'fas fa-arrow-down' : 'fas fa-arrow-right'"></button>
                    </td>

                    <td *ngFor="let col of tableColumns" [frozen]="(col.frozen)" pFrozenColumn [alignFrozen]="col.frozen" style="width:200px; word-wrap: break-word; overflow: clip" [class.align-right-column]="alignEndColumn(col)">
                        <span *ngIf="!col.template">{{ renderValue(item, col) }}</span>
                        <span *ngIf="col.template === 'actions' && actionTemplate">
                               <app-stt-table-actions [template]="actionTemplate" [params]="item" [tableReference]="this" style="float: right"></app-stt-table-actions>
                        </span>

                    </td>
                </tr>

            </ng-template>
            <ng-template pTemplate="rowexpansion" let-item>
                <div [ngStyle]="{'max-width.px': containerWith, 'margin-left.px': expandComputedMargin}">
                    <p-divider></p-divider>
                    <app-stt-table-raw-expand #expandReference [data]="item" [template]="expandComponent" [table]="grid"></app-stt-table-raw-expand>
                    <p-divider></p-divider>
                </div>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="5">Nu sunt inregistrari </td>
                </tr>
            </ng-template>
    </p-table>
