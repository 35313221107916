import { Component, OnInit } from '@angular/core';
import { SttTableComponent } from '../../../../shared/components/stt-table/stt-table.component';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { RawMaterialTypesModel } from 'src/app/shared/entities/raw-material-types.model';
import { RawMaterialTypesAddEditComponent } from '../raw-material-types-add-edit/raw-material-types-add-edit.component';
import { ConfirmationService, MessageService, PrimeNGConfig } from 'primeng/api';
import { ValidationMessageService } from '../../../../shared/services/base/validation-message-handle';
import { RawMaterialTypeService } from '../../../../shared/services/app/raw-material-type.service';
import { finalize } from "rxjs/operators";

@Component({
  selector: 'app-raw-material-types-action-table',
  templateUrl: './raw-material-types-action-table.component.html',
  styleUrls: ['./raw-material-types-action-table.component.scss']
})
export class RawMaterialTypesActionTableComponent implements OnInit {

  public data: RawMaterialTypesModel;
  public table: SttTableComponent;
  public ref: DynamicDialogRef;
  public loading = true;

  constructor(
      public dialogService: DialogService,
      public confirmationService: ConfirmationService,
      public primengConfig: PrimeNGConfig,
      public itemService: RawMaterialTypeService,
      public messageService: MessageService,
      private validationMessageService: ValidationMessageService,
  ) {

  }

  ngOnInit(): void {
  }

  setParams(entity: RawMaterialTypesModel): void {
      this.data = entity;
  }

  setTableReference(table: SttTableComponent): void
  {
      this.table = table;
  }


  showDialog() {
      this.ref = this.dialogService.open(RawMaterialTypesAddEditComponent, {
          header: 'Modificare Tip de Materie prima',
          width: '30%',
          contentStyle: {'max-height': '70vh'},
          baseZIndex: 10000,
          closeOnEscape: false,
          data: this.data,
      });

      this.ref.onClose.subscribe((raw_material_type: RawMaterialTypesModel) => {
          if (raw_material_type) {
              this.table.refresh();
          }
      });
  }

    confirm() {
        this.confirmationService.confirm({
            header: 'Confirmare stergere Tip materie prima',
            message: 'Sunteti sigur ca doriti sa stergeti acest Tip de materie prima?',
            key: 'deleteRawMaterialType',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Da',
            rejectLabel: 'Nu',
            acceptButtonStyleClass: 'p-button-md p-button-success',
            rejectButtonStyleClass: 'p-button-md p-button-danger',
            accept: () => {
                this.deleteItem(this.data.uid);
            },
            reject: () => {
                this.confirmationService.close();
                this.table.refresh();
            }
        });
    }

    deleteItem(uid: string){
        this.itemService.delete(uid)
            .pipe(finalize(() => {this.loading = false;this.table.refresh();}))
            .subscribe((response: any) => {
                this.messageService.add({
                    severity: 'success',
                    summary: 'Tipul de materie prima a fost sters cu success!'
                });
            });
    }
}
