<form [formGroup]="form" style="max-width: 100%!important;" autocomplete="false" (ngSubmit)="onSave()">
    <div class="grid fields-group">
        <div class="col-12">
            <div class="grid p-fluid">
                <div class="field col-12 md:col-6">
                    <app-stt-input inputId="name" [control]="form.controls['name']"
                                   icon="pi pi-users"
                                   label="Denumire"></app-stt-input>
                </div>
                <div class="field col-12 md:col-6">
                    <app-stt-input inputId="code" [control]="form.controls['code']"
                                   icon="pi pi-key"
                                   label="Cod SAP"></app-stt-input>
                </div>
            </div>
            <div class="grid p-fluid">
                <div class="field col-12 md:col-6">
                    <app-stt-input inputId="uniqueCode" [control]="form.controls['uniqueCode']"
                                   icon="pi pi-building" type="text"
                                   label="CUI"></app-stt-input>
                </div>
                <div class="field col-12 md:col-6">
                    <app-stt-input inputId="registrationNumber" [control]="form.controls['registrationNumber']"
                                   icon="pi pi-building" type="text"
                                   label="Numar de inregistrare"></app-stt-input>
                </div>
            </div>
            <div class="grid p-fluid">

                <div class="field col-12 md:col-6">
                    <app-stt-input inputId="email" [control]="form.controls['email']"
                                   icon="pi pi-envelope" type="email"
                                   label="Email"></app-stt-input>
                </div>

                <div class="field col-12 md:col-6">
                    <app-stt-input inputId="phone" [control]="form.controls['phone']"
                                   icon="pi pi-phone" type="text"
                                   label="Telefon"></app-stt-input>
                </div>
            </div>
            <div class="grid p-fluid">
                <div class="field col-12 md:col-6">
                    <app-stt-dropdown placeholder="Nu ati selectat judetul!" endPoint="counties"
                                      icon="pi pi-map"
                                      [formControlParent]="form.controls['county_id']" label="Judet"></app-stt-dropdown>
                </div>
                <div class="field col-12 md:col-6">
                    <app-stt-dropdown placeholder="Nu ati selectat orasul!" endPoint="cities"
                                       icon="pi pi-map"
                                      formControlNameDependency="county_id"
                                      [formControlParent]="form.controls['city_id']" label="Oras"></app-stt-dropdown>
                </div>
            </div>
            <div class="grid p-fluid">
                <div class="field col-12 md:col-6">
                    <app-stt-input inputId="address" [control]="form.controls['address']"
                                   icon="pi pi-home"  type="text"
                                   label="Adresa"></app-stt-input>
                </div>
                <div class="field col-12 md:col-6">
                    <app-stt-dropdown placeholder="Nu ati selectat tipul de companie!"
                                      endPoint="transport-company-types/dropdown"
                                      [formControlParent]="form.controls['type_id']"
                                      icon="pi pi-th-large"
                                      label="Tip companie"
                                      [dropDownWithTrashed]="dropDownWithTrashed"></app-stt-dropdown>
                </div>
            </div>
        </div>
    </div>
    <p-progressBar mode="indeterminate" class="addEditProgressBar" *ngIf="loading"></p-progressBar>

    <div class="actions-area">
        <div class="actions-left">
            <button pButton pRipple type="button" label="Anulare" class="p-button-raised p-button-danger"
                    icon="pi pi-ban" [disabled]="loading" (click)="onCancel()">
            </button>
        </div>

        <div class="actions-right">
            <button pButton pRipple type="submit" label="Salveaza" class="p-button-raised p-button-success"
                    icon="pi pi-save" [disabled]="loading">
            </button>
        </div>
    </div>
</form>
