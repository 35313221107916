import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MenuItem } from 'primeng/api';


@Component({
    selector: 'app-nomenclature',
    templateUrl: './nomenclature.component.html',
    styleUrls: ['./nomenclature.component.scss']
})
export class NomenclatureComponent implements OnInit {
    @Input() addNewEntityButtonLabel = null;
    @Output() addNewEntityEvent = new EventEmitter();

    public items: MenuItem[];

    constructor() {
    }

    ngOnInit(): void {

        this.items = [
            { label: 'FNC', icon: 'fas fa-industry', routerLink: ['/nomenclatures/fnc'] },
            { label: 'Utilizatori', icon: 'fas fa-users', routerLink: ['/nomenclatures/users'] },
            { label: 'Clienti', icon: 'fas fa-users-rectangle', routerLink: ['/nomenclatures/customers'] },
            { label: 'Furnizori', icon: 'fas fa-person-chalkboard', routerLink: ['/nomenclatures/suppliers'] },
            {
                label: 'Stocare', icon: 'fas fa-box', items: [
                    {
                        label: 'Tipuri de stocare',
                        icon: 'fas fa-boxes-stacked',
                        routerLink: ['/nomenclatures/storage-types']
                    },
                    {
                        label: 'Spatii de stocare',
                        icon: 'fas fa-warehouse',
                        routerLink: ['/nomenclatures/storage-spaces']
                    },
                ]
            },


            {
                label: 'Produse', icon: 'fas fa-box-open', items: [
                    { label: 'Produse', icon: 'fas fa-box-open', routerLink: ['/nomenclatures/products'] },
                    { label: 'Produse Client', icon: 'fas fa-users fa-fw', routerLink: ['/nomenclatures/customer-products'] },
                    { label: 'Specii', icon: 'fas fa-pen-ruler', routerLink: ['/nomenclatures/product-categories'] },
                    // { label: 'Materii prime', icon: 'fas fa-dolly', routerLink: ['/nomenclatures/row-materials'] },
                    // {
                    //     label: 'Tipuri Materie prima',
                    //     icon: 'fas fa-table-cells-large',
                    //     routerLink: ['/nomenclatures/row-material-types']
                    // },
                    { label: 'Retete', icon: 'fas fa-rectangle-list', routerLink: ['/nomenclatures/recipes'] },
                    {
                        label: 'Tip ambalaj',
                        icon: 'fas fa-cubes',
                        routerLink: ['/nomenclatures/product-packing-types']
                    },
                    { label: 'Unitati de masura', icon: 'fas fa-ruler', routerLink: ['/nomenclatures/units-measure'] },
                ]
            },

            {
                label: 'Materii prime', icon: 'fas fa-dolly', items: [
                    { label: 'Materii prime', icon: 'fas fa-dolly', routerLink: ['/nomenclatures/row-materials'] },
                    {
                        label: 'Tipuri Materie prima',
                        icon: 'fas fa-table-cells-large',
                        routerLink: ['/nomenclatures/row-material-types']
                    },
                ]
            },

            {
                label: 'Laborator', icon: 'fas fa-vial-virus', items: [
                    { label: 'Enzime', icon: 'fas fa-virus', routerLink: ['/nomenclatures/enzymes'] },
                    { label: 'Coccidiostatice', icon: 'fas fa-bacteria', routerLink: ['/nomenclatures/coccibiostatics'] },
                ]
            },

            {
                label: 'Transport', icon: 'fas fa-truck', items: [
                    {
                        label: 'Companii',
                        icon: 'fas fa-building-user',
                        routerLink: ['/nomenclatures/transport-company']
                    },
                    {
                        label: 'Tipuri companii',
                        icon: 'fas fa-building-flag',
                        routerLink: ['/nomenclatures/transport-company-types']
                    },
                    { label: 'Soferi', icon: 'fas fa-id-card', routerLink: ['/nomenclatures/transport-unit-drivers'] },
                    {
                        label: 'Tipuri de transport',
                        icon: 'fas fa-cart-flatbed',
                        routerLink: ['/nomenclatures/transport-unit-types']
                    },
                ]
            },


        ];
    }


    addNewEntity(event: any): void {
        //Bug deschide multiple dialogs la press enter (LOOK) some work , others not
        event.target.parentElement.blur();
        this.addNewEntityEvent.emit();
    }
}

