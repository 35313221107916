import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class TableCommunicationService {
    requestComplete$: Observable<any>;
    tableReloadEvent$: Observable<any>;
    tableSetParameters$: Observable<any>;

    public requestComplete = new Subject<any>();
    private tableReloadEvent = new Subject<any>();
    private tableSetParameters = new Subject<any>();

    constructor() {
        this.requestComplete$ = this.requestComplete.asObservable();
        this.tableReloadEvent$ = this.tableReloadEvent.asObservable();
        this.tableSetParameters$ = this.tableSetParameters.asObservable();
    }

    reload(data?: any): void {
        this.tableReloadEvent.next(data);
    }

    setParameters(data: any): void {
        this.tableSetParameters.next(data);
    }


}
