import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { AuthIsAdminService } from '../../guards/auth-is-admin.service';
import { NomenclatureComponent } from './nomenclature.component';
import { FncComponent } from './fnc/fnc.component';
import { CustomersComponent } from './customers/customers.component';
import { SuppliersComponent } from './suppliers/suppliers.component';
import { RouterModule, Routes } from '@angular/router';
import { AppMainComponent } from '../../app.main.component';
import { ProductsComponent } from './products/products.component';
import { StorageSpacesComponent } from './storage/storage-spaces/storage-spaces.component';
import { RawMaterialsComponent } from './raw-materials/raw-materials.component';
import { RecipesComponent } from './recipes/recipes.component';
import { AuthGuardService } from '../../guards/auth-guard.service';
import { TabMenuModule } from 'primeng/tabmenu';
import { MenubarModule } from 'primeng/menubar';
import { MenuModule } from 'primeng/menu';
import { ButtonModule } from 'primeng/button';
import { UsersComponent } from './users/users.component';
import { Table, TableModule, TableService } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { DividerModule } from 'primeng/divider';
import { PaginatorModule } from 'primeng/paginator';
import { SharedModule } from '../../shared/shared.module';
import { UsersActionsTemplateComponent } from './users/users-actions-template/users-actions-template.component';
import { DialogModule } from 'primeng/dialog';
import { UsersAddEditComponent } from './users/users-add-edit/users-add-edit.component';
import { RippleModule } from 'primeng/ripple';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { ChipsModule } from 'primeng/chips';
import { InputMaskModule } from 'primeng/inputmask';
import { CascadeSelectModule } from 'primeng/cascadeselect';
import { PasswordModule } from 'primeng/password';
import { FieldsetModule } from 'primeng/fieldset';
import { CheckboxModule } from 'primeng/checkbox';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { SkeletonModule } from 'primeng/skeleton';
import { ProgressBarModule } from 'primeng/progressbar';
import { CustomersAddEditComponent } from './customers/customers-add-edit/customers-add-edit.component';
import { CustomersActionsTableComponent } from './customers/customers-actions-table/customers-actions-table.component';
import { SuppliersAddEditComponent } from './suppliers/suppliers-add-edit/suppliers-add-edit.component';
import { SuppliersActionTableComponent } from './suppliers/suppliers-action-table/suppliers-action-table.component';
import { FncAddEditComponent } from './fnc/fnc-add-edit/fnc-add-edit.component';
import { FncTableActionComponent } from './fnc/fnc-table-action/fnc-table-action.component';
import { TabViewModule } from 'primeng/tabview';
import {
    CustomersAddressesComponent
} from './customers/customers-add-edit/customers-addresses/customers-addresses.component';
import {
    CustomersContactsComponent
} from './customers/customers-add-edit/customers-contacts/customers-contacts.component';
import { AccordionModule } from 'primeng/accordion';
import { BadgeModule } from 'primeng/badge';
import {
    SupplierContactsComponent
} from './suppliers/suppliers-add-edit/supplier-contacts/supplier-contacts.component';
import {
    StorageSpaceTableActionComponent
} from './storage/storage-spaces/storage-space-table-action/storage-space-table-action.component';
import {
    StorageSpaceAddEditComponent
} from './storage/storage-spaces/storage-space-add-edit/storage-space-add-edit.component';
import {
    StorageTypeAddEditComponent
} from './storage/storage-types/storage-type-add-edit/storage-type-add-edit.component';
import { StorageTypesComponent } from './storage/storage-types/storage-types.component';
import {
    StorageTypeTableActionComponent
} from './storage/storage-types/storage-type-table-action/storage-type-table-action.component';
import { BlockUIModule } from 'primeng/blockui';
import { ProductsAddEditComponent } from './products/products-add-edit/products-add-edit.component';
import { ProductsActionTableComponent } from './products/products-action-table/products-action-table.component';
import { ProductPackingTypeComponent } from './product-packing-type/product-packing-type.component';
import { RawMaterialsAddEditComponent } from './raw-materials/raw-materials-add-edit/raw-materials-add-edit.component';
import {
    RawMaterialsActionTableComponent
} from './raw-materials/raw-materials-action-table/raw-materials-action-table.component';
import {
    ProductPackingTypeAddEditComponent
} from './product-packing-type/product-packing-type-add-edit/product-packing-type-add-edit.component';
import {
    ProductPackingTypeActionTableComponent
} from './product-packing-type/product-packing-type-action-table/product-packing-type-action-table.component';
import { UnitMeasuresComponent } from './unit-measures/unit-measures.component';
import {
    UnitMeasureActionTableComponent
} from './unit-measures/unit-measure-action-table/unit-measure-action-table.component';
import { UnitMeasureAddEditComponent } from './unit-measures/unit-measure-add-edit/unit-measure-add-edit.component';
import { RawMaterialTypesComponent } from './raw-material-types/raw-material-types.component';
import {
    RawMaterialTypesActionTableComponent
} from './raw-material-types/raw-material-types-action-table/raw-material-types-action-table.component';
import {
    RawMaterialTypesAddEditComponent
} from './raw-material-types/raw-material-types-add-edit/raw-material-types-add-edit.component';
import { TransportCompanyTypesComponent } from './transport/transport-company-types/transport-company-types.component';
import {
    TransportCompanyTypesAddEditComponent
} from './transport/transport-company-types/transport-company-types-add-edit/transport-company-types-add-edit.component';
import {
    TransportCompanyTypesActionTableComponent
} from './transport/transport-company-types/transport-company-types-action-table/transport-company-types-action-table.component';
import { TransportUnitTypesComponent } from './transport/transport-unit-types/transport-unit-types.component';
import {
    TransportUnitTypeAddEditComponent
} from './transport/transport-unit-types/transport-unit-type-add-edit/transport-unit-type-add-edit.component';
import {
    TransportUnitTypeActionTableComponent
} from './transport/transport-unit-types/transport-unit-type-action-table/transport-unit-type-action-table.component';
import { TransportUnitDriversComponent } from './transport/transport-unit-drivers/transport-unit-drivers.component';
import {
    TransportUnitDriverAddEditComponent
} from './transport/transport-unit-drivers/transport-unit-driver-add-edit/transport-unit-driver-add-edit.component';
import {
    TransportUnitDriverActionTableComponent
} from './transport/transport-unit-drivers/transport-unit-driver-action-table/transport-unit-driver-action-table.component';
import { RecipeAddEditComponent } from './recipes/recipe-add-edit/recipe-add-edit.component';
import { RecipeActionTableComponent } from './recipes/recipe-action-table/recipe-action-table.component';
import { TransportCompanyComponent } from './transport/transport-company/transport-company.component';
import {
    TransportCompanyAddEditComponent
} from './transport/transport-company/transport-company-add-edit/transport-company-add-edit.component';
import {
    TransportCompanyTableActionComponent
} from './transport/transport-company/transport-company-table-action/transport-company-table-action.component';
import { TransportUnitsComponent } from './transport/transport-units/transport-units.component';
import {
    TransportUnitAddEditComponent
} from './transport/transport-units/transport-unit-add-edit/transport-unit-add-edit.component';
import {
    TransportUnitActionTableComponent
} from './transport/transport-units/transport-unit-action-table/transport-unit-action-table.component';
import { ProductCategoriesComponent } from './product-categories/product-categories.component';
import {
    ProductCategoriesAddEditComponent
} from './product-categories/product-categories-add-edit/product-categories-add-edit.component';
import {
    ProductCategoriesActionTableComponent
} from './product-categories/product-categories-action-table/product-categories-action-table.component';
import {
    SupplierAddressesComponent
} from './suppliers/suppliers-add-edit/supplier-addresses/supplier-addresses.component';
import { CustomerProductsComponent } from './customer-products/customer-products.component';
import { CustomerProductsAddEditComponent } from './customer-products/customer-products-add-edit/customer-products-add-edit.component';
import { CustomerProducsActionTableComponent } from './customer-products/customer-producs-action-table/customer-producs-action-table.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { RawMaterialGroupComponent } from './raw-material-group/raw-material-group.component';
import { RawMaterialGroupAddEditComponent } from './raw-material-group/raw-material-group-add-edit/raw-material-group-add-edit.component';
import { RawMaterialGroupActionTableComponent } from './raw-material-group/raw-material-group-action-table/raw-material-group-action-table.component';
import { EnzymesComponent } from './enzymes/enzymes.component';
import { EnzymesAddEditComponent } from './enzymes/enzymes-add-edit/enzymes-add-edit.component';
import { EnzymesActionTableComponent } from './enzymes/enzymes-action-table/enzymes-action-table.component';
import { CoccibiostaticsComponent } from './coccibiostatics/coccibiostatics.component';
import { CoccibiostaticsAddEditComponent } from './coccibiostatics/coccibiostatics-add-edit/coccibiostatics-add-edit.component';
import { CoccibiostaticsActionTableComponent } from './coccibiostatics/coccibiostatics-action-table/coccibiostatics-action-table.component';

const routes: Routes = [
    { path: 'nomenclatures', redirectTo: 'nomenclatures/fnc', pathMatch: 'full' },
    {
        path: 'nomenclatures', component: AppMainComponent,
        children: [
            { path: 'fnc', component: FncComponent, canActivate: [AuthGuardService], data: {roles : ['Administrator']}},
            { path: 'customers', component: CustomersComponent, canActivate: [AuthGuardService], data: {roles: ['Administrator']} },
            { path: 'customer-products', component: CustomerProductsComponent, canActivate: [AuthGuardService], data: {roles: ['Administrator']}},
            { path: 'suppliers', component: SuppliersComponent, canActivate: [AuthGuardService], data: {roles: ['Administrator']} },
            { path: 'products', component: ProductsComponent, canActivate: [AuthGuardService], data: {roles: ['Administrator']} },
            { path: 'product-packing-types', component: ProductPackingTypeComponent, canActivate: [AuthGuardService] , data: {roles: ['Administrator']}},
            { path: 'storage-types', component: StorageTypesComponent, canActivate: [AuthGuardService], data: {roles: ['Administrator']} },
            { path: 'storage-spaces', component: StorageSpacesComponent, canActivate: [AuthGuardService], data: {roles: ['Administrator']} },
            { path: 'row-materials', component: RawMaterialsComponent, canActivate: [AuthGuardService], data: {roles: ['Administrator']} },
            { path: 'row-material-types', component: RawMaterialTypesComponent, canActivate: [AuthGuardService], data: {roles: ['Administrator']} },
            { path: 'raw-material-groups', component: RawMaterialGroupComponent, canActivate: [AuthGuardService], data: {roles: ['Administrator']} },
            { path: 'units-measure', component: UnitMeasuresComponent, canActivate: [AuthGuardService], data: {roles: ['Administrator']} },
            { path: 'recipes', component: RecipesComponent, canActivate: [AuthGuardService], data: {roles: ['Administrator', 'Laborant']} },
            { path: 'users', component: UsersComponent, canActivate: [AuthGuardService], data: {roles: ['Administrator']} },
            { path: 'enzymes', component: EnzymesComponent, canActivate: [AuthGuardService], data: {roles: ['Administrator']} },
            { path: 'coccibiostatics', component: CoccibiostaticsComponent, canActivate: [AuthGuardService], data: {roles: ['Administrator']} },
            {
                path: 'transport-company-types',
                component: TransportCompanyTypesComponent,
                canActivate: [AuthGuardService], data: {roles: ['Administrator','Logistica']}
            },
            { path: 'transport-company', component: TransportCompanyComponent, canActivate: [AuthGuardService] , data: {roles: ['Administrator','Logistica']}},
            { path: 'transport-unit-types', component: TransportUnitTypesComponent, canActivate: [AuthGuardService] , data: {roles: ['Administrator','Logistica']}},
            {
                path: 'transport-unit-drivers',
                component: TransportUnitDriversComponent,
                canActivate: [AuthGuardService], data: {roles: ['Administrator','Logistica']}
            },
            { path: 'product-categories', component: ProductCategoriesComponent, canActivate: [AuthGuardService] , data: {roles: ['Administrator','Logistica']}},

        ]
    },
];

@NgModule({
    declarations: [
        NomenclatureComponent,
        FncComponent,
        CustomersComponent,
        SuppliersComponent,
        ProductsComponent,
        StorageSpacesComponent,
        RawMaterialsComponent,
        RecipesComponent,
        UsersComponent,
        UsersActionsTemplateComponent,
        UsersAddEditComponent,
        CustomersAddEditComponent,
        CustomersActionsTableComponent,
        SuppliersAddEditComponent,
        SuppliersActionTableComponent,
        FncAddEditComponent,
        FncTableActionComponent,
        CustomersAddressesComponent,
        CustomersContactsComponent,
        SupplierContactsComponent,
        StorageSpacesComponent,
        StorageSpaceTableActionComponent,
        StorageSpaceAddEditComponent,
        StorageTypeAddEditComponent,
        StorageTypesComponent,
        StorageTypeTableActionComponent,
        ProductsAddEditComponent,
        ProductsActionTableComponent,
        ProductPackingTypeComponent,
        RawMaterialsAddEditComponent,
        RawMaterialsActionTableComponent,
        ProductPackingTypeAddEditComponent,
        ProductPackingTypeActionTableComponent,
        UnitMeasuresComponent,
        UnitMeasureActionTableComponent,
        UnitMeasureAddEditComponent,
        RawMaterialTypesComponent,
        RawMaterialTypesActionTableComponent,
        RawMaterialTypesAddEditComponent,
        TransportCompanyTypesComponent,
        TransportCompanyTypesAddEditComponent,
        TransportCompanyTypesActionTableComponent,
        TransportUnitTypesComponent,
        TransportUnitTypeAddEditComponent,
        TransportUnitTypeActionTableComponent,
        TransportUnitDriversComponent,
        TransportUnitDriverAddEditComponent,
        TransportUnitDriverActionTableComponent,
        RecipeAddEditComponent,
        RecipeActionTableComponent,
        TransportCompanyComponent,
        TransportCompanyAddEditComponent,
        TransportCompanyTableActionComponent,
        TransportUnitsComponent,
        TransportUnitAddEditComponent,
        TransportUnitActionTableComponent,
        ProductCategoriesComponent,
        ProductCategoriesAddEditComponent,
        ProductCategoriesActionTableComponent,
        SupplierAddressesComponent,
        CustomerProductsComponent,
        CustomerProductsAddEditComponent,
        CustomerProducsActionTableComponent,
        RawMaterialGroupComponent,
        RawMaterialGroupAddEditComponent,
        RawMaterialGroupActionTableComponent,
        EnzymesComponent,
        EnzymesAddEditComponent,
        EnzymesActionTableComponent,
        CoccibiostaticsComponent,
        CoccibiostaticsAddEditComponent,
        CoccibiostaticsActionTableComponent,
    ],
    imports: [
        RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'}),
        CommonModule,
        TabMenuModule,
        MenubarModule,
        MenuModule,
        ButtonModule,
        TableModule,
        DropdownModule,
        MultiSelectModule,
        FormsModule,
        InputTextModule,
        DividerModule,
        PaginatorModule,
        SharedModule,
        DialogModule,
        RippleModule,
        AutoCompleteModule,
        CalendarModule,
        ChipsModule,
        InputMaskModule,
        CascadeSelectModule,
        PasswordModule,
        FieldsetModule,
        ReactiveFormsModule,
        CheckboxModule,
        ToggleButtonModule,
        SkeletonModule,
        ProgressBarModule,
        ButtonModule,
        TabViewModule,
        AccordionModule,
        BadgeModule,
        BlockUIModule,
        BreadcrumbModule,
        ConfirmDialogModule,
    ],
    providers: [Table, TableService, TransportUnitAddEditComponent]
})
export class NomenclatureModule {
}
