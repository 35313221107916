import { BaseModel, BaseNomenclatorModel } from './base.model';
import { TicketItemModel } from './ticket-item.model';

export class ConsumptionTicketModel extends BaseModel {
    uid: string;
    fnc_id: number;
    documentNo: string;
    code: string;
    documentDate: Date;
    description: string;
    status: number;
    items: Array<TicketItemModel> = [];
}
