import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpApiService} from '../base/http-api.service';
import {ApiResponseModel} from '../../entities/api-response.model';
import {CustomerModel} from '../../entities/customer.model';
import {TableService} from 'primeng/table';

@Injectable({
    providedIn: 'root'
})

export class CustomerService {

    constructor(
        public httpApiService: HttpApiService,
    ) {

    }

    public getList(params?: TableService): Observable<ApiResponseModel>
    {
        return this.httpApiService.httpGet('customers', params);
    }

    public get(uid): Observable<ApiResponseModel>
    {
        return this.httpApiService.httpGet('customers/view', {uid});
    }

    public set(customer: CustomerModel): Observable<ApiResponseModel>
    {
        return this.httpApiService.httpPost('customers/store', customer);
    }

    public delete(uid): Observable<ApiResponseModel> {
        return this.httpApiService.httpDelete('customers/destroy', { uid });
    }

}


