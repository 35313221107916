import { BaseModel, BaseNomenclatorModel } from './base.model';
import { ProductPackingTypeModel } from './product-packing-type.model';
import { UnitMeasureModel } from './unit-measure.model';
import { ProductCategoryModel } from './product-category.model';

export class ProductModel extends BaseNomenclatorModel {
    uid: string;
    name: string;
    code: string;
    customer_product_id: number;
    product_packing_type_id: number;
    product_category_id: number;
    fnc_id: number;
    unit_measure_id: number;
    recipe_id: number;
}


