import { Component, OnInit, ViewChild } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SttTableComponent } from '../../../shared/components/stt-table/stt-table.component';
import { SttTableService } from '../../../shared/components/stt-table/stt-table-service';
import {
    RawMaterialTypesActionTableComponent
} from './raw-material-types-action-table/raw-material-types-action-table.component';
import { RawMaterialTypesAddEditComponent } from './raw-material-types-add-edit/raw-material-types-add-edit.component';
import { RawMaterialTypesModel } from 'src/app/shared/entities/raw-material-types.model';
import { RawMaterialTypeService } from 'src/app/shared/services/app/raw-material-type.service';

@Component({
    selector: 'app-raw-material-types',
    templateUrl: './raw-material-types.component.html',
    styleUrls: ['./raw-material-types.component.scss']
})
export class RawMaterialTypesComponent implements OnInit {
    @ViewChild('table', { static: false }) table: SttTableComponent;

    public tableEndpoint = 'raw-material-types';
    public tableActionComponent = RawMaterialTypesActionTableComponent;
    public tableQuery: any;
    public ref: DynamicDialogRef;

    constructor(public dialogService: DialogService) {
        this.tableQuery = new SttTableService();
    }

    ngOnInit(): void {

    }

    addNewEntity() {
        this.ref = this.dialogService.open(RawMaterialTypesAddEditComponent, {
            header: 'Adaugare Tip materie prima',
            width: '30%',
            contentStyle: { 'max-height': '70vh' },
            baseZIndex: 10000,
            closeOnEscape: false,
            data: null,
        });

        this.ref.onClose.subscribe((raw_material_type: RawMaterialTypesModel) => {
            if (raw_material_type) {
                this.table.refresh();
            }
        });
    }
}
