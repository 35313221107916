import { Injectable } from '@angular/core';
import { Observable , of} from 'rxjs';
import {delay, tap} from 'rxjs/operators';
import { ApiResponseModel } from '../../entities/api-response.model';
import { ProductionNoteModel } from '../../entities/production-note.model';
import { HttpApiService } from '../base/http-api.service';

@Injectable({
    providedIn: 'root'
})
export class ProductionNoteService {

    constructor(
        public httpApiService: HttpApiService,
    ) {

    }

    public get(uid): Observable<ApiResponseModel> {
        return this.httpApiService.httpGet('production-notes/view', {uid});
    }

    public set(item: ProductionNoteModel): Observable<ApiResponseModel> {
        return this.httpApiService.httpPost('production-notes/store', item);
    }

    public getProductStock(productId, fncId): Observable<ApiResponseModel> {
        return this.httpApiService.httpGet('production-notes/view-product-stocks', {productId, fncId});
    }

    public getRecipeRawMaterialStock(recipeId, fncId): Observable<ApiResponseModel> {
        return this.httpApiService.httpGet('production-notes/view-raw-material-stocks', {recipeId, fncId});
    }

    public getRawMaterialStock(rawMaterialId,fncId): Observable<ApiResponseModel> {
        return this.httpApiService.httpGet('production-notes/view-raw-material-stock', {rawMaterialId, fncId});
    }

    public getPNRawMaterialStock(productionNoteId): Observable<ApiResponseModel> {
        return this.httpApiService.httpGet('production-notes/view-raw-material-stocks', {productionNoteId});
    }

    public delete(uid): Observable<ApiResponseModel> {
        return this.httpApiService.httpDelete('production-notes/destroy', { uid });
    }

    public statusChange(status: number, uid): Observable<ApiResponseModel> {
        return this.httpApiService.httpGet('production-notes/change-status', {status, uid});
    }

    public importFile(file: any): Observable<ApiResponseModel>
    {
        return this.httpApiService.httpPost('production-notes/import-file', file);
    }

    public statusFinalized(file: any): Observable<ApiResponseModel> {
        return this.httpApiService.httpPost('production-notes/finalize-status',  file);
    }

    public getLiveDosage(uid): Observable<ApiResponseModel> {
        return this.httpApiService.httpGet('production-notes/view-live-dosage',  { uid });
    }
}


