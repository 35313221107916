import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { SalesComponent } from './sales.component';
import { AppMainComponent } from 'src/app/app.main.component';
import { AuthGuardService } from 'src/app/guards/auth-guard.service';



const routes: Routes = [
    {path: 'sales', redirectTo: 'sales/list', pathMatch: 'full'},
    {
        path: 'sales', component: AppMainComponent,
        children: [
            {path: 'list', component: SalesComponent, canActivate: [AuthGuardService]},
        ]
    },
];

@NgModule({
  declarations: [
    SalesComponent
  ],
  imports: [
    CommonModule,
      RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'}),
  ]
})
export class SalesModule { }
