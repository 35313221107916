import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TransportCompanyModel } from '../../../../../shared/entities/transport-company.model';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { HttpApiService } from '../../../../../shared/services/base/http-api.service';
import { ValidationMessageService } from '../../../../../shared/services/base/validation-message-handle';
import { MessageService } from 'primeng/api';
import { TransportCompanyService } from '../../../../../shared/services/app/transport-company.service';
import { CustomerModel } from '../../../../../shared/entities/customer.model';
import { finalize } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'app-transport-company-add-edit',
    templateUrl: './transport-company-add-edit.component.html',
    styleUrls: ['./transport-company-add-edit.component.scss']
})
export class TransportCompanyAddEditComponent implements OnInit {

    public uid: string = null;
    public transportCompany: TransportCompanyModel = new TransportCompanyModel();
    public loading = false;
    public form: UntypedFormGroup;
    public dropDownWithTrashed = false;

    constructor(
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private formBuilder: UntypedFormBuilder,
        private httpApiService: HttpApiService,
        private validationMessageService: ValidationMessageService,
        private transportCompanyService: TransportCompanyService,
        private messageService: MessageService
    ) {
    }

    ngOnInit(): void {
        this.uid = (this.config.data) ? this.config.data.uid : null;

        this.createForm();

        if (this.uid) {
            this.getItem();
        }

        return;
    }

    onCloseDialog(user: CustomerModel) {
        this.ref.close(user);
    }

    createForm(): void {
        this.form = this.formBuilder.group({
            uid: [this.transportCompany.uid],
            code: [this.transportCompany.code, [Validators.required, Validators.maxLength(200)]],
            name: [this.transportCompany.name, [Validators.required, Validators.maxLength(100)]],
            uniqueCode: [this.transportCompany.uniqueCode, [Validators.required, Validators.maxLength(200)]],
            registrationNumber: [this.transportCompany.registrationNumber, [Validators.required, Validators.maxLength(200)]],
            address: [this.transportCompany.address, [Validators.required, Validators.maxLength(1000)]],
            email: [this.transportCompany.email, [Validators.required, Validators.maxLength(200), Validators.email]],
            phone: [this.transportCompany.phone, [Validators.required, Validators.maxLength(200)]],
            county_id: [this.transportCompany.county_id, Validators.required],
            city_id: [this.transportCompany.city_id, Validators.required],
            type_id: [this.transportCompany.type_id, Validators.required],
            transportUnits: this.formBuilder.array([]),
        });
    }

    getItem(): void {
        this.loading = true;
        this.dropDownWithTrashed = true;
        this.transportCompanyService.get(this.uid)
            .pipe(finalize(() => {this.loading = false; this.dropDownWithTrashed = false;}))
            .subscribe((response: any) => {
                this.transportCompany.map(response.payload);
                this.form.patchValue(this.transportCompany);
            });
    }

    onCancel(): void {
        this.ref.close(null);
    }

    checkIfFormValid(): boolean {
        if (this.form.invalid) {
            this.messageService.add({
                severity: 'error',
                summary: 'Formularul nu este valid!',
                detail: 'Vericficati toate campurile si incercati din nou.'
            });
            this.form.markAllAsTouched();
            return false;
        }
        return true;
    }

    onSave(): void {
        if (!this.checkIfFormValid()) {
            return;
        }
        this.loading = true;
        const data = this.form.getRawValue();

        this.transportCompanyService.set(data)
            .pipe(finalize(() => this.loading = false))
            .subscribe((response: any) => {
                this.transportCompany.map(response.payload);
                this.form.patchValue(this.transportCompany);
                this.messageService.add({
                    severity: 'success',
                    summary: 'Compania de transport a fost salvat cu success!'
                });
                this.ref.close(this.transportCompany);
            }, err => {
                if (err instanceof HttpErrorResponse) {
                    this.validationMessageService.serverSideValidation(err, this.form, '', true);
                }
            });
    }

}
