import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SttTableService } from '../../../shared/components/stt-table/stt-table-service';
import {
    OrdersCustomerActionTableComponent
} from './orders-customer-action-table/orders-customer-action-table.component';
import { OrdersCustomersTransportComponent } from './orders-customers-transport/orders-customers-transport.component';

@Component({
    selector: 'app-orders-customer',
    templateUrl: './orders-customer.component.html',
    styleUrls: ['./orders-customer.component.scss']
})
export class OrdersCustomerComponent implements OnInit {
    public tableEndpoint = 'customer-orders';
    public tableActionComponent = OrdersCustomerActionTableComponent;
    public tableQuery: any;
    public ref: DynamicDialogRef;
    public expandComponent = OrdersCustomersTransportComponent;
    public userRole: string;

    constructor(public dialogService: DialogService, private router: Router) {
        this.tableQuery = new SttTableService()
            .addInclude('customerAddress')
            .addInclude('customer')
            .addInclude('fnc')
            .addInclude('productCategory');
    }

    goToTransportView() {
        this.router.navigate(['/orders/customer/transport-view']);
    }

    goToTransportMap() {
        this.router.navigate(['/orders/customer/transport-map']);
    }

    ngOnInit(): void {

        const role = JSON.parse(localStorage.getItem('user'));
        this.userRole = role.roles[0].name;
    }
}
