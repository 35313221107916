<div class="p-inputgroup">
    <span class="p-inputgroup-addon" *ngIf="icon && iconPosition === 'left'">
        <i [class]="icon"></i>
    </span>
    <div class="p-float-label">
        <p-calendar [formControl]="convertedControl" [minDate]="minimumDate" appendTo="body"
                    [inputId]="inputId" [disabledDays]="disabledDays" (onSelect)="onChange($event)" (onClearClick)="onChangeClear()" (onTodayClick)="onTodayClick()" [readonlyInput]="true"
                    [showButtonBar]="true" [showOnFocus]="showCalendar"></p-calendar>
<!--        dataType="string" dateFormat="dd/mm/yy"-->
        <label [for]="inputId">
            {{ label }} <span *ngIf="hasRequired(control) && label" style="color: red; font-style: italic">*</span>
        </label>
    </div>
    <span class="p-inputgroup-addon" *ngIf="icon && iconPosition === 'right'">
        <i [class]="icon"></i>
    </span>
</div>
<app-stt-validator-message [control]="convertedControl"></app-stt-validator-message>
