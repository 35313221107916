import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResponseModel } from '../../entities/api-response.model';
import { DeliveryNoteModel } from '../../entities/delivery-note.model';
import { HttpApiService } from '../base/http-api.service';

@Injectable({
    providedIn: 'root'
})
export class DeliveryNoteService {

    constructor(
        public httpApiService: HttpApiService,
    ) {

    }

    public getLots(productId): Observable<ApiResponseModel> {
        return this.httpApiService.httpGet('delivery-notes/view-lots', { productId });
    }

    public get(uid): Observable<ApiResponseModel> {
        return this.httpApiService.httpGet('delivery-notes/view', { uid });
    }

    public set(item: DeliveryNoteModel): Observable<ApiResponseModel> {
        return this.httpApiService.httpPost('delivery-notes/store', item);
    }

    public delete(uid): Observable<ApiResponseModel> {
        return this.httpApiService.httpDelete('delivery-notes/destroy', { uid });
    }

    public statusChange(status: number, uid): Observable<ApiResponseModel> {
        return this.httpApiService.httpGet('delivery-notes/change-status', {status, uid});
    }
}
