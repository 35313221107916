import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AppMainComponent } from './app.main.component';
import { ErrorComponent } from './static-pages/error/error.component';
import { AccessDeniedComponent } from './static-pages/access-denied/access-denied.component';
import { NotFoundComponent } from './static-pages/not-found/not-found.component';
import { DashboardComponent } from './app-vitall/dashboard/dashboard.component';
import { NomenclatureModule } from './app-vitall/nomenclature/nomenclature.module';
import { AuthModule } from './auth/auth.module';
import { StockManagementModule } from './app-vitall/stock-management/stock-management.module';
import { SupplyModule } from './app-vitall/supply/supply.module';
import { AuthGuardService } from './guards/auth-guard.service';
import { LogisticsModule } from './app-vitall/logistics/logistics.module';
import { ReportsModule } from './app-vitall/reports/reports.module';
import { SalesModule } from './app-vitall/sales/sales.module';
import { ProductionModule } from './app-vitall/production/production.module';
import { SharedModule } from './shared/shared.module';
import { OrdersModule } from './app-vitall/orders/orders.module';
import { LaboratoryModule } from './app-vitall/laboratory/laboratory.module';

const routes: Routes = [
    { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
    {
        path: '', component: AppMainComponent,
        children: [
            { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuardService] ,
              data :{roles: ['Administrator', 'Gestionar', 'Laborant', 'Achizitii','Logistica','Productie']},}
        ]
    },

    { path: 'error', component: ErrorComponent },
    { path: 'access-denied', component: AccessDeniedComponent },
    { path: 'notfound', component: NotFoundComponent },
    { path: '**', redirectTo: '/notfound' },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
        AuthModule,
        NomenclatureModule,
        LogisticsModule,
        ProductionModule,
        ReportsModule,
        SalesModule,
        StockManagementModule,
        SupplyModule,
        SharedModule,
        OrdersModule,
        LaboratoryModule
    ],
    exports: [RouterModule]
})

export class AppRoutingModule {

}
