<app-nomenclature [addNewEntityButtonLabel]="'Adauga Client'" (addNewEntityEvent)="addNewEntity()"></app-nomenclature>

<p-divider></p-divider>
<p>Clienti</p>
<p-divider></p-divider>
<div class="grid">
    <div class="col-12">
        <div class="card">
            <app-stt-table #table
                    [endpoint]="tableEndpoint"
                    [actionTemplate]="tableActionComponent"
                    [query]="tableQuery">
            </app-stt-table>
        </div>
    </div>
</div>

<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" appendTo="body" closeOnEscape="true" dismissableMask="true" key="deleteCustomer" ></p-confirmDialog>
