import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpApiService } from '../shared/services/base/http-api.service';
import { AuthRequestModel, AuthResponseModel } from './models/auth.model';
import { UserModel } from './models/user.model';
import { RecoverPasswordRequestModel, ResetPasswordRequestModel } from './models/recover-password-request.model';
import { ApiOdataResponseModel, ApiResponseModel } from '../shared/entities/api-response.model';
import { OperatorType, OrderBy, Query } from 'ngx-odata-v4';

@Injectable({
    providedIn: 'root'
})

export class UserService {

    public tokenLocalStorageKey = 'auth-token';
    public redirectUrl = '/';

    constructor(
        public httpApiService: HttpApiService,
        private router: Router,
    ) {

    }

    public checkAuth(): boolean {
        const token = localStorage.getItem(this.tokenLocalStorageKey);

        if (token) {
            // TODO Check if it`s a valid JWT token
            return true;
        } else {
            return false;
        }

    }

    public isAdmin(): boolean {
        const user = this.getUserFromLocalStorage();
        return user.isAdmin;
    }

    public setUserDetails(userDetails: AuthResponseModel): void {
        localStorage.setItem(this.tokenLocalStorageKey, userDetails.access_token);
        localStorage.setItem('user_name', userDetails.user.firstName + ' ' + userDetails.user.lastName);
        localStorage.setItem('auth_expire_in', userDetails.expires_in.toString());
        localStorage.setItem('auth_expire_at', userDetails.expire_at.toString());
        localStorage.setItem('user', JSON.stringify(userDetails.user));
    }

    public getUserFromLocalStorage(): UserModel {
        return new UserModel().map(JSON.parse(localStorage.getItem('user')));
    }

    public getUserNameFromLocalStorage(): string {
        return localStorage.getItem('user_name');
    }

    public setRedirectUrl(url: string): void {
        this.redirectUrl = url;
    }

    public logout(): void {

        localStorage.removeItem(this.tokenLocalStorageKey);
        localStorage.removeItem('user_name');
        localStorage.removeItem('user_type');
        localStorage.removeItem('auth_expire_in');
        localStorage.removeItem('auth_expire_at');
        localStorage.removeItem('user');

        this.router.navigateByUrl('/auth/login');
    }

    public login(authModel: AuthRequestModel): Observable<any> {
        return this.httpApiService.httpPost('auth/login', authModel);
    }

    public register(registerModel): Observable<any> {
        return this.httpApiService.httpPost('auth/register', registerModel);
    }

    public recoverPassword(recoverPasswordRequestModel: RecoverPasswordRequestModel): void {

        this.httpApiService.httpPost('auth/forgot-password', recoverPasswordRequestModel).subscribe((response) => {
            if (response.status === true) {

                /* Swal.fire({
                     title: 'Recuperare parola',
                     html: 'Daca adresa de email exista in sistem, veti primii un link pentru resetarea parolei.',
                     icon: 'success',

                     onClose: () => {
                         this.router.navigateByUrl('/auth/login?email=' + recoverPasswordRequestModel.email);
                     }
                 });*/


            }
        });

    }

    public resetPassword(resetPasswordModel: ResetPasswordRequestModel): void {
        this.httpApiService.httpPost('auth/reset-password', resetPasswordModel).subscribe((response) => {
            if (response.status === true) {
                /*  Swal.fire({
                      title: 'Resetare parola',
                      html: 'Parola a fost resetata cu succes. Te poti autentifica in platforma folosind noua parola.',
                      icon: 'success',

                      onClose: () => {
                          this.router.navigateByUrl('/auth/login');
                      }
                  });*/
            }
        });
    }

    public getUserProfile(uid?: string): Observable<ApiResponseModel> {
        return this.httpApiService.httpGet('users/view', { uid });
    }

    public create(data: UserModel): Observable<ApiResponseModel> {
        return this.httpApiService.httpPost('users/store', data);
    }

    public update(data: UserModel): Observable<ApiResponseModel> {
        return this.httpApiService.httpPost('users/update', data);
    }

    // public delete(data: UserModel): Observable<ApiResponseModel> {
    //     return this.httpApiService.httpDelete('users/destroy', data);
    // }

    public restore(data: UserModel): Observable<ApiResponseModel> {
        return this.httpApiService.httpPost('users/restore', data);
    }

    public getRoles(query?: Query): Observable<ApiOdataResponseModel> {
        return this.httpApiService.httpGet('odata/Roles?' + query.compile());
    }

    public delete(uid): Observable<ApiResponseModel> {
        return this.httpApiService.httpDelete('users/destroy', { uid });
    }

}


