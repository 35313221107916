import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LogisticsComponent } from './logistics.component';
import { RouterModule, Routes } from '@angular/router';
import {AppMainComponent} from '../../app.main.component';
import { AuthGuardService } from 'src/app/guards/auth-guard.service';

const routes: Routes = [
    {path: 'logistics', redirectTo: 'logistics/list', pathMatch: 'full'},
    {
        path: 'logistics', component: AppMainComponent,
        children: [
            {path: 'list', component: LogisticsComponent, canActivate: [AuthGuardService]},
        ]
    },
];

@NgModule({
  declarations: [
    LogisticsComponent
  ],
  imports: [
    CommonModule,
      RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'}),
  ]
})
export class LogisticsModule { }
