import { Component, OnInit, ViewChild } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SttTableComponent } from '../../../../shared/components/stt-table/stt-table.component';
import { SttTableService } from '../../../../shared/components/stt-table/stt-table-service';
import {
    TransportUnitDriverActionTableComponent
} from './transport-unit-driver-action-table/transport-unit-driver-action-table.component';
import {
    TransportUnitDriverAddEditComponent
} from './transport-unit-driver-add-edit/transport-unit-driver-add-edit.component';
import { TransportUnitDriverModel } from 'src/app/shared/entities/transport-unit-driver.model';
import { TransportUnitDriverService } from 'src/app/shared/services/app/transport-unit-driver.service';

@Component({
    selector: 'app-transport-unit-drivers',
    templateUrl: './transport-unit-drivers.component.html',
    styleUrls: ['./transport-unit-drivers.component.scss']
})
export class TransportUnitDriversComponent implements OnInit {

    @ViewChild('table', { static: false }) table: SttTableComponent;

    public tableEndpoint = 'transport-unit-drivers';
    public tableActionComponent = TransportUnitDriverActionTableComponent;
    public tableQuery: any;
    public ref: DynamicDialogRef;

    constructor(private transportUnitDriverService: TransportUnitDriverService, public dialogService: DialogService) {
        this.tableQuery = new SttTableService();
    }

    ngOnInit(): void {

    }

    addNewEntity() {
        this.ref = this.dialogService.open(TransportUnitDriverAddEditComponent, {
            header: 'Adaugare Sofer unitate transport',
            width: '70%',
            contentStyle: { 'max-height': '70vh' },
            baseZIndex: 10000,
            closeOnEscape: false,
            data: null,
        });

        this.ref.onClose.subscribe((item: TransportUnitDriverModel) => {
            if (item) {
                this.table.refresh();
            }
        });
    }
}
