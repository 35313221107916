import { Component, OnInit, ViewChild } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SttTableComponent } from '../../../../shared/components/stt-table/stt-table.component';
import { SttTableService } from '../../../../shared/components/stt-table/stt-table-service';
import {
    TransportUnitTypeActionTableComponent
} from './transport-unit-type-action-table/transport-unit-type-action-table.component';
import {
    TransportUnitTypeAddEditComponent
} from './transport-unit-type-add-edit/transport-unit-type-add-edit.component';
import { TransportUnitTypeModel } from 'src/app/shared/entities/transport-unit-type.model';
import { TransportUnitTypeService } from 'src/app/shared/services/app/transport-unit-type.service';

@Component({
    selector: 'app-transport-unit-types',
    templateUrl: './transport-unit-types.component.html',
    styleUrls: ['./transport-unit-types.component.scss']
})
export class TransportUnitTypesComponent implements OnInit {

    @ViewChild('table', { static: false }) table: SttTableComponent;

    public tableEndpoint = 'transport-unit-types';
    public tableActionComponent = TransportUnitTypeActionTableComponent;
    public tableQuery: any;
    public ref: DynamicDialogRef;

    constructor(private transportUnitTypeService: TransportUnitTypeService, public dialogService: DialogService) {
        this.tableQuery = new SttTableService();
    }

    ngOnInit(): void {

    }

    addNewEntity() {
        this.ref = this.dialogService.open(TransportUnitTypeAddEditComponent, {
            header: 'Adaugare Tip unitate transport',
            width: '30%',
            contentStyle: { 'max-height': '70vh' },
            baseZIndex: 10000,
            closeOnEscape: false,
            data: null,
        });

        this.ref.onClose.subscribe((item: TransportUnitTypeModel) => {
            if (item) {
                this.table.refresh();
            }
        });
    }
}
