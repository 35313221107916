<div [formGroup]="form" class="grid fields-group">
    <ng-container>
        <div class="col-12" style="padding: 0px 10px 0px 15px;" >
            <div><p style="font-size:15px">Cantitate Mapata: </p>{{quantityTransportMapped}}
                / {{form.value.quantity}}</div>
            <br>
            <div *ngFor="let transport of formTransport().controls; let i_transport = index">

                <div class="grid p-fluid justify-content-start" style="margin-top: 10px;">
                    <div class="col-11 grid">
                        <div class="col-3">
                            <ng-container [formGroup]="transport">
                                <app-stt-dropdown
                                    endPoint="transport-companies"
                                    placeholder="Nu ati selectat o companie!"
                                    [formControlParent]="transport.controls['transport_company_id']"
                                    label="Companie Transport"
                                    icon="pi pi-car">
                                </app-stt-dropdown>
                            </ng-container>
                        </div>
                        <div class="col-3">
                            <ng-container [formGroup]="transport">
                                <app-stt-dropdown
                                    endPoint="transport-unit-types"
                                    placeholder="Nu ati selectat o unitate!"
                                    [formControlParent]="transport.controls['transport_unit_type_id']"
                                    label="Tip unitate Transport">
                                </app-stt-dropdown>
                            </ng-container>
                        </div>
                        <div class="col-3">
                            <ng-container [formGroup]="transport">
                                <app-stt-dropdown
                                    endPoint="transport-unit-drivers"
                                    placeholder="Nu ati selectat un sofer!"
                                    [fields]=" { text: 'firstName', value: 'id'}"
                                    [formControlParent]="transport.controls['transport_unit_driver_id']"
                                    label="Sofer Transport"
                                    icon="pi pi-users"
                                >
                                </app-stt-dropdown>
                            </ng-container>
                        </div>
                        <div class="col-3">
                            <ng-container [formGroup]="transport">
                                <app-stt-input inputId="quantityPerTransport" [control]="transport.controls['transport_quantity']"
                                               label="Cantitate pe transport" icon="pi pi-box" type="number"></app-stt-input>
                            </ng-container>
                        </div>
                    </div>
                        <div class="col-1">
                            <div *ngIf="i_transport === (formTransport().value.length - 1)">
                                <p-button [disabled]="formTransport().at(i_transport).invalid"
                                          styleClass="p-button-success"
                                          (onClick)="checkAddTransport(i_transport)"> Adauga
                                </p-button>
                            </div>
                        </div>
                </div>
            </div>
            <div class="grid p-fluid">
                <div class="col-11">
                    <p-progressBar [value]="progressValue"></p-progressBar>
                </div>
            </div>
        </div>
        <div class="flex-box" style="display:flex;justify-content:space-between;">

        </div>
        <div class="actions-area">
            <div class="actions-right">
                <p-button styleClass="p-button-success" class="saveButton" (click)="onSave()" [ngStyle]="{'padding-left': '15px'}"> Aloca Transport</p-button>
            </div>
        </div>
    </ng-container>
</div>
