import { BaseNomenclatorModel } from './base.model';
import { ProductionNoteOpenOrderModel } from './production-note-open-order.model';
import { ProductionNoteRawMaterialModel } from './production-note-raw-material.model';

export class ProductionNoteModel extends BaseNomenclatorModel {
    uid: string;
    product_id: number;
    lot: string;
    documentNo: string;
    code: number;
    fnc_id: number;
    startDate: Date;
    endDate: Date;
    sarja: number;
    productionNoteCustomerOrderItem: Array<ProductionNoteOpenOrderModel>;

    production_quantity: number;
    current_stock: number;

    productionNoteItems: Array<ProductionNoteRawMaterialModel>;
}
