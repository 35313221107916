<div class="p-inputgroup">
    <span class="p-inputgroup-addon" *ngIf="icon && iconPosition === 'left'">
        <i [ngClass]="icon"></i>
    </span>
    <div class="p-float-label">
        <p-password *ngIf="type === 'password'; else input" [id]="inputId"
                    [formControl]="convertedControl"></p-password>
        <ng-template #input>
            <input [type]="type" [id]="inputId" [formControl]="convertedControl" [readOnly]="readonly"
                   [class.read-only]="readonly" pInputText>
        </ng-template>
        <label [for]="inputId">
            {{ label }} <span *ngIf="hasRequired(control) && label" style="color: red; font-style: italic">*</span>
        </label>
    </div>
    <span class="p-inputgroup-addon" *ngIf="icon && iconPosition === 'right'">
        <i [ngClass]="icon"></i>
    </span>
</div>
<app-stt-validator-message [control]="control" [inputType]="validatorType"></app-stt-validator-message>
