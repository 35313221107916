import { Component, OnInit } from '@angular/core';
import {
    ProductCategoriesAddEditComponent
} from '../../product-categories/product-categories-add-edit/product-categories-add-edit.component';
import { ProductCategoryModel } from '../../../../shared/entities/product-category.model';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { SttTableComponent } from '../../../../shared/components/stt-table/stt-table.component';
import { CustomerProductsAddEditComponent } from '../customer-products-add-edit/customer-products-add-edit.component';
import { CustomerProductModel } from '../../../../shared/entities/customer-product.model';
import { ConfirmationService, MessageService, PrimeNGConfig } from 'primeng/api';
import { ValidationMessageService } from '../../../../shared/services/base/validation-message-handle';
import { CustomerProductsService } from '../../../../shared/services/app/customer-products.service';
import { finalize } from "rxjs/operators";


@Component({
  selector: 'app-customer-producs-action-table',
  templateUrl: './customer-producs-action-table.component.html',
  styleUrls: ['./customer-producs-action-table.component.scss']
})
export class CustomerProducsActionTableComponent implements OnInit {

    public data: CustomerProductModel;
    public table: SttTableComponent;
    public ref: DynamicDialogRef;
    public loading = true;

  constructor(public dialogService: DialogService,
              public confirmationService: ConfirmationService,
              public primengConfig: PrimeNGConfig,
              public itemService: CustomerProductsService,
              public messageService: MessageService,
              private validationMessageService: ValidationMessageService,) {

  }

  ngOnInit(): void {
  }

    setParams(entity: CustomerProductModel): void {
        this.data = entity;
    }

    setTableReference(table: SttTableComponent): void
    {
        this.table = table;
    }

    showDialog() {
        this.ref = this.dialogService.open(CustomerProductsAddEditComponent, {
            header: 'Modificare Produse Client',
            width: '70%',
            contentStyle: {'max-height': '70vh'},
            baseZIndex: 10000,
            closeOnEscape: false,
            data: this.data,
        });

        this.ref.onClose.subscribe((item: CustomerProductModel) => {
            if (item) {
                this.table.refresh();
            }
        });
    }

    confirm() {
        this.confirmationService.confirm({
            header: 'Confirmare stergere Produs Client',
            message: 'Sunteti sigur ca doriti sa stergeti acest Produs client?',
            key: 'deleteCustomerProduct',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Da',
            rejectLabel: 'Nu',
            acceptButtonStyleClass: 'p-button-md p-button-success',
            rejectButtonStyleClass: 'p-button-md p-button-danger',
            accept: () => {
                this.deleteItem(this.data.uid);
            },
            reject: () => {
                this.confirmationService.close();
                this.table.refresh();
            }
        });
    }

    deleteItem(uid: string){
        this.itemService.delete(uid)
            .pipe(finalize(() => {this.loading = false;this.table.refresh();}))
            .subscribe((response: any) => {
                this.messageService.add({
                    severity: 'success',
                    summary: 'Produsul de client a fost sters cu success!'
                });
            });
    }

}
